import urls from "../../app-js/urls";
import axios from "../../app-js/axios";
// import { showSnackBar } from "../../components/common/snackbar";

const state = {
  category_list: [],
  services_list: [],
  customDurationData: null,
  toggle_description_modal: {
    flag: false,
    data: "",
  },
};
const getters = {
  get_category_list: (state) => state.category_list,
  get_services_list: (state) => state.services_list,
  get_custom_duration_data: (state) => state.customDurationData,
  getToggleDescriptionDrawer: (state) => state.toggle_description_modal,
};

const mutations = {
  LIST_CATEGORY(state, payload) {
    state.category_list = payload;
  },
  LIST_SERVICES(state, payload) {
    state.services_list = payload;
  },
  FILTER_SERVICES(state, payload) {
    state.services_list = payload;
  },
  SET_CUSTOM_DURATION_DATA(state, payload) {
    state.customDurationData = payload;
  },
  SET_DESCRIPTION_MODAL(state, payload) {
    state.toggle_description_modal = payload;
  },
};
const actions = {
  ListCategory({ commit, dispatch }) {
    let parlour_id = localStorage.getItem("parlour_id");
    let params = {
      parlour_id: parlour_id,
    };
    const successHandler = (response) => {
      let category_list = response.data.service_category_list;
      commit("LIST_CATEGORY", category_list);
    };
    const failureHandler = (error) => {
      // if (error && error.data && error.data.message) {
      //   showSnackBar(error.data.message, "#B3261E", 1500);
      // }
      dispatch("snackbar/showSnack", {
        flag: true,
        text: error.data.message,
        color: "#B3261E",
        timeout: 1500,
      });
    };
    return axios("get", urls.LIST_CATEGORY, {
      params,
      onSuccess: successHandler,
      onFailure: failureHandler,
      isTokenRequired: true,
    });
  },
  ListServices({ commit, dispatch }) {
    let parlour_id = localStorage.getItem("parlour_id");
    let params = {
      parlour_id: parlour_id,
    };
    const successHandler = (response) => {
      let services_list = response.data.service_list;
      commit("LIST_SERVICES", services_list);
    };
    const failureHandler = (error) => {
      dispatch("snackbar/showSnack", {
        flag: true,
        text: error.data.message,
        color: "#B3261E",
        timeout: 1500,
      });
    };
    return axios("get", urls.LIST_SERVICE, {
      params,
      onSuccess: successHandler,
      onFailure: failureHandler,
      isTokenRequired: true,
    });
  },
  callFilterServicesApi({ commit, dispatch }, item_id) {
    this.selectedItem = item_id;
    let parlour_id = localStorage.getItem("parlour_id");
    let params = {
      parlour_id: parlour_id,
      category: item_id,
    };
    const successHandler = (response) => {
      let service_list = response.data.service_list;
      commit("FILTER_SERVICES", service_list);
    };
    const failureHandler = (error) => {
      dispatch("snackbar/showSnack", {
        flag: true,
        text: error.data.message,
        color: "#B3261E",
        timeout: 1500,
      });
    };
    return axios("get", urls.LIST_SERVICE, {
      params,
      onSuccess: successHandler,
      onFailure: failureHandler,
      isTokenRequired: true,
    });
  },
  setCustomDurationData({ commit }, payload) {
    commit("SET_CUSTOM_DURATION_DATA", payload);
  },
  toogleDescriptionModal({ commit }, payload) {
    commit("SET_DESCRIPTION_MODAL", payload);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_value"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="528"
      height="100vh"
    >
      <v-card>
        <v-card elevation="1" class="fixed_header">
          <v-card-title class="text-center">
            <v-icon color="black" @click="close_drawer"
              >mdi-close-circle</v-icon
            >
            <span class="ma-auto">Reschedule Appointment</span>
          </v-card-title>
        </v-card>
        <div class="middle_content">
          <v-form ref="new_appointment_drawer">
            <v-card elevation="1" class="mt-5 mx-2 pb-2">
              <v-card-text>
                <p class="header">CLIENT</p>
                <v-divider></v-divider>
              </v-card-text>
              <v-autocomplete
                readonly
                outlined
                class="mx-3"
                dense
                :items="client_list"
                item-value="id"
                item-text="full_name"
                placeholder="Select a client or create new"
                v-model="selectedClient"
                :rules="[$rules.required]"
              >
                <!-- <template v-slot:item="{ item }">
                                    <v-list-item-group>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.full_name }} - {{ item.mobile }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </template> -->
              </v-autocomplete>
              <!-- <p class="mx-4 client_text d-flex align-center jutify-center" @click="openCreateClientDialog"><v-icon class="mr-2" color="primary">mdi-plus-circle</v-icon>Add Client
                            </p> -->
            </v-card>
            <v-card class="mt-5 mx-2">
              <v-card-text>
                <div class="d-flex">
                  <p class="header">DATE & TIME OF APPOINTMENT</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="sub_text_2nd_card">Doesn't Repeat
                                    </p> -->
                </div>
                <v-divider></v-divider>
              </v-card-text>
              <!-- Date Field -->
              <v-col cols="12" sm="6" md="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      outlined
                      dense
                      placeholder="Choose Date"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :active-picker.sync="activePicker"
                    @change="save"
                    event-color="#F2797B"
                    v-model="date"
                    no-title
                    scrollable
                    :min="currentDate()"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="#F2797B" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="#F2797B" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- Time Field -->
              <!-- <v-menu ref="time_menu" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="time" transition="scale-transition" offset-y max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined dense class="mx-3" v-model="time" label="Select Time"
                                        prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                        :rules="[$rules.required]"></v-text-field>
                                </template>
                                <v-time-picker v-if="menu2" v-model="time" full-width format="ampm"
                                    @click:minute="$refs.time_menu.save(time)"></v-time-picker>
                            </v-menu> -->
              <v-col cols="12" sm="6" md="12">
                <v-row class="d-flex">
                  <v-col md="3.5">
                    <v-autocomplete
                      v-model="hrs"
                      :items="hours"
                      outlined
                      dense
                      placeholder="HH"
                    ></v-autocomplete>
                  </v-col>
                  <v-col md="3.5">
                    <v-autocomplete
                      placeholder="MM"
                      v-model="mins"
                      :items="minutes"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col md="3.5" class="pt-2">
                    <v-btn-toggle
                      class="d-flex"
                      active-class="activeDiscBtn"
                      v-model="selectedAMPM"
                      rounded
                      mandatory
                    >
                      <v-btn class="rupee_dis_btn" value="AM">AM</v-btn>
                      <v-btn class="percent_dis_btn" value="PM">PM</v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
            <!--  -->
            <v-card class="mt-5 mx-2">
              <v-card-text>
                <p class="header">EXECUTIVES</p>
                <v-divider> </v-divider>
              </v-card-text>
              <v-select
                clearable
                dense
                class="ma-2"
                outlined
                :items="executive_list"
                item-value="id"
                item-text="full_name"
                placeholder="Select an executive"
                v-model="selectedExecutive"
                :rules="[$rules.required]"
              >
              </v-select>
            </v-card>
            <!--  -->
            <v-card class="mt-5 mx-2">
              <v-card-text>
                <p class="header">SERVICES</p>
                <v-divider></v-divider>
                <div
                  class=""
                  v-for="(item, index) in service_cart_list"
                  :key="index"
                >
                  <div class="d-flex align-center my-3">
                    <div>
                      <div>
                        <p class="mb-0 service_title">
                          {{ item.service_item.service_name }}
                        </p>
                        <p class="mb-2 service_sub_title">
                          {{ item.service_item.duration }}
                          {{ item.service_item.duration_type }}
                          <v-icon>mdi-circle-small</v-icon> ₹ {{ item.price }}
                        </p>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn outlined text class="multiple_services">
                      <v-icon
                        dark
                        left
                        color="#F2797B"
                        @click="
                          addOrDeleteServices(item.quantity, 'minus', item.id)
                        "
                      >
                        mdi-minus-circle
                      </v-icon>
                      {{ item.quantity }}
                      <v-icon
                        dark
                        right
                        color="#F2797B"
                        @click="
                          addOrDeleteServices(item.quantity, 'add', item.id)
                        "
                      >
                        mdi-plus-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions class="d-flex align-center mt-n2">
                <v-btn
                  text
                  dense
                  class="text_3rd_card text-capitalize"
                  @click="openSelectServiceModal()"
                  ><v-icon class="icon_3rd_card" left>mdi-plus-circle</v-icon>
                  Add A Service</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
          <v-card class="mt-5 mx-2">
            <v-card-text>
              <p class="header">NOTES</p>
              <v-divider> </v-divider>
            </v-card-text>
            <v-text-field
              placeholder="Add an appointment note"
              class="ma-2 mx-4"
              v-model="notes"
              @input="notes = capitalize(notes)"
            ></v-text-field>
          </v-card>
        </div>
        <v-row class="fixed_bottom mx-0">
          <v-col cols="12">
            <v-btn
              block
              class="booking_btn my-8 text-capitalize"
              @click="updateBooking()"
            >
              Save Changes
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <clientModalMob @callApi="getClientList"></clientModalMob>
    <selectServiceDrawerMob></selectServiceDrawerMob>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import clientModalMob from "./clientModalMob.vue";
import selectServiceDrawerMob from "./selectServiceDrawerMob.vue";
export default {
  data() {
    return {
      name: "updateMobAppointmentDrawer",
      menu: "",
      date: "",
      activePicker: null,
      dateFormatted: "",
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
      selectedExecutive: "",
      advance_amount: 1000,
      notes: "",
      payment_mode: 1,
      selectedClient: "",
      client_list: [],
      executive_list: [],
      time: null,
      menu2: false,
      current_booking_id: null,
      number_of_service: 1,
      cart_list: [],
      filtered_services_id_list: [],
      // service_disable: true,
      booking_details: [],
      hours: Array.from({ length: 12 }, (_, i) =>
        String(i + 1).padStart(2, "0")
      ),
      // minutes: [
      //   "00",
      //   ...Array.from({ length: 59 }, (_, i) => String(i + 1).padStart(2, "0")),
      // ],
      minutes: ["00", "15", "30", "45"],
      hrs: "",
      mins: "00",
      selectedAMPM: "",
    };
  },
  components: {
    clientModalMob,
    selectServiceDrawerMob,
  },
  computed: {
    ...mapGetters({
      dialog: "appointment/getUpdateAppointmentMobile",
      appointment_details: "appointment/getAppointmentDetail",
      service_cart_lists: "appointment/get_cart_list",
    }),
    dialog_value: {
      get() {
        return this.dialog;
      },
      set(newValue) {
        return newValue;
      },
    },
    service_cart_list: {
      get() {
        return this.service_cart_lists;
      },
      set(newValue) {
        this.$store.dispatch("appointment/setValueOfCartList", newValue);
      },
    },
  },
  watch: {
    selectedClient(val) {
      this.$store.dispatch("appointment/setSelectedClientData", val);
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    service_cart_list(val) {
      this.filtered_services_id_list = val.map((current_value) => {
        return current_value.id;
      });
    },
    appointment_details(val) {
      if (val) {
        this.booking_details = val;
        this.current_booking_id = val?.id;
        this.selectedClient = val?.client.id;

        this.dateFormatted = this.formatDate(val?.appointment_date);
        this.date = val?.appointment_date;
        this.se = val?.services;
        this.service_cart_list = this.se;
        this.selectedExecutive = val.executive;
        this.notes = val.note;
        // this.time = val.appointment_start_time
        this.hrs = this.extractHrs(val.appointment_service_start_time);
        this.mins = this.extractMins(val.appointment_service_start_time);
        this.selectedAMPM = this.extractAmPm(
          val.appointment_service_start_time
        );
      }
    },
    dialog_value(value) {
      if (value == true) this.getClientList();
    },
  },
  mounted() {
    this.getClientList();
    this.getExecutiveList();
  },
  methods: {
    extractHrs(time) {
      const [hrs] = time.split(":");
      return hrs;
    },

    extractMins(time) {
      const [, mins] = time.split(":");
      return mins.split(" ")[0]; // Remove the AM/PM part
    },
    extractAmPm(time) {
      const [, ampm] = time.split(" ");
      return ampm;
    },
    convertTo24HourFormat(dateString, hours, minutes, ampm) {
      const [year, month, day] = dateString.split("-");
      const seconds = "00"; // Always set seconds to '00'
      if (ampm === "PM" && hours != 12) {
        hours = parseInt(hours) + 12;
      } else if (ampm === "AM" && hours === 12) {
        hours = 0;
      }
      return `${year}-${month}-${day}T${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    close_drawer() {
      this.$store.dispatch("appointment/toggleUpdateAppointmentMob", false);
      // clear the data after closing
      this.$refs.new_appointment_drawer.reset();
      this.notes = "";
      this.$store.dispatch("appointment/clear_cart_list");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    currentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
      const day = String(today.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    openCreateClientDialog() {
      let data = {
        toggle_flag: true,
        clientAddOrEditFlag: "",
      };
      this.$store.dispatch("dialog/setNewClientDrawer", data);
    },
    getClientList(new_client_id) {
      this.selectedClient = new_client_id;
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        status: "all",
      };
      const successHandler = (response) => {
        this.client_list = response.data.client_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_CLIENT_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getExecutiveList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.executive_list = response.data.executive_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.LIST_EXECUTIVE, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    updateBooking() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        id: this.current_booking_id,
      };
      let data = {
        parlour: parlour_id,
        client: this.selectedClient,
        appointment_date: this.date,
        appointment_start_time: this.convertTo24HourFormat(
          this.date,
          this.hrs,
          this.mins,
          this.selectedAMPM
        ),
        // "services": this.selectedCheckboxId,
        executive: this.selectedExecutive.id,
        note: this.notes,
        service_cart_item: this.filtered_services_id_list,
      };
      const successHandler = (response) => {
        this.client_list = response.data.client_list;
        let payload = {
          status: "upcoming",
        };
        this.$store.dispatch("appointment/getBookingList", payload);
        this.$store.dispatch("appointment/toggleUpdateAppointmentMob", false);
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("patch", this.$apiUrl.UPDATE_BOOKING, {
        params: params,
        data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    openSelectServiceModal() {
      let payload = {
        parlour_id: "",
        client_id: this.selectedClient,
        search: "",
        booking_id: this.current_booking_id,
      };
      this.$store.dispatch(
        "appointment/getListOfCategoriesAndServices",
        payload
      );
      let data = {
        flag: true,
        opened_from: "updateAppointmentDrawer",
      };
      this.$store.dispatch("appointment/toogleSelectServiceDrawer", data);
    },
    Calculatedtime() {
      const combinedDateTime = `${this.date}T${this.time}:00`;
      return combinedDateTime;
    },
    addOrDeleteServices(quantity, action_name, service_id) {
      var updated_quantity;
      if (action_name === "minus" && quantity != 0) {
        updated_quantity = quantity - 1;
      } else if (action_name === "add") {
        updated_quantity = quantity + 1;
      }
      let params = {
        id: service_id,
      };
      let data = {
        quantity: updated_quantity,
      };
      const successHandler = () => {
        // call get api for cart
        let payload = {
          client_id: this.selectedClient,
          appointment: this.current_booking_id,
        };
        this.$store.dispatch("appointment/get_service_cart_list", payload);
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("patch", this.$apiUrl.ADD_OR_DELETE_SERVICES, {
        params: params,
        data: data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>

<style scoped>
.activeDiscBtn {
  color: #f2797b !important;
}

.v-autocomplete >>> .v-input__append-inner {
  display: none !important;
}

.client_text {
  cursor: pointer !important;
}

.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-color: #f2797b;
}

.service_title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.service_sub_title {
  color: grey;
  font-weight: 400;
  font-size: 14px;
}

.service_cost {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.sheet {
  background-color: #fafafa;
}

.header {
  font-size: 12px;
  font-weight: 600;
}

.text_3rd_card {
  color: rgb(242, 121, 123);
  cursor: pointer;
}

.v-text-field .v-input__slot {
  border-radius: 200px !important;
}

.icon_3rd_card {
  color: rgb(242, 121, 123);
}

.booking_btn {
  background-color: rgb(242, 121, 123) !important;
  color: white !important;
}
</style>

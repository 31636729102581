<template>
    <v-row justify="center">
        <v-dialog v-model="flag" persistent max-width="528" height="200">
            <v-card>
                <v-card-title class="d-flex align-center justify-center">
                    <span class="text-h6">Service Details</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <!-- text-field -->
                            <v-col cols="12" sm="6" md="12" class="d-flex flex-column align-center justify-center">
                                <div>
                                    <p><span class="title">Service Name</span> <v-text-field dense outlined readonly
                                            v-model="service_data.service_name"></v-text-field></p>
                                    <p><span class="title">Duration Of service</span><v-text-field dense outlined
                                            v-model="service_data.duration"></v-text-field></p>
                                    <p><span class="title">Cost Of Service</span><v-text-field dense outlined
                                            v-model="service_data.price"></v-text-field></p>
                                    <p><span class="title">Description</span><v-textarea outlined class="description"
                                            v-model="service_data.description"></v-textarea></p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <!-- <v-divider></v-divider> -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="EditStatus()">
                        Yes
                    </v-btn>
                    <v-btn @click="closeModal()" class="text-capitalize white--text" color="#F2797B">
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "ServiceDescription",
    data() {
        return {
            flag: "",
            service_data: ""
        }
    },
    computed: {
        ...mapGetters({
            dialog: "services/getToggleDescriptionDrawer",
        }),
    },
    watch: {
        dialog(val) {
            this.flag = val.flag
            this.service_data = val.data
        }
    },
    methods: {
        closeModal() {
            let payload = {
                flag: false,
                data: ""
            }
            this.$store.dispatch("services/toogleDescriptionModal", payload)
        },
    }
}
</script>
<style scoped>
.v-text-field>>>.v-text-field__details {
    display: none !important;
}

.description {
    word-wrap: normal
}

.title {
    color: black !important;
    font-weight: 500;
}

label {
    color: black !important
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}

.flag_img {
    width: 20px;
    height: 20px;
}
</style>
<template>
  <div>
    <div>
      <v-tabs
        grow
        height="95px"
        active-class="mob-active-tab"
        color="#F2797B"
        v-model="current_tab_index"
        slider-color="#F2797B"
        class="taba"
      >
        <v-tab
          v-for="(item, index) in tab_name"
          :key="index"
          @click="changeTabTitle(item)"
          class="d-flex flex-column"
        >
          <div>{{ item.text }}</div>
          <div>
            <span v-if="item.text === 'New Enquiries'">
              {{ total_enquiries }}</span
            >
            <span v-else-if="item.text === 'Follow Ups'">
              {{ total_follow_ups }}</span
            >
            <span v-else-if="item.text === 'Irrelevant'">
              {{ total_irrelevant }}</span
            >
            <span v-else-if="item.text === 'Enquiries Booked'">
              {{ enquiry_booked }}</span
            >
          </div>
        </v-tab>
      </v-tabs>
      <v-text-field
        dense
        outlined
        hide-details
        placeholder="Search enquiries"
        class="mt-5 mx-2 search_bar"
        prepend-inner-icon="mdi-magnify"
        v-model="searchQuery" clearable
      ></v-text-field>
      <!-- <v-select :items="status_items" outlined dense placeholder="Status :" v-model="selected_status"
                @change="getListOfAllEnquiries" prepend-inner-icon="mdi-filter-outline" class="filter"></v-select> -->

      <!-- skeleton loader -->

      <v-row v-if="listLoader === true" class="my-4 mx-2">
        <v-col cols="12">
          <v-skeleton-loader max-width="400" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader max-width="400" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <div
        v-if="listLoader == false && enquiry_list?.length === 0"
        class="d-flex align-center justify-center"
      >
        No Data Available
      </div>
      <div v-if="listLoader == false && enquiry_list?.length > 0">
        <v-card
          class="my-4 mx-2"
          v-for="(item, index) in enquiry_list"
          :key="index"
        >
          <v-card-title class="d-flex align-center justify-center">
            <v-avatar color="#CE6161" class="white--text">{{
              getInitialsOfName(item.name)
            }}</v-avatar>
            <div class="ml-2">
              <p class="name">{{ item.name }}</p>
              <p class="number">{{ item.mobile }}</p>
            </div>
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              {{ item.created }}
            </v-chip>
          </v-card-title>
          <v-divider> </v-divider>
          <v-card-text v-if="current_tab_name === 'irrelevant'">
            <!-- <div>
              On:<span class="query_data">{{ item.created }}</span>
            </div> -->
            <div class="d-flex align-center my-2">
              <div class="mr-2 font-weight-bold">Reason:</div>
              <div class="query_data">
                {{ item.irrelevant_reason }}
              </div>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <!-- <div>
              On:<span class="query_data">{{ item.created }}</span>
            </div> -->
            <div class="d-flex align-center">
              <div class="mr-2 my-2 font-weight-bold">Query:</div>
              <div class="query_data">
                {{ item.query }}
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions
            v-if="
              current_tab_name !== 'irrelevant' &&
              current_tab_name !== 'enquiry_booked'
            "
          >
            <v-row>
              <v-col class="pb-0">
                <v-btn
                  block
                  outlined
                  color="#F2797B"
                  class="text-capitalize"
                  @click="handleAction(action_list[0], item.id)"
                >
                  {{ action_list[0] }}</v-btn
                >
              </v-col>
              <v-col>
                <v-btn
                  block
                  class="create_booking_btn text-capitalize"
                  @click="handleAction(action_list[1], item.id)"
                  >{{ action_list[1] }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </div>
      <v-fab-transition>
        <v-btn
          color="pink"
          dark
          absolute
          top
          right
          fab
          class="fab_btn"
          @click="openEnquiryModal()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
    <EnquiryModalMobile></EnquiryModalMobile>
    <IrrelevantModal></IrrelevantModal>
    <ConfirmationModal></ConfirmationModal>
    <appointment-drawer-mob @reload-enquiry-list="getListOfAllEnquiries()" />
    <select-service-drawer-mob />
  </div>
</template>
<script>
import EnquiryModalMobile from "../../components/enquiry/EnquiryModalMobile.vue";
import IrrelevantModal from "@/components/enquiry/IrrelevantModal.vue";
import ConfirmationModal from "@/components/enquiry/ConfirmationModal.vue";
import appointmentDrawerMob from "@/components/appointments/mobile_view/appointmentDrawerMob.vue";
import selectServiceDrawerMob from "@/components/appointments/mobile_view/selectServiceDrawerMob.vue";
import { mapGetters } from "vuex";
export default {
  name: "EnquiryMobile",
  components: {
    EnquiryModalMobile,
    ConfirmationModal,
    IrrelevantModal,
    appointmentDrawerMob,
    selectServiceDrawerMob,
  },
  data() {
    return {
      listLoader: false,
      current_tab_name: "new_enquiry",
      current_tab_index: "",
      current_action_name: "",
      current_action_id: "",
      total_enquiries: "",
      total_follow_ups: "",
      total_irrelevant: "",
      enquiry_booked: "",
      action_list: [],
      enquiry_list: [],
      params: null,
      initials_of_name: "",
      tab_name: [
        {
          text: "New Enquiries",
          align: "start",
          sortable: false,
          value: "new_enquiry",
          // class: "primary_2 white--text",
        },
        {
          text: "Follow Ups",
          align: "start",
          sortable: false,
          value: "follow_ups",
        },
        {
          text: "Irrelevant",
          align: "start",
          sortable: false,
          value: "irrelevant",
        },
        {
          text: "Enquiries Booked",
          align: "start",
          sortable: false,
          value: "enquiry_booked",
        },
      ],
      action: "",
      data: null,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      triggerGetListApi: "enquiry_details/getEnquiryListApiFlag",
    }),
    ...mapGetters({
      triggerUpdateListApi: "enquiry_details/getUpdateEnquiryListApiFlag",
    }),
    ...mapGetters({
      moveToIrrelevant: "enquiry_details/getMoveToIrrelevantFlag",
    }),
    ...mapGetters({
      reason_id: "enquiry_details/getReasonId",
      reason_text: "enquiry_details/getCustomReasonText",
    }),
  },
  watch: {
    searchQuery() {
      this.filterEnquiryList();
    },
    triggerGetListApi() {
      this.getListOfAllEnquiries();
    },
    triggerUpdateListApi() {
      this.updateListOfAllEnquiries(
        this.current_action_name,
        this.current_action_id
      );
    },
    moveToIrrelevant() {
      this.updateListOfAllEnquiries(this.current_action_name, this.reason_id);
    },
  },
  mounted() {
    this.getListOfAllEnquiries();
  },
  methods: {
    getInitialsOfName(name) {
      return this.$initials(name);
    },
    getTableHeaders() {
      if (this.current_tab_name === "irrelevant") {
        return [
          {
            text: "DATE",
            align: "start",
            sortable: false,
            value: "created",
          },
          {
            text: "CLIENT_NAME",
            align: "start",
            sortable: false,
            value: "name",
          },
          {
            text: "MOBILE",
            align: "start",
            sortable: false,
            value: "mobile",
          },
          {
            text: "REASON",
            align: "start",
            sortable: false,
            value: "reason",
          },
        ];
      } else {
        return [
          {
            text: "DATE",
            align: "start",
            sortable: false,
            value: "created",
          },
          {
            text: "CLIENT_NAME",
            align: "start",
            sortable: false,
            value: "name",
          },
          {
            text: "MOBILE",
            align: "start",
            sortable: false,
            value: "mobile",
          },
          {
            text: "QUERY",
            align: "start",
            sortable: false,
            value: "query",
          },
          {
            text: "ACTION",
            align: "start",
            sortable: false,
            value: "actions",
          },
        ];
      }
    },
    openEnquiryModal() {
      this.$store.dispatch("dialog/setNewEnquiryDrawerMob", true);
    },
    changeTabTitle(item) {
      this.current_tab_name = item.value;
      this.getListOfAllEnquiries();
    },
    handleAction(action_name, id) {
      this.current_action_name = action_name;
      this.current_action_id = id;
      if (action_name === "Create Booking") {
        this.$store.dispatch(
          "appointment/toggleCreateBookingMobileDrawer",
          true
        );
        this.$store.dispatch("appointment/setCurrentEnquiry_id", id);
      } else if (action_name === "Follow Up Done") {
        this.$store.dispatch("dialog/setConfirmationModal");
      } else if (action_name === "Mark As Irrelevant") {
        this.$store.dispatch("dialog/setIrrelevant");
      }
    },
    // updateListOfAllEnquiries
    updateListOfAllEnquiries(action_name, id) {
      this.listLoader = true;
      if (action_name === "Mark As Irrelevant") {
        var irrelevant_type = id;
        this.action = "irrelevant";
        this.data = {
          enquiry_status: this.action,
          irrelevant_type: irrelevant_type,
        };
        let field_id = this.current_action_id;
        this.params = { id: field_id };
      } else if (action_name === "Follow Up Done") {
        this.action = "follow_ups";
        this.data = {
          enquiry_status: this.action,
        };
        this.params = { id };
      }
      // make params dynamic

      const successHandler = (response) => {
        this.listLoader = false;
        this.total_enquiries = response.data.new_enquiry;
        this.total_follow_ups = response.data.follow_ups;
        this.total_irrelevant = response.data.irrelevant;
        // this.enquiry_booked = response?.data?.enquiry_booked;
        // console.log('enquiry', response.data.enquiry_booked);
        this.enquiry_list = response.data.enquiry_list;
        this.action_list = response.data.actions;
        if (action_name === "Mark As Irrelevant") {
          this.$store.dispatch("dialog/setIrrelevant");
          this.getListOfAllEnquiries();
        } else if (action_name === "Follow Up Done") {
          this.$store.dispatch("dialog/setConfirmationModal");
          this.getListOfAllEnquiries();
        }
      };
      const failureHandler = (error) => {
        this.showSnackBar(error.data.message, "#B3261E", 1500);
        this.listLoader = false;
      };
      return this.$axios("patch", this.$apiUrl.UPDATE_ENQUIRY_LIST, {
        params: this.params,
        data: this.data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // api calling for enquiries list
    getListOfAllEnquiries() {
      this.listLoader = true;
      let parlour_id = localStorage.getItem("parlour_id");
      let status = this.current_tab_name;
      let params = {
        parlour_id: parlour_id,
        status: status,
        "search": this.searchQuery,
      };
      const successHandler = (response) => {
        this.total_enquiries = response.data.new_enquiry;
        this.total_follow_ups = response.data.follow_ups;
        this.total_irrelevant = response.data.irrelevant;
        this.enquiry_booked = response.data.enquiry_booked;
        this.enquiry_list = response.data.enquiry_list;
        if (response?.data?.actions) this.action_list = response?.data?.actions;
        this.show_Loader = false;
        this.listLoader = false;
      };
      const failureHandler = (error) => {
        this.showSnackBar(error.data.message, "#B3261E", 1500);
        this.listLoader = false;
      };
      return this.$axios("get", this.$apiUrl.GET_ENQUIRY_LIST, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    filterEnquiryList() {
      this.listLoader = true;
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        status: this.current_tab_name,
        search: this.searchQuery,
      };
      const successHandler = (response) => {
        this.total_enquiries = response.data.new_enquiry;
        this.total_follow_ups = response.data.follow_ups;
        this.total_irrelevant = response.data.irrelevant;
        this.enquiry_list = response.data.enquiry_list;
        this.action_list = response.data.actions;
        this.show_Loader = false;
        this.listLoader = false;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
        this.listLoader = false;
      };
      return this.$axios("get", this.$apiUrl.GET_ENQUIRY_LIST, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>
<style scoped>
.row {
  display: block !important;
}

p {
  margin-bottom: 0px !important;
}

.fab_btn {
  position: fixed;
  left: 80vw;
  bottom: 5vh;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: 76vh !important;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #39434d;
  margin-bottom: -10px !important;
}

.search_bar >>> .v-text-field__details {
  display: none !important;
}

.v-tabs >>> .v-slide-group__prev {
  display: none !important;
}

.number {
  font-size: 12px;
  font-weight: 500;
  color: #697498;
}

.query_data {
  font-size: 12px;
  font-weight: 500;
  color: #39434d;
}

.create_booking_btn {
  background-color: #f2797b !important;
  color: white;
}

.search_bar {
  background-color: white !important;
}
</style>

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#F2797B",
        // primary: "#003399", // changed
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#E50000",
        new_error: "#B3261E",
        success: "#4CA955",
        secondary_2: "#F18324",
        primary_2: "#194E76",
        accent_2: "#D3D3D3",
        warning: "#F2C94C",
        table_header: "#fab376",
        table_header_2: "#fdd9bb",
        table_header_3: "#fef2e8",
        tab_header: "#fcf7ea",
      },
    },
  },
});

<template>
    <v-app v-if="$isDesktop">
        <Drawer></Drawer>
        <v-main class="main_body pl-0">
            <SnackBar></SnackBar>
            <NetworkIssue></NetworkIssue>

            <Body></Body>
        </v-main>
    </v-app>
    <v-app v-else>
        <v-main class="mob-main-body pb-0">
            <SnackBar></SnackBar>

            <Body />
        </v-main>
        <Drawer />
    </v-app>
</template>
<script>
import NetworkIssue from "../common/NetworkIssue.vue"
import SnackBar from "../common/SnackBar.vue"
import Body from "./Body.vue"
import Drawer from "../layout/Drawer.vue"
export default {
    name: "Base",
    components: {
        Body,
        Drawer,
        SnackBar,
        NetworkIssue
    },
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.main_body {
    background-color: #FAFAFA;
    height: 100% !important;
    /* max-height: 100vh !important; */
    /* overflow:hidden !important; */
}

.mob-main-body {
    background-color: #FAFAFA;
    width: 100vh !important;
}

/* .v-main__wrap {
    flex:none !important
} */
</style>
<template>
  <div>
    <!-- 1st Card -->
    <v-card class="pa-5 ma-2 profile_card">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar size="52" color="#F2797B" class="white--text mr-2"
            >AJ</v-avatar
          >
          <div>
            <p class="current-user_name">{{ user_details.name }}</p>
            <p class="current_user_role">{{ user_details.role }}</p>
          </div>
        </div>
        <v-icon right class="justify-right" @click="openProfilePage()"
          >mdi-chevron-right</v-icon
        >
      </div>
      <v-divider class="my-5"></v-divider>
      <div class="d-flex align-center justify-center">
        <v-btn
          text
          class="text-capitalize"
          @click="logout_user"
          color="#8C1D18"
        >
          <v-icon left dark> mdi-logout </v-icon> Logout
        </v-btn>
      </div>
    </v-card>
    <!-- 2nd Card -->
    <v-card elevation="2" class="tabs_card ma-2 py-2">
      <v-simple-table>
        <tbody>
          <tr
            v-for="(item, index) in more_tabs"
            :key="index"
            @click="navigateToUrl(item.title)"
          >
            <td>{{ item.title }}</td>
            <td>
              <v-icon right class="justify-right">mdi-chevron-right</v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <LogOutConfirmation></LogOutConfirmation>
  </div>
</template>
<script>
import LogOutConfirmation from "@/components/profile/LogOutConfirmation.vue";
import { mapGetters } from "vuex";
export default {
  name: "MoreTabs",
  data() {
    return {
      more_tabs: [
        {
          title: "Clients",
        },
        {
          title: "Services",
        },
        {
          title: "Team Management",
        },
        {
          title: "Reports",
        },
      ],
    };
  },
  components: {
    LogOutConfirmation,
  },
  computed: {
    ...mapGetters({ user_details: "profile/get_current_user_details" }),
  },
  methods: {
    logout_user() {
      this.$store.dispatch("dialog/setLogoutConfirmationModal");
    },
    navigateToUrl(tab) {
      if (tab === "Clients") {
        this.$router.push({ name: "client" });
      } else if (tab === "Services") {
        this.$router.push({ name: "services" });
      } else if (tab === "Team Management") {
        this.$router.push({ name: "team-management" });
      } else if (tab === "Reports") {
        this.$router.push({ name: "reports" }).catch(() => {});
      }
    },
    openProfilePage() {
      this.$router
        .push({
          name: "profile",
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.current-user_name {
  font-size: 20px !important;
  color: black;
  font-weight: 500;
}

.current_user_role {
  font-size: 14px !important;
  color: #9397a7;
  font-weight: 400;
}

p {
  margin-bottom: 0px;
}

.tabs_card,
.profile_card {
  border-radius: 11px !important;
}
</style>

<template>
    <div>
        <EnquiryDesktop v-if="$isDesktop"></EnquiryDesktop>
        <EnquiryMobile v-if="$isMobile"></EnquiryMobile>
    </div>
</template>
<script>
import EnquiryDesktop from "../../components/enquiry/EnquiryDesktop.vue"
import EnquiryMobile from '@/components/enquiry/EnquiryMobile.vue';
export default {
    name: "enquiry",
    data() {
        return {

        }
    },
    components: {
        EnquiryDesktop,
        EnquiryMobile
    }
}
</script>
<style scoped></style>
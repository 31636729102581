<template>
    <div>
        <ClientDesktop v-if="$isDesktop"></ClientDesktop>
        <ClientMobile v-if="$isMobile"></ClientMobile>
    </div>
</template>
<script>
import ClientDesktop from '@/components/clients/ClientDesktop.vue';
import ClientMobile from "../../components/clients/ClientMobile.vue"
export default {
    name: "client",
    data() {
        return {

        }
    },
    components: {
        ClientDesktop,
        ClientMobile
    }
}
</script>
<style scoped></style>
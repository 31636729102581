<template>
    <div>
        <v-container class="pa-md-12 px-0 margin-top">
            <v-card elevation="3" class="px-5 py-8" style="border-radius:28px">
                <v-form ref="parlour_details_form">
                    <v-row class="d-flex" dense>
                        <v-col md="6" >
                            <v-img v-if="$isDesktop" src="../../assets/bruCC.svg" class="welcome_img">
                            </v-img>
                            <v-img v-else src="../../assets/brushLogo.png" height="90" max-width="89" class="mx-auto"></v-img>
                        </v-col>
                      
                        <v-col md="6" sm="12" xs="12" class="margin">
                            <v-row class="d-block">
                                <v-col>
                                    <p class="sub_text_1 mt-8 mr-20" v-if="$isDesktop">
                                        Fill your professional details
                                    </p>
                                    <p class="mob_header ml-10" v-else>
                                        Fill your professional details
                                    </p>
                                    <div class="d-flex justify-center align-center" style="cursor:pointer">
                                        <v-avatar size="106" v-if="file_url"> <img :src=file_url alt="error">
                                            <v-icon color="red" class="pb-4 pl-3 delete-icon"
                                                @click="delete_logo">mdi-trash-can</v-icon>
                                        </v-avatar>
                                        <v-avatar v-else class="avatar_bg_color" size="106" style="position: relative;"
                                            @click="triggerAvatarInputField()">
                                            <div>Logo</div>
                                            <v-img src="../../assets/camera_icon.svg" class="pb-5 camera-icon"></v-img>
                                        </v-avatar>
                                    </div>
                                    <input type="file" accept="image/*" ref="input_field_avatar" @change="upload_logo"
                                        style="visibility: hidden;" />
                                </v-col>
                                <v-col class="margin-top">
                                    <label>
                                        Parlour Name*
                                    </label>
                                    <v-text-field outlined dense placeholder="Enter the name of your parlor"
                                        v-model.trim="parlour_details.parlour_name"
                                        :rules="[$rules.name_3, $rules.required, $rules.noSpaces]"
                                        @input="parlour_details.parlour_name = capitalize(parlour_details.parlour_name)">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="mt-n5">
                                    <label>
                                        First Name*
                                    </label>
                                    <v-text-field outlined dense placeholder=" First name"
                                        v-model="parlour_details.first_name"
                                        @input="parlour_details.first_name = capitalize(parlour_details.first_name)"
                                        :rules="[$rules.name_2, $rules.required, $rules.noSpaces]">
                                    </v-text-field>
                                </v-col>
                                <v-col class="mt-n5">
                                    <label>
                                        Last Name*
                                    </label>
                                    <v-text-field outlined dense placeholder="Last name"
                                        v-model="parlour_details.last_name"
                                        :rules="[$rules.name_2, $rules.required, $rules.noSpaces]"
                                        @input="parlour_details.last_name = capitalize(parlour_details.last_name)">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="mt-n5">
                                    <label>
                                        Country*
                                    </label>
                                    <!-- country text-field -->
                                    <v-text-field readonly dense outlined placeholder="Please select country"
                                        class="md-mr-0 mr-2" v-model="selected_country" prepend-inner-icon
                                        :rules="[$rules.required, $rules.noSpaces]"
                                        @input="selected_country = capitalize(selected_country)">
                                        <template #prepend-inner>
                                            <div class="d-flex align-center justify-center">
                                                <v-img :src="selected_flag" class="flag_img"></v-img> &nbsp;
                                            </div>
                                            <v-spacer></v-spacer>
                                            <v-menu offset-y>
                                                <!-- <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template> -->
                                                <v-list>
                                                    <v-list-item v-for="(item, index) in country_list" :key="index"
                                                        @click="selectedCountry(item)">
                                                        <v-list-item-title>{{ item.name
                                                            }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-text-field>
                                    <!-- text-field -->
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="mt-n5">
                                    <label>
                                        Location*
                                    </label>
                                    <v-text-field outlined dense placeholder=" Address Of your establishment"
                                        v-model="parlour_details.location" class="location"
                                        :rules="[$rules.required, $rules.noSpaces]"
                                        @input="parlour_details.location = capitalize(parlour_details.location)">
                                        <template v-slot:append>
                                            <v-icon style="cursor:pointer" @click="openLocationModal"
                                                color="primary">mdi-crosshairs-gps</v-icon>
                                        </template>
                                    </v-text-field>
                                    <!-- {{parlour_details.location}}
                                    <GmapAutocomplete :key="autocompleteInputValue"
                                         @place_changed='setPlace' placeholder="Address Of your establishment"
                                        v-model="parlour_details.location" class="autocomplete_field" :rules="[$rules.required]"
                                        @input="parlour_details.location = capitalize(parlour_details.location)"/> -->
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn block color="#F2797B" class="white--text text-capitalize btn_mobile"
                                    @click="navigateToGalleryPage()">Next
                                </v-btn>
                                <!-- <v-img src="../../assets/stepper.svg" class="mt-3" style="height:20px; width:10px">
                                </v-img> -->
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
            <LocationModal class="location_modal"></LocationModal>
            <SnackBar></SnackBar>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import SnackBar from "../common/SnackBar.vue"
import LocationModal from "../signup/LocationModal.vue"
export default {
    name: "ParlourDetail",
    data() {
        return {
            parlour_details: {
                logo: "",
                parlour_name: "",
                first_name: "",
                last_name: "",
                country: 1,
                location: "",
            },
            country_list: [],
            selected_flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
            selected_country: "India",
            file_url: ""
        }
    },
    components: {
        LocationModal,
        SnackBar
    },
    computed: {
        ...mapGetters({ map_address: 'parlour_details/getMapAdrress' }),
    },
    watch: {
        map_address(val) {
            this.parlour_details.location = val.address
        }
    },
    methods: {
        openLocationModal() {
            this.$store.dispatch("dialog/setLocation", true);
        },
        triggerAvatarInputField() {
            this.$refs.input_field_avatar.click();
        },
        upload_logo() {
            let selected_file = this.$refs.input_field_avatar.files.item(0);
            this.parlour_details.logo = selected_file
            var full_file_type = selected_file.type
            var file_type_array = full_file_type.split("/")
            var file_type = file_type_array[0]
            console.log("file is", file_type)
            const reader = new FileReader();
            reader.onload = (event) => {
                this.file_url = event.target.result;
            };
            reader.readAsDataURL(selected_file);
        },
        // getListOfPhoneCodes api calling
        getListOfPhoneCodes() {
            const successHandler = (response) => {
                this.country_list = response.data.phone_code_list
            }
            const failureHandler = (error) => {
                if (error) {
                    console.log("error is ", error)
                }
            }
            return this.$axios("get", this.$apiUrl.PHONE_CODE, {
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: false,
            });
        },
        selectedCountry(item) {
            this.selected_flag = item.flag;
            this.selected_country = item.name;
            this.parlour_details.country = item.id;
        },
        getParlourDetails() {
            let parlour_id = localStorage.getItem("parlour_id")
            let params = {
                id: parlour_id // Adding parlour_id as a query parameter
            }
            const successHandler = (response) => {
                this.parlour_details = response.data.parlour_detail
            }
            const failureHandler = (error) => {
                if (error) {
                    console.log("error is ", error)
                }
            }
            return this.$axios("get", this.$apiUrl.GET_PARLOUR_DETAILS, {
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
        navigateToGalleryPage() {
            // if (!this.file_url && this.$refs.parlour_details_form.validate()) {
            //     this.showSnackBar("Please add the logo", "#E50000", 2000);
            // }


            if (this.$refs.parlour_details_form.validate()) {
                this.$store.dispatch("parlour_details/setParlourInfo", this.parlour_details)
                this.$emit("childBtnClick", "2");
            }
        },
        delete_logo() {
            this.file_url = ""
        }
    },
    mounted() {
        this.getListOfPhoneCodes()
        this.getParlourDetails()
    }
}
</script>
<style scoped>
/* .v-input>>>.v-text-field__details {
    display: none !important;
} */
.autocomplete_field {
    border: solid 2px black;
    width: 405px !important
}

.flag_img {
    width: 20px;
    height: 20px;
}

.v-avatar {
    overflow: visible !important;
}

.avatar_bg_color {
    position: relative !important;
    top: 0 !important;
    left: 0;
    border: solid 1px black
        /* background: linear-gradient(to bottom, #33ccff 0%, #ff33cc 100%); */
}

.camera-icon {
    position: absolute;
    top: 70px;
    right: 5px;
    width: 29px;
    height: 29px;
    /* z-index: 999 !important; */
    /* Ensure the camera icon is on top */
}

.delete-icon {
    position: absolute;
    top: 90px;
    right: 5px;
    width: 29px;
    height: 29px;
    z-index: 999;
}

.avatar_bg_color {
    position: relative !important;
    top: 0 !important;
    left: 0;
    /* background: linear-gradient(to bottom, #33ccff 0%, #ff33cc 100%); */
}

.camera_icon>>>.v-responsive__sizer {
    padding-bottom: 9px !important;
}

.camera_icon>>>.v-image__image--cover {
    background-size: auto !important;
    background-position: right !important;

}

/* .welcome_img {
    width: 474px;
} */

.logo_img {
    max-width: 185.96px !important;
    height: 58px !important;
}

.sub_text_1 {
    font-weight: 700;
    font-size: 34px;
    color: #404C57;
    line-height: 1.2 !important;
}

@media all and (min-width: 900px) {
    .margin {
        margin-top: -44px !important;
    }

    .margin-top {
        margin-top: -36px !important;
    }
}

@media all and (max-width: 600px) {
    .mob_header {
        font-weight: 700;
        color: black;
        font-size: 20px;
    }

    .camera_icon {
        position: absolute;
        top: 319px !important;
        right: 133px !important;
        z-index: 1 !important;
    }

    .btn_mobile {
        margin-bottom: 20px !important;
    }
}
</style>
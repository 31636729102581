<template>
    <div>
        <reportsDesktop v-if="$isDesktop"></reportsDesktop>
        <reportsMobile v-else></reportsMobile>
    </div>
</template>

<script>
import reportsDesktop from '@/components/reports/reportsDesktop.vue'
import reportsMobile from "@/components/reports/reportsMob.vue"
export default {
    name: "reports",
    components: {
        reportsDesktop,
        reportsMobile
    }
}
</script>
<style scoped></style>
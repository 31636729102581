<template>
    <div>
        <DashboardDesktop v-if="$isDesktop"></DashboardDesktop>
        <DasboardMobile v-if="$isMobile"></DasboardMobile>
    </div>
</template>
<script>
import DashboardDesktop from '@/components/dashboard/DashboardDesktop.vue';
import DasboardMobile from '@/components/dashboard/DasboardMobile.vue';
export default {
    name: "dashboard",
    components: {
        DashboardDesktop,
        DasboardMobile
    }
}
</script>
<style scoped></style>
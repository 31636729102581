<template>
    <div>
        <div class="d-flex flex-column justify-center" v-if="$isDesktop">
            <v-row class="ma-0">
                <v-col class="d-flex align-center ml-10">
                    <p class="module_title mb-0">{{ $route.meta.title }}</p>
                </v-col>
                <v-col class="d-flex justify-end mr-7">
                    <v-btn class="text-capitalize white--text ma-5" color="#F2797B" @click="openClientModal">
                        <v-icon left>
                            mdi-plus-circle-outline
                        </v-icon>Add Client
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="ma-0 ml-10">
                <v-col md="3">
                    <v-text-field outlined dense hide-details placeholder="Search Clients"
                        prepend-inner-icon="mdi-magnify" v-model="searchQuery" />
                </v-col>
                <v-spacer>
                </v-spacer>
                <!-- <v-col md="3" class="mr-10">
                    <v-select clearable :items="status_items" outlined dense placeholder="Status :"
                        v-model="selected_status" @change="getClientList" class="filter">
                        <template v-slot:prepend-inner>
                            <div class="d-flex align-center">
                                <v-icon>mdi-filter-outline</v-icon>
                                <span class="mr-1">Status</span>
                                <span class="mr-1">:</span>
                                    </div>
                                </template>
                        </v-select>
                        </v-col> -->
            </v-row>
            <v-row>
                <v-col md="12" class="mr-15">

                    <!-- Skeleton Loader -->
                    <v-skeleton-loader v-if="show_Loader" class="mx-auto" max-width="1190"
                        type="card"></v-skeleton-loader>

                    <!-- Data Table -->
                    <v-data-table v-else :headers="client_headers" :items="client_list"
                        :items-per-page="20" class="elevation-1 mx-12">
                        <template v-slot:[`item.id`]="{ item }">
                            <router-link :to="{ name: 'client-details', params: { id: item.id } }">{{ item.sequence_num
                                }}</router-link>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip class="ma-2" color="#E6FBD9" text-color="green">
                                {{ item.status }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <CreateClientModal @callApi="getClientList"></CreateClientModal>
        </div>
    </div>
</template>
<script>
import CreateClientModal from '@/components/clients/CreateClientModal.vue'
export default {
    name: "client",
    data() {
        return {
            client_headers: [
                {
                    text: 'CLIENT ID',
                    value: 'id',
                    align: 'start',
                    sortable: false,
                },
                { text: 'CLIENT NAME', value: 'full_name', sortable: false, },
                { text: 'GENDER', value: 'gender', sortable: false, },
                { text: 'MOBILE', value: 'mobile', sortable: false, },
                { text: 'LAST BOOKED ON', value: 'last_booked_on', sortable: false, },
            ],
            client_list: [],
            status_items: ["all", "active", "inactive"],
            selected_status: "all",
            searchQuery: "",
            show_Loader: true
        }
    },
    watch: {
        searchQuery() {
            this.getClientList();
        }
    },
    components: {
        CreateClientModal
    },
    mounted() {
        this.getClientList();
    },
    methods: {
        openClientModal() {
            let data = {
                toggle_flag: true,
                clientAddOrEditFlag: ""
            }
            this.$store.dispatch("dialog/setNewClientDrawer", data)
        },
        getClientList() {
            let parlour_id = localStorage.getItem("parlour_id");
            let params = {
                "parlour_id": parlour_id,
                "status": this.selected_status,
                "search": this.searchQuery
            }
            const successHandler = (response) => {
                this.show_Loader = false
                this.client_list = response.data.client_list.map((client, index) => ({
                    ...client,
                    sequence_num: index + 1
                }));

            }
            const failureHandler = (error) => {
                if (error && error.data && error.data.message) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("get", this.$apiUrl.GET_CLIENT_LIST, {
                params: params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
    }
}
</script>
<style scoped>
.v-data-table>>>.v-data-table__wrapper table thead tr th {
    color: #333333 !important;
    font-size: 14px !important;
}

.v-data-table>>>.v-data-table__wrapper table td {
    color: #333333 !important;
    font-weight: 550 !important;
    font-size: 14px !important;
}

.filter {
    width: 225px !important;
}

.v-data-table>>>.v-data-table-header {
    background-color: #FBF1F2 !important;
}

.actionBtn {
    border-radius: 10px;
    padding: 6px 10px 6px 10px !important;
    width: 79px !important;
    font-size: 12px !important;
}

.name {
    font-weight: 600;
    font-size: 14px;
}

.number {
    font-size: 12px;
    font-weight: 500;
    color: #697498;
    margin-top: -12px;
}

.data_status {
    width: 325.33px;
    height: 162px;
    border-radius: 16px;
    background-color: #FFEDED !important;
}

.tab {
    width: 341.33px;
    height: 120px;
    border: solid 1px;
    border-color: #DFE1E3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.active-tab {
    background-color: #F2797B;
    color: white !important
}

.mob-active-tab {
    color: #F2797B;
}

.v-application .pt-4 {
    padding-top: 0px !important;
}
</style>
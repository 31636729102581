import Vue from "vue";
import Vuex from "vuex";
import snackbar from "./modules/snackbar";
import dialog from "./modules/dialog";
import parlour_details from "./modules/parlour_details";
import enquiry_details from "./modules/enquiry_details";
import profile from "../store/modules/profile";
import services from "./modules/services";
import team from "./modules/team";
import appointment from "./modules/appointment";
import client from "./modules/client";
import reports from "./modules/reports"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    snackbar,
    dialog,
    profile,
    parlour_details,
    enquiry_details,
    services,
    team,
    appointment,
    client,
    reports
  },
});

<template>
  <v-row justify="center">
    <v-dialog
      v-model="drawer"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="528"
      height="100vh"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Client's Details</span>
          <v-spacer></v-spacer>
          <span>
              <v-icon color="black" large @click="closeModal">mdi-close</v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="post_client_detail_form">
              <v-row dense>
                <v-col cols="12" sm="6" md="12">
                  <label>Mobile Number*</label>
                  <v-text-field
                    @keypress="isNumber($event)"
                    maxlength="10"
                    dense
                    outlined
                    placeholder="Please enter mobile number"
                    class="md-mr-0 mr-2"
                    v-model="mobile"
                    prepend-inner-icon
                    :rules="[$rules.mobile_number]"
                  >
                    <template #prepend-inner>
                      <!-- {{ selected_flag }} -->
                      <v-img :src="selected_flag" class="flag_img"></v-img>
                      &nbsp;
                      <p class="mt-1">{{ selected_phone_code }}</p>
                      &nbsp;
                      <!-- <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template>
<v-list>
    <v-list-item v-for="(item, index) in country_list" :key="index" @click="selectPhoneCode(item)">
        <v-list-item-title>{{ item.phone_code }} {{ item.name
            }}</v-list-item-title>
    </v-list-item>
</v-list>
</v-menu> -->
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>First Name*</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="Enter your first name"
                    required
                    v-model="first_name"
                    :rules="[$rules.name_2]"
                    @input="first_name = capitalize(first_name)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>Last Name*</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="Enter your last name"
                    v-model="last_name"
                    :rules="[$rules.name_2]"
                    @input="last_name = capitalize(last_name)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Gender*</label>
                  <v-select
                    clearable
                    dense
                    outlined
                    :items="['Male', 'Female', 'Other']"
                    placeholder="Select a gender"
                    required
                    v-model="gender"
                    :rules="[$rules.required]"
                  ></v-select>
                </v-col>
               
                <v-col cols="12" sm="6" md="12">
                  <label>Email</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="Enter your email"
                    v-model="email"
                    :rules="[$rules.Email]"
                  ></v-text-field>
                </v-col>

                <!-- Date Picker -->
                <v-col cols="12" sm="6" md="12">
                  <label>Birthday</label>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        readonly
                        outlined
                        dense
                        placeholder="Please select your birth date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="save"
                      event-color="#F2797B"
                      v-model="date"
                      :active-picker.sync="activePicker"
                      no-title
                      scrollable
                      min="1963-08-31"
                      :max="currentDate()"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#F2797B" @click="closeModal()">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#F2797B"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#F2797B"
            class="text-capitalize"
            @click="closeModal"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="AddClient"
            class="text-capitalize white--text"
            color="#F2797B"
          >
            <v-icon left> mdi-plus-circle-outline </v-icon> Add Client
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "clientModal",
  data() {
    return {
      menu: "",
      date: "",
      dateFormatted: "",
      parlour: null,
      first_name: "",
      last_name: "",
      gender: "",
      country: "103",
      email: "",
      mobile: "",
      birthday_date: "1998-05-12",
      country_list: [],
      selected_flag:
        "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      selected_country: "India",
      selected_phone_code: "+91",
      activePicker: null,
    };
  },
  computed: {
    ...mapGetters({
      drawer: "appointment/getClientDrawerMobile",
    }),
  },
  mounted() {
    this.getListOfPhoneCodes();
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    closeModal() {
      this.$store.dispatch("appointment/setNewClientDrawerMob", false);
      this.$refs.post_client_detail_form.reset();
       this.menu = "";
          this.date = "";
          this.first_name = "";
          this.last_name = "";
          this.gender = "";
          this.email = "";
          this.mobile = "";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    currentDate() {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      const year = yesterday.getFullYear();
      const month = String(yesterday.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
      const day = String(yesterday.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    AddClient() {
      if (this.$refs.post_client_detail_form.validate()) {
        this.parlour = localStorage.getItem("parlour_id");
        let data = {
          parlour: this.parlour,
          first_name: this.first_name,
          last_name: this.last_name,
          gender: this.gender,
          country: this.country,
          email: this.email,
          mobile: this.mobile,
          birthday_date: this.date,
        };
        const successHandler = (response) => {
          this.$store.dispatch("appointment/setNewClientDrawerMob", false);
          let new_client_id = response.data.response.id;
          this.$emit("callApi", new_client_id);
          this.$store.dispatch(
            "appointment/setSelectedClientData",
            new_client_id
          );
          this.$refs.post_client_details_form.reset();
          this.menu = "";
          this.date = "";
          this.first_name = "";
          this.last_name = "";
          this.gender = "";
          this.email = "";
          this.mobile = "";
          this.birthday_date = "1998-05-12";
        };
        const failureHandler = (error) => {
          if (error && error.data && error.data.message) {
            this.showSnackBar(error.data.message, "#B3261E", 1500);
          }
        };
        const finallyHandler = () => {};
        return this.$axios("post", this.$apiUrl.CREATE_CLIENT, {
          data,
          onSuccess: successHandler,
          onFailure: failureHandler,
          onFinally: finallyHandler,
          isTokenRequired: true,
        });
      }
    },
    // getListOfPhoneCodes api calling
    getListOfPhoneCodes() {
      const successHandler = (response) => {
        this.country_list = response.data.phone_code_list;
      };
      const failureHandler = () => {};
      return this.$axios("get", this.$apiUrl.PHONE_CODE, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: false,
      });
    },
    selectPhoneCode(item) {
      this.selected_flag = item.flag;
      this.selected_country = item.name;
      this.country = item.id;
      this.selected_phone_code = item.phone_code;
    },
  },
};
// }
</script>

<style scoped>
.mdi-chevron-down::before {
  margin-top: -8px !important;
}

label {
  color: black !important;
}

.flag_img {
  width: 20px;
  height: 20px;
}

.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 20px !important;
}
</style>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_value"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-sheet class="sheet">
        <v-card elevation="1" class="fixed_header">
          <v-card-title class="text-center">
            <v-icon color="black" @click="close_drawer"
              >mdi-close-circle</v-icon
            >
            <span class="ma-auto">Complete Appointment</span>
          </v-card-title>
        </v-card>
        <div class="middle_content">
          <v-card elevation="1" class="mt-5 mx-2">
            <div class="user_info_div d-flex align-center">
              <div class="">
                <v-avatar
                  color="#F2797B"
                  size="46"
                  class="logged_in_user_avatar white--text ma-2"
                >
                  {{ initials }}
                </v-avatar>
              </div>
              <div>
                <p class="logged_in_user_name">{{ client_full_name }}</p>
                <p v-if="booking_detail" class="logged_in_user_number">
                  client since {{ booking_detail.client_joining_date }}
                </p>
              </div>
            </div>
          </v-card>
          <v-card
            class="mt-3 mx-2"
            v-for="(item, index) in selected_appointments"
            :key="index"
          >
            <v-card-title>
              <p class="title">
                SERVICES (ORDER ID #{{ item.booking_ref_id }} )
              </p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="selected_appointments">
              <div>
                <div
                  class="d-flex align-center my-3"
                  v-for="(subItem, subIndex) in item.service_detail"
                  :key="subIndex"
                >
                  <div>
                    <p class="mb-0 service_title">{{ subItem.service_name }}</p>
                    <p class="mb-0 service_sub_title">{{ subItem.duration }}</p>
                  </div>
                  <v-spacer></v-spacer>
                  <p>₹{{ subItem.price }}</p>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <v-card class="mt-3 mx-2">
            <v-card-title>
              <p class="title">AMOUNT</p>
            </v-card-title>
            <v-divider></v-divider>
            <!--  -->
            <v-card-text>
              <div class="d-flex align-center">
                <div class="py-0">
                  <p class="mb-0 service_title">Include GST</p>
                  <p class="mb-0 service_sub_title">
                    Turn Off to create a cash estimate
                  </p>
                </div>
                <v-spacer></v-spacer>
                <v-switch inset v-model="is_gst_include"></v-switch>
              </div>
              <v-divider></v-divider>
              <div class="my-3">
                <div class="d-flex align-center">
                  <p>Subtotal</p>
                  <v-spacer></v-spacer>
                  <p>₹{{ subtotal }}</p>
                </div>
                <div v-if="discount_flag">
                  <div class="d-flex align-center discMode">
                    <p v-if="selectedDiscMode == 'percent'">
                      Discounts ({{ discount }}%)
                    </p>
                    <v-spacer></v-spacer>
                    <p v-if="selectedDiscMode == 'percent'">
                      ₹{{ calculatedDiscount }}
                    </p>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <p v-if="selectedDiscMode == 'rupee'">Discount</p>
                    <v-spacer></v-spacer>
                    <p v-if="selectedDiscMode == 'rupee'">₹{{ discount }}</p>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <p>Tax (18%)</p>
                  <v-spacer></v-spacer>
                  <p>₹{{ gst }}</p>
                </div>
                <div class="d-flex align-center">
                  <p class="total_chargable_amount">Total</p>
                  <v-spacer></v-spacer>
                  <p class="total_chargable_amount">₹{{ total }}</p>
                </div>
              </div>
              <v-divider></v-divider>
              <p
                v-if="!discount_flag"
                class="discount_text"
                @click="addDiscount"
              >
                Add Discount
              </p>
              <div v-else class="d-flex align-center">
                <v-text-field
                  placeholder="add your discount"
                  v-model="discount"
                ></v-text-field>
                <v-btn-toggle
                  class="d-flex"
                  active-class="activeDiscBtn"
                  v-model="selectedDiscMode"
                  rounded
                  mandatory
                >
                  <v-btn class="rupee_dis_btn" value="rupee">₹</v-btn>
                  <v-btn class="percent_dis_btn" value="percent">%</v-btn>
                </v-btn-toggle>
              </div>
            </v-card-text>
          </v-card>
          <!--  -->
          <v-form ref="payment_page">
            <v-card class="mx-2">
              <v-card-text class="mt-2">
                <div
                  class="d-flex"
                  v-for="(item, index) in payment_mode_list"
                  :key="index"
                >
                  <div class="d-flex align-center justify-center">
                    <v-img
                      src="../../../assets/cash_icon.svg"
                      class="ma-2"
                      v-if="item.payment_mode == 'Cash'"
                    ></v-img>
                    <v-img
                      src="../../../assets/debitcard_icon.svg"
                      class="ma-2"
                      v-if="item.payment_mode == 'Debit Card'"
                    ></v-img>
                    <v-img
                      src="../../../assets/upi_icon.svg"
                      class="ma-2"
                      v-if="item.payment_mode == 'Upi'"
                    ></v-img>
                    <p>{{ item.payment_mode }}</p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-radio-group v-model="selectedPaymentMode">
                    <v-radio :value="item.id"></v-radio>
                  </v-radio-group>
                </div>
              </v-card-text>
            </v-card>
          </v-form>
        </div>
        <v-card class="mt-2 fixed_bottom">
          <v-card-actions class="d-flex align-center justify-center">
            <v-btn
              dense
              color="primary"
              block
              class="add_service_btn my-2 text-capitalize"
              @click="finalPayment"
              >Collect Payment</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PaymentPageMob",
  data() {
    return {
      calculatedDiscount: "",
      selectedDiscMode: null,
      selectedPaymentMode: "",
      booking_detail: null,
      initials: "",
      subtotal: 0,
      total: "",
      gst: "",
      bookingId: "",
      payment_mode_list: [],
      total_discount_percent: 0,
      total_discount_amount: 0,
      discount_flag: false,
      discount: 0,
      is_gst_include: false,
      more_service_data: [],
      client_id: "",
      client_full_name: "",
      selected_appointments_data: null,
      booking_id_array: [],
    };
  },
  computed: {
    ...mapGetters({
      flag: "appointment/get_toggle_payment_page_mob",
      current_tab_name: "appointment/get_current_tab_name",
      selected_appointments: "appointment/get_selected_appointments_data",
    }),
    dialog_value: {
      get() {
        return this.flag;
      },
      set(newValue) {
        return newValue;
      },
    },
    selectedService: {
      get() {
        return this.selectedServiceData.data;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  watch: {
    selected_appointments(val) {
      if (val) {
        this.selected_appointments_data = val;
        this.client_full_name = val[0]?.client_full_name;
        if (this.client_full_name) {
          this.initials = this.$initials(this.client_full_name);
        }
      }
      this.client_id = val[0]?.client;
      let Big_array = val.map((value) => value.service_detail);
      this.booking_id_array = val.map((value) => value.id);
      let small_array = Big_array.flatMap((value) => value);
      let price_array = small_array.map((value) => value.price);
      this.subtotal = 0;
      for (let a = 0; a < price_array.length; a++) {
        this.subtotal += price_array[a];
      }
      if (!this.discount_flag && this.is_gst_include === true) {
        this.gst = Math.round((18 / 100) * this.subtotal);
        this.total = Math.round(this.subtotal + (18 / 100) * this.subtotal);
      } else if (!this.discount_flag && this.is_gst_include === false) {
        this.gst = 0;
        this.total = Math.round(this.subtotal);
      }
    },
    discount() {
      // when gst is include
      if (this.selectedDiscMode === "rupee" && this.is_gst_include === true) {
        let amount_after_Discount = this.subtotal - this.discount;
        this.gst = Math.round((18 / 100) * amount_after_Discount);
        this.total = Math.round(amount_after_Discount + this.gst);
      } else if (
        this.selectedDiscMode == "percent" &&
        this.is_gst_include === true
      ) {
        this.calculatedDiscount = Math.round(
          (this.discount / 100) * this.subtotal
        );
        let amount_after_Discount = this.subtotal - this.calculatedDiscount;
        this.gst = Math.round((18 / 100) * amount_after_Discount);
        this.total = Math.round(amount_after_Discount + this.gst);
      }

      // when gst is not included
      else if (
        this.selectedDiscMode == "rupee" &&
        this.is_gst_include === false
      ) {
        let amount_after_Discount = this.subtotal - this.discount;
        this.gst = 0;
        this.total = Math.round(amount_after_Discount + this.gst);
      } else if (
        this.selectedDiscMode == "percent" &&
        this.is_gst_include === false
      ) {
        this.calculatedDiscount = Math.round(
          (this.discount / 100) * this.subtotal
        );
        let amount_after_Discount = this.subtotal - this.calculatedDiscount;
        this.gst = 0;
        this.total = Math.round(amount_after_Discount + this.gst);
      }
    },
    selectedDiscMode() {
      // when gst is include
      if (this.selectedDiscMode == "rupee" && this.is_gst_include === true) {
        let amount_after_Discount = this.subtotal - this.discount;
        this.gst = Math.round((18 / 100) * amount_after_Discount);
        this.total = Math.round(amount_after_Discount + this.gst);
      } else if (
        this.selectedDiscMode == "percent" &&
        this.is_gst_include === true
      ) {
        this.calculatedDiscount = Math.round(
          (this.discount / 100) * this.subtotal
        );
        let amount_after_Discount = this.subtotal - this.calculatedDiscount;
        this.gst = Math.round((18 / 100) * amount_after_Discount);
        this.total = Math.round(amount_after_Discount + this.gst);
      }
      // when gst is not included
      else if (
        this.selectedDiscMode == "rupee" &&
        this.is_gst_include === false
      ) {
        let amount_after_Discount = this.subtotal - this.discount;
        this.gst = 0;
        this.total = Math.round(amount_after_Discount + this.gst);
      } else if (
        this.selectedDiscMode == "percent" &&
        this.is_gst_include === false
      ) {
        this.calculatedDiscount = Math.round(
          (this.discount / 100) * this.subtotal
        );
        let amount_after_Discount = this.subtotal - this.calculatedDiscount;
        this.gst = 0;
        this.total = Math.round(amount_after_Discount + this.gst);
      }
    },
    is_gst_include() {
      if (!this.discount_flag) {
        if (this.is_gst_include === true) {
          this.gst = Math.round((18 / 100) * this.subtotal);
          this.total = Math.round(this.subtotal + (18 / 100) * this.subtotal);
        } else if (this.is_gst_include === false) {
          this.gst = 0;
          this.total = Math.round(this.subtotal);
        }
      } else {
        // when gst is include
        if (this.selectedDiscMode === "rupee" && this.is_gst_include === true) {
          let amount_after_Discount = Math.round(this.subtotal - this.discount);
          this.gst = Math.round((18 / 100) * amount_after_Discount);
          this.total = Math.round(amount_after_Discount + this.gst);
        } else if (
          this.selectedDiscMode === "percent" &&
          this.is_gst_include === true
        ) {
          this.calculatedDiscount = Math.round(
            (this.discount / 100) * this.subtotal
          );
          let amount_after_Discount = this.subtotal - this.calculatedDiscount;
          this.gst = Math.round((18 / 100) * amount_after_Discount);
          this.total = Math.round(amount_after_Discount + this.gst);
        }
        // when gst is not included
        else if (
          this.selectedDiscMode === "rupee" &&
          this.is_gst_include === false
        ) {
          let amount_after_Discount = this.subtotal - this.discount;
          this.gst = 0;
          this.total = Math.round(amount_after_Discount + this.gst);
        } else if (
          this.selectedDiscMode == "percent" &&
          this.is_gst_include === false
        ) {
          this.calculatedDiscount = Math.round(
            (this.discount / 100) * this.subtotal
          );
          let amount_after_Discount = this.subtotal - this.calculatedDiscount;
          this.gst = 0;
          this.total = Math.round(amount_after_Discount + this.gst);
        }
      }
    },
    dialog_value(value) {
      if (value == false) {
        this.client_id = "";
        this.discount = 0;
        this.booking_id_array = [];
        this.is_gst_include = false;
        this.subtotal = 0;
        this.selectedPaymentMode = "";
        this.discount_flag =  false;

      }
    },
  },
  components: {},
  mounted() {
    this.getPaymentMode();
  },
  methods: {
    close_drawer() {
      this.$store.dispatch("appointment/togglePaymentPageMob", false);
    },
    addDiscount() {
      this.discount_flag = true;
    },
    finalPayment(item) {
      if (this.selectedPaymentMode !== "") {
        console.log("item", item);
        let parlour_id = localStorage.getItem("parlour_id");
        let data = {
          client: this.client_id,
          parlour: parlour_id,
          appointments: this.booking_id_array,
          is_gst_include: this.is_gst_include,
          pay_amount: this.subtotal,
          payment_mode: this.selectedPaymentMode,
        };
        if (this.selectedDiscMode == "percent") {
          data.total_discount_percent = this.discount;
        } else {
          data.total_discount_amount = this.discount;
        }
        const successHandler = (response) => {
          this.showSnackBar(response.data.message, "#4CA955", 1500);
          // close the modal
          this.$store.dispatch("appointment/togglePaymentPageMob", false);

          // call the get api and move the status from upcoming to completed
          let payload = {
            status: this.current_tab_name,
          };
          this.$store.dispatch("appointment/getBookingList", payload);
          // clear the payment mode
          this.selectedPaymentMode = this.cash_id;
          // selected_appointments
          this.$store.dispatch("appointment/clear_selected_appointment_data");
          this.$emit("clear_selected_appointment_data");
        };
        const failureHandler = (error) => {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        };
        const finallyHandler = () => {
          console.log("finallyHandler");
        };
        return this.$axios("post", this.$apiUrl.COMPLETE_PAYMENT, {
          data,
          onSuccess: successHandler,
          onFailure: failureHandler,
          onFinally: finallyHandler,
          isTokenRequired: true,
        });
      } else {
        this.showSnackBar("Please select the payment mode", "#B3261E", 1500);
      }
    },
    getPaymentMode() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.payment_mode_list = response.data.payment_mode_list;
        const cashPaymentMode = this.payment_mode_list.find(
          (mode) => mode.payment_mode === "Cash"
        );

        // Check if the cashPaymentMode is found
        if (cashPaymentMode) {
          // Set the selectedPaymentMode to the ID of the Cash payment mode
          this.cash_id = cashPaymentMode.id;
          this.selectedPaymentMode = this.cash_id;
        }
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_PAYMENT_MODE, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>

<style scoped>
.v-dialog > * {
  width: 100%;
  height: 100vh;
}

.v-dialog--fullscreen > .v-card {
  min-height: unset !important;
}

.total_chargable_amount {
  font-weight: 500 !important;
  color: #000000 !important;
}

.activeDiscBtn {
  color: #f2797b !important;
}

.rupee_dis_btn {
  border-right: none !important;
  padding-left: 20px !important;
}

.percent_dis_btn {
  border-left: none !important;
  padding-left: 20px !important;
}

.discount_text {
  color: #f2797b;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer !important;
  margin-top: 10px !important;
}

.discMode {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.title {
  color: grey;
  font-weight: 600;
  font-size: 12px !important;
}

.add_service_btn {
  color: #f2797b;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer !important;
}

.service_title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.service_sub_title {
  color: grey;
  font-weight: 400;
  font-size: 14px;
}

.service_cost {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.user_info_div {
  cursor: pointer !important;
  display: flex;
  margin-bottom: 3px;
}

.logged_in_user_name {
  font-weight: 500 !important;
  font-size: 16px;
}

.logged_in_user_number {
  font-weight: 400;
  font-size: 12px;
}

p {
  margin-bottom: 0px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px !important;
}

.v-input >>> .v-text-field__details {
  display: none !important;
}
</style>

<template>
  <div>
    <ServicesDesktop v-if="$isDesktop"></ServicesDesktop>
    <ServicesMobile v-if="$isMobile"></ServicesMobile>
  </div>
</template>
<script>
import ServicesDesktop from "@/components/services/ServicesDesktop.vue";
import ServicesMobile from "@/components/services/ServicesMobile.vue";
export default {
  name: "Services",
  components: {
    ServicesDesktop,
    ServicesMobile,
  },
};
</script>
<style scoped>
</style>
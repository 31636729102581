<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="328" height="160">
      <v-card>
        <v-card-title>
          <v-row><v-col class="pl-1">Move To Irrelevant</v-col></v-row>
        </v-card-title>
        <v-form ref="irrelevant_form">
          <v-card-text class="pb-0">
            <v-row dense>
              <v-col cols="12" sm="6" md="12">
                <label>Choose a reason</label>
                <v-select
                  clearable
                  dense
                  outlined
                  v-model="selected_reason"
                  :items="irrelevant_list"
                  return-object
                  item-value="id"
                  item-text="title"
                  placeholder="Select a reason"
                  @change="selectReason"
                  required
                  :rules="[$rules.required]"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="text-capitalize"
            color="#F2797B"
            @click="closeModal"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#F2797B"
            class="text-capitalize white--text"
            @click="postReason()"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CustomIrrelevantReasonModal
      @call_get_api="getIrrelevantList()"
    ></CustomIrrelevantReasonModal>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import CustomIrrelevantReasonModal from "./CustomIrrelevantReasonModal.vue";
export default {
  name: "IrrelevantModal",
  data() {
    return {
      irrelevant_list: [],
      selected_reason: "",
    };
  },
  components: {
    CustomIrrelevantReasonModal,
  },
  computed: {
    ...mapGetters({
      dialog: "dialog/getIrrelevant",
      reason_id: "enquiry_details/getReasonId",
      reason_texts: "enquiry_details/getCustomReasonText",
      customReasonData: "enquiry_details/getCustomReasonData",
    }),
  },
  mounted() {
    this.getIrrelevantList();
  },
  methods: {
    closeModal() {
      this.$store.dispatch("dialog/setIrrelevant");
      this.$refs.irrelevant_form.reset();
    },
    getIrrelevantList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.irrelevant_list = response.data.irrelevant_list;
      };
      const failureHandler = () => {};
      return this.$axios("get", this.$apiUrl.GET_IRRELEVANT_LIST, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    selectReason(selectedItem) {
      this.$store.dispatch("enquiry_details/setCustomReason", {
        id: selectedItem.id,
        title: selectedItem.title,
      });
      if (selectedItem.title === "Others") {
        this.$store.dispatch("dialog/setCustomIrrelevantReasonModal");
      }
    },
    async postReason() {
      if (this.$refs.irrelevant_form.validate()) {
        await this.$store.dispatch("enquiry_details/setMoveToIrrelevant");
        // this.$refs.irrelevant_form.reset();
        setTimeout(() => {
          this.$refs.irrelevant_form.reset();
        }, 300); // Adjust the delay as needed
      }
    },
  },
};
</script>
<style scoped>
label {
  color: black !important;
}

.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 20px !important;
}

.flag_img {
  width: 20px;
  height: 20px;
}
</style>

<template>
  <v-container class="container pr-md-11">
    <v-row class="ma-0">
      <v-col class="d-flex align-center px-0" cols="8">
        <v-icon large color="black" class="mr-2" @click="goBackToTeamPage"
          >mdi-arrow-left</v-icon
        >
        <p class="page_title">{{ $route.meta.title }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="user_info_div pl-0">
        <div>
          <v-avatar
            color="#F2797B"
            size="60"
            class="logged_in_user_avatar white--text ma-2"
            >{{ initials }}</v-avatar
          >
        </div>
        <div>
          <p class="mb-0 member_name">{{ full_name }}</p>
          <p class="mb-0 member_role">{{ role }}</p>
        </div>
      </v-col>

      <v-col class="d-flex justify-end" cols="4">
        <!-- <v-btn icon><v-icon color="black">mdi-dots-vertical</v-icon></v-btn> -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                class="status_action"
                v-if="!staff_detail?.is_active"
                @click="handleStatusAction(staff_detail)"
                >Activate</v-list-item-title
              >
              <v-list-item-title
                class="status_action"
                v-if="staff_detail?.is_active"
                @click="handleStatusAction(staff_detail)"
                >Deactivate</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-card width="100%">
        <v-card-title class="d-flex align-center">
          <p class="header">Personal Information</p>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isEditDisable"
            @click="openInviteTeamModal"
            class="edit_btn text-capitalize"
            outlined
            color="#79747E"
            ><v-icon>mdi-file-edit</v-icon>Edit</v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mb-3">
            <p class="title">Name</p>
            <p class="sub_title" v-if="staff_detail && staff_detail.full_name">
              {{ staff_detail?.full_name }}
            </p>
          </div>
          <div class="mb-3">
            <p class="title">Gender</p>
            <p class="sub_title" v-if="staff_detail && staff_detail.gender">
              {{ staff_detail?.gender }}
            </p>
          </div>
          <div class="mb-3" v-if="staff_detail && staff_detail.mobile">
            <p class="title">Phone</p>
            <p class="sub_title">(+91){{ staff_detail.mobile }}</p>
          </div>
          <div class="mb-3" v-if="staff_detail && staff_detail.id_proof">
            <p class="title">Aadhaar Card/Voter ID</p>
            <!-- <div class="d-flex align-center">
              <v-img :src="staff_detail.id_proof" class="id_proof"> </v-img>
              <div class="view_action" @click="viewDocument()">View</div>
            </div> -->
            <!-- <div class="d-flex flex-column">
              <v-img :src="staff_detail.id_proof" class="id_proof mb-2"></v-img>
              <div class="view_action" @click="viewDocument()">View</div>
            </div> -->
            <div class="d-flex flex-column">
              <v-img :src="staff_detail.id_proof" class="id_proof mb-2"></v-img>
              <div class="view_action" @click="viewDocument()">View</div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-row>
    <InviteTeamModal></InviteTeamModal>
    <InviteTeamModalMobile></InviteTeamModalMobile>
    <statusConfirmationModal></statusConfirmationModal>
  </v-container>
</template>
<script>
import InviteTeamModal from "@/components/team-management/InviteTeamModal.vue";
import InviteTeamModalMobile from "@/components/team-management/InviteTeamModalMobile.vue";
import statusConfirmationModal from "@/components/team-management/statusConfirmationModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "MemberDetail",
  data() {
    return {
      staff_detail: null,
      initials: "",
      full_name: "",
      role: "",
      isEditDisable: false,
    };
  },
  components: {
    InviteTeamModal,
    InviteTeamModalMobile,
    statusConfirmationModal,
  },
  computed: {
    ...mapGetters({
      staff_details: "team/get_staff_details",
    }),
  },
  watch: {
    staff_details(val) {
      this.staff_detail = val;
      this.full_name = val.full_name;
      this.initials = this.$initials(this.full_name);
      this.role = val.role;
      let logged_in_user_role = localStorage.getItem("user_role");
      if (logged_in_user_role === "admin" && this.role === "Admin") {
        this.isEditDisable = true;
      } else if (logged_in_user_role === "staff" && this.role === "Admin") {
        this.isEditDisable = true;
      } else if (
        logged_in_user_role === "staff" &&
        this.role === "Staff Manager"
      ) {
        this.isEditDisable = true;
      }
    },
  },
  mounted() {
    let staff_id = this.$route.params.id;
    this.$store.dispatch("team/callGetStaffDetails", staff_id);
  },
  methods: {
    handleStatusAction(item) {
      let payload = {
        flag: true,
        member_detials: item,
      };
      this.$store.dispatch("dialog/setMemberStatusConfirmition", payload);
    },
    openInviteTeamModal() {
      if (this.$isDesktop) {
        let staff_id = this.$route.params.id;
        // cal get api for details
        this.$store.dispatch("team/callGetStaffDetails", staff_id);
        // open modal
        this.$store.dispatch("dialog/setInviteTeamModal", "edit");
      } else {
        let staff_id = this.$route.params.id;
        // cal get api for details
        this.$store.dispatch("team/callGetStaffDetails", staff_id);
        // open modal
        let payload = {
          open: true,
          flag: "edit",
        };
        this.$store.dispatch("dialog/setNewMemberModalMobile", payload);
      }
    },
    goBackToTeamPage() {
      this.$router.push({
        name: "team-management",
      });
    },
    viewDocument() {
      window.open(this.staff_details.id_proof);
    },
  },
};
</script>

<style scoped>
.view_action {
  /* position: relative !important; */
  /* top: 90% !important; */
  /* left: 38% !important; */
  /* text-align: center; */
  /* background-color: black; */
  /* font-weight: bold; */
  margin-top: 6px;
  margin-left: 40px;
  /* color: black !important; */
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  color: #F2797B !important;
}

.action_delete {
  position: relative !important;
  top: -21px !important;
  left: 73% !important;
  color: #8c1d18 !important;
  background-color: white !important;
  font-weight: 700;
  cursor: pointer;
}

.container {
  margin-left: 30px;
  margin-right: 0px;
}

p {
  margin-bottom: 0px;
}

.id_proof {
  max-width: 120px !important;
  max-height: 120px !important;
  /* border: 1px dashed grey !important; */
}

.page_title {
  color: #404c57;
  font-weight: 700;
  font-size: 30px;
}

.user_info_div {
  display: flex;
  align-items: center;
  width: 100%;
}

.edit_btn {
  border-radius: 15px;
}

.member_name {
  color: #000000;
  font-weight: 500;
  font-size: 28px !important;
}

.member_role {
  color: #9397a7;
  font-weight: 400;
  font-size: 18px !important;
}

.header {
  color: #39434d;
  font-weight: 600;
  font-size: 20px !important;
}

.title {
  color: #6e7b89;
  font-weight: 400;
  font-size: 14px !important;
}

.sub_title {
  color: #39434d;
  font-weight: 500;
  font-size: 16px !important;
}

@media all and (max-width: 601px) {
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .member_name {
    color: #000000;
    font-weight: 500;
    font-size: 18px !important;
  }

  .member_role {
    color: #9397a7;
    font-weight: 400;
    font-size: 15px !important;
  }
}
</style>

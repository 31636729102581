// this store is only to toggle dialogs and modals
export default {
  namespaced: true,
  state: {
    new_booking_drawer: false,
    mobile_booking_drawer: "",
    booking_details_drawer: false,
    new_enquiry_drawer: false,
    new_client_drawer: {
      clientAddOrEditFlag: "",
      toggle_flag: "",
    },
    new_client_drawer_mobile: {
      clientAddOrEditFlag: "",
      toggle_flag: "",
    },
    create_bill_drawer: false,
    bill_details_drawer: false,
    service_drawer: false,
    editOraddService: "",
    category_drawer: false,
    category_drawer_mobile: false,
    addOrEditCategory: "",
    edit_category_confirmition: {
      flag: false,
      category_id: "",
      is_active: "",
    },
    checkout_drawer: {
      flag: false,
      data: "",
    },
    payment_drawer: {
      flag: false,
      data: "",
    },
    partially_paid_modal: false,
    locationModal: false,
    custom_query: false,
    irrelevant_modal: false,
    confirmation_modal: false,
    custom_irrelevant_modal: false,
    user_profile_modal: false,
    parlour_profile_modal: false,
    logout_confirmation_modal: false,
    invite_team_modal: "",
    addOrEditFlag: "",
    booking_cancellation_data: {
      flag: false,
      clicked_id: "",
    },
    customDurationModal: false,
    member_status_confirmation: {
      flag: false,
      member_details: "",
    },
    add_new_team_member_mobile: {
      open: false,
      flag: "",
    },
    new_enquiry_modal_mobile: false,
    UserNumberEditDialog: false,
    newNumDialog: false,
    toggle_report_page: false,
  },
  getters: {
    getNewBookingDrawer: (state) => state.new_booking_drawer,
    getNewEnquiryDrawer: (state) => state.new_enquiry_drawer,
    getNewClientDrawer: (state) => state.new_client_drawer,
    getNewClientDrawerMobile: (state) => state.new_client_drawer_mobile,
    getCreateBillDrawer: (state) => state.create_bill_drawer,
    getBillDetailsDrawer: (state) => state.bill_details_drawer,
    getServiceDrawer: (state) => state.service_drawer,
    getEditOraddService: (state) => state.editOraddService,
    getCategoryDrawer: (state) => state.category_drawer,
    getCategoryDrawerMobile: (state) => state.category_drawer_mobile,
    getAddOrEditCategory: (state) => state.addOrEditCategory,
    getBookingDetails: (state) => state.booking_details_drawer,
    getMobileBookingDrawer: (state) => state.mobile_booking_drawer,
    getCheckoutDrawer: (state) => state.checkout_drawer,
    getPaymentDrawer: (state) => state.payment_drawer,
    getPartiallyPaidModal: (state) => state.partially_paid_modal,
    getLocationModal: (state) => state.locationModal,
    getCustomQueryModal: (state) => state.custom_query,
    getIrrelevant: (state) => state.irrelevant_modal,
    getConfirmation: (state) => state.confirmation_modal,
    getCustomIrrelevantModal: (state) => state.custom_irrelevant_modal,
    getUserProfileModal: (state) => state.user_profile_modal,
    getParlourProfileModal: (state) => state.parlour_profile_modal,
    getLogoutConfirmationModal: (state) => state.logout_confirmation_modal,
    getInviteTeamModal: (state) => state.invite_team_modal,
    getAddorEditFlag: (state) => state.addOrEditFlag,
    getCustomDurationModal: (state) => state.customDurationModal,
    getEditCategoryConfirmition: (state) => state.edit_category_confirmition,
    getMemberStatusConfirmation: (state) => state.member_status_confirmation,
    getNewTeamMemberMobileModalFlag: (state) =>
      state.add_new_team_member_mobile,
    getNewEnquiryModalMobileFlag: (state) => state.new_enquiry_modal_mobile,
    getEditUserNumFlag: (state) => state.UserNumberEditDialog,
    getnewNumDialog: (state) => state.newNumDialog,
    gettoggleReportPage: (state) => state.toggle_report_page,
  },
  mutations: {
    SET_NEW_ENQUIRY_DRAWER(state) {
      state.new_enquiry_drawer = !state.new_enquiry_drawer;
    },
    SET_NEW_CLIENT_DRAWER(state, payload) {
      state.new_client_drawer.toggle_flag = payload.toggle_flag;
      state.new_client_drawer.clientAddOrEditFlag = payload.clientAddOrEditFlag;
    },
    SET_NEW_CLIENT_DRAWER_MOBILE(state, payload) {
      state.new_client_drawer_mobile.toggle_flag = payload.toggle_flag;
      state.new_client_drawer_mobile.clientAddOrEditFlag =
        payload.clientAddOrEditFlag;
    },
    SET_CREATE_BILL_DRAWER(state) {
      state.create_bill_drawer = !state.create_bill_drawer;
    },
    SET_BILL_DETAIL_DRAWER(state) {
      state.bill_details_drawer = !state.bill_details_drawer;
    },
    SET_SERVICE_DRAWER(state, payload) {
      state.service_drawer = !state.service_drawer;
      state.editOraddService = payload;
    },
    SET_CATEGORY_DRAWER(state, payload) {
      state.category_drawer = !state.category_drawer;
      state.addOrEditCategory = payload;
    },
    SET_CATEGORY_DRAWER_MOBILE(state, payload) {
      state.category_drawer_mobile = !state.category_drawer_mobile;
      state.addOrEditCategory = payload;
    },
    SET_CHECKOUT_DRAWER(state, payload) {
      state.checkout_drawer.flag = payload.flag;
      state.checkout_drawer.data = payload.data;
    },
    SET_PAYMENT_DRAWER(state, payload) {
      state.payment_drawer.flag = payload.flag;
      state.payment_drawer.data = payload.data;
    },
    SET_PARTIALLY_PAID_MODAL(state) {
      state.partially_paid_modal = !state.partially_paid_modal;
    },
    SET_LOCATION_MODAL(state, payload) {
      state.locationModal = payload;
    },
    SET_CUSTOM_QUERY(state) {
      state.custom_query = !state.custom_query;
    },
    SET_IRRELEVANT_MODAL(state) {
      state.irrelevant_modal = !state.irrelevant_modal;
    },
    SET_CONFIRMATION_MODAL(state) {
      state.confirmation_modal = !state.confirmation_modal;
    },
    SET_CUSTOM_IRRELEVANT_MODAL(state) {
      state.custom_irrelevant_modal = !state.custom_irrelevant_modal;
    },
    SET_USER_PROFILE_MODAL(state) {
      state.user_profile_modal = !state.user_profile_modal;
    },
    SET_PARLOUR_PROFILE_MODAL(state) {
      state.parlour_profile_modal = !state.parlour_profile_modal;
    },
    SET_LOGOUT_CONFIRMATION_MODAL(state) {
      state.logout_confirmation_modal = !state.logout_confirmation_modal;
    },
    SET_INVITE_TEAM_MODAL(state, payload) {
      state.invite_team_modal = !state.invite_team_modal;
      state.addOrEditFlag = payload;
    },
    SET_CUSTOM_DURATION_MODAL(state, payload) {
      state.customDurationModal = payload;
    },
    EDIT_CATEGORY_CONFIRMITION(state, payload) {
      state.edit_category_confirmition = payload;
    },
    EDIT_MEMBER_STATUS_CONFIRMITION(state, payload) {
      state.member_status_confirmation = payload;
    },
    SET_NEW_MEMBER_MODAL_MOBILE(state, payload) {
      state.add_new_team_member_mobile = payload;
      // state.addOrEditFlag = payload.flag;
    },
    SET_NEW_ENQUIRY_MODAL_MOBILE(state, payload) {
      state.new_enquiry_modal_mobile = payload;
    },
    SET_USER_NUM_EDIT(state, payload) {
      state.UserNumberEditDialog = payload;
    },
    SET_NEW_NUM_DIALOG(state, payload) {
      state.newNumDialog = payload;
    },
    TOGGLE_REPORT_PAGE(state, payload) {
      state.toggle_report_page = payload;
    },
  },
  actions: {
    setNewEnquiryDrawer({ commit }) {
      commit("SET_NEW_ENQUIRY_DRAWER");
    },
    setNewClientDrawer({ commit }, payload) {
      commit("SET_NEW_CLIENT_DRAWER", payload);
    },
    setNewClientDrawerMobile({ commit }, payload) {
      commit("SET_NEW_CLIENT_DRAWER_MOBILE", payload);
    },
    setCreateBillDrawer({ commit }) {
      commit("SET_CREATE_BILL_DRAWER");
    },
    setBillDetailDrawer({ commit }) {
      commit("SET_BILL_DETAIL_DRAWER");
    },
    setServiceDrawer({ commit }, payload) {
      commit("SET_SERVICE_DRAWER", payload);
    },
    setCategoryDrawer({ commit }, payload) {
      commit("SET_CATEGORY_DRAWER", payload);
    },
    setCategoryDrawerMobile({ commit }, payload) {
      commit("SET_CATEGORY_DRAWER_MOBILE", payload);
    },
    setCheckoutDrawer({ commit }, payload) {
      commit("SET_CHECKOUT_DRAWER", payload);
    },
    setPaymentDrawer({ commit }, payload) {
      commit("SET_PAYMENT_DRAWER", payload);
    },
    setPartiallyPaid({ commit }) {
      commit("SET_PARTIALLY_PAID_MODAL");
    },
    setLocation({ commit }, payload) {
      commit("SET_LOCATION_MODAL", payload);
    },
    setCustomQuery({ commit }) {
      commit("SET_CUSTOM_QUERY");
    },
    setIrrelevant({ commit }) {
      commit("SET_IRRELEVANT_MODAL");
    },
    setConfirmationModal({ commit }) {
      commit("SET_CONFIRMATION_MODAL");
    },
    setCustomIrrelevantReasonModal({ commit }) {
      commit("SET_CUSTOM_IRRELEVANT_MODAL");
    },
    setUserProfileModal({ commit }) {
      commit("SET_USER_PROFILE_MODAL");
    },
    setParlourProfileModal({ commit }) {
      commit("SET_PARLOUR_PROFILE_MODAL");
    },
    setLogoutConfirmationModal({ commit }) {
      commit("SET_LOGOUT_CONFIRMATION_MODAL");
    },
    setInviteTeamModal({ commit }, payload) {
      commit("SET_INVITE_TEAM_MODAL", payload);
    },
    setCustomDurationModal({ commit }, payload) {
      commit("SET_CUSTOM_DURATION_MODAL", payload);
    },
    setEditCategoryConfirmition({ commit }, payload) {
      commit("EDIT_CATEGORY_CONFIRMITION", payload);
    },
    setMemberStatusConfirmition({ commit }, payload) {
      commit("EDIT_MEMBER_STATUS_CONFIRMITION", payload);
    },

    setNewMemberModalMobile({ commit }, payload) {
      commit("SET_NEW_MEMBER_MODAL_MOBILE", payload);
    },
    setNewEnquiryDrawerMob({ commit }, payload) {
      commit("SET_NEW_ENQUIRY_MODAL_MOBILE", payload);
    },
    setUserNumEdit({ commit }, payload) {
      commit("SET_USER_NUM_EDIT", payload);
    },
    setNewNumDialog({ commit }, payload) {
      commit("SET_NEW_NUM_DIALOG", payload);
    },
    toggleReportPage({ commit }, payload) {
      commit("TOGGLE_REPORT_PAGE", payload);
    },
  },
};

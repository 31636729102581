const URLS = {
  // BASE_URL: "http://139.5.189.24:8098/",
  // BASE_URL: process.env.VUE_APP_BASE_URL,
  SEND_OTP_REGISTER: "/user/register_parlour/",
  SEND_OTP_LOGIN: "/user/login/",
  RESEND_OTP: "/user/resend_otp/",
  VERIFY_OTP: "/user/verify_otp/",
  PHONE_CODE: "/master/list_phone_code/",
  GET_PARLOUR_MEDIA: "/parlour/parlour_media_detail/",
  POST_PARLOUR_MEDIA: "/parlour/parlour_media/",
  POST_PARLOUR_DETAILS: "/parlour/parlour/",
  EDIT_PARLOUR_DETAILS: "/parlour/parlour_detail/",
  GET_PARLOUR_DETAILS: "/parlour/parlour_detail/",
  DELETE_PARLOUR_MEDIA: "/parlour/parlour_media_detail/",
  STAFF_LOGIN: "/user/staff_login/",
  MEDIA_DELETE: "/parlour/parlour_media_detail/",
  CREATE_CLIENT: "/client/client/",
  GET_CLIENT_LIST: "/client/client/",
  GET_CLIENT_DETAILS: "/client/client_detail/",
  GET_CLIENT_ACTIVITY_HISTORY: "/client/client_activity_log/",
  EDIT_CLIENT_DETAILS: "/client/client_detail/",
  GET_QUERY_LIST: "/parlour/parlour_query/",
  CREATE_NEW_QUERY: "/parlour/parlour_query/",
  CREATE_ENQUIRY: "/enquiry/enquiry/",
  GET_ENQUIRY_LIST: "/enquiry/enquiry/",
  UPDATE_ENQUIRY_LIST: "/enquiry/enquiry_detail/",
  GET_IRRELEVANT_LIST: "parlour/parlour_irrelevant/",
  CREATE_IRRELEVANT_REASON: "parlour/parlour_irrelevant/",
  GET_NAME:"/enquiry/get_client_name/",
  GET_APPOINTENT_DATA:"/enquiry/get_appointment_data/",
  // services  Module (category)
  CREATE_CATEGORY: "/service/category/",
  LIST_CATEGORY: "/service/category/",
  EDIT_CATEGORY: "/service/category_detail/",
  DELETE_CATEGORY: "/service/category_detail/",
  // services  Module (services)
  CREATE_SERVICE: "/service/service/",
  LIST_SERVICE: "/service/service/",
  GET_SERVICE_DETAIL: "/service/service_detail/",
  EDIT_SERVICE: "/service/service_detail/",
  DELETE_SERVICE: "/service/service_detail/",
  SERVICE_DURATION_LIST: "/parlour/service_duration/",
  CATEGORY_AND_SERVICE_LIST: "/service/service_list/",
  GET_CATEGORY_WITH_SERVICES: "/service/get_service_list/",
  CREATE_CUSTOM_SERVICE_DURATION: "/parlour/service_duration/",
  POST_CLIENT_SERVICE: "/booking/client_service_cart/",
  ADD_OR_DELETE_SERVICES: "/booking/cart_item_update/",
  GET_SERVICE_CART_LIST: "/booking/cart_item_list/",
  DELETE_CATEGORY_SERVICE :"/service/category_detail/",

  // Appointments
  GET_BOOKING_LIST: "/booking/booking/",
  CREATE_BOOKING: "/booking/booking/",
  LIST_EXECUTIVE: "/staff/executive_list/",
  GET_BOOKING_DETAILS: "/booking/booking_detail/",
  UPDATE_BOOKING: "/booking/booking_detail/",
  GET_PAYMENT_MODE: "/parlour/payment_mode/",
  GET_CANCELLATION_REASON_LISTING: "/parlour/booking_cancelled_query/",
  POST_CANCELLATION_REASON: "/booking/cancelled_appointment/",
  POST_CUSTOM_CANCEL_REASON: "/parlour/booking_cancelled_query/",
  COMPLETE_PAYMENT: "/booking/complete_appointment_payment/",
  POST_APPOINTMENT_CLOSURE:"/booking/remove_service_cart/",
  

  // staff
  GET_TEAM_LIST: "/staff/staff/",
  LIST_STAFF_ROLE: "/parlour/staff_roles/",
  CREATE_STAFF: "/staff/staff/",
  EDIT_STAFF: "/staff/staff_detail/",
  GET_STAFF_DETAIL: "/staff/staff_detail/",

  // Dashboard
  GET_DASHBOARD_LISTING: "/parlour/dashboard/",
  GET_UPCOMING_APPOINTMENT_LIST:"/parlour/get_upcoming_bookings/",

  // Profile
  EDIT_MOBILE_SEND_OTP: "/user/change_mobile_otp/",
  EDIT_MOBILE_VERIFY_OTP: "/user/verify_change_mobile_otp/",

  // Reports
  GET_SERVICE_REPORT: "/parlour/dashboard_service_report/",
  GET_EXECUTIVE_REPORT: "/parlour/dashboard_executive_report/",
  GET_SERVICE_LIST: "/service/dashboard_service_list/",
  GET_EXECUTIVE_LIST: "/staff/dashboard_executive_list/",
  GENERATE_REPORT: "/parlour/get_executive_report/",

  // Account Delete
  DELETE_ACCOUNT: "/parlour/parlour_delete_account/",
  DELETE_VERIFY_OTP :"/parlour/parlour_delete_account_verify_otp/",

};
export default URLS;

import { render, staticRenderFns } from "./ParlourDetail.vue?vue&type=template&id=3df71914&scoped=true"
import script from "./ParlourDetail.vue?vue&type=script&lang=js"
export * from "./ParlourDetail.vue?vue&type=script&lang=js"
import style0 from "./ParlourDetail.vue?vue&type=style&index=0&id=3df71914&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df71914",
  null
  
)

export default component.exports
<template>
  <v-row justify="center">
    <v-dialog
      v-model="drawer.toggle_flag"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="528"
      height="100vh"
    >
      <v-card>
        <v-container>
          <v-card-title class="d-flex main_title mb-5">
            <div>
              <span v-if="drawer && drawer.clientAddOrEditFlag == 'edit'"
                >Edit Client</span
              >
              <span v-else> Add client</span>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon
              ><v-icon color="black" class="mr-2" @click="closeModal"
                >mdi-close</v-icon
              ></v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-form ref="post_client_details_form">
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <label>First Name*</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="Enter your first name"
                    required
                    v-model="first_name"
                    :rules="[$rules.name_1, $rules.noSpaces, $rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>Last Name*</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="Enter your last name"
                    v-model="last_name"
                    :rules="[$rules.name_1, $rules.noSpaces, $rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Gender*</label>
                  <v-select
                    clearable
                    dense
                    outlined
                    :items="['Male', 'Female', 'Other']"
                    placeholder="Gender"
                    :rules="[$rules.required]"
                    v-model="gender"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Mobile Number*</label>
                  <v-text-field
                    @keypress="isNumber($event)"
                    maxlength="10"
                    dense
                    outlined
                    placeholder="Please enter mobile number"
                    class="md-mr-0 mr-2"
                    v-model="mobile"
                    prepend-inner-icon
                    :rules="[$rules.mobile_number]"
                  >
                    <template #prepend-inner>
                      <!-- {{ selected_flag }} -->
                      <v-img :src="selected_flag" class="flag_img"></v-img>
                      &nbsp;
                      <p class="mt-1">{{ selected_phone_code }}</p>
                      &nbsp;
                      <!-- <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template>
                                            <v-list>
                                                <v-list-item v-for="(item, index) in country_list" :key="index" @click="selectPhoneCode(item)">
                                                    <v-list-item-title>{{ item.phone_code }} {{ item.name
                                                        }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                            </v-menu> -->
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Email</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="Enter your email"
                    v-model="email"
                    :rules="[$rules.Email]"
                  ></v-text-field>
                </v-col>

                <!-- Date Picker -->
                <v-col cols="12" sm="6" md="12">
                  <label>Birthday</label>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        outlined
                        dense
                        placeholder="Please select your birth date"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      event-color="#F2797B"
                      v-model="date"
                      no-title
                      scrollable
                      min="1963-08-31"
                      :max="currentDate()"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#F2797B" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#F2797B"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <v-btn
                  block
                  outlined
                  color="#F2797B"
                  class="text-capitalize"
                  @click="closeModal"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  :loading="loader"
                  block
                  v-if="drawer && drawer.clientAddOrEditFlag == 'edit'"
                  @click="AddOrEditClient"
                  class="text-capitalize white--text"
                  color="#F2797B"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon> Edit Client
                </v-btn>
                <v-btn
                  v-else
                  :loading="loader"
                  block
                  @click="AddOrEditClient"
                  class="text-capitalize white--text"
                  color="#F2797B"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon> Add Client
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CreateClientModal",
  data() {
    return {
      menu: "",
      date: "",
      parlour: null,
      first_name: "",
      last_name: "",
      gender: "",
      country: "103",
      email: "",
      mobile: "",
      birthday_date: "1998-05-12",
      country_list: [],
      selected_flag:
        "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      selected_country: "India",
      selected_phone_code: "+91",
      loader: false,
    };
  },
  computed: {
    ...mapGetters({
      drawer: "dialog/getNewClientDrawerMobile",
      client_detail: "client/get_client_details",
    }),
  },
  mounted() {
    this.getListOfPhoneCodes();
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    // client_detail(val) {
    //   console.log("val", val);
    //   this.first_name = val.first_name;
    //   this.last_name = val.last_name;
    //   this.gender = val.gender;
    //   this.country = val.country;
    //   this.email = val.email;
    //   this.mobile = val.mobile;
    //   this.date = val.birthday_date;
    //   this.dateFormatted = this.formatDate(this.date);
    // },
    "drawer.toggle_flag"(value) {
      if (value && this.drawer.clientAddOrEditFlag === "edit") {
        this.first_name = this.client_detail.first_name;
        this.last_name = this.client_detail.last_name;
        this.gender = this.client_detail.gender;
        this.country = this.client_detail.country;
        this.email = this.client_detail.email;
        this.mobile = this.client_detail.mobile;
        this.date = this.client_detail.birthday_date;
        this.dateFormatted = this.formatDate(this.date);
      }
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    closeModal() {
      let data = {
        toggle_flag: false,
        clientAddOrEditFlag: "",
      };
      this.$store.dispatch("dialog/setNewClientDrawerMobile", data);
      this.$refs.post_client_details_form.reset();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    currentDate() {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      const year = yesterday.getFullYear();
      const month = String(yesterday.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
      const day = String(yesterday.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },

    AddOrEditClient() {
      if (this.drawer.clientAddOrEditFlag === "edit") {
        this.EditClient();
      } else {
        this.AddClient();
      }
    },
    AddClient() {
      if (this.$refs.post_client_details_form.validate()) {
        this.loader = true;
        this.parlour = localStorage.getItem("parlour_id");
        let data = {
          parlour: this.parlour,
          first_name: this.first_name,
          last_name: this.last_name,
          gender: this.gender,
          country: this.country,
          email: this.email,
          mobile: this.mobile,
          birthday_date: this.birthday_date,
        };
        const successHandler = () => {
          
          let data = {
            toggle_flag: false,
            clientAddOrEditFlag: "",
          };
          this.$store.dispatch("dialog/setNewClientDrawerMobile", data);
          this.$emit("callApi");
          this.$refs.post_client_details_form.reset();
          this.first_name = "";
          this.last_name = "";
          this.gender = "";
          this.birthday_date = "1998-05-12";
          this.email = "";
          this.mobile = "";
          this.loader = false;
        };
        const failureHandler = (error) => {
          console.log("error of createClient ", error);
          this.loader = false;
          this.showSnackBar(
            "please fill all the required details",
            "#B3261E",
            1500
          );
        };
        const finallyHandler = () => {
          this.loader = false;
        };
        return this.$axios("post", this.$apiUrl.CREATE_CLIENT, {
          data,
          onSuccess: successHandler,
          onFailure: failureHandler,
          onFinally: finallyHandler,
          isTokenRequired: true,
        });
      }
    },
    EditClient() {
      if (this.$refs.post_client_details_form.validate()) {
        this.parlour = localStorage.getItem("parlour_id");
        let client_id = this.$route.params.id;
        let params = {
          id: client_id,
        };
        let data = {
          parlour: this.parlour,
          first_name: this.first_name,
          last_name: this.last_name,
          gender: this.gender,
          country: this.country,
          email: this.email,
          mobile: this.mobile,
          birthday_date: this.date,
        };
        const successHandler = (response) => {
          let data = {
            toggle_flag: false,
            clientAddOrEditFlag: "",
          };
          this.$store.dispatch("dialog/setNewClientDrawerMobile", data);
          let client_id = this.$route.params.id;
          this.$store.dispatch("client/callGetClientDetails", client_id);
          this.showSnackBar(response.data.message, "#4CA955", 1500);
          this.$refs.post_client_details_form.reset();
        };
        const failureHandler = (error) => {
          if (error && error.data && error.data.message) {
            this.showSnackBar(error.data.message, "#B3261E", 1500);
          }
        };
        const finallyHandler = () => {};
        return this.$axios("patch", this.$apiUrl.EDIT_CLIENT_DETAILS, {
          params,
          data,
          onSuccess: successHandler,
          onFailure: failureHandler,
          onFinally: finallyHandler,
          isTokenRequired: true,
        });
      }
    },
    // getListOfPhoneCodes api calling
    getListOfPhoneCodes() {
      const successHandler = (response) => {
        this.country_list = response.data.phone_code_list;
      };
      const failureHandler = (error) => {
        console.log("error is ", error);
      };
      return this.$axios("get", this.$apiUrl.PHONE_CODE, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: false,
      });
    },
    selectPhoneCode(item) {
      this.selected_flag = item.flag;
      this.selected_country = item.name;
      this.country = item.id;
      this.selected_phone_code = item.phone_code;
    },
  },
};
// }
</script>
<style scoped>
.main_title {
  background-color: #fce4e5 !important;
}

.mdi-chevron-down::before {
  margin-top: -8px !important;
}

label {
  color: black !important;
}

.flag_img {
  width: 20px;
  height: 20px;
}

.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 20px !important;
}

/* . {
    color: #1D1B20 !important;
    font-weight: 400;
    font-size: 22px;
} */
</style>

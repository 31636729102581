<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="528" height="200">
      <v-card>
        <v-card-title>
          <span class="text-h6">Edit Service</span>
          <v-spacer></v-spacer>
          <span>
            <v-icon color="black" large @click="closeModal">mdi-close</v-icon>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form ref="new_service_modal">
              <v-row dense>
                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                  <label>Service Name*</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="e.g. Men's Haircut"
                    required
                    v-model="service_name"
                    @input="service_name = capitalize(service_name)"
                    :rules="[$rules.required, $rules.name_3, $rules.noSpaces]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                  <label>Category Name*</label>
                  <v-select
                    clearable
                    dense
                    outlined
                    :items="category_list"
                    item-text="name"
                    item-value="id"
                    placeholder="Select Category"
                    required
                    v-model="selectedCategory"
                    :rules="[$rules.required]"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                  <label>Duration*</label>
                  <v-select
                    clearable
                    dense
                    outlined
                    :items="duration_list"
                    item-text="duration"
                    item-value="id"
                    placeholder="Select Duration"
                    return-object
                    required
                    v-model="selectedDuration"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:item="{ item }">
                      <span
                        :style="{
                          color:
                            item.duration === 'Add Duration' ? '#F2797B' : 'initial',
                        }"
                        >{{ item.duration }}</span
                      >
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                  <label>Price*</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="e.g.₹200.0"
                    v-model.trim="price"
                    :rules="[$rules.AMOUNT]"
                    prepend-inner-icon="mdi-currency-inr"
                    small
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                  <label>Description</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="Write a description about the service"
                    v-model="description"
                    @input="description = capitalize(description)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#F2797B"
            class="text-capitalize"
            @click="closeModal()"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loader"
            @click="CreateorEditService"
            class="text-capitalize white--text"
            color="#F2797B"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CustomDurationModal></CustomDurationModal>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import CustomDurationModal from "./CustomDurationModal.vue";
export default {
  name: "NewServiceModal",
  data() {
    return {
      service_name: "",
      selectedCategory: "",
      selectedDuration: "",
      price: null,
      description: "",
      category_list: [],
      duration_list: [],
      current_item_id: "",
      current_item_name: "",
      flagForAddOrEdit: "",
      DurationListflag: false,
      loader: false,
    };
  },
  components: {
    CustomDurationModal,
    // setCustomDurationModal
  },
  mounted() {
    this.$store.dispatch("services/ListCategory");
    this.getServiceDurationList();
  },
  computed: {
    ...mapGetters({
      dialog: "dialog/getServiceDrawer",
      categoryListFromState: "services/get_category_list",
      getEditOraddService: "dialog/getEditOraddService",
      custom_duration_data: "services/get_custom_duration_data",
      parlour_details: "profile/get_parlour_details",
    }),
  },
  watch: {
    getEditOraddService(val) {
      this.current_item_id = val.current_item_id;
      this.current_item_name = val.current_item_name;
      if (val.title === "Add A Service") {
        (this.flagForAddOrEdit = true),
          (this.selectedCategory = val.current_item_id);
      } else if (val.title === "Create A Service") {
        (this.flagForAddOrEdit = true), (this.selectedCategory = "");
      } else if (val.title === "Edit Service") {
        (this.service_name = val.current_item_name),
          (this.selectedCategory = val.category),
          (this.selectedDuration = val.duration_id),
          (this.price = val.price),
          (this.description = val.current_item_description);
        this.flagForAddOrEdit = false;
      }
    },
    categoryListFromState() {
      this.category_list = [];
      for (let i = 0; i < this.categoryListFromState.length; i++) {
        this.category_list.push({
          name: this.categoryListFromState[i].category_name,
          id: this.categoryListFromState[i].id,
        });
      }
    },
    selectedDuration(val) {
      if (val.duration === "Add Duration") {
        this.$store.dispatch("dialog/setCustomDurationModal", true);
      }
    },
    custom_duration_data(val) {
      this.selectedDuration = val;

      this.getServiceDurationList();
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch("dialog/setServiceDrawer");
      this.$refs.new_service_modal.reset();
      this.selectedCategory = "";
      this.selectedDuration = "";
      this.service_name = "";
      this.description = "";
    },
    getServiceDurationList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        let service_duration_list = response.data.service_duration_list;
        for (let i = 0; i < service_duration_list.length; i++) {
          this.duration_list.push({
            duration: service_duration_list[i].duration,
            id: service_duration_list[i].id,
          });
        }
      };
      const failureHandler = (error) => {
        console.log("error is ", error);
      };
      return this.$axios("get", this.$apiUrl.SERVICE_DURATION_LIST, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    CreateorEditService() {
      if (this.flagForAddOrEdit == true) {
        this.CreateService();
      } else {
        this.EditService();
      }
    },
    CreateService() {
      if (this.$refs.new_service_modal.validate()) {
        this.loader = true;
        let parlour_id = localStorage.getItem("parlour_id");
        var data;
        data = {
          parlour: parlour_id,
          service_name: this.service_name,
          category: this.selectedCategory,
          duration: this.selectedDuration.id,
          price: this.price,
          description: this.description,
        };
        // }
        const successHandler = (response) => {
          this.loader = false;
          this.$store.dispatch(
            "services/callFilterServicesApi",
            this.selectedCategory
          );
          this.$store.dispatch("dialog/setServiceDrawer");

          this.$store.dispatch("profile/callParlourDetailsApi");

          this.$refs.new_service_modal.reset();
          this.selectedCategory = "";
          this.selectedDuration = "";
          this.service_name = "";
          this.description = "";
          this.showSnackBar(response.data.message, "#4CA955", 1500);
        };
        const failureHandler = (error) => {
          if (error && error.data && error.data.message) {
            this.loader = false;
            this.showSnackBar(error.data.message, "#B3261E", 1500);
          }
        };
        return this.$axios("post", this.$apiUrl.CREATE_SERVICE, {
          data,
          onSuccess: successHandler,
          onFailure: failureHandler,
          isTokenRequired: true,
        });
      }
    },
    EditService() {
      this.loader = true;
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        id: this.current_item_id,
      };
      let data = {
        service_name: this.service_name,
        parlour: parlour_id,
        category: this.selectedCategory,
        duration: this.selectedDuration.id,
        price: this.price,
        description: this.description,
      };
      const successHandler = (response) => {
        this.loader = false;
        this.$store.dispatch("dialog/setServiceDrawer");
        this.$store.dispatch(
          "services/callFilterServicesApi",
          this.selectedCategory
        );
        this.showSnackBar(response.data.message, "#4CA955", 1500);
        this.$refs.new_service_modal.reset();
        this.selectedCategory = "";
        this.selectedDuration = "";
        this.service_name = "";
        this.description = "";
      };
      const failureHandler = (error) => {
        this.loader = false;
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("patch", this.$apiUrl.EDIT_SERVICE, {
        params,
        data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>
<style scoped>
.v-list-item__title {
  color: blue !important;
}

label {
  color: black !important;
}

.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 20px !important;
}
</style>

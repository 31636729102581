<template>
    <div>
        <!-- <div class="pt-4 pb-7" style="position: relative; height:100vh">
            <router-view></router-view>
        </div> -->
           <div class="pt-4 pb-7" v-if="$isDesktop">
            <router-view></router-view>
        </div>
        <div v-if="$isMobile">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    name: "Body"
}
</script>
<style scoped></style>
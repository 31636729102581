<template>
    <v-row justify="center">
        <v-dialog v-model="drawer" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Parlour Details</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="parlour_profile">
                            <v-row dense>
                                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                                    <label>Parlour Name</label>
                                    <v-text-field dense outlined placeholder="Enter your first name" required
                                        v-model="parlour_name"
                                        @input="parlour_name = capitalize(parlour_name)"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                                    <label>Country</label>
                                    <v-text-field disabled dense outlined placeholder="Please select country" class="md-mr-0 mr-2"
                                        v-model="country_name" prepend-inner-icon
                                        @input="country_name = capitalize(country_name)">
                                        <template #prepend-inner>
                                            <!-- {{ selected_flag }} -->
                                            <v-img :src="selected_flag" class="flag_img"></v-img>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item v-for="(item, index) in country_list" :key="index"
                                                        @click="selectPhoneCode(item)">
                                                        <v-list-item-title>{{ item.phone_code }} {{ item.name
                                                        }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                                    <label>Address</label>
                                    <v-text-field dense outlined placeholder="Enter your address" required v-model="address"
                                        @input="address = capitalize(address)" prepend-inner-icon="mdi-map-marker"
                                        @click:prepend-inner="openLocationModal"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal">
                        Cancel
                    </v-btn>
                    <v-btn class="text-capitalize white--text" color="#F2797B" @click="editParlourDetails">
                        Edit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <LocationModal></LocationModal>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import LocationModal from '../signup/LocationModal.vue';
export default {
    name: "UserProfileModal",
    data() {
        return {
            parlour_name: "",
            mobile: null,
            address: "",
            country_id: "103",
            country_name: "",
            selected_phone_code: "+91",
            selected_flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
        }
    },
    computed: {
        ...mapGetters({
            drawer: "dialog/getParlourProfileModal",
            parlour_details: "profile/get_parlour_details",
            parlour_basic_details: "profile/get_parlour_basic_details",
            map_address: 'parlour_details/getMapAdrress'
        })
    },
    components: {
        LocationModal
    },
    watch: {
        map_address(val) {
            this.address = val.address
        },
        parlour_basic_details() {
            this.country_name = this.parlour_basic_details.country_name
            this.address = this.parlour_basic_details.location
            this.parlour_name = this.parlour_basic_details.parlour_name
        }
    },
    mounted() {
        this.$store.dispatch("profile/callParlourDetailsApi")
        this.getListOfPhoneCodes()
    },
    methods: {
        closeModal() {
            this.$store.dispatch("dialog/setParlourProfileModal")
        },
        openLocationModal() {
            this.$store.dispatch("dialog/setLocation", true);
        },
        getListOfPhoneCodes() {
            const successHandler = (response) => {
                this.country_list = response.data.phone_code_list
            }
            const failureHandler = (error) => {
                console.log("error is ", error)
            }
            return this.$axios("get", this.$apiUrl.PHONE_CODE, {
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: false,
            });
        },
        // selectPhoneCode
        selectPhoneCode(item) {
            this.selected_phone_code = item.phone_code
            this.selected_flag = item.flag
            this.country_id = item.id
            this.country_name = item.name
        },

        // patch api =>  
        editParlourDetails() {
            let data = new FormData()
            data.append("parlour_name", this.parlour_name)
            data.append("country", this.country_id)
            data.append("location", this.address)
            let params = {
                id: localStorage.getItem("parlour_id")
            };
            const successHandler = (response) => {
                this.country_list = response.data.phone_code_list
                this.$store.dispatch("dialog/setParlourProfileModal")
                // get parlourdetails api to update the staff details in ui after editing the details
                this.$store.dispatch("profile/setUpdateProfileFlag")
                this.$store.dispatch("profile/callParlourDetailsApi")
                this.$refs.parlour_profile.reset();
            }
            const failureHandler = (response) => {
                // console.log("error is ", error)
                 this.showSnackBar(response.data.message, "#B3261E", 1500);
            }
            return this.$axios("patch", this.$apiUrl.EDIT_PARLOUR_DETAILS, {
                data,
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        }
    }
}
// }
</script>
<style scoped>
label {
    color: black !important
}

.flag_img {
    width: 20px;
    height: 20px;
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}
</style>
<template>
  <v-container>
    <v-container class="d-flex flex-column justify-center mt-3">
      <v-row class="d-flex align-center mt-1">
        <div class="d-flex">
          <v-icon color="black" class="ma-2" @click="goBack"
            >mdi-arrow-left</v-icon
          >
          <p class="page_name mb-0">{{ $route.meta.title }}</p>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex mr-1" v-if="current_user_role === 'Admin'">
          <v-btn
            class="text-capitalize logout-btn py-3"
            outlined
            @click="deleteAccount"
          >
            <v-icon left dark> mdi-delete </v-icon> Delete Account
          </v-btn>
        </div>
        <div class="d-flex" v-if="$isDesktop">
          <v-btn
            class="text-capitalize logout-btn"
            outlined
            @click="logout_user"
          >
            <v-icon left dark> mdi-logout </v-icon> Logout
          </v-btn>
        </div>
      </v-row>
      <v-row class="d-flex align-center mt-8">
        
        <v-avatar v-if='user_logo == ""' color="primary" size="56" class="white--text mr-5">{{
          initials
        }}</v-avatar>
        <v-avatar v-else size="56" class="white--text mr-5"><img :src="user_logo"></v-avatar>
        <div>
          <p class="user_name">{{ current_user }}</p>
          <p class="user_role">{{ current_user_role }}</p>
        </div>
      </v-row>
      <v-row class="mt-10">
        <v-card class="flex-grow-1">
          <v-card-title>
            <div class="d-flex justify-space-between">
              <p class="title">Personal Information</p>
              <!-- <v-spacer></v-spacer> -->
              <!-- <v-icon large @click="openUserProfileModal">mdi-pencil-circle-outline</v-icon> -->
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle>
            <div class="d-flex">
              <div>
                <p class="text_header">Name</p>
                <p class="text_content">{{ current_user }}</p>
              </div>
              <v-spacer></v-spacer>
              <v-icon large @click="openUserProfileModal"
                >mdi-pencil-circle-outline</v-icon
              >
            </div>
            <div class="mt-4 mb-2 d-flex">
              <div>
                <p class="text_header">Phone</p>
                <p class="text_content">(+91){{ current_user_mobile }}</p>
              </div>
              <v-spacer></v-spacer>
              <v-icon large @click="updateUserMobileModal"
                >mdi-pencil-circle-outline</v-icon
              >
            </div>
          </v-card-subtitle>
        </v-card>
      </v-row>
      <v-row class="my-5">
        <v-card class="flex-grow-1">
          <v-card-title>
            <div class="d-flex justify-space-between">
              <p class="title">Parlour Details</p>
              <v-spacer></v-spacer>
              <v-icon large @click="openParlourProfileModal"
                >mdi-pencil-circle-outline</v-icon
              >
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle>
            <div>
              <p class="text_header">Parlour Name</p>
              <p class="text_content">{{ parlour_details.parlour_name }}</p>
            </div>
            <div class="mt-4 mb-2">
              <p class="text_header">Country</p>
              <p class="text_content">{{ parlour_details.country_name }}</p>
            </div>
            <div class="mt-4 mb-2">
              <p class="text_header">Address</p>
              <p class="text_content">{{ parlour_details.location }}</p>
            </div>
          </v-card-subtitle>
        </v-card>
      </v-row>
      <UserProfileDialog></UserProfileDialog>
      <ParlourProfileModal></ParlourProfileModal>
      <LogOutConfirmation></LogOutConfirmation>
      <UserNumberEditDialog></UserNumberEditDialog>
      <UserNewNumberDialog></UserNewNumberDialog>
    </v-container>
  </v-container>
</template>
<script>
import UserProfileDialog from "@/components/profile/UserProfileDialog.vue";
import ParlourProfileModal from "@/components/profile/ParlourProfileModal.vue";
import LogOutConfirmation from "@/components/profile/LogOutConfirmation.vue";
import UserNumberEditDialog from "../../components/profile/UserNumberEditDialog.vue";
import UserNewNumberDialog from "../../components/profile/UserNewNumberDialog.vue";
import { mapGetters } from "vuex";
export default {
  name: "Profile",
  data() {
    return {
      parlour_details: "",
      logged_in_user_id: "",
      current_user: "",
      current_user_mobile: "",
      current_user_role: "",
      initials: "",
      user_logo:"",
    };
  },
  computed: {
    ...mapGetters({
      profile_flag: "profile/get_update_profile_flag",
    }),
  },
  watch: {
    profile_flag() {
      this.getParlourDetails();
    },
  },
  components: {
    UserProfileDialog,
    ParlourProfileModal,
    LogOutConfirmation,
    UserNumberEditDialog,
    UserNewNumberDialog,
  },
  mounted() {
    this.getParlourDetails();
  },
  methods: {
    goBack() {
      if (this.$isDesktop) {
        this.$router.push({
          name: "dashboard",
        });
      } else {
        this.$router.push({
          name: "more-tabs",
        });
      }
    },
    openUserProfileModal() {
      this.$store.dispatch("dialog/setUserProfileModal");
    },
    openParlourProfileModal() {
      this.$store.dispatch("dialog/setParlourProfileModal");
    },
    updateUserMobileModal() {
      this.$store.dispatch("dialog/setUserNumEdit", true);
    },
    logout_user() {
      this.$store.dispatch("dialog/setLogoutConfirmationModal");
    },
    deleteAccount() {
      this.$router.push("/delete-account");
    },
    getParlourDetails() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        id: parlour_id, // Adding parlour_id as a query parameter
      };
      const successHandler = (response) => {
        this.parlour_details = response.data.parlour_detail;
        this.current_user =
          response.data.parlour_detail.logged_in_user.full_name;
        this.initials = this.$initials(this.current_user);
        this.user_logo = response.data.parlour_detail.logo
        this.current_user_mobile =
          response.data.parlour_detail.logged_in_user.mobile;
        this.current_user_role =
          response.data.parlour_detail.logged_in_user.role;
        this.current_user_role =
          response.data.parlour_detail.logged_in_user.role;
        this.logged_in_user_id = response.data.parlour_detail.logged_in_user.id;
        localStorage.setItem("user_id", this.logged_in_user_id);

        let current_user_details = {
          name: this.current_user,
          number: this.current_user_mobile,
          role: this.current_user_role,
          user_id: this.logged_in_user_id,
          first_name: response.data.parlour_detail.first_name,
          last_name: response.data.parlour_detail.last_name,
        };
        this.$store.dispatch("profile/setUserDetails", current_user_details);
      };
      const failureHandler = () => {};
      return this.$axios("get", this.$apiUrl.GET_PARLOUR_DETAILS, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>
<style scoped>
.logout-btn {
  color: #b90225 !important;
  border-radius: 10px !important;
}

.title {
  font-weight: 800;
}

p {
  margin-bottom: 0 !important;
}

.edit_btn {
  color: #79747e;
  border-radius: 100px !important;
}

.v-card__title {
  display: block !important;
}

.title {
  color: black !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.text_header {
  color: #6e7b89 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.text_content {
  color: #39434d !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.user_name {
  color: #000000 !important;
  font-size: 28px !important;
  font-weight: 500 !important;
}

.user_role {
  color: #9397a7 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.page_name {
  color: #404c57 !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

@media all and (max-width: 601px) {
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
</style>

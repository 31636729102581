<template>
    <v-row justify="space-around">
        <v-dialog transition="dialog-bottom-transition" max-width="328" height="100" v-model="dialog">
            <v-card>
                <v-toolbar color="#F2797B" dark>Other Enquiries</v-toolbar>
                <v-form ref="custom_query_modal">
                    <v-card-text>
                        <v-textarea class="mt-5" outlined v-model="query" :rules="[$rules.required]" name="input-7-4"
                            label="Please write your query here ...."></v-textarea>
                    </v-card-text>
                </v-form>
                <v-card-actions class="justify-end">
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal">Close</v-btn>
                    <v-btn class="text-capitalize white--text" color="#F2797B" @click="CreateCustomQuery">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "CustomQueryModal",
    data() {
        return {
            parlour: "",
            query: "",
            query_id: "",
            flag: false
        }
    },
    computed: {
        ...mapGetters({
            dialog: "dialog/getCustomQueryModal",
        })
    },
    methods: {
        // create new query custom query to get Id
        CreateCustomQuery() {
            if (this.$refs.custom_query_modal.validate()) {
                this.parlour = localStorage.getItem("parlour_id")
                let data = {
                    "parlour": this.parlour,
                    "query_title": this.query
                }
                const successHandler = (response) => {
                    this.$store.dispatch("dialog/setCustomQuery")
                    this.query_id = response.data.response.query_id;
                    this.flag = true,
                        this.$store.dispatch("enquiry_details/setCustomQueryInfo", {
                            query_text: this.query,
                            query_id: this.query_id,
                            flag: this.flag,
                        })
                    this.$refs.custom_query_modal.reset();
                }
                const failureHandler = (error) => {
                    console.log("error of createClient ", error)
                };
                const finallyHandler = () => {
                };
                return this.$axios("post", this.$apiUrl.CREATE_NEW_QUERY, {
                    data,
                    onSuccess: successHandler,
                    onFailure: failureHandler,
                    onFinally: finallyHandler,
                    isTokenRequired: true,
                });
            }
        },
        closeModal() {
            this.$store.dispatch("dialog/setCustomQuery")
        }
    }
}
</script>
import Vue from "vue";
import VueRouter from "vue-router";
import Base from "../components/layout/Base.vue";
import dashboard from "../views/dashboard/dashboard.vue";
import Client from "../views/clients-page/Client.vue";
import enquiry from "../views/enquiries/Enquiry.vue";
import Signup from "../views/signup-page/Signup.vue";
import BasicParlourDetails from "../views/signup-page/BasicParlourDetails.vue";
import Profile from "../views/profile/Profile.vue";
import Login from "../views/login/Login.vue";
import Services from "../views/services-page/Services";
import appointment from "../views/appointments/appointment.vue";
import TeamManagement from "../views/team-management/TeamManagement.vue";
import MemberDetail from "../views/team-management/MemberDetail.vue";
import TermsOfService from "../views/termsAndPolicies/TermsOfService.vue";
import PrivacyPolicy from "../views/termsAndPolicies/PrivacyPolicy.vue";
import MoreTabs from "../views/more-tabs(mobile)/MoreTabs.vue";
import reports from "../views/reports/report.vue";
import ClientDetails from "../views/clients-page/ClientDetail.vue";
import accountDeletionPage from "../views/account-delete/accountDeletionPage.vue"

Vue.use(VueRouter);
const routes = [
  // before login
  {
    path: "/",
    name: "login",
    meta: { requiresAuth: false, title: "Login", show_back_btn: false },
    component: Login,
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: Signup,
    meta: { requiresAuth: false },
  },
  {
    path: "/parlour-details",
    name: "parlour-details",
    component: BasicParlourDetails,
    meta: { requiresAuth: false },
  },
  {
    path: "/terms-of-service",
    name: "terms-of-service",
    component: TermsOfService,
    meta: { requiresAuth: false },
  },
  {
    path: "/privacy-policies",
    name: "privacy-policies",
    component: PrivacyPolicy,
    meta: { requiresAuth: false },
  },
  // after login
  {
    path: "/",
    name: "admin",
    component: Base,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { requiresAuth: true, title: "Dashboard", show_back_btn: false },
        component: dashboard,
      },
      {
        path: "/profile",
        name: "profile",
        meta: { requiresAuth: true, title: "Profile", show_back_btn: false },
        component: Profile,
      },
      {
        path: "/client",
        name: "client",
        meta: { requiresAuth: true, title: "Clients", show_back_btn: false },
        component: Client,
      },
      {
        path: "client-details/:id",
        name: "client-details",
        meta: {
          requiresAuth: true,
          title: "Client Details",
          show_back_btn: false,
        },
        component: ClientDetails,
      },
      {
        path: "/enquiries",
        name: "enquiries",
        meta: { requiresAuth: true, title: "Enquiries", show_back_btn: false },
        component: enquiry,
      },
      {
        path: "/appointments",
        name: "appointments",
        meta: {
          requiresAuth: true,
          title: "Appointments",
          show_back_btn: false,
        },
        component: appointment,
      },
      {
        path: "/services",
        name: "services",
        meta: { requiresAuth: true, title: "Services", show_back_btn: true },
        component: Services,
      },
      {
        path: "/team-management",
        name: "team-management",
        meta: {
          requiresAuth: true,
          title: "Team Management",
          show_back_btn: true,
        },
        component: TeamManagement,
      },
      {
        path: "member-details/:id",
        name: "member-details",
        meta: {
          requiresAuth: true,
          title: "Details",
          show_back_btn: false,
        },
        component: MemberDetail,
      },
      {
        path: "more-tabs",
        name: "more-tabs",
        meta: {
          requiresAuth: true,
          title: "",
          show_back_btn: false,
        },
        component: MoreTabs,
      },
      {
        path: "reports",
        name: "reports",
        meta: {
          requiresAuth: true,
          title: "Reports",
          show_back_btn: false,
        },
        component: reports,
      },
      {
        path: "delete-account",
        name: "delete-account",
        meta: {
          requiresAuth: true,
          title: "Delete Account",
          show_back_btn: false,
        },
        component: accountDeletionPage
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
router.beforeEach((to, from, next) => {
  // let token = localStorage.getItem("access_token");
  let parlour_id = localStorage.getItem("parlour_id");
  if (to.meta.requiresAuth === true && !parlour_id) {
    router.push({ name: "login" });
  } else if (
    from.name === "dashboard" &&
    to.name === "login" &&
    parlour_id !== null
  ) {
    next(false);
  } else next();
});

export default router;

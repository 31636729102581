<template>
  <div>
    <v-snackbar v-model="onlineStatusSnackbar" :color="onlineStatusColor" top center timeout="2000">
      {{ onlineStatusMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar()">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
  
<script>
export default {
  name: "NetworkIssue",
  data() {
    return {
      onlineStatusSnackbar: false,
      onlineStatusMessage: "",
      onlineStatusColor: "",
    };
  },
  mounted() {
    window.addEventListener("online", this.showOnlineSnackbar);
    window.addEventListener("offline", this.showOfflineSnackbar);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.showOnlineSnackbar);
    window.removeEventListener("offline", this.showOfflineSnackbar);
  },
  methods: {
    closeSnackbar() {
      this.onlineStatusSnackbar = false;
    },
    showOnlineSnackbar() {
      this.onlineStatusMessage = "You are online now!";
      this.onlineStatusColor = "green";
      this.onlineStatusSnackbar = true;
    },
    showOfflineSnackbar() {
      this.onlineStatusMessage = "Oops! You are offline now!";
      this.onlineStatusColor = "#E50000";
      this.onlineStatusSnackbar = true;
    },
  },
};
</script>
  
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Enquiry Details</span>
                    <v-spacer></v-spacer>
                    <span>
                        <v-icon color="black" large @click="closeModal">mdi-close</v-icon>
                    </span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-form ref="new_enquiry_creating_form">
                            <v-row dense>
                                <v-col cols="12" sm="6" md="12" class="pb-0 pt-0">
                                    <label>Mobile Number*</label>
                                    <!-- country text-field -->
                                    <v-text-field @keypress="isNumber($event)" maxlength="10" dense outlined
                                        placeholder="Please enter mobile number" class="md-mr-0 mr-2" v-model="mobile"
                                        prepend-inner-icon :rules="[$rules.mobile_number]" @change="getName()">
                                        <template #prepend-inner>
                                            <v-img :src="selected_flag" class="flag_img"></v-img> &nbsp;
                                            <p class="mt-1">{{ selected_phone_code }}</p> &nbsp;
                                            <!-- <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template>
<v-list>
    <v-list-item v-for="(item, index) in country_list" :key="index" @click="selectPhoneCode(item)">
        <v-list-item-title>{{ item.phone_code }}</v-list-item-title>
    </v-list-item>
</v-list>
</v-menu> -->
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="12" class="pb-0 pt-0">
                                    <label>Name*</label>
                                
                                    <v-text-field dense outlined placeholder="Enter Customer's Name" required
                                        v-model="name" :rules="[$rules.name, $rules.required]"
                                        @input="name = capitalize(name)"></v-text-field>
                                </v-col>
                                
                                <!-- text-field -->
                                <v-col cols="12" sm="6" md="12" class="pb-0 pt-0">
                                    <label>Query*</label>
                                    <v-select clearable dense outlined :items="query_list" return-object
                                        item-value="query_id" item-text="query_title" placeholder="Select a query"
                                        v-model="selectedQuery" :rules="[$rules.required]">
                                    </v-select>
                                    <!-- Options -->
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal()">
                        Cancel
                    </v-btn>
                    <v-btn :loading="loader" @click="CreateNewEnquiry" class="text-capitalize white--text" color="#F2797B">
                        Add Enquiry
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <CustomQueryModal></CustomQueryModal>
    </v-row>
</template>
<script>
import CustomQueryModal from './CustomQueryModal.vue'
import { mapGetters } from 'vuex'
export default {
    name: "NewEnquiryModal",
    data() {
        return {
            parlour: "",
            name: "",
            country: "103",
            mobile: "",
            selectedQuery: "",
            query_id: "",
            // query: "",
            country_list: [],
            selected_flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
            selected_country: "India",
            selected_phone_code: "+91",
            query_list: [],
            loader: false
        }
    },
    components:
    {
        CustomQueryModal
    },
    computed: {
        ...mapGetters({
            dialog: "dialog/getNewEnquiryDrawer",
            CustomQueryObject: "enquiry_details/getCustomQueryObject",
            querylist_apiFlag: "enquiry_details/getQueryListApiFlag",
        }),
    },
    watch: {
        CustomQueryObject(newValue) {
            if (newValue && newValue.id) { // Check if newValue and its property exist
                this.getListOfQueries()
                this.query_id = newValue.id;
                this.selectedQuery = newValue;
            }
        },
        selectedQuery(newVal) {
            if (newVal) {
                if (newVal.query_title == "Others") {
                    this.$store.dispatch("dialog/setCustomQuery")
                } else {
                    if (typeof newVal === 'object') {
                        this.query_id = newVal.id;
                    }
                }
            }
        },
        querylist_apiFlag() {
            this.getListOfQueries()
        },
    },
    mounted() {
        this.getListOfPhoneCodes()
        this.getListOfQueries()
    },
    methods: {
        closeModal() {
            this.$store.dispatch("dialog/setNewEnquiryDrawer")
            this.$refs.new_enquiry_creating_form.reset();
            this.name = ""
        },
        // getListOfPhoneCodes api calling 
        getListOfPhoneCodes() {
            const successHandler = (response) => {
                this.country_list = response.data.phone_code_list
            }
            const failureHandler = (error) => {
                console.log("error is ", error)
            }
            return this.$axios("get", this.$apiUrl.PHONE_CODE, {
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: false,
            });
        },

        selectPhoneCode(item) {
            this.selected_flag = item.flag;
            this.selected_country = item.name;
            this.country = item.id;
            this.selected_phone_code = item.phone_code
        },

        // list all queries
        getListOfQueries() {
            let params = {
                parlour_id: localStorage.getItem("parlour_id")
            }
            const successHandler = (response) => {
                this.query_list = response.data.query_list
            }
            const failureHandler = (error) => {
                console.log("error is ", error)
            }
            return this.$axios("get", this.$apiUrl.GET_QUERY_LIST, {
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true
            });
        },
        // post new enquiry into the table
        CreateNewEnquiry() {
            if (this.$refs.new_enquiry_creating_form.validate()) {
                this.loader = true
                this.parlour = localStorage.getItem("parlour_id")
                let data = {
                    "parlour": this.parlour,
                    "name": this.name,
                    "country": this.country,
                    "mobile": this.mobile,
                    "query": this.query_id,
                }
                const successHandler = (response) => {
                    this.loader = true
                    this.$store.dispatch("dialog/setNewEnquiryDrawer");
                    this.$refs.new_enquiry_creating_form.reset();
                    this.showSnackBar(response.data.message, "#4CA955", 1500);

                    // trigger get api for listing all enquiries
                    this.$store.dispatch("enquiry_details/setEnquiryListApi");
                }
                const failureHandler = (error) => {
                    if (error && error.data && error.data.message) {
                        this.loader = false
                        this.showSnackBar(error.data.message, "#B3261E", 1500);
                    }
                };
                const finallyHandler = () => {
                    this.loader = false
                };
                return this.$axios("post", this.$apiUrl.CREATE_ENQUIRY, {
                    data,
                    onSuccess: successHandler,
                    onFailure: failureHandler,
                    onFinally: finallyHandler,
                    isTokenRequired: true,
                });
            }
        },
        getName(){
            let params = {
                parlour_id: localStorage.getItem("parlour_id"),
                mobile: this.mobile
            }
            const successHandler = (response) => {
                this.name = response.data.response_json.client_name
            }
            const failureHandler = (error) => {
                console.log("error is ", error)
            }
            return this.$axios("get", this.$apiUrl.GET_NAME, {
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true
            });
        }
    }
}
</script>
<style scoped>
.mdi-chevron-down::before {
    margin-top: -8px !important;
}

label {
    color: black !important
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}

.flag_img {
    width: 20px;
    height: 20px;
}
</style>
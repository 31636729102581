<template>
  <v-container class="d-flex justify-center align-center">
    <v-card elevation="2" class="px-5 my-md-5" style="border-radius: 28px">
      <div class="d-flex justify-center">
        <v-img src="../../assets/brush_logo.svg" class="logo_img mt-8"></v-img>
      </div>
      <v-card-title class="card_title"
        >Upload your parlour's photos or videos here</v-card-title
      >
      <div class="file_main_div ma-5 d-flex">
        <div v-for="item in file_array" :key="item.index">
          <template v-if="item.type === 'image'">
            <v-img :src="item.url" class="image_div ma-5 d-flex justify-right">
              <div
                class="delete_div pa-4 d-flex align-center justify-center"
                style="position: relative"
                @click="deleteMedia(item)"
              >
                <v-icon color="red">mdi-delete-outline</v-icon>
              </div>
            </v-img>
          </template>
          <template v-else>
            <video :src="item.url" class="video_div ma-5"></video>
            <div
              class="pa-4 d-flex align-center justify-center delete_div_video"
              style="position: relative; bottom: 302px; left: 160px"
              @click="deleteMedia(item)"
            >
              <v-icon style="color: red">mdi-delete-outline</v-icon>
            </div>
          </template>
        </div>

        <div
          class="file_div ma-5 text-center d-flex flex-column align-center justify-center"
          @click="triggerInputField()"
          style="position: relative"
        >
          <v-progress-circular
            v-if="isLoading"
            class="circular-progress ma-5 text-center d-flex flex-column align-center justify-center"
            :size="60"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div v-else>
            <v-icon color="black" class="file_upload_icon mb-2" large
              >mdi-file-upload-outline</v-icon
            >
            <p class="text_upload">
              Upload your parlour's videos here or
              <span class="browse_btn">browse</span>
            </p>
          </div>
        </div>
      </div>

      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              block
              outlined
              class="text-capitalize"
              color="#F2797B"
              @click="navigateToParlourDetailPage()"
            >
              Back
            </v-btn>
          </v-col>

          <v-col>
            <v-btn
              :loading="signUpLoading"
              block
              class="sign_up_btn text-capitalize white--text"
              @click="register_parlour()"
              color="#F2797B"
            >
              Sign Up
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <input
        @change="fileChangeHandle()"
        accept="video/*,image/*"
        type="file"
        ref="file_uploading_field"
        style="visibility: hidden"
      />
      <!-- <ImageCropperModal v-model="cropperModal" :image="file_url" @onCrop="onCrop" /> -->
    </v-card>
  </v-container>
</template>
<script>
// import ImageCropperModal from '../common/ImageCropperModal.vue'
import { mapGetters } from "vuex";
export default {
  name: "ParlourGallery",
  data() {
    return {
      file_url: "",
      file_array: [],
      selected_file: null,
      // parlour_details: null,
      file_id_array: [],
      parlour_id: "",
      media_id: null,
      latitude: "",
      longitude: "",
      isLoading: false,
      signUpLoading: false,
      // cropperModal: false,
    };
  },
  components: {
    // ImageCropperModal
  },
  computed: {
    ...mapGetters({
      parlour_detail: "parlour_details/getParlourDetails",
      address: "parlour_details/getMapAdrress",
    }),
    map_address: {
      get() {
        return this.address;
      },
      set(newValue) {
        return newValue;
      },
    },
    parlour_details: {
      get() {
        return this.parlour_detail;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  mounted() {
    this.getParlourMedia();
  },
  methods: {
    triggerInputField() {
      this.$refs.file_uploading_field.click();
    },
    fileChangeHandle() {
      this.uploadFile();
    },
    uploadFile() {
      this.selected_file = this.$refs.file_uploading_field.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        this.file_url = event.target.result;
      };
      reader.readAsDataURL(this.selected_file);
      this.postParlourMedia();
    },

    //getParlourMedia
    getParlourMedia() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        id: parlour_id,
      };
      const successHandler = () => {};
      const failureHandler = () => {};
      return this.$axios("get", this.$apiUrl.GET_PARLOUR_MEDIA, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },

    // api for posting parlour media
    postParlourMedia() {
      this.isLoading = true;
      let data = new FormData();
      data.append("file", this.selected_file);
      const successHandler = (response) => {
        this.isLoading = false;
        this.file_url = response.data.parlour_media.file;
        var full_file_type = this.selected_file.type;
        var file_type_array = full_file_type.split("/");
        var file_type = file_type_array[0];
        var file_id = response.data.parlour_media.id;
        this.media_id = file_id;
        this.file_id_array.push(file_id);
        this.file_array.push({
          url: this.file_url,
          type: file_type,
          id: file_id,
        });
      };
      const failureHandler = (error) => {
        if (error) {
          console.log("error is", error);
          this.isLoading = false;
        }
      };
      const finallyHandler = () => {
        console.log("finallyhandler");
      };
      return this.$axios("post", this.$apiUrl.POST_PARLOUR_MEDIA, {
        data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        onFinally: finallyHandler,
        isTokenRequired: true,
      });
    },

    // register parlour api POST_PARLOUR_DETAILS
    register_parlour() {
      this.signUpLoading = true;
      let data = new FormData();
      data.append("logo", this.parlour_details.logo);
      data.append("parlour_name", this.parlour_details.parlour_name);
      data.append("first_name", this.parlour_details.first_name);
      data.append("last_name", this.parlour_details.last_name);
      data.append("country", this.parlour_details.country);
      data.append("location", this.parlour_details.location);
      data.append("parlour_media", this.file_id_array);
      data.append("latitude", this.map_address.latitude);
      data.append("longitude", this.map_address.longitude);

      const successHandler = (response) => {
        this.signUpLoading = false;
        this.parlour_id = response.data.response.parlour_id;
        let parlour_is_active = response.data.response.is_active;

        // we are getting all the user and parlour details here also and will update that in store
        let role = response.data.response.role;
        let mobile = response.data.response.mobile;
        let name = response.data.response.name;
        let user_id = response.data.response.user_id;
        let is_staff_added = response.data.response.is_staff_added;
        let is_service_added = response.data.response.is_service_added;
        localStorage.setItem("parlour_id", this.parlour_id);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("user_role", role);
        localStorage.setItem("parlour_is_active", parlour_is_active);

        let current_user_details = {
          name: name,
          number: mobile,
          role: role,
          user_id: user_id,
        };
        let parlour_details = {
          parlour_id: this.parlour_id,
          is_staff_added: is_staff_added,
          is_service_added: is_service_added,
        };
        this.$store.dispatch("profile/setParlourDetails", parlour_details);
        this.$store.dispatch("profile/setUserDetails", current_user_details);
        this.$emit("childBtnClick", "3");

        this.$router.push({
          name: "dashboard",
        });
        this.showSnackBar("Logged In", "success", 1500);
      };
      const failureHandler = (error) => {
        if (error) {
          this.signUpLoading = false;
          console.log(error);
        }
      };
      const finallyHandler = () => {
        console.log("finallyHandler");
      };
      return this.$axios("post", this.$apiUrl.POST_PARLOUR_DETAILS, {
        data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        onFinally: finallyHandler,
        isTokenRequired: true,
      });
    },

    // navigateToParlourDetailPage
    navigateToParlourDetailPage() {
      this.$emit("childBtnClick", "1");
    },
    deleteMedia(item) {
      let params = {
        id: item.id,
      };
      const successHandler = () => {
        let index = this.file_array.findIndex((file) => file.id === item.id);
        this.file_array.splice(index, 1);
      };
      const failureHandler = () => {
        // if (error) {
        // }
      };
      const finallyHandler = () => {};
      return this.$axios("delete", this.$apiUrl.MEDIA_DELETE, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        onFinally: finallyHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>
<style scoped>
.text_upload {
  color: grey;
  font-weight: 600;
}

.card_title {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.circular-progress {
  position: absolute;
  top: 40%;
  left: 38%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* Adjust z-index as needed */
}

.delete_div {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 2px !important;
  position: absolute !important;
  top: 6px;
  left: 163px;
}

.delete_div_video {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 2px !important;
}

.browse_btn {
  color: black !important;
  font-weight: 600 !important;
  text-decoration: underline;
}

.logo_img {
  max-width: 185.96px !important;
  height: 58px !important;
}

.file_main_div {
  /* max-width: 800px; */
  max-width: 742px;
  border: 1px solid #f2797b;
  border-radius: 18px !important;
  display: flex;
  /* justify-content: center !important; */
  flex-wrap: wrap;
  gap: 10px !important;
  cursor: pointer !important;
}

.file_div {
  width: 200px !important;
  height: 280px;
  border-radius: 10px !important;
  border: dotted 2px #6e7b89;
  background-color: #f2f3f3;
}

.image_div,
.video_div {
  width: 200px !important;
  height: 280px;
}

@media all and (max-width: 600px) {
  .file_main_div {
    max-width: 800px;
    border: 1px solid #f2797b;
    border-radius: 18px !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    flex-wrap: wrap;
    gap: unset !important;
    cursor: pointer !important;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none !important;
  }

  /* .v-application .ma-5 {
        margin: 31px !important;
    } */

  .v-card__title {
    font-size: 21px !important;
  }
}
</style>

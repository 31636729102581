<template>
  <v-row justify="center">
    <v-bottom-sheet v-model="dialog_value" max-width="528" height="100vh">
      <v-card
        style="border-top-left-radius: 15px; border-top-right-radius: 15px"
      >
        <v-card-text>
          <div class="d-flex">
            <p class="mb-0 header my-5 mb-5">Generate Report</p>
            <v-spacer></v-spacer>
            <v-icon @click="closeModal()">mdi-close</v-icon>
          </div>
          <div>
            <div>
              <label>Date</label>
              <v-menu
                ref="menu2"
                v-model="menu_2"
                :close-on-content-click="false"
                :return-value.sync="dates_2"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="status_filter"
                    clearable
                    outlined
                    dense
                    v-model="dates_2"
                    placeholder="From - To"
                    append-icon="mdi-chevron-down"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates_2" no-title scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu_2 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(dates_2)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
            <div>
              <label>Report Type</label>
              <v-select
                clearable
                :items="report_type_list"
                item-value="id"
                item-text="category_name"
                outlined
                dense
                v-model="selected_report_type"
                class="status_filter"
                placeholder="Select Report Type"
              >
              </v-select>
            </div>
            <div v-if="active_filter === 'Sales Report'">
              <label>Category</label>
              <v-select
                clearable
                :items="category_list"
                item-value="id"
                item-text="category_name"
                outlined
                dense
                v-model="selected_category"
                class="status_filter"
                placeholder="Select Category"
              >
              </v-select>
            </div>
            <div v-else>
              <label>Executive</label>
              <v-select
                clearable
                :items="executive_list"
                item-value="id"
                item-text="full_name"
                outlined
                dense
                v-model="selected_executive"
                class="status_filter"
                placeholder="Select Executive"
              >
              </v-select>
            </div>
          </div>
          <v-card-actions>
            <v-btn
              :disabled="isGenerateReportDisabled"
              block
              class="text-capitalize white--text"
              color="primary"
              @click="generateReport"
            >
              Generate
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "reportFilterPage",
  data() {
    return {
      menu_2: "",
      dates_2: [],
      executive_list: [],
      selected_executive: "",
      selected_category: "",
      status_category: "",
      table_list: [],
      report_type_list: ["Sales Report", "Executive Report"],
      selected_report_type: "Executive Report",
      active_filter: "",
      show_Loader: false,
    };
  },
  mounted() {
    this.getExecutiveList();
    this.getCategoryListing();
  },
  computed: {
    ...mapGetters({
      dialog_value: "dialog/gettoggleReportPage",
      category_list: "services/get_category_list",
    }),
  //   isGenerateReportDisabled() {
  //     if (
  //       (this.selected_category || this.selected_executive) &&
  //       this.dates_2 &&
  //       this.dates_2[0] &&
  //       this.dates_2[1]
  //     ) {
  //       return false; // Enable button if both category/executive and dates are selected
  //     } else {
  //       return true; // Disable button if any condition is not met
  //     }
  //   },
  // },
   isGenerateReportDisabled() {
      if (this.selected_report_type === "Sales Report" && this.selected_category && this.dates_2[0] && this.dates_2[1]) {
        return false; // Enable button if Sales Report and category and dates are selected
      } else if (this.selected_report_type === "Executive Report" && this.selected_executive && this.dates_2[0] && this.dates_2[1]) {
        return false; // Enable button if Executive Report and executive and dates are selected
      } else {
        return true; // Disable button if any condition is not met
      }
    },
  },
  watch: {
    searchQuery() {
      this.generateReport();
    },
    selected_executive(val) {
      if (val) {
        this.selected_category = "";
        // this.generateReport()
      }
    },
    selected_category(val) {
      if (val) {
        // this.generateReport()
        this.selected_executive = "";
      }
    },
    selected_report_type(val) {
      if (val === "Executive Report") {
        this.active_filter = "Executive Report";
      } else {
        this.active_filter = "Sales Report";
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch("dialog/toggleReportPage", false);
    },
    getExecutiveList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.executive_list = response.data.staff_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_EXECUTIVE_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getCategoryListing() {
      this.$store.dispatch("services/ListCategory");
    },
    generateReport() {
      this.show_Loader = true;
      let parlour_id = localStorage.getItem("parlour_id");
       // Ensure dates_2 are defined and have values before proceeding
  if (!this.dates_2 || !this.dates_2[0] || !this.dates_2[1]) {
    console.error("Dates are not properly selected");
    this.show_Loader = false;
    return;
  }
      //  this.$store.dispatch("reports/totalAmount", null);
      let params = {
        parlour_id: parlour_id,
        executive: this.selected_executive,
        from_date: this.dates_2[0],
        to_date: this.dates_2[1],
        search: this.searchQuery,
        service_category: this.selected_category,
      };
      console.log("Params: ", params);
      const successHandler = (response) => {
        this.show_Loader = false;
        this.table_list = response.data.response;
        this.table_list.forEach((item) => {
          this.total_amount = item.total_amount;
        });
        this.$store.dispatch("reports/reportsData", this.table_list);
        this.$store.dispatch("reports/totalAmount", this.total_amount);
        let report_status = {
          selected_report_type: this.selected_report_type,
          from_date: this.dates_2[0],
          to_date: this.dates_2[1],
        };
        this.$store.dispatch("reports/report_status", report_status);
        this.$store.dispatch("dialog/toggleReportPage", false);
      };
      const failureHandler = (error) => {
        this.show_Loader = false;
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GENERATE_REPORT, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 18px !important;
}

.header {
  color: #232323;
  font-weight: 600;
  font-size: 20px;
}

label {
  color: #121212;
  font-weight: 400;
  font-size: 12px;
}
</style>

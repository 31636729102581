<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Move To Follow Up</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <!-- text-field -->
                            <v-col cols="12" sm="6" md="12">
                                <p class="mb-0">Are you sure you want to move the enquiry to follow up ?</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined @click="closeModal()" class="text-capitalize" color="#F2797B">
                        No
                    </v-btn>
                    <v-btn  color="#F2797B" class="text-capitalize white--text" @click="moveToFollowUp()">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "ConfirmationModal",
    data() {
        return {
            // parlour: "",
            // name: "",
            // country: "",
            // mobile: "",
            // reason_list: [],
        }
    },
    computed: {
        ...mapGetters({
            dialog: "dialog/getConfirmation",
        }),
    },

    methods: {
        closeModal() {
            this.$store.dispatch("dialog/setConfirmationModal")
        },

        moveToFollowUp() {
            this.$store.dispatch("enquiry_details/setUpdateEnquiryListApi");
        }
    }
}
</script>
<style scoped>
label {
    color: black !important
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}

.flag_img {
    width: 20px;
    height: 20px;
}
</style>
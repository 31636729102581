<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field outlined dense hide-details placeholder="Search Clients" prepend-inner-icon="mdi-magnify"
          v-model="searchQuery" class="search_bar" />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="6">
        <v-select  clearable  :items="status_items" outlined dense placeholder="Status :" v-model="selected_status"
          @change="getClientList" prepend-inner-icon="mdi-filter-outline" class="filter"></v-select>
      </v-col>
    </v-row> -->
    <v-row class="ma-2 header">Clients</v-row>


    <!-- skeleton loader -->
    <v-row v-if="client_list.length === 0">
      <v-col cols="12">
        <v-skeleton-loader max-width="400" type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader max-width="400" type="list-item-two-line"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row>
      <v-simple-table fixed-header class="mb-2">
        <template v-slot:default>
          <tbody>
            <tr v-for="(item, index) in client_list" :key="index" class="py-12">
              <td>
                <!-- <p class="client_name my-2">{{ item.full_name }}</p> -->
                <p class="mt-1">
                  <router-link :to="{ name: 'client-details', params: { id: item.id } }">{{ item.full_name
                    }}</router-link>
                </p>
                <p class="client_number my-2">+ 91{{ item.mobile }}</p>
              </td>
              <td class="text-right"> <v-chip class="status_chip">{{ item.status }}</v-chip></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-fab-transition>
        <v-btn color="pink" dark absolute top right fab @click="openCreateNewClientModal()" class="fab_btn">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-row>
    <CreateClientModalMobile @callApi="getClientList"></CreateClientModalMobile>
  </v-container>
</template>
<script>
import CreateClientModalMobile from './CreateClientModalMobile.vue';
export default {
  name: "ClientMobile",
  data() {
    return {
      status_items: ["all", "active", "inactive"],
      selected_status: "all",
      searchQuery: "",
      client_list: [],
    };
  },
  components: {
    CreateClientModalMobile
  },
  mounted() {
    this.getClientList();
  },
  watch: {
    searchQuery() {
      this.getClientList();
    }
  },
  methods: {
    openCreateNewClientModal() {
      let data = {
        toggle_flag: true,
        clientAddOrEditFlag: "add"
      }
      this.$store.dispatch("dialog/setNewClientDrawerMobile", data)
    },
    getClientList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        "parlour_id": parlour_id,
        "status": this.selected_status,
        "search": this.searchQuery
      }
      const successHandler = (response) => {
        this.show_Loader = false
        this.client_list = response.data.client_list
      }
      const failureHandler = (error) => {
        this.showSnackBar(error.data.message, "#B3261E", 1500);
      }
      return this.$axios("get", this.$apiUrl.GET_CLIENT_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  }
};
</script>
<style scoped>
.fab_btn {
  position: fixed;
  left: 80vw;
  bottom: 5vh;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: 76vh !important;
}

p {
  margin-bottom: 0 !important;
}

.filter>>>.v-text-field__details {
  display: none !important;
}

.search_bar,
.filter {
  background-color: white !important;
}

.header {
  color: black !important;
  font-weight: 600;
  font-size: 20px;
}

.row {
  display: block !important;
}

.client_name {
  font-size: 16px;
  font-weight: 400;
  color: #404C57;
}

.client_number {
  font-size: 11px;
  font-weight: 400;
  color: #727272;
}

.status_chip {
  background-color: #E6FBD9 !important;
  color: #0F5B1D;
}
</style>
<template>
    <div>
        <TeamManagementDesktop v-if="$isDesktop"></TeamManagementDesktop>
        <TeamManagementMobile v-else></TeamManagementMobile>
    </div>
</template>
<script>
import TeamManagementDesktop from '../../components/team-management/TeamManagementDesktop.vue';
import TeamManagementMobile from '../../components/team-management/TeamManagementMobile.vue';
export default {
    name: "TeamMangement",
    components: {
        TeamManagementDesktop,
        TeamManagementMobile
    }
}
</script>
<style scoped></style>
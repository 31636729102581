<template>
  <v-row justify="center">
    <v-dialog v-model="flag" persistent max-width="528" height="200">
      <v-card>
        <v-card-title>
          <span class="text-h6">Edit Status</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row dense>
              <!-- text-field -->
              <v-col
                cols="12"
                sm="6"
                md="12"
                v-if="category_is_active == false"
              >
                <span class="label"
                  >Are you sure you want to activate team member ?</span
                >
              </v-col>
              <v-col cols="12" sm="6" md="12" v-else>
                <span class="label"
                  >Are you sure you want to deactivate team member ?</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#F2797B"
            class="text-capitalize"
            @click="EditStatus()"
          >
            Yes
          </v-btn>
          <v-btn
            @click="closeModal()"
            class="text-capitalize white--text"
            color="#F2797B"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "statusConfirmationModal",
  data() {
    return {
      team_member_id: "",
      flag: "",
      category_is_active: "",
    };
  },
  computed: {
    ...mapGetters({
      dialog: "dialog/getMemberStatusConfirmation",
    }),
  },
  watch: {
    dialog(val) {
       console.log('val',val);
      (this.flag = val.flag),
        (this.team_member_id = val.member_detials.id),
        (this.member_is_active = val.member_detials.is_active);
       
    },
  },
  methods: {
    closeModal() {
      let payload = {
        flag: false,
      };
      this.$store.dispatch("dialog/setMemberStatusConfirmition", payload);
    },
    EditStatus() {
      let params = {
        id: this.team_member_id,
      };
      let data = {
        is_active: !this.member_is_active,
      };
      const successHandler = () => {
        let payload = {
          flag: false,
        };
        this.$store.dispatch("dialog/setMemberStatusConfirmition", payload);
        let data = {
          search: "",
          status: "all",
        };
        this.$store.dispatch("team/callTeamListApi", data);
         let staff_id = this.$route.params.id;
        this.$store.dispatch("team/callGetStaffDetails", staff_id);
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("patch", this.$apiUrl.EDIT_STAFF, {
        params,
        data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>
<style scoped>
label {
  color: black !important;
}

.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 20px !important;
}

.flag_img {
  width: 20px;
  height: 20px;
}
</style>

export default {
  namespaced: true,
  state: {
    parlour_data: null,
    map_address:{
      address :"",
      latitude:"",
      longitude:""
    }
  },
  getters: {
    getParlourDetails: (state) => state.parlour_data,
    getMapAdrress: (state) => state.map_address,
  },
  mutations: {
    SET_PARLOUR_INFO(state, payload) {
      state.parlour_data = payload;
    },
    SET_MAP_ADDRESS(state, payload) {
      state.map_address = payload
      // state.map_address.address = payload.address;
      // state.map_address.latitude = payload.latitude;
      // state.map_address.longitude = payload.longitude;

    },
  },
  actions: {
    setParlourInfo({ commit }, payload) {
      commit("SET_PARLOUR_INFO", payload);
    },
    setMapAddress({ commit }, payload) {
      commit("SET_MAP_ADDRESS", payload);
    },
  },
};

<template>
    <v-row justify="center">
        <v-dialog v-model="drawer" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6 heading">Logout</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-5">
                    <p class="sub_text">Are You Sure You Want To Logout ?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal">
                        No
                    </v-btn>
                    <v-btn class="text-capitalize white--text" color="#F2797B" @click="logout">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "LogOutConfirmation",
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            drawer: "dialog/getLogoutConfirmationModal",
        })
    },
    mounted() {
        // this.getListOfPhoneCodes()
    },
    methods: {
        closeModal() {
            this.$store.dispatch("dialog/setLogoutConfirmationModal")
        },
        logout() {
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("parlour_id")
            localStorage.removeItem("user_id")
            localStorage.removeItem("user_role")
            localStorage.removeItem("is_parlour_registered")
            localStorage.removeItem("parlour_is_active")
            this.$store.dispatch('dialog/setNewBookingDrawer', false);
            this.$store.dispatch("dialog/setLogoutConfirmationModal")
            this.$router.push({
                name: "login"
            })
        },
    }
}
// }
</script>
<style scoped>
label {
    color: black !important
}

.heading {
    color: #F2797B;
}

.sub_text {
    color: black;
    font-size: 16px;
}

.flag_img {
    width: 20px;
    height: 20px;
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}
</style>
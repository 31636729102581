import { render, staticRenderFns } from "./TeamManagement.vue?vue&type=template&id=517c3edc&scoped=true"
import script from "./TeamManagement.vue?vue&type=script&lang=js"
export * from "./TeamManagement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517c3edc",
  null
  
)

export default component.exports
<template>
    <div>
        <appointmentDesktop v-if="$isDesktop"></appointmentDesktop>
        <appointmentMobile  v-if="$isMobile"></appointmentMobile>
    </div>
</template>
<script>
import appointmentDesktop from '@/components/appointments/appointmentDesktop.vue'
import appointmentMobile from '@/components/appointments/mobile_view/appointmentMobile.vue'
export default {
    name: "appointment",
    components:{
        appointmentDesktop,
        appointmentMobile,
    }
}
</script>
<style scoped></style>
// export default function getInitials(name) {
//     const words = name.split(' '); // Split the name into words
//     let initials = '';
    
//     // Iterate through each word and extract the first character
//     for (let i = 0; i < words.length; i++) {
//       initials += words[i].charAt(0).toUpperCase(); // Add the uppercase first character to the initials string
//     }
    
//     return initials;
//   }
export default function getInitials(name) {
  if (!name) {
    return ''; // Return an empty string or handle it accordingly
  }
  const parts = name.split(' ');
  return parts.map(part => part[0]).join('');
}
  
//   // Example usage:
//   const fullName = 'John Doe';
//   const initials = getInitials(fullName); // Returns 'JD'
  
<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog.open"
      height="200"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="card_title">
          <span class="text-h6">Invite Team Members</span>
          <v-spacer></v-spacer>
          <span>
            <v-icon color="black" large @click="closeModal">mdi-close</v-icon>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form ref="invite_team_modal">
              <v-row dense>
                <v-col cols="12" sm="6" md="12">
                  <label>Role Of Team Member*</label>
                  <v-select
                    clearable
                    dense
                    outlined
                    return-object
                    :items="staff_role_list"
                    item-value="id"
                    item-text="role"
                    placeholder="Select a role"
                    v-model="staff_role"
                    :rules="[$rules.required]"
                  >
                  </v-select>
                  <!-- Options -->
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Mobile Number</label>
                  <!-- country text-field -->
                  <v-text-field
                    @keypress="isNumber($event)"
                    maxlength="10"
                    clearable
                    dense
                    outlined
                    placeholder="Please enter mobile number"
                    class="md-mr-0 mr-2 mobile_num_field"
                    v-model="mobile"
                    prepend-inner-icon
                    :rules="[$rules.Mobile_number]"
                  >
                    <template #prepend-inner>
                      <v-img :src="selected_flag" class="flag_img"></v-img>
                      &nbsp;
                      <p>{{ selected_phone_code }}</p>
                      &nbsp;
                      <!-- <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template>
<v-list>
    <v-list-item v-for="(item, index) in country_list" :key="index" @click="selectPhoneCode(item)">
        <v-list-item-title>{{ item.phone_code }}</v-list-item-title>
    </v-list-item>
</v-list>
</v-menu> -->
                    </template>
                  </v-text-field>
                  <!-- <p>We'll send the Sign link to this phone </p> -->
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Name*</label>
                  <v-text-field
                    clearable
                    dense
                    outlined
                    placeholder="Enter Your Name"
                    required
                    v-model="full_name"
                    @input="full_name = capitalize(full_name)"
                    :rules="[
                      () => !!full_name || 'Name is required',
                      $rules.required,
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Gender*</label>
                  <v-select
                    clearable
                    dense
                    outlined
                    :items="['Male', 'Female', 'Other']"
                    placeholder="Gender"
                    required
                    v-model="gender"
                    :rules="[$rules.required]"
                  ></v-select>
                </v-col>
               
                <v-col cols="12" sm="6" md="12" class="mt-5">
                  <div
                    @click="triggerInputField()"
                    v-if="file_url"
                    class="d-flex flex-column align-center justify-center"
                  >
                    <v-img :src="file_url" class="adhar_field"></v-img>
                    <v-icon @click="deleteDoc()" class="action_delete"
                      >mdi-delete</v-icon
                    >
                  </div>
                  <label
                    v-else
                    class="d-flex flex-column align-center justify-center"
                    @click="triggerInputField()"
                    >Upload Aadhaar Card/Voter ID*
                    <div
                      class="adhar_field d-flex flex-column align-center justify-center"
                    >
                      <div
                        class="d-flex flex-column align-center justify-center"
                      >
                        <v-img
                          src="../../assets//file_upload.svg"
                          class="file_upload_icon"
                        ></v-img>
                        <p class="browse_adhar_btn">Browse</p>
                        <p class="upload_adhar_btn">to upload file</p>
                      </div>
                    </div>
                  </label>
                  <input
                    @change="fileChangeHandle()"
                    accept="image/*"
                    type="file"
                    ref="file_uploading_field"
                    style="visibility: hidden"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="dialog.flag == 'edit'">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#F2797B"
            class="text-capitalize"
            @click="closeModal()"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loader"
            @click="addOrEditStaff"
            class="text-capitalize white--text"
            color="#F2797B"
          >
            Update Details
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#F2797B"
            class="text-capitalize"
            @click="closeModal()"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loader"
            @click="addOrEditStaff"
            class="text-capitalize white--text"
            color="#F2797B"
            v-if="staff_role && staff_role.role === 'Admin'"
          >
            Send Invite
          </v-btn>
          <v-btn
            :loading="loader"
            @click="addOrEditStaff"
            class="text-capitalize white--text"
            color="#F2797B"
            v-if="staff_role && staff_role.role === 'Staff Manager'"
          >
            Send Invite
          </v-btn>
          <v-btn
            :loading="loader"
            @click="addOrEditStaff"
            class="text-capitalize white--text"
            color="#F2797B"
            v-if="staff_role && staff_role.role === 'Executive'"
          >
            Add Executive
          </v-btn>
        </v-card-actions>
        <!-- <ImageCropperModal v-model="cropperModal" :image="file_url" @onCrop="onCrop" /> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
// import ImageCropperModal from "@/components/common/ImageCropperModal.vue"
export default {
  name: "InviteTeamModalMobile",
  data() {
    return {
      selected_flag:
        "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      selected_country: "India",
      selected_phone_code: "+91",
      country: "103",
      mobile: "",
      gender: "",
      staff_role: "",
      full_name: "",
      file_url: "",
      selected_file: null,
      country_list: [],
      staff_role_list: [],
      cropperModal: false,
      loader: false,
    };
  },
  components: {
    // ImageCropperModal
  },
  computed: {
    ...mapGetters({
      dialog: "dialog/getNewTeamMemberMobileModalFlag",
      // addOrEditFlag: "dialog/getAddorEditFlag",
      get_staff_details: "team/get_staff_details",
      parlour_details: "profile/get_parlour_details",
    }),
  },
  watch: {
    get_staff_details() {
      (this.mobile = this.get_staff_details.mobile),
        (this.gender = this.get_staff_details.gender),
        (this.staff_role = this.get_staff_details.staff_role),
        (this.full_name = this.get_staff_details.full_name),
        (this.file_url = this.get_staff_details.id_proof);
    },
  },
  mounted() {
    // this.$store.dispatch("team/callGetStaffDetails")
    //   this.country = this.get_staff_details.country.id,
    this.getListOfPhoneCodes();
    this.getListOfRoles();
  },
  methods: {
    closeModal() {
      let payload = {
        open: false,
        flag: "",
      };
      this.$store.dispatch("dialog/setNewMemberModalMobile", payload);
      this.$refs.invite_team_modal.reset();
      (this.file_url = ""), (this.selected_file = null);
    },
    // getListOfPhoneCodes api calling
    getListOfPhoneCodes() {
      const successHandler = (response) => {
        this.country_list = response.data.phone_code_list;
      };
      const failureHandler = () => {};
      return this.$axios("get", this.$apiUrl.PHONE_CODE, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: false,
      });
    },
    selectPhoneCode(item) {
      this.selected_flag = item.flag;
      this.selected_country = item.name;
      this.country = item.id;
      this.selected_phone_code = item.phone_code;
    },
    triggerInputField() {
      this.$refs.file_uploading_field.click();
    },
    fileChangeHandle() {
      this.uploadFile();
    },
    uploadFile() {
      this.selected_file = this.$refs.file_uploading_field.files[0];
      // var full_file_type = this.selected_file.type
      // var file_type_array = full_file_type.split("/")
      // var file_type = file_type_array[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        this.file_url = event.target.result;
        // this.cropperModal = true
        // this.file_array.push({
        //     url: this.file_url,
        //     type: file_type
        // })
      };
      reader.readAsDataURL(this.selected_file);
      // this.postParlourMedia();
    },
    deleteDoc() {
      this.file_url = "";
    },

    // onCrop(croppedImage) {
    //     this.file_url = croppedImage.toDataURL();
    //     croppedImage.toBlob((blob) => {
    //         this.selected_file = new File([blob], "image.jpg");
    //     });
    // },

    // getListOfPhoneCodes api calling
    getListOfRoles() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.staff_role_list = response.data.staff_role_list;
      };
      const failureHandler = () => {};
      return this.$axios("get", this.$apiUrl.LIST_STAFF_ROLE, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    addOrEditStaff() {
      if (this.dialog.flag == "edit") {
        this.editStaffDetails();
      } else {
        this.addStaff();
      }
    },
    // post api
    addStaff() {
      if (this.$refs.invite_team_modal.validate()) {
        {
          this.loader = true;
          this.parlour_id = localStorage.getItem("parlour_id");
          let data = new FormData();
          data.append("parlour", this.parlour_id);
          data.append("gender", this.gender);
          data.append("country", this.country);
          data.append("full_name", this.full_name);
          data.append("staff_role", this.staff_role.id);

          if (this.selected_file) {
            data.append("id_proof", this.selected_file);
          }
          if (this.mobile) {
            data.append("mobile", this.mobile);
          }

          const successHandler = (response) => {
            this.loader = false;
            let datas = {
              open: false,
              flag: "edit",
            };
            this.$store.dispatch("dialog/setNewMemberModalMobile", datas);
            this.$refs.invite_team_modal.reset();
            (this.file_url = ""), (this.selected_file = null);

            // check if is_staff-added from get-parlour-detail api is true or false
            // if it comes true then dispatch step 1 completed
            this.$store.dispatch("profile/callParlourDetailsApi");

            // trigger get api for listing all staff
            let payload = {
              parlour_id: "",
              searchQuery: "",
              status: "",
            };
            this.$store.dispatch("team/callTeamListApi", payload);
            this.showSnackBar(response.data.message, "#4CA955", 1500);
          };
          const failureHandler = (error) => {
            if (error && error.data && error.data.message) {
              this.loader = false;
              this.showSnackBar(error.data.message, "#B3261E", 1500);
            }
          };
          const finallyHandler = () => {};
          return this.$axios("post", this.$apiUrl.CREATE_STAFF, {
            data,
            onSuccess: successHandler,
            onFailure: failureHandler,
            onFinally: finallyHandler,
            isTokenRequired: true,
          });
        }
      }
    },
    // patch api
    editStaffDetails() {
      if (this.$refs.invite_team_modal.validate()) {
        let staff_id = this.$route.params.id;
        let parlour_id = localStorage.getItem("parlour_id");
        let params = {
          id: staff_id,
        };
        let data = new FormData();
        data.append("parlour", parlour_id);
        data.append("mobile", this.mobile);
        data.append("gender", this.gender);
        data.append("country", this.country);
        data.append("full_name", this.full_name);
        data.append("staff_role", this.staff_role.id);
        if (this.selected_file) {
          data.append("id_proof", this.selected_file);
        }
        const successHandler = (response) => {
          this.staff_detail = response.data.staff_detail;
          // call get api for staff details to update the ui
          this.$store.dispatch("team/callGetStaffDetails", staff_id);
          // close the modal
          let payload = {
            open: false,
            flag: "edit",
          };
          this.$store.dispatch("dialog/setNewMemberModalMobile", payload);
          this.showSnackBar(response.data.message, "#4CA955", 1500);
        };
        const failureHandler = (error) => {
          if (error && error.data && error.data.message) {
            this.showSnackBar(error.data.message, "#B3261E", 1500);
          }
        };
        return this.$axios("patch", this.$apiUrl.EDIT_STAFF, {
          data,
          params,
          onSuccess: successHandler,
          onFailure: failureHandler,
          isTokenRequired: true,
        });
      }
    },
  },
};
</script>
<style scoped>
.mobile_num_field >>> .v-input__prepend-inner {
  display: flex;
  align-items: center;
}

.card_title {
  background-color: #fce4e5;
}

.browse_adhar_btn {
  text-decoration: underline !important;
}

.upload_adhar_btn {
  color: grey !important;
}

label {
  color: black !important;
}

p {
  margin-bottom: 0px;
}

/* .v-input>>>.v-text-field__details {
    display: none;
} */

.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 20px !important;
}
.action_delete {
  position: relative !important;
  top: -116px !important;
  left: 10% !important;
  color: #8c1d18 !important;
  background-color: white !important;
  font-weight: 700;
  cursor: pointer;
  z-index: 1;
}

.flag_img {
  width: 20px;
  height: 20px;
}

.adhar_field {
  width: 120px !important;
  height: 120px !important;
  border: 1px dashed grey !important;
  cursor: pointer !important;
}

.file_upload_icon {
  width: 23px;
  height: 25px;
}
</style>

<template>
    <v-container>
        <v-row>
            <v-col md="12" class="pt-0 pb-0">
                <p class="table_title mt-10">
                    {{ report_status?.selected_report_type }} <span class="date_report"
                        v-if="report_status?.from_date">({{ report_status?.from_date }} <span
                            v-if="report_status?.to_date">to</span> {{ report_status?.to_date }})</span></p>
            </v-col>
        </v-row> 
       
        <div
        class="d-flex align-center text-end justify-end mb-2"
        v-if="total_amount"
      >
        <div class="font-weight-bold ">Total Amount :  {{ total_amount }}</div>
      </div>
        <v-row v-if="report_data.length === 0" class="mt-15">
            <v-col class="d-flex align-center justify-center">
                <v-card class="pa-15" style="border-radius: 18px;">
                    <p class="mb-0">There is no data available</p>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col v-if="isLoading">
                <v-skeleton-loader class="mx-auto" max-width="1190" type="card"></v-skeleton-loader>
            </v-col>
            <v-col>
       
                <!-- <div v-if=""> -->
                    <template v-if="report_status?.selected_report_type===this.$keys.EXECUTIVE_REPORT">

                <v-card style="border-radius: 18px;" v-for="(item, index) in report_data" :key="index">
                    <v-card-text>
                        <div class="d-flex align-center py-2">
                            <v-avatar size="38" color="primary" class="white--text mr-2">{{ $initials(item.full_name)
                                }}</v-avatar>
                            <p class="mb-0 name">{{ item.full_name }}</p>
                        </div>
                        <v-divider></v-divider>
                        <div v-for="(subItem, subIndex) in item.total_services" :key="subIndex">
                            <div class="d-flex align-center py-2">
                                <p class="mb-0 service_name">{{ subItem.service_name }} {{
                        subItem.no_of_services_provided }} </p>
                                <v-spacer></v-spacer>
                                <p class="mb-0 price">₹{{ subItem.sales_generated }}</p>
                            </div>
                            <v-divider></v-divider>
                        </div>
                    </v-card-text>
                </v-card>   
                    </template>
                <!-- </div>     -->

                
                <v-card v-else style="border-radius: 18px;" v-for="(item, index) in report_data" :key="index">
                    <v-card-text>
                        <div class="d-flex align-center py-2">
                            <v-avatar size="38" color="primary" class="white--text mr-2">{{ $initials(item.category_name)
                                }}</v-avatar>
                            <p class="mb-0 name">{{ item.category_name}}</p>
                        </div>
                        <v-divider></v-divider>
                        <div v-for="(subItem, subIndex) in item.total_services" :key="subIndex">
                            <div class="d-flex align-center py-2">
                                <p class="mb-0 service_name">{{ subItem.service_name }} {{
                        subItem.no_of_services_provided }} </p>
                                <v-spacer></v-spacer>
                                <p class="mb-0 price">₹{{ subItem.sales_generated }}</p>
                            </div>
                            <v-divider></v-divider>
                        </div>
                    </v-card-text>
                </v-card>
            






                         <!-- Executive Report Card -->
                <!-- <div v-if="isExecutiveReport">
                    <v-card style="border-radius: 18px;" v-for="(item, index) in report_data" :key="index">
                        <v-card-text>
                            <div class="d-flex align-center py-2">
                                <v-avatar size="38" color="primary" class="white--text mr-2">{{ $initials(item.full_name) }}</v-avatar>
                                <p class="mb-0 name">{{ item.full_name }}</p>
                            </div>
                            <v-divider></v-divider>
                            <div v-for="(subItem, subIndex) in item.total_services" :key="subIndex">
                                <div class="d-flex align-center py-2">
                                    <p class="mb-0 service_name">{{ subItem.service_name }} {{ subItem.no_of_services_provided }} </p>
                                    <v-spacer></v-spacer>
                                    <p class="mb-0 price">₹{{ subItem.sales_generated }}</p>
                                </div>
                                <v-divider></v-divider>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>

               
                 <div v-else>
                    <v-card style="border-radius: 18px;" v-for="(item, index) in report_data" :key="index">
                        <v-card-text>
                            <div class="d-flex align-center py-2">
                                <v-avatar size="38" color="primary" class="white--text mr-2">{{ $initials(item.category_name) }}</v-avatar>
                                <p class="mb-0 name">{{ item.category_name}}</p>
                            </div>
                            <v-divider></v-divider>
                            <div v-for="(subItem, subIndex) in item.total_services" :key="subIndex">
                                <div class="d-flex align-center py-2">
                                    <p class="mb-0 service_name">{{ subItem.service_name }} {{ subItem.no_of_services_provided }} </p>
                                    <v-spacer></v-spacer>
                                    <p class="mb-0 price">₹{{ subItem.sales_generated }}</p>
                                </div>
                                <v-divider></v-divider>
                            </div>
                        </v-card-text>
                    </v-card>
                </div> --> 
                

            </v-col>
        </v-row>
        <v-fab-transition>
            <v-btn color="primary" dark class="white--text fab_btn py-4 text-capitalize"
                @click="openFilterBar()"><v-icon class="mr-1">mdi-chart-bar</v-icon>Generate Report</v-btn>
        </v-fab-transition>
        <v-spacer></v-spacer>
        <reportsFilterPage></reportsFilterPage>
    </v-container>
</template>

<script>
import reportsFilterPage from "../reports/reportFilterPage.vue"
import { mapGetters } from "vuex"
export default {
    name: "reportsMob",
    data() {
        return {
            isLoading: false,
          
        }
    },
    methods: {
        openFilterBar() {
            this.$store.dispatch("dialog/toggleReportPage", true)
        }
    },
    computed: {
        ...mapGetters({
            report_data: "reports/get_report_data",
            report_status: "reports/get_report_status",
            total_amount: "reports/get_report_total_amount",
        }),
    },
    components: {
        reportsFilterPage
    }
}
</script>

<style scoped>
.table_title {
    font-weight: 600 !important;
    font-size: 17px !important;
    color: #1E1B39 !important;
}

.fab_btn {
    position: fixed;
    left: 40vw;
    bottom: 12vh;
    border-radius: 15px;
}

.header {
    font-size: 16px;
    color: #1E1B39 !important;
    font-weight: 700;
}

.sub_header {
    font-size: 12px;
    color: #1E1B39 !important;
    font-weight: 400;
}

.name {
    font-size: 14px;
    color: #39434D !important;
    font-weight: 600;
}

.service_name {
    font-size: 14px;
    color: #39434D !important;
    font-weight: 500;
}

.price {
    font-size: 14px;
    color: #39434D !important;
    font-weight: 500;
}
</style>
<template>
  <v-sheet>
    <v-navigation-drawer
      v-model="dialog_value"
      app
      temporary
      right
      class="sheet"
      stateless
      width="400"
    >
      <v-card elevation="1" class="fixed_header">
        <v-card-title class="text-center">
          <v-icon color="black" @click="close_drawer">mdi-close-circle</v-icon>
          <span class="ma-auto"> Appointment Details</span>
        </v-card-title>
      </v-card>
      <div class="middle_content">
        <v-card elevation="1" class="mt-5 mx-2">
          <div class="user_info_div d-flex align-center">
            <div class="">
              <v-avatar
                color="#F2797B"
                size="46"
                class="logged_in_user_avatar white--text ma-2"
              >
                {{ initials }}
              </v-avatar>
            </div>
            <div class="d-flex flex-column justify-center">
              <p class="logged_in_user_name mb-0" v-if="booking_details">
                {{ booking_details.client.client }}
              </p>
              <p v-if="booking_details" class="joining_date mb-0">
                Client Since {{ booking_details.client_joining_date }}
              </p>
            </div>
          </div>
        </v-card>
        <!--  -->
        <v-card class="mt-3 mx-2">
          <v-card-text v-if="booking_details">
            <div>
              <p class="mb-0 client_data">
                {{ booking_details.appointment_service_start_time }} -
                {{ booking_details.appointment_service_end_time }}
              </p>
              <p>{{ booking_details.appointment_service_date }}</p>
            </div>
            <div>
              <p class="mb-0 client_data">Executive</p>
              <p class="">{{ booking_details.executive.name }}</p>
            </div>
            <div>
              <p class="mb-0 client_data">Booked By</p>
              <p class="">
                {{ booking_details.created_by }} on
                {{ booking_details.created_date }}
              </p>
            </div>
            <div>
              <p class="mb-0 client_data">Booking ID</p>
              <p class="">{{ booking_details.booking_ref_id }}</p>
            </div>
          </v-card-text>
        </v-card>
        <!--  -->
        <v-card class="mt-3 mx-2">
          <v-card-text v-if="booking_details">
            <div v-for="(item, index) in booking_details.services" :key="index">
              <div class="d-flex align-center my-3">
                <div>
                  <p class="mb-0 service_title">
                    {{ item.service_item.service_name }} × {{ item.quantity }}
                  </p>
                  <p class="mb-0 service_sub_title">
                    {{ item.service_item.duration }}
                  </p>
                </div>
                <v-spacer></v-spacer>
                <p>₹{{ item.price }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <!--  -->
        <v-card class="mt-5 mx-2" v-if="current_tab_name === 'cancelled'">
          <v-card-text>
            <p class="client_data">CANCELLATION REASON</p>
            <v-divider> </v-divider>
          </v-card-text>
          <v-text-field
            readonly
            class="mx-4 pt-0 mt-0 notes"
            v-model="cancel_reason"
            @input="cancel_reason = capitalize(cancel_reason)"
          ></v-text-field>
        </v-card>
        <!--  -->
        <v-card class="mt-5 mx-2" v-if="notes">
          <v-card-text>
            <p class="client_data">NOTES</p>
            <v-divider> </v-divider>
          </v-card-text>
          <v-textarea
            readonly
            placeholder="Add an appointment note"
            class="mx-4 pt-0 mt-0 notes"
            v-model="notes"
            @input="notes = capitalize(notes)"
            multi-line
            filled
          ></v-textarea>
        </v-card>
        <!--  -->
        <v-card class="mt-5 mx-2 mb-2" v-if="current_tab_name === 'completed'">
          <v-card-text class="pb-0">
            <p class="client_data">BILLING SUMMARY</p>
            <v-divider> </v-divider>
          </v-card-text>
          <v-card-text>
            <div>
              <div class="d-flex align-center">
                <p class="service_title">Subtotal</p>
                <v-spacer></v-spacer>
                <p>₹{{ booking_details?.billing_summary?.subtotal }}</p>
              </div>
              <div class="d-flex align-center">
                <p class="service_title">Tax</p>
                <v-spacer></v-spacer>
                <p>₹{{ booking_details?.billing_summary?.tax }}</p>
              </div>
              <div class="d-flex align-center">
                <p style="font-weight: 600; color: black">Total</p>
                <v-spacer></v-spacer>
                <p v-if="booking_details">
                  ₹{{ booking_details?.billing_summary?.total }}
                </p>
              </div>
            </div>
            <v-divider></v-divider>
            <p
              class="mb-0 pt-4"
              style="color: #828282; font-size: 14px; font-style: italic"
              v-if="booking_details"
            >
              Paid By {{ booking_details?.billing_summary?.payment_type }}
            </p>
          </v-card-text>
        </v-card>
      </div>
    </v-navigation-drawer>
  </v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      name: "appointmentDetailsDrawer",
      booking_details: null,
      initials: "",
      notes: "",
      cancel_reason: "",
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      dialog: "appointment/getDetailDrawer",
      appointment_details: "appointment/getAppointmentDetail",
      current_tab_name: "appointment/get_current_tab_name",
    }),
    dialog_value: {
      get() {
        return this.dialog.flag;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  watch: {
    appointment_details(val) {
      if (val) {
        this.booking_details = val;
        this.initials = this.$initials(val.client.client);
        this.notes = val.note;
        this.cancel_reason = val.reason;
      }
    },
  },
  mounted() {},
  methods: {
    getCurrentTime() {
      const now = new Date();
      return `${now.getHours()}:${now.getMinutes()}`;
    },
    close_drawer() {
      let payload = {
        flag: false,
      };
      this.$store.dispatch("appointment/toggleDetailsDrawer", payload);
      // clear the data after closing
      this.$refs.new_appointment_drawer.reset();
      this.notes = "";
      this.$store.dispatch("appointment/clear_cart_list");
    },
  },
};
</script>
<style scoped>
.notes >>> .v-input {
  color: grey !important;
}

.joining_date {
  font-weight: 500;
  font-size: 14px;
  color: #77797e;
}

.client_data {
  color: black;
  weight: 500;
  font-size: 14px;
}

.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-color: #f2797b;
}

.fixed_header {
  position: sticky;
  top: 0px;
  bottom: 100px;
  left: 0;
  right: 0;
  z-index: 1000;
}

.middle_content {
  overflow-y: scroll;
  height: 100vh !important;
}

.service_title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.service_sub_title {
  color: grey;
  font-weight: 400;
  font-size: 14px;
}

.service_cost {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.sheet {
  background-color: #fafafa;
}

.header {
  font-size: 12px;
  font-weight: 600;
}

.text_3rd_card {
  color: rgb(242, 121, 123);
  cursor: pointer;
}

.v-text-field .v-input__slot {
  border-radius: 200px !important;
}

.icon_3rd_card {
  color: rgb(242, 121, 123);
}

.booking_btn {
  background-color: rgb(242, 121, 123) !important;
  color: white !important;
}
</style>

<template>
    <v-app class="main_div">
        <v-container class="mt-11">
            <v-row class="d-flex align-center">
                <v-col v-if="$isDesktop">
                    <v-img src="../../assets/brush_logo.svg" class="logo_img"></v-img>
                </v-col>
                <v-col class="d-flex">
                    <v-row>
                        <v-col md="2" cols="3" class="d-flex align-center pl-0">
                            <v-icon large left @click="goBack">mdi-arrow-left-thick</v-icon>
                        </v-col>
                        <!-- <v-col md="2"></v-col> -->
                        <v-col md="8" cols="6" class="d-flex align-center justify-center mr-auto">
                            <h1 class="header">Terms Of Service</h1>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="d-flex">
                <v-col md="6" v-if="$isDesktop">
                    <v-img src="../../assets/welcome_img.svg" class="welcome_img" v-if="$isDesktop"></v-img>
                </v-col>
                <v-col md="6" class="pa-0">
                    <v-card class="terms_Card">
                        <v-card-text>
                            <p class="title">Welcome to Brush CircleTM</p>
                            <p class="title">Introduction</p>
                            <p>
                                Thank You for using Brush Circle products, services and features provided to you from
                                the
                                platform.
                            </p>
                            <p class="title">Our Service at glance</p>
                            <ul>
                                <li>We provide accounting and booking management services for Salons and Parlours.</li>
                                <li>We provide for managing the executives and employees of Salons and their
                                    relationship
                                    with the
                                    Salon.</li>
                                <li>
                                    We facilitate in visibility and discovery of salons and artists as marketplace for
                                    users
                                    for
                                    mutual benefit of salons and users.
                                </li>
                            </ul>
                            <p class="title">Terms Of Service</p>
                            <p class="title">Age requirements</p>
                            <p>
                                You must be at least 14 years old to use the platform as a user with the permission of
                                parents
                                or guardian. You must be of 18 years of age or more to use the platform as Salon owner.
                                If
                                you
                                are a minor you represent that you have your parent's or legal guardian's permission to
                                use
                                the
                                Platform. Please have them read the Full Terms.
                            </p>


                            <p class="title">Account</p>
                            <p>Your Account is created based on the Mobile Number for uniqueness and authenticity.</p>
                            <p class="title">
                                Restrictions
                            </p>
                            <p>
                                You are not permitted to broadcast the services provided by the platform to unauthorized
                                users.
                                You are not permitted to sell your membership to any other person directly or indirectly
                                by
                                giving them access to services. Subscription is non transferrable and non refundable.
                                Any
                                such
                                refraction or misuse i.e. by illegal selling or access will lead to your subscription be
                                suspended.
                            </p>

                            <p class="title">Disclaimer</p>
                            <p>
                                All the data is secured and saved into secure and reliable cloud servers which can be
                                proven
                                to
                                the user at any given point of time. Since we are not saving the data in our servers
                                loss of
                                data from cloud server will not be the responsibility of Brush circle, however proper
                                caution is
                                exercised while maintaining the database on a reliable cloud server and the liability of
                                data
                                loss is limited to the liability of database services provider.
                            </p>

                            <p class="title">Dispute Resolution</p>
                            <p>
                                We strive to serve the interest of community with highest level of integrity still some
                                inconvenience though regrettable is a possibility. You can contact our support team via
                                email at
                                support@brushcircle.com or brushcircleofficial@gmail.com for amicable resolution. Any
                                dispute or
                                litigation is subject to Raipur Jurisdiction under the aegis of Bilaspur High Court. You
                                agree
                                to resolve the dispute by alternate means such as Arbitration and Mediation.
                            </p>

                            <p class="title">Platform owner</p>
                            <p>
                                Platform is fully owned by Suraj Laxmi Technologies Pvt. ltd. a company operating under
                                the
                                Indian Companies Act 2013, registered at 10, Geedam road, Jagdalpur.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
<script>
export default {
    name: "TermsOfService",
    data() {
        return {
            is_resend_enable: true,
            is_timer_enable: false,
            countDownTimer: null,
            otp_field: "",
            mobile: null,
            send_otp_btn_loader: false,
            verify_btn_loader: false,
            flag: "",
            country_name: "",
            phone_code_list: null,
            selected_phone_code: "+91",
            selected_flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
            switch_to_verify_page: false,
        }
    },
    components: {

    },
    watch: {
        otp_field() {
            if (this.otp_field.length === 6) {
                this.verifyOtp();
            }
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        openPrivacyPolicy() {
            window.open()
        },
        navigateToSignUpPage() {
            this.$router.push({
                name: "sign-up"
            })
        },
        goBackToMainLoginPage() {
            this.switch_to_verify_page = false,
                this.mobile = ""
        }
    }
}
</script>
<style scoped>
.terms_Card {
    overflow-y: scroll;
    height: 80vh !important;
}

::-webkit-scrollbar {
    display: none;
}

.main_div {
    background-image: url("../../assets/bg_img_1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.logo_img {
    width: 185.96px;
}

.title {
    color: black !important;
    font-weight: 400 !important;
    font-size: 15px !important;
}

@media all and (max-width:600px) {
    .header {
        font-size: 20px !important;
    }
}
</style>
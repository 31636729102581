import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import messages from "./app-js/messages";
import rules from "../src/app-js/rules";
import vuetify from "./plugins/vuetify";
import urls from "./app-js/urls";
import axios from "axios";
import axios_req from "../src/app-js/axios";
import * as VueGoogleMaps from "vue2-google-maps";
import initials from "../src/app-js/initials";
import DatePicker from "../src/components/common/DatePicker.vue";
import VueApexCharts from 'vue-apexcharts'
import keys from "@/helper/keys"

// import NetworkIssue from "../src/components/common/NetworkIssue.vue";

Vue.prototype.$initials = initials;
Vue.prototype.$DatePicker = DatePicker;
Vue.prototype.$messages = messages;
Vue.prototype.$rules = rules;
Vue.prototype.$keys = keys;
Vue.config.productionTip = false;
Vue.prototype.$axios = axios_req;
Vue.prototype.$apiUrl = urls;
Vue.prototype.eventBus = new Vue();
// axios.defaults.baseURL = urls.BASE_URL;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// process.env.VUE_BASE_URL;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAj-8YOIObv9854-VOk1uXfuzf_LTJ7Yuc",
    libraries: "places",
  },
});
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)
Vue.mixin({
  computed: {
    $isDesktop() {
      return this.$vuetify.breakpoint.width >= 1000;
    },
    $isTablet() {
      const width = this.$vuetify.breakpoint.width;
      return width < 1000 && width >= 600;
    },
    $isMobile() {
      const width = this.$vuetify.breakpoint.width;
      // Check if width is less than 600 and greater than 0 to avoid recursion
      return width < 600 && width >= 0;
    },
  },

  methods: {
    showSnackBar(text, color_type, timeout) {
      this.$store.dispatch("snackbar/showSnack", {
        flag: true,
        text,
        color: color_type,
        timeout: timeout,
      });
    },
    capitalize(value) {
      if (value) {
        let capitalize_word = value.charAt(0).toUpperCase() + value.slice(1);
        return capitalize_word;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
      ) {
          evt.preventDefault();
      } else {
          return true;
      }
  },
    // handle status code
    handleStatusCode(response) {
      if (response.status === 401) {
        this.showSnackBar("error", "Invalid Access token", 2000, true, true);
        return true;
      } else if (response.status === 440) {
        this.showSnackBar("error", "Session Expired", 2000, true, true);
        return true;
      } else if (response.status === 404) {
        this.showSnackBar("error", "Page Not Found", 2000, true, true);
        return true;
      } else if (response.status === 403) {
        this.showSnackBar("error", "Invalid token", 2000, true, true);
        return true;
      } else if (response.status === 500) {
        this.showSnackBar("error", "Internal server error", 2000, true, true);
        return true;
      } else {
        return false;
      }
    },
  },
});

// window.addEventListener("online", function () {
//   alert("You are online now!");
// });

// window.addEventListener("offline", function () {
//   alert("Oops! You are offline now!");
// });
{
  /* <NetworkIssue></NetworkIssue>; */
}
  
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// async function getToken() {
//   let date = new Date().getTime() / 1000;
//   let access = localStorage.getItem("access");
//   console.log(access);
//   let refresh = localStorage.getItem("refresh");
//   let decode_access = jwt_decode(access);
//   let decode_refresh = jwt_decode(refresh);
//   if (decode_access.exp > date) {
//     return access;
//   } else if (decode_refresh.exp > date) {
//     return refresh;
//   } else {
//     localStorage.removeItem("user_id");
//     localStorage.removeItem("verified");
//     localStorage.removeItem("access");
//     localStorage.removeItem("refresh");
//     localStorage.clear();
//     sessionStorage.removeItem("session");
//     sessionStorage.clear();
//     store.state.Auth.checkStatus = "LOGIN";
//     store.state.isPrivate = false;
//     store.state.Auth.flagVerified = false;
//     router.push({ name: "AuthPage" });
//   }
// }

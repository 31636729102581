<template>
    <v-container>
        <v-row>
            <v-col class="d-flex align-center">
                <p class="module_title mb-0">{{ $route.meta.title }}</p>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn class="text-capitalize white--text my-5" color="#F2797B" @click="openInviteTeamModal">
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>Invite Team Member
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between">
            <v-col md="3">
                <v-text-field outlined dense hide-details placeholder="Search Team Members" prepend-inner-icon="mdi-magnify"
                    v-model="search_query" />
            </v-col>

            <v-col md="3" class="d-flex justify-end filter">
                <v-select clearable :items="status_items" item-value="value" outlined dense
                    v-model="selected_status" class="filter" @change="filterFun">
                    <template v-slot:prepend-inner>
                        <div class="d-flex align-center">
                            <v-icon>mdi-filter-outline</v-icon>
                            <span class="mr-1">Status</span>
                            <span class="mr-1">:</span>
                        </div>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12">
                <!-- Skeleton Loader -->
                <v-skeleton-loader v-if="isLoading" class="mx-auto" max-width="1190" type="card"></v-skeleton-loader>

                <!-- Data Table -->
                <v-data-table v-else :headers="team_headers" :items="team_list" :items-per-page="5"
                    class="elevation-1 header">
                    <template v-slot:[`item.full_name`]="{ item }">
                        <router-link :to="{ name: 'member-details', params: { id: item.id } }">{{ item.full_name
                        }}</router-link>
                    </template>
                    <template v-slot:[`item.mobile`]="{ item }">
                     <span v-if="item.mobile">{{item.mobile}}</span>
                     <span v-else>-</span>
                    </template>
                    
                    <template v-slot:[`item.status`]="{ item }">
                        <!-- <v-chip :class="[item.is_active ? 'status_active' : 'status_inactive']">{{ item.status }}</v-chip> -->
                        <v-chip class="status_active" v-if="item.status == 'Activate'">Active Member</v-chip>
                         <v-chip class="status_inactive" v-else>InActive Member</v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list v-for="(subItem, subIndex) in item.status_action_items" :key="subIndex">
                                <v-list-item @click="handleStatusAction(item)">
                                    <v-list-item-title class="status_action" >{{
                                        subItem.title
                                    }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <InviteTeamModal></InviteTeamModal>
        <statusConfirmationModal></statusConfirmationModal>
    </v-container>
</template>
<script>
import InviteTeamModal from './InviteTeamModal.vue';
import statusConfirmationModal from './statusConfirmationModal.vue';
import { mapGetters } from 'vuex'
export default {
    name: "TeamMangementDesktop",
    data() {
        return {
            team_headers: [
                {
                    text: 'NAME',
                    align: 'start',
                    sortable: false,
                    value: 'full_name',
                    id: 'id'
                },
                { text: 'MOBILE', value: 'mobile',  sortable: false,},
                { text: 'GENDER', value: 'gender',  sortable: false, },
                { text: 'ROLE', value: 'staff_role',  sortable: false, },
                { text: 'STATUS', value: 'status',  sortable: false, },
                { text: 'ACTIONS', value: 'actions',  sortable: false, },
            ],
            team_list: [],
            // action_list: ["archive"],
            search_query: "",
            status_items: [{ text: "All", value: "all" }, { text: "Active", value: "active" }, { text: "Inactive", value: "inactive" }],
            selected_status: "all",
            show_Loader: false
        }
    },
    components: {
        InviteTeamModal,
        statusConfirmationModal
    },
    computed: {
        ...mapGetters({ staff_list_state: 'team/get_team_list' }),
        isLoading() {
            return this.$store.state.team.isTeamLoading;
        },
    },
    watch: {
        staff_list_state() {
            this.team_list = this.staff_list_state
        },
        search_query() {
            let parlour_id = localStorage.getItem("parlour_id")
            let payload = {
                parlour_id: parlour_id,
                searchQuery: this.search_query,
                status: this.selected_status
            }
            this.$store.dispatch("team/callTeamListApi", payload)
        }
    },
    mounted() {
        let parlour_id = localStorage.getItem("parlour_id")
        let payload = {
            parlour_id: parlour_id,
            searchQuery: this.search_query,
            status: this.selected_status
        }
        this.$store.dispatch("team/callTeamListApi", payload)
    },
    methods: {
        openInviteTeamModal() {
            this.$store.dispatch("dialog/setInviteTeamModal")
        },
        handleStatusAction(item) {
            let payload = {
                flag: true,
                member_detials: item
            }
            this.$store.dispatch("dialog/setMemberStatusConfirmition", payload)
        },
        filterFun() {
            let parlour_id = localStorage.getItem("parlour_id")
            let payload = {
                parlour_id: parlour_id,
                searchQuery: this.search_query,
                status: this.selected_status
            }
            this.$store.dispatch("team/callTeamListApi", payload)
        }
    }
}
</script>
<style scoped>
/* .filter{
   width:300px !important 
} */
.v-data-table >>> .v-data-table__wrapper table thead tr th {
    color: #333333 !important;
    font-size:14px !important;
  }
  .v-data-table >>> .v-data-table__wrapper table td {
    color: #333333 !important;
    font-weight: 550 !important;
    font-size: 14px !important;
  }
.header>>>.v-data-table-header {
    background-color: #FBF1F2 !important;
}

.status_action {
    cursor: pointer;
}

.filter {
    max-width: 240px !important;
}

.status_active {
    background-color: #E6FBD9 !important;
    color: #0F5B1D !important;
}

.status_inactive {
    background-color: #FFE8D7 !important;
    color: #931222 !important
}

.v-application a {
    color: black;
}
</style>
//call api to get parlour details
import urls from "../../app-js/urls";
import axios from "../../app-js/axios";
export default {
  namespaced: true,
  state: {
    client_details: [],
  },
  getters: {
    get_client_details: (state) => state.client_details,
  },
  mutations: {
    GET_CLIENT_DETAILS_API(state, payload) {
      state.client_details = payload;
    },
  },
  actions: {
    callGetClientDetails({ commit }, client_id) {
      let params = {
        id: client_id,
      };
      const successHandler = (response) => {
        let client_details = response.data.client_detail;
        commit("GET_CLIENT_DETAILS_API", client_details);
      };
      const failureHandler = () => {};
      return axios("get", urls.GET_CLIENT_DETAILS, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};

<template>
  <div>
    <v-tabs
      grow
      active-class="mob-active-tab"
      color="#F2797B"
      v-model="current_tab_index"
      class="booking_tabs"
    >
      <v-tab
        v-for="(item, index) in tab_name"
        :key="index"
        @click="changeTabTitle(item)"
        class="d-flex flex-column"
      >
        <div class="tab_name text-capitalize mt-3">{{ item.text }}</div>
        <div class="tab_number_data">
          <span v-if="item.value === 'upcoming'"> {{ total_upcomings }}</span>
          <span v-else-if="item.value === 'completed'">
            {{ total_completed }}</span
          >
          <span v-else-if="item.value === 'cancelled'">
            {{ total_cancelled }}</span
          >
        </div>
      </v-tab>
    </v-tabs>
    <v-row class="mt-8 mx-0">
      <v-col>
        <v-text-field
          outlined
          dense
          hide-details
          placeholder="Search Appointments"
          prepend-inner-icon="mdi-magnify"
          v-model="searchQuery"
          clearable
        />
      </v-col>
      <!-- <v-col v-if="current_tab_index == 0" class="d-flex justify-end">
                <v-select clearable :items="next_days_list" item-value="value" outlined dense
                    v-model="selected_day_type" @change="getBookingList" class="filter-day-type">
                    <template v-slot:prepend-inner>
                        <div class="d-flex align-center">
                            <v-icon>mdi-filter-outline</v-icon>
                            <span class="mr-1">Next</span>
                        </div>
                    </template>
                </v-select>
            </v-col> -->
      <v-col cols="12" v-if="current_tab_index == 0">
        <v-menu
          ref="menu4"
          v-model="menu_4"
          :close-on-content-click="false"
          :return-value.sync="dates_4"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="status_filter"
              clearable
              outlined
              dense
              :value="dateFilter"
              placeholder="From - To"
              append-icon="mdi-chevron-down"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="(dates_4 = []), getBookingList()"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates_4" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu4 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="handleOKClickDate()">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <!-- Bill Type Filter -->
      <div class="d-flex">
        <v-col cols="6" v-if="current_tab_index == 1">
          <v-select
            clearable
            :items="bill_type"
            item-value="value"
            outlined
            dense
            placeholder="Bill Type"
            v-model="selected_bill_type"
            @change="getBookingList"
            class="bill-type"
            prepend-inner-icon="mdi-filter-outline"
            append-icon="mdi-chevron-down"
          >
            <!-- <template v-slot:prepend-inner>
              <div class="d-flex align-center">
                <v-icon>mdi-filter-outline</v-icon>
                <span class="mr-1">Bill</span>
                <span class="mr-1">Type</span>
                <span>:</span>
              </div>
            </template> -->
          </v-select>
        </v-col>
        <v-col
          :cols="current_tab_index == 1 ? '6' : '12'"
          v-if="current_tab_index !== 0"
        >
          <v-menu
            ref="menu3"
            v-model="menu_2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateFilter2"
                class="status_filter"
                clearable
                outlined
                dense
                placeholder="From - To"
                append-icon="mdi-chevron-down"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="(dates_2 = []), getBookingList()"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates_2" no-title scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu3 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="handleOKClick"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </div>
      <!-- <v-select clearable :items="past_months" item-value="value" outlined dense v-model="selected_month_type"
                    @change="getBookingList" class="filter-month-type ma-1 mr-12">

                    <template v-slot:prepend-inner>
                        <div class="d-flex align-center">
                            <v-icon>mdi-filter-outline</v-icon>
                            <span class="mr-1">Past</span>
                        </div>
                    </template></v-select>
            </v-col> -->
    </v-row>

    <!-- {{ selected_appointment }} -->
    <!-- skeleton loader -->
    <v-row v-if="booking_list.length === 0 && !loading_flag" class="mt-15">
      <v-col cols="12" class="d-flex align-center justify-center">
        <p>There is no data avaialble</p>
      </v-col>
    </v-row>
    <v-row v-if="loading_flag" class="mx-0">
      <v-col cols="12">
        <v-skeleton-loader max-width="400" type="card"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader max-width="400" type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-card
      class="mt-2 mx-2"
      v-for="(item, index) in filteredArray.length > 0
        ? filteredArray
        : booking_list"
      :key="index"
    >
      <v-card-subtitle class="d-flex justify-space-between align-center py-0">
        <div class="d-flex align-center">
          <v-checkbox
            multiple
            :value="item"
            v-model="selected_appointment"
            v-if="current_tab_index == 0"
          ></v-checkbox>
          <p class="grey_text">#{{ item.booking_ref_id }}</p>
        </div>
        <p class="grey_text">
          Booked On : <span class="booking_date">{{ item.booked_on }} </span>
        </p>
      </v-card-subtitle>
      <v-divider> </v-divider>
      <div @click="openAppointmentDetailsDrawer(item)">
        <v-card-title>
          <v-avatar color="#CE6161" class="white--text"
            >{{ $initials(item?.client_full_name) }}
          </v-avatar>
          <div class="ml-2">
            <p class="mb-0 client_name">{{ item?.client_full_name }}</p>
            <p class="mb-0 client_number">{{ item?.client_mobile_number }}</p>
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex">
          <div>
            <div
              class="d-flex"
              v-for="(subItem, subIndex) in item.service_detail"
              :key="subIndex"
            >
              <p class="client_name">
                {{ subItem.service_name }}
                <span class="client_number">{{ subItem.duration }}</span>
              </p>
              <v-spacer> </v-spacer>
            </div>
          </div>
          <v-spacer></v-spacer>
          <p class="client_name">{{ item.total_amount }}</p>
          <!--  -->
        </v-card-text>
        <v-card-text>
          <div>
            <span class="card-text">
              <span class="client_number mr-1">On</span>
              <span class="client_name">{{ item.appointment_date }}</span>
              <span class="client_number">&nbsp;at&nbsp;</span>
              <span class="client_name">{{ item.appointment_start_time }}</span>
              <br />
              <span class="client_number mr-1">Executive</span>
              <span class="client_name">{{ item.executive }}</span>
            </span>
          </div>
          <div v-if="item.note !== ''">
            <p class="client_number">
              Notes : <span class="client_name">{{ item.note }}</span>
            </p>
          </div>
        </v-card-text>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row class="d-flex" style="display: flex !important">
          <!-- <v-col class="pb-0" cols="6">
                        <v-btn v-if="current_tab_name === 'upcoming'" block outlined color="#F2797B"
                            class="text-capitalize" @click="cancelAppointment()">Cancel</v-btn>
                    </v-col> -->
          <v-col cols="12">
            <v-btn
              v-if="current_tab_name === 'upcoming'"
              block
              class="create_booking_btn text-capitalize"
              @click="updateAppointment(item)"
              >Update</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-fab-transition v-if="!showBtns">
      <v-btn
        color="primary"
        dark
        absolute
        top
        right
        fab
        @click="openCreateNewBookingModal()"
        class="fab_btn_1"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <!-- <v-row> -->
      <div class="d-flex align-center justify-center text-center mx-3" style="position: fixed; bottom: 12vh">
        <!-- <v-col cols="6" > -->
          <v-fab-transition>
            <v-btn v-if="showBtns" 
              color="primary"
              dark
              class="white--text mr-4" style="font-size: 13px"
              @click="cancelAppointment()"
              >Cancel Booking</v-btn
            >
          </v-fab-transition>
        <!-- </v-col> -->
       <v-spacer></v-spacer>
        <!-- <v-col cols="6" > -->
          <v-fab-transition>
            <v-btn v-if="showBtns"
              color="primary"
              dark
              class="white--text"  style="font-size: 13px"
              @click="completePayment"
              >Complete Booking</v-btn
            >
          </v-fab-transition>
        <!-- </v-col> -->
      </div>
    <!-- </v-row> -->
    <paymentPageMob
      @clear_selected_appointment_data="clearAppointmentData"
    ></paymentPageMob>
    <selectServicesDrawerMob></selectServicesDrawerMob>
    <appointmentDrawerMob></appointmentDrawerMob>
    <appointmentCancelModal
      @clear_selected_appointment_data="clearAppointmentData"
    ></appointmentCancelModal>
    <appointmentDetailsDrawerMob></appointmentDetailsDrawerMob>
    <updateMobAppointmentDrawer></updateMobAppointmentDrawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import paymentPageMob from "./paymentPageMob.vue";
import selectServicesDrawerMob from "@/components/appointments/mobile_view/selectServiceDrawerMob.vue";
import appointmentDrawerMob from "./appointmentDrawerMob.vue";
import appointmentCancelModal from "../appointmentCancelModal.vue";
import appointmentDetailsDrawerMob from "../mobile_view/appointmentDetailsMob.vue";
import updateMobAppointmentDrawer from "./appointmentMobUpdate.vue";
export default {
  name: "BookingMobile",
  data() {
    return {
      total_cancelled: null,
      total_completed: null,
      total_upcomings: null,
      booking_list: [],
      dates_2: [],
      menu_2: "",
      dates_4: [],
      menu_4: "",
      tab_name: [
        {
          text: "Upcoming",
          align: "center",
          sortable: false,
          value: "upcoming",
          // class: "primary_2 white--text",
        },
        {
          text: "Completed",
          align: "center",
          sortable: false,
          value: "completed",
          // class: "primary_2 white--text",
        },
        {
          text: "Cancelled",
          align: "center",
          sortable: false,
          value: "cancelled",
          // class: "primary_2 white--text",
        },
      ],
      current_tab_index: "",
      current_tab_name: "upcoming",
      num_of_bookings: "",
      searchQuery: "",
      bill_type: [
        {
          text: "All",
          value: "ALL",
        },
        {
          text: "Gst",
          value: "GST",
        },
        {
          text: "Estimate",
          value: "Estimate",
        },
      ],
      selected_bill_type: "",
      next_days_list: [
        {
          text: "7 Days",
          value: "7",
        },
      ],
      selected_day_type: "",
      past_months: [
        {
          text: "1 Month",
          value: this.currentDate(),
        },
      ],
      selected_month_type: "",
      // singleSelect: "",
      selected_appointment: [],
      initials: "",
      showBtns: false,
      filteredArray: [],
    };
  },
  components: {
    paymentPageMob,
    selectServicesDrawerMob,
    appointmentDrawerMob,
    appointmentCancelModal,
    appointmentDetailsDrawerMob,
    updateMobAppointmentDrawer,
  },
  computed: {
    ...mapGetters({
      booking_lists: "appointment/get_booking_list",
      total_booking_num: "appointment/get_total_booking_num",
      loading_flag: "appointment/getIsLoadingBookingMobile",
    }),
    isLoading() {
      return this.$store.state.appointment.isBookingLoading;
    },
    dateFilter() {
      return this.dates_4.join(" ~ ");
    },
    dateFilter2() {
      return this.dates_2.join(" ~ ");
    },
  },
  watch: {
    booking_lists(val) {
      this.booking_list = val;
    },
    total_booking_num(val) {
      this.total_upcomings = val.upcoming;
      this.total_completed = val.completed;
      this.total_cancelled = val.cancelled;
    },
    searchQuery() {
      // call get api for booking
      let payload = {
        status: this.current_tab_name,
        search: this.searchQuery,
        bill_type: this.selected_bill_type,
        next_days: this.selected_day_type,
        past_month: this.selected_month_type,
      };
      this.$store.dispatch("appointment/getBookingList", payload);
    },
    selected_appointment(val) {
      if (val?.length !== 0) {
        this.showBtns = true;
      }
      if (val.length === 0) {
        this.filteredArray = [];
        this.showBtns = false;
      }
      if (val) {
        this.filteredArray = this.booking_list.filter((item) => {
          return item?.client_mobile_number === val[0]?.client_mobile_number;
        });
      }
    },
  },
  mounted() {
    let payload = {
      status: this.current_tab_name,
      search: this.searchQuery,
      bill_type: this.selected_bill_type,
      next_days: this.selected_day_type,
      past_month: this.selected_month_type,
    };
    this.$store.dispatch("appointment/getBookingList", payload);
  },
  methods: {
    handleOKClickDate() {
      this.$refs.menu4.save(this.dates_4);
      this.menu_4 = false;
      this.getBookingList();
    },
    handleOKClick() {
      this.$refs.menu3.save(this.dates_2);
      this.menu_2 = false;
      this.getBookingList();
    },
    updateAppointment(item) {
      this.$store.dispatch("appointment/toggleUpdateAppointmentMob", true);
      this.$store.dispatch("appointment/get_appointment_detail_api", item.id);
    },
    openAppointmentDetailsDrawer(item) {
      let payload = {
        flag: true,
      };
      this.$store.dispatch("appointment/toggleMobDetailsDrawer", payload);
      this.$store.dispatch("appointment/get_appointment_detail_api", item.id);
    },
    openCreateNewBookingModal() {
      this.$store.dispatch("appointment/toggleCreateBookingMobileDrawer", true);
    },
    getBookingList() {
      let payload = {
        status: this.current_tab_name,
        search: this.searchQuery,
        bill_type: this.selected_bill_type,
        next_days: this.selected_day_type,
        past_month: this.selected_month_type,
      };
      if (this.dates_2[0]) {
        payload.from_date = this.dates_2[0];
        payload.to_date = this.dates_2[1];
      } else if (this.dates_4[0]) {
        payload.from_date = this.dates_4[0];
        payload.to_date = this.dates_4[1];
      }
      this.$store.dispatch("appointment/getBookingList", payload);
    },
    currentDate() {
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      return formattedDate;
    },
    changeTabTitle(item) {
      this.current_tab_name = item.value;
      let payload = {
        status: this.current_tab_name,
        search: this.searchQuery,
        bill_type: this.selected_bill_type,
        next_days: this.selected_day_type,
        past_month: this.selected_month_type,
      };
      this.$store.dispatch(
        "appointment/set_current_tab_name",
        this.current_tab_name
      );
      this.$store.dispatch("appointment/getBookingList", payload);
      this.selected_appointment = [];
    },
    getTableHeaders() {
      if (this.current_tab_name === "upcoming") {
        return [
          {
            text: "",
            align: "center",
            sortable: false,
            value: "checkbox",
          },
          {
            text: "BOOKED ON",
            align: "center",
            sortable: false,
            value: "booked_on",
            // class: "primary_2 white--text",
          },
          {
            text: "CLIENT NAME",
            align: "center",
            sortable: false,
            value: "client_full_name",
            // class: "primary_2 white--text",
          },
          {
            text: "MOBILE",
            align: "center",
            sortable: false,
            value: "client_mobile_number",
            // class: "primary_2 white--text",
          },
          {
            text: "APPOINMENT DATE",
            align: "center",
            sortable: false,
            value: "booking_date",
            // class: "primary_2 white--text",
          },
          {
            text: "SERVICES",
            align: "center",
            sortable: false,
            value: "services",
            // maxWidth:'2%'
          },
          {
            text: "EXECUTIVE",
            align: "center",
            sortable: false,
            value: "executive",
            // class: "primary_2 white--text",
          },
          {
            text: "ACTIONS",
            align: "center",
            sortable: false,
            value: "actions",
            // class: "primary_2 white--text",
          },
        ];
      } else if (this.current_tab_name === "completed") {
        return [
          {
            text: "SERIAL#",
            align: "center",
            sortable: false,
            value: "booking_ref_id",
            // class: "primary_2 white--text",
          },
          {
            text: "INVOICE#",
            align: "center",
            sortable: false,
            value: "invoice_no",
            // class: "primary_2 white--text",
          },
          {
            text: "DATE",
            align: "center",
            sortable: false,
            value: "booking_date",
            // class: "primary_2 white--text",
          },
          {
            text: "CLIENT NAME",
            align: "center",
            sortable: false,
            value: "client_full_name",
            // class: "primary_2 white--text",
          },
          {
            text: "SERVICES",
            align: "center",
            sortable: false,
            value: "services",
            // class: "primary_2 white--text",
          },
          {
            text: "TOTAL",
            align: "center",
            sortable: false,
            value: "total_amount",
            // class: "primary_2 white--text",
          },
          {
            text: "BILL TYPE",
            align: "center",
            sortable: false,
            value: "bill_type",
            // class: "primary_2 white--text",
          },
          {
            text: "CREATED BY",
            align: "center",
            sortable: false,
            value: "created_by",
            // class: "primary_2 white--text",
          },
          {
            text: "ACTIONS",
            align: "center",
            sortable: false,
            value: "actions",
            // class: "primary_2 white--text",
          },
        ];
      } else {
        return [
          {
            text: "BOOKED ON",
            align: "center",
            sortable: false,
            value: "booked_on",
            // class: "primary_2 white--text",
          },
          {
            text: "CLIENT NAME",
            align: "center",
            sortable: false,
            value: "client_full_name",
            // class: "primary_2 white--text",
          },
          {
            text: "MOBILE",
            align: "center",
            sortable: false,
            value: "client_mobile_number",
            // class: "primary_2 white--text",
          },
          {
            text: "BOOKING DATE",
            align: "center",
            sortable: false,
            value: "booking_date",
            // class: "primary_2 white--text",
          },
          {
            text: "REASON",
            align: "center",
            sortable: false,
            value: "cancelled_reason",
            // class: "primary_2 white--text",
          },
        ];
      }
    },
    // api calling for appointment list
    cancelAppointment() {
      this.$store.dispatch(
        "appointment/selected_appointments_data",
        this.selected_appointment
      );
      // first open the modal for reasons
      this.$store.dispatch("appointment/toggleCancellationModal", true);
    },
    completePayment() {
      this.$store.dispatch(
        "appointment/selected_appointments_data",
        this.selected_appointment
      );
      this.$store.dispatch("appointment/togglePaymentPageMob", true);
    },
    clearAppointmentData() {
      this.selected_appointment = [];
    },
    reschedule(item) {
      this.$store.dispatch("appointment/toggleUpdateAppointmentDrawer", true);
      this.$store.dispatch("appointment/get_appointment_detail_api", item.id);
    },
  },
};
</script>

<style scoped>
.booking_tabs >>> .v-tabs-bar {
  height: 68px !important;
}

.v-main__wrap {
  flex: none !important;
}

.fab_btn_1 {
  position: fixed;
  left: 80vw;
  bottom: 5vh;
}

.fab_btn_2 {
  position: fixed;
  /* left: 49vw; */
  bottom: 12vh;
}

.fab_btn_3 {
  position: fixed;
  /* left: 5vw; */
  bottom: 12vh;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: 76vh !important;
}

.row {
  display: block !important;
}

p {
  margin-bottom: 0px !important;
}

.create_booking_btn {
  background-color: #f2797b !important;
  color: white;
}

.booking_date {
  color: #000;
  font-size: 12px !important;
  font-weight: 700;
}

.grey_text {
  color: #697498;
  font-size: 10px !important;
  font-weight: 500;
}

.client_name {
  color: #39434d;
  font-size: 14px !important;
  font-weight: 600;
}

.client_number {
  color: #697498;
  font-size: 12px !important;
  font-weight: 500;
}
</style>

export default {
  namespaced: true,
  state: {
    report_data: [],
    report_status: null,
    report_total_amount : null,
  },
  getters: {
    get_report_data: (state) => state.report_data,
    get_report_status: (state) => state.report_status,
    get_report_total_amount: (state) => state.report_total_amount,
  },
  mutations: {
    REPORT_DATA(state, payload) {
      state.report_data = payload;
    },
    REPORT_STATUS(state, payload) {
      state.report_status = payload;
    },
    REPORT_TOTAL_AMOUNT(state, payload){
      state.report_total_amount = payload;
    }
  },
  actions: {
    reportsData({ commit }, payload) {
      commit("REPORT_DATA", payload);
    },
    report_status({ commit }, payload) {
      commit("REPORT_STATUS", payload);
    },
    totalAmount({commit}, payload){
      commit("REPORT_TOTAL_AMOUNT", payload);
    }
  },
};

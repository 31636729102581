<template>
    <v-snackbar v-model="getSnackBar.flag" :color="getSnackBar.color" right top :timeout="getSnackBar.timeout">
        <b class="text--white">
            {{ getSnackBar.text }}
        </b>
        <template v-slot:action="{ attrs }">
            <v-btn dark small fab text @click="getSnackBar.flag = !getSnackBar.flag" class="float-right px-0"
                v-bind="attrs">
                <!-- <b>close</b> -->
                <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "snackBar",
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            getSnackBar: "snackbar/getSnackBar",
        })
    }
}
</script>
<template>
  <div  class="d-flex justify-center align-center" style="height:100vh">
    <div class="d-flex justify-center align-center">
      <v-stepper flat tile v-model="el" class="stepper_header">
        <!-- steps number  start-->
        <v-stepper-content step="1">
          <ParlourDetail @childBtnClick="updateEl" class="mt-16"></ParlourDetail>
        </v-stepper-content>
        <v-stepper-content step="2">
          <ParlourGallery @childBtnClick="updateEl" @goBack="updateEl"></ParlourGallery>
        </v-stepper-content>
        <div>
          <v-stepper-header class="mx-auto">
            <v-stepper-step :complete="el >= 1" step="1" class="pa-0 header_name" color="primary">
            </v-stepper-step>
            <v-divider class="mx-1"></v-divider>
            <v-stepper-step :complete="el >= 2" step="2" class="pa-0 header_name" color="primary">
            </v-stepper-step>
          </v-stepper-header>
        </div>
        <!-- steps end -->
        <!-- Contents started -->
        <!-- <v-stepper-content step="1">
          <ParlourDetail @childBtnClick="updateEl"></ParlourDetail>
        </v-stepper-content>
        <v-stepper-content step="2">
          <ParlourGallery @childBtnClick="updateEl" @goBack="updateEl"></ParlourGallery>
        </v-stepper-content> -->
        <!-- Contents ended -->
      </v-stepper>
    </div>
  </div>
</template>
<script>
import ParlourDetail from "../../components/signup/ParlourDetail.vue"
import ParlourGallery from "../../components/signup/Parlour'sGallery.vue";
export default {
  name: "BasicParlourDetails",
  data() {
    return {
      el: 1,
    }
  },
  components: {
    ParlourDetail,
    ParlourGallery
  },
  methods: {
    updateEl(newValue) {
      this.el = newValue;
      // this.stepper_value_action({ stepper_value: this.el });
    },
  }
}
</script>
<style scoped>
.v-stepper__header {
  margin-top: -19px !important;
  box-shadow: none !important;
  max-width: 200px !important
}

.v-stepper__content {
  padding: 0px !important;
  margin-top: -21px !important;
}

.v-stepper {
  border-radius: 4px;
  overflow: hidden;
  position: static !important;
}

/* .v-stepper__header>>>.v-stepper__step__step {
  color: #F2797B !important;
  background-color: #F2797B !important;
}


.v-stepper__step__step>>>.primary {
  background-color: #F2797B !important;
} */
.theme--light.v-stepper .v-stepper__header .v-divider {
  border-color: #F2797B !important;
}

.theme--light.v-stepper .v-stepper__step__step {
  color: black !important;
  background-color: antiquewhite !important;
}

/* .stepper_header {
    width: 300px !important;
  } */
@media all and (max-width: 600px) {
  .v-stepper__content {
    padding: 20px !important;
    margin-top: -21px !important;
  }

  .v-stepper__header {
    box-shadow: none;
    padding: 54px !important;
    font-weight: 500;
  }

}
</style>
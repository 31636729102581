<template>
    <v-sheet>
        <v-navigation-drawer v-model="drawer_value" absolute temporary right class="" width="400">
            <!-- 1st Card -->
            <v-card elevation="1">
                <v-card-title class="text-center">
                    <v-icon color="black" @click="close_drawer">mdi-close-circle</v-icon>
                    <span class="ma-auto main_header">Create Bill</span>
                </v-card-title>
            </v-card>
            <!-- 2nd Card -->
            <v-card elevation="1" class="mt-5 mx-2">
                <v-card-text>
                    <p class="card_header">CLIENT</p>
                </v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                    <v-select clearable placeholder="select a client or create new"></v-select>
                </v-card-text>
            </v-card>
            <!-- 3rd Card -->
            <v-card class="mt-5 mx-2">
                <v-card-text>
                    <p class="card_header">DATE & TIME OF APPOINTMENT</p>
                </v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                    <v-text-field dense placeholder="Choose Date" outlined class="mx-2">
                    </v-text-field>
                </v-card-text>
                <v-divider class="mx-4">
                </v-divider>
                <v-card-text>
                    <v-select clearable dense outlined placeholder="Select Time" class="mx-2 time_field">
                    </v-select>
                </v-card-text>
            </v-card>
            <!-- 4th Card -->
            <v-card elevation="1" class="mt-5 mx-2 pb-2">
                <v-card-text>
                    <p class="card_header">SERVICES</p>
                </v-card-text>
                <v-divider class="mx-2"></v-divider>
                <v-card-text>
                    <div class="d-flex">
                        <p class="card_sub_header">Men's Haircut</p><v-spacer></v-spacer><span
                            class="card_sub_header">₹150</span>
                    </div>
                    <p>60 minutes</p>
                </v-card-text>
                <v-divider class="mx-2"></v-divider>
                <v-card-text>
                    <div>
                        <div class="d-flex">
                            <p class="card_sub_header">Facial</p>
                            <v-spacer></v-spacer>
                            <span class="card_sub_header">
                                ₹150
                            </span>
                        </div>
                        <p>30 minutes</p>
                    </div>
                </v-card-text>
                <v-divider class="mx-2"></v-divider>
                <v-card-actions class="d-flex mt-3">
                    <v-icon color="#F2797B" class="mx-2">mdi-plus-circle</v-icon>
                    <span class="add-service">Add Another Service</span>
                </v-card-actions>

            </v-card>
            <!-- 5th card -->
            <v-card class="mt-5 mx-2">
                <v-card-text>
                    <p class="card_header">AMOUNT</p>
                </v-card-text>
                <v-divider class="mx-2"> </v-divider>
                <v-card-text>
                    <div class="d-flex">
                        <div>
                            <p class="mb-0 card_sub_header">Include GST</p>
                            <p class="italic_text">Turning Off creates a cash estimate</p>
                        </div>
                        <v-spacer></v-spacer>
                        <v-switch></v-switch>
                    </div>
                    <div class="mt-n3">
                        <v-text-field placeholder="HSN Number" class="pt-0"></v-text-field>
                    </div>
                </v-card-text>
                <v-divider class="mx-2"> </v-divider>
                <v-card-text>
                    <div class="d-flex">
                        <p class="amount_sub_text pb-2">Subtotal</p>
                        <v-spacer></v-spacer>
                        <p>₹450.00</p>
                    </div>
                    <div class="d-flex">
                        <p class="amount_sub_text pb-2">Tax(18%)</p>
                        <v-spacer></v-spacer>
                        <p>₹55.00</p>
                    </div>
                    <div class="d-flex mb-3">
                        <p class="card_sub_header pb-2">Total</p>
                        <v-spacer></v-spacer>
                        <p>₹505</p>
                    </div>
                </v-card-text>
                <v-divider class="mx-2"></v-divider>
                <v-card-actions class="text-right">
                    <p class="add-service py-5 mr-3">Add Discount</p>
                </v-card-actions>
            </v-card>
            <!-- last card (6th)-->
            <v-card class="mt-5 mx-2">
                <v-card-text class="card_header">MEMBERSHIP & OFFERS
                </v-card-text>
                <v-divider class="mx-2"></v-divider>
                <v-card-actions class="d-block">
                    <div class="d-flex">
                        <div>
                            <p class="card_sub_header">Apply a Membership</p>
                            <p class="italic_text">Choose which membership to apply</p>
                        </div>
                        <v-spacer></v-spacer>
                        <v-icon>mdi-chevron-right</v-icon>
                    </div>
                </v-card-actions>
                <v-divider class="mx-2"></v-divider>
                <v-card-actions class="d-block">
                    <div class="d-flex">
                        <div>
                            <p class="card_sub_header">Apply an Offer</p>
                            <p class="italic_text">Choose which offer to apply</p>
                        </div>
                        <v-spacer></v-spacer>
                        <v-icon>mdi-chevron-right</v-icon>
                    </div>
                </v-card-actions>
            </v-card>
            <v-btn block class="checkout_btn ml-2 mr-13 my-8 text-capitalize">
                Checkout
            </v-btn>
        </v-navigation-drawer>
    </v-sheet>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "CreateBillModal",
    data() {
        return {
            items: [
                { title: 'Home', icon: 'mdi-view-dashboard' },
                { title: 'About', icon: 'mdi-forum' },
            ],
        }
    },
    methods: {
        close_drawer() {
            this.$store.dispatch('dialog/setCreateBillDrawer');
        }
    },
    computed: {
        ...mapGetters({
            drawer: "dialog/getCreateBillDrawer",
        }),
        drawer_value: {
            get() {
                return this.drawer
            },
            set(newValue) {
                return newValue
            }
        }
 
    }
}
</script>
<style scoped>
p {
    margin-bottom: 0 !important;
}

.v-application .mb-2 {
    margin-bottom: 0px !important;
}

.time_field>>>.v-input__slot {
    margin-bottom: 14px !important;
}

.v-input>>>.v-text-field__details {
    display: none !important;
}

.main_header {
    font-weight: 600;
    font-size: 24px !important;
}

.add-service {
    color: #F2797B;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.card_header {
    font-size: 12px !important;
    font-weight: 600 !important;
    /* color: #000000 !important; */
}

.card_sub_header {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    /* color: #0000 !important; */
}

.amount_sub_text {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.italic_text {
    font-weight: 400;
    font-style: italic;
    font-size: 14px !important;
    color: grey;
}

.checkout_btn {
    background-color: rgb(242, 121, 123) !important;
    color: white !important;
}
</style>
<template>
    <v-row justify="space-around">
        <v-dialog transition="dialog-bottom-transition" max-width="328" height="100" v-model="dialog">
            <v-card>
                <v-form ref="custom_reason_modal">
                    <v-toolbar color="#F2797B" dark>Other reasons</v-toolbar>
                    <v-card-text>
                        <v-textarea class="mt-5" outlined v-model="custom_reason" name="input-7-4"
                            label="Please write your reason here ...." :rules="[$rules.required]"></v-textarea>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal">Close</v-btn>
                        <v-btn class="text-capitalize white--text" color="#F2797B"
                            @click="CreateCustomReason">Submit</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "CustomIrrelevantModal",
    data() {
        return {
            custom_reason: "",
            flag: false,
            parlour: "",
        }
    },
    computed: {
        ...mapGetters({
            dialog: "dialog/getCustomIrrelevantModal",
        })
    },
    methods: {
        // create new query custom query to get Id
        CreateCustomReason() {
            if (this.$refs.custom_reason_modal.validate()) {
                this.parlour = localStorage.getItem("parlour_id")
                let data = {
                    "parlour": this.parlour,
                    "title": this.custom_reason
                }
                const successHandler = (response) => {
                    let reason_id = response.data.response.irrelevant_id
                    this.$store.dispatch("enquiry_details/setCustomReason", {
                        id: reason_id,
                        title: this.custom_reason
                    })
                    this.$emit('call_get_api');
                    this.$store.dispatch("dialog/setCustomIrrelevantReasonModal")
                    this.$refs.custom_reason_modal.reset()
                }
                const failureHandler = (error) => {
                    console.log("error of createClient ", error)
                };
                const finallyHandler = () => {
                    console.log("finallyHandler")
                };
                return this.$axios("post", this.$apiUrl.CREATE_IRRELEVANT_REASON, {
                    data,
                    onSuccess: successHandler,
                    onFailure: failureHandler,
                    onFinally: finallyHandler,
                    isTokenRequired: true,
                });
            }
        },
        closeModal() {
            this.$refs.custom_reason_modal.reset()
            this.$store.dispatch("dialog/setCustomIrrelevantReasonModal")
        }
    }
}
</script>
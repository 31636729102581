<template>
    <v-row justify="center">
        <v-dialog v-model="drawer" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">User Details</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="user_profile">
                            <v-row dense>
                                <v-col cols="12" sm="6" md="6">
                                    <label>First Name</label>
                                    <v-text-field dense type = "text" outlined placeholder="Enter your first name" :rules="[$rules.name_1]"
                                        v-model="first_name" @input="first_name = capitalize(first_name)"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <label>Last Name</label>
                                    <v-text-field dense outlined placeholder="Enter your last name" v-model="last_name" :rules="[$rules.name_1]"
                                        @input="last_name = capitalize(last_name)"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal">
                        Cancel
                    </v-btn>
                    <v-btn class="text-capitalize white--text" color="#F2797B" @click="submit">
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "UserProfileModal",
    data() {
        return {
            first_name: "",
            last_name: "",
            mobile: null,
            country_id: "103",
            address: "Raipur,Pandri",
            selected_phone_code: "+91",
            selected_flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
        }
    },
    computed: {
        ...mapGetters({
            drawer: "dialog/getUserProfileModal",
            user_details: "profile/get_current_user_details",
        })
    },
    watch: {
        user_details() {
            this.first_name = this.user_details.first_name
            this.last_name = this.user_details.last_name
            this.mobile = this.user_details.number
        }
    },
    mounted() {
        this.$store.dispatch("profile/callParlourDetailsApi")
        this.getListOfPhoneCodes()
    },
    methods: {
        closeModal() {
            this.$store.dispatch("dialog/setUserProfileModal")
        },
        getListOfPhoneCodes() {
            const successHandler = (response) => {
                this.country_list = response.data.phone_code_list
            }
            const failureHandler = (error) => {
                if (error) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("get", this.$apiUrl.PHONE_CODE, {
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: false,
            });
        },
        // selectPhoneCode
        selectPhoneCode(item) {
            this.selected_phone_code = item.phone_code
            this.selected_flag = item.flag
            this.country_id = item.id
        },

        submit() {
            if (this.$refs.user_profile.validate()) {
            let logged_in_user = localStorage.getItem("user_role")
            if (logged_in_user === "staff") {
                this.editStaffDetails()
            }
            else if (logged_in_user === "admin") {
                this.editParlourDetails()
            }
        }
        },
        // patch api =>
        editStaffDetails() {
            let user_id = localStorage.getItem("user_id")
            let params = {
                id: user_id
            };
            let data = new FormData()
            data.append("first_name", this.first_name)
            data.append("last_name", this.last_name)
            data.append("mobile", this.mobile)
            data.append("country", this.country_id)
            const successHandler = (response) => {
                console.log("response of editStaffDetails api is ", response)
                this.$store.dispatch("dialog/setUserProfileModal");
                // get parlourdetails api to update the staff details in ui after editing the details
                this.$store.dispatch("profile/setUpdateProfileFlag")
                this.$store.dispatch("profile/callParlourDetailsApi")
                this.$refs.user_profile.reset();
            }
            const failureHandler = (error) => {
                if (error) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("patch", this.$apiUrl.EDIT_STAFF, {
                data,
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
        editParlourDetails() {
            let params = {
                "id": localStorage.getItem("parlour_id")
            }
            let data = new FormData()
            data.append("first_name", this.first_name)
            data.append("last_name", this.last_name)
            data.append("mobile", this.mobile)
            data.append("country", this.country_id)
            const successHandler = (response) => {
                console.log("response of editParlourDetails is ", response)
                this.$store.dispatch("dialog/setUserProfileModal")
                // get parlourdetails api to update the staff details in ui after editing the details
                this.$store.dispatch("profile/setUpdateProfileFlag")
                this.$refs.user_profile.reset();
            }
            const failureHandler = (error) => {
                if (error) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("patch", this.$apiUrl.EDIT_PARLOUR_DETAILS, {
                data,
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        }
    }
}
// }
</script>
<style scoped>
label {
    color: black !important
}

.flag_img {
    width: 20px;
    height: 20px;
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}
</style>
<template>
  <v-container class="d-flex flex-column justify-center">
    <div>
      <v-row class="ma-0">
        <v-col class="d-flex align-center">
          <p class="module_title">{{ $route.meta.title }}</p>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn class="text-capitalize white--text" color="#F2797B" @click="createNewBooking">
            <v-icon left> mdi-plus-circle-outline </v-icon> New Appointment
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0 mb-2 d-flex justify-between" style="gap: 15px">
        <v-col md="3.5" class="px-0">
          <v-card elevation="0" class="data_status d-flex align-center">
            <div class="ma-8">
              <v-img src="../../assets/sales_graph.svg"></v-img>
            </div>
            <v-spacer></v-spacer>
            <v-card-text class="d-flex flex-column justify-center">
              <p class="count mb-2" v-if="total_sales !== null && total_sales !== undefined">
                ₹ {{ total_sales }}
              </p>
              <p class="count mb-2" v-else>₹ 0</p>
              <p class="tab_text mb-2">Recent Sales</p>
              <p class="tab_sub_text mb-2">Today</p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-spacer></v-spacer> -->

        <v-col md="3.5" class="px-0">
          <v-card elevation="0" class="data_status d-flex align-center">
            <div class="ma-8">
              <v-img src="../../assets/sales_graph.svg"></v-img>
            </div>
            <v-card-text class="d-flex flex-column justify-center">
              <p class="count mb-2" v-if="!total_visits ||
            total_visits !== null ||
            total_visits !== undefined
            ">
                {{ total_visits }}
              </p>
              <p class="count mb-2" v-else>0</p>
              <p class="tab_text mb-2">Total Visits</p>
              <p class="tab_sub_text mb-2">This Month</p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-spacer></v-spacer> -->

        <v-col md="3.5" class="px-0">
          <v-card elevation="0" class="data_status d-flex align-center">
            <div class="ma-8">
              <v-img src="../../assets/sales_graph.svg"></v-img>
            </div>
            <v-card-text class="d-flex flex-column justify-center">
              <p class="count mb-2" v-if="!total_appointments ||
            total_appointments !== null ||
            total_appointments !== undefined
            ">
                {{ total_appointments }}
              </p>
              <p class="count mb-2" v-else>0</p>
              <p class="tab_text mb-2">Appointments</p>
              <p class="tab_sub_text mb-2">Today</p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- </v-col> -->
      </v-row>
    </div>

    <!-- skeleton loader -->
    <!-- <v-row>
            <v-skeleton-loader class="mx-auto" max-width="300" type="card"></v-skeleton-loader>
        </v-row> -->

    <!-- <v-row class="d-flex align-center justify-center mt-11"
            v-if="is_parlour_registered && (!is_staff_added || !is_service_added)">
            <v-card class="card d-flex flex-column align-center justify-center">
                <v-card-title class="card d-flex flex-column align-center justify-center">
                    <p class="mb-0 welcome_card_title">Hi,Welcome To BrushCircle!</p>
                    <p class="welcome_card_subtitle">Let's help you setup your acount.</p>
                </v-card-title>
            
                <div>
                    <v-stepper v-model="el" vertical flat color="#FFF7F7">
                        <v-stepper-step step="1" :complete="el >= 1">
                            Add A Staff Member
                        </v-stepper-step>
                        <v-stepper-content step="1">
                        </v-stepper-content>
                        <v-stepper-step step="2" :complete="el == 2">
                            Add a Service
                        </v-stepper-step>
                        <v-stepper-content step="2"></v-stepper-content>
                    </v-stepper>
                </div>
                <v-card-actions>
                    <v-btn v-if="el == 0" class="text-capitalize welcome_card_btn my-2" color="primary"
                        @click="addStaff">Add
                        Staff</v-btn>
                    <v-btn v-else class="text-capitalize welcome_card_btn my-2" color="primary" @click="addService">Add
                        Service</v-btn>
                </v-card-actions>
            </v-card>
        </v-row> -->

    <!-- Graph here -->
    <v-row class="scrollable-content mt-5">
      <!-- sales -->
      <v-col md="6" class="fill-height">
        <v-card class="fill-height graph_card" :class="{ chart_card: service_report_list.length === 0 }">
          <v-card-text>
            <div>
              <p class="mb-0 card_type pl-2">Sales</p>
              <div class="d-flex align-center justify-space-between">
                <div md="4" class="card_title mx-2">
                  <p>By Services</p>
                </div>
                <div md="4" class="ma-2" style="min-width: 150.68px; max-width: 170px">
                  <!-- <v-select clearable v-model="week_filter" filled rounded dense
                                        placeholder="This Week" :items="week_filter_items"></v-select> -->

                  <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="sales_dates"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field style="min-width: 150.68px; max-width: 170px" clearable filled rounded dense
                        v-model="sales_dates" placeholder="From - To" append-icon="mdi-chevron-down" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="sales_dates" no-title scrollable range>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu1.save(sales_dates)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div md="4" class="ma-2" style="min-width: 150.68px; max-width: 170px">
                  <v-select clearable v-model="service_filter_value" filled rounded dense placeholder="All Services"
                    :items="services_filter_items" item-value="id" item-text="category_name"></v-select>
                </div>
              </div>
            </div>
            <div>
              <v-divider></v-divider>
            </div>
            <!-- pie chart -->
            <div v-if="service_report_list.length === 0"
              class="d-flex align-center justify-center mt-12 center_style text-center">
              <p>There is not data available</p>
            </div>
            <div class="d-flex align-center justify-center center_style text-center"
              :class="{ dis: service_report_list.length === 0 }">
              <div class="d-flex align-center justify-center">
                <apexchart width="180" height="180" type="pie" :options="options" :series="options.series"
                  :labels="options.labels" :colors="options.colors">
                </apexchart>
              </div>
              <div class="service_card_price_list">
                <v-row v-for="(item, index) in service_report_list" :key="index" class="d-flex">
                  <v-col md="3.5" v-if="item.service_name" class="d-flex pr-0 report_service_name">
                    <v-icon x-small class="mr-3" :color="colors[index % colors.length]">mdi-circle</v-icon>
                    {{ item.service_name }}</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                  <v-col md="3.5" v-if="item.amount" class="pr-0">₹{{ item.amount }}</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                  <v-col md="3.5" v-if="item.percent" class="pr-0">{{ item.percent }}%</v-col>
                  <v-col v-else class="pr-0">-</v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <!-- executive -->
      <v-col md="6" class="fill-height">
        <v-card class="fill-height graph_card" :class="{ chart_card: executive_list_report.length === 0 }">
          <v-card-text>
            <p class="mb-0 card_type pl-2">Sales</p>
            <div class="d-flex align-center justify-space-between">
              <p class="card_title mx-2">By Executives</p>
              <div>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field style="min-width: 150.68px; max-width: 170px" clearable filled rounded dense
                      v-model="dates" placeholder="From - To" append-icon="mdi-chevron-down" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" no-title scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(dates)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div md="4" class="ma-2" style="min-width: 150.68px; max-width: 170px">
                <v-select clearable v-model="executive_filter_value" filled rounded dense placeholder="All Executives"
                  :items="executive_list" item-value="id" item-text="full_name"></v-select>
              </div>
            </div>
            <div>
              <v-divider></v-divider>
            </div>
            <!-- pie chart -->
            <div class="d-flex align-center justify-center">
              <div v-if="executive_list_report.length === 0" class="d-flex align-center justify-center mt-10 mx-auto">
                <p>There is not data available</p>
              </div>
              <div class="d-flex align-center justify-center kkk" :class="{ dis: executive_list_report.length === 0 }">
                <apexchart width="180" height="180" type="pie" :options="options_executive"
                  :series="options_executive.series" :labels="options_executive.labels"
                  :colors="options_executive.colors"></apexchart>
              </div>
              <div class="service_card_price_list" :class="{ dis: executive_list_report.length === 0 }">
                <v-row v-for="(item, index) in executive_list_report" :key="index" class="d-flex">
                  <v-col md="3.5" v-if="item.executive_name" class="d-flex pr-0 report_service_name">
                    <v-icon x-small class="mr-3" :color="colors[index % colors.length]">mdi-circle</v-icon>
                    {{ item.executive_name }}</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                  <v-col md="3.5" v-if="item.amount" class="pr-0">
                    ₹{{ item.amount }}
                  </v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                  <v-col md="3.5" v-if="item.percent" class="pr-0">{{ item.percent }}%</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--  -->

    <!-- <v-row class="ma-0 d-flex align-center justify-center overlay" v-if="is_parlour_active === 'false'">
            <v-card class="activation_card d-flex flex-column align-center justify-center pa-8">
                <Logo_text></Logo_text>
                <p class="user_name mt-3">Hello, {{ userDetails.name }}!</p>
                <p class="activation_text">Your account hasn't been activated yet</p>
                <p class="mb-2 activation_text">To start enjoying our services, please reach out to us </p>
                <p class="activation_text">for account activation</p>
            </v-card>
        </v-row> -->

        <v-menu offset-x="40" max-width="400" >
      <template v-slot:activator="{ on, attrs }">
        <v-badge bordered top color="red accent-4" dot offset-x="0" offset-y="0">
          <v-avatar color="primary white--text" size="56" class="upcomingbtn" v-bind="attrs" v-on="on">New</v-avatar>
        </v-badge>
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in upcomingAppointment" :key="index">
          <v-list-item-title>{{ item.message }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <CreateBillModal></CreateBillModal>
    <InviteTeamModal></InviteTeamModal>
    <NewServiceModal></NewServiceModal>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import InviteTeamModal from "../team-management/InviteTeamModal.vue";
import CreateBillModal from "@/components/sales/CreateBillModal.vue";
import NewServiceModal from "../services/NewServiceModal.vue";
// import Logo_text from "../../assets/Logo_text"
export default {
  name: "DashboardMobile",
  data() {
    return {
      colors: [
        "#AA4B4D",
        "#CE6264",
        "#F2797B",
        "#F59495",
        "#F7AFB0",
        "#FAC9CA",
      ],
      is_parlour_registered: "",
      is_staff_added: "",
      is_service_added: "",
      is_parlour_active: "",
      appointment_list: [],
      total_appointments: "0",
      total_sales: "0",
      total_visits: "0",
      options: {
        series: [],
        labels: [],
        colors: [
          "#AA4B4D",
          "#CE6264",
          "#F2797B",
          "#F59495",
          "#F7AFB0",
          "#FAC9CA",
        ],
        legend: {
          show: false,
        },
      },
      options_executive: {
        series: [],
        labels: [],
        colors: [
          "#AA4B4D",
          "#CE6264",
          "#F2797B",
          "#F59495",
          "#F7AFB0",
          "#FAC9CA",
        ],
        legend: {
          show: false,
        },
      },
      client_list: [],
      status_items: ["all", "active", "inactive"],
      selected_status: "all",
      searchQuery: "",
      dates: [],
      menu: "",
      sales_dates: [],
      menu1: "",
      week_filter_items: ["This Week"],
      services_filter_items: [],
      service_filter_value: "",
      week_filter: "",
      week_filter_value: "",
      service_report_list: [],
      executive_list_report: [],
      executive_list: [],
      executive_filter_value: "",
      upcomingAppointment: [],
    };
  },
  components: {
    CreateBillModal,
    InviteTeamModal,
    NewServiceModal,
    // Logo_text,
  },
  computed: {
    ...mapGetters({
      parlourDetails: "profile/get_parlour_details",
      userDetails: "profile/get_current_user_details",
      category_list: "services/get_category_list",
    }),
    el: {
      get() {
        return this.parlourDetails.el;
      },
    },
  },
  watch: {
    // check if the parlour is registered or not and 2 steps (staff and service) are added or not  , we will check it from get parlour details api
    parlourDetails(val) {
      this.is_parlour_registered = val.is_parlour_registered;
      this.is_service_added = val.is_service_added;
      this.is_staff_added = val.is_staff_added;
      this.is_parlour_active = val.is_parlour_active;
      localStorage.setItem("parlour_is_active", this.is_parlour_active);
    },
    executive_filter_value() {
      this.getExecutiveReport();
    },
    dates(val) {
      if (val[1]) {
        this.getExecutiveReport();
      }
    },
    sales_dates(val) {
      if (val[1]) {
        this.getServiceReport();
      }
    },
    week_filter(val) {
      if (val === "This Week") {
        let date = new Date();
        let yr = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDay();
        this.week_filter_value = `${yr}-${month}-${day}`;
        this.getServiceReport();
      } else {
        this.week_filter_value = "";
        this.getServiceReport();
      }
    },
    service_filter_value(val) {
      this.getServiceReport(val);
    },
    searchQuery(search_query) {
      this.generateReport(search_query);
    },
  },
  mounted() {
    this.is_parlour_active = localStorage.getItem("parlour_is_active");

    this.$store.dispatch("profile/callParlourDetailsApi");
    this.getListing();
    this.getServiceReport();
    this.getExecutiveReport();
    this.getServiceList();
    this.getExecutiveList();
    this.getUpcomingAppointmentList();
  },
  methods: {
    addStaff() {
      this.$store.dispatch("dialog/setInviteTeamModal");
    },
    addService() { },
    createNewBooking() {
      // /appointments
      this.$router.push("/appointments").catch((error) => {
        console.log(error);
      });
      this.$store.dispatch("appointment/toggleCreateBookingDrawer", true);
    },
    openCreateBillModal() {
      this.$store.dispatch("dialog/setCreateBillDrawer");
    },
    getListing() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        status: "upcoming",
      };
      const successHandler = (response) => {
        this.appointment_list = response.data.appointment_list;
        this.total_appointments = response.data.appointments.count;
        this.total_sales = response.data.recent_sales.amount;
        this.total_visits = response.data.total_visits.count;
        this.show_Loader = false;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_DASHBOARD_LISTING, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    // add graphs here
    getServiceReport(selected_service_id) {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        // date: this.week_filter_value,
        from_date: this.sales_dates[0],
        to_date: this.sales_dates[1],
        service: selected_service_id,
      };
      const successHandler = (response) => {
        this.service_report_list = response.data.response;
        this.options = {
          labels: this.service_report_list.map((item) => item.service_name),
        };
        this.options.series = this.service_report_list.map(
          (item) => item.amount
        );
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_SERVICE_REPORT, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getExecutiveReport() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        from_date: this.dates[0],
        to_date: this.dates[1],
        executive: this.executive_filter_value,
      };
      const successHandler = (response) => {
        this.executive_list_report = response.data.response;
        this.options_executive = {
          labels: this.executive_list_report.map(
            (value) => value.executive_name
          ),
        };
        this.options_executive.series = this.executive_list_report.map(
          (value) => value.amount
        );
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_EXECUTIVE_REPORT, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    // getServiceList() {
    //   let parlour_id = localStorage.getItem("parlour_id");
    //   let params = {
    //     parlour_id: parlour_id,
    //   };
    //   const successHandler = (response) => {
    //     this.services_filter_items = response.data.service_list;
    //   };
    //   const failureHandler = (error) => {
    //     if (error && error.data && error.data.message) {
    //       this.showSnackBar(error.data.message, "#B3261E", 1500);
    //     }
    //   };
    //   return this.$axios("get", this.$apiUrl.GET_SERVICE_LIST, {
    //     params: params,
    //     onSuccess: successHandler,
    //     onFailure: failureHandler,
    //     isTokenRequired: true,
    //   });
    // },

    getServiceList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.services_filter_items = response.data.service_category_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.LIST_CATEGORY, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },



    getExecutiveList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.executive_list = response.data.staff_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_EXECUTIVE_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getUpcomingAppointmentList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.upcomingAppointment = response.data.response_json.upcoming_bookings;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_UPCOMING_APPOINTMENT_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>

<style scoped>
.center_style {
  display: flex !important;
  align-content: center !important;
  justify-items: center !important;
  align-items: center !important;
}

.graph_card {
  border-radius: 20px !important;
}

.report_service_name {
  color: #1e1b39 !important;
  font-weight: 600 !important;
}

.count {
  font-weight: 700;
  font-size: 32px;
  color: #283037;
}

.tab_text {
  font-weight: 500;
  font-size: 24px;
  color: #283037;
}

.tab_sub_text {
  font-weight: 500;
  font-size: 20px;
  color: #878787;
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  /* cursor: pointer; */
}

.user_name {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #9397a7 !important;
}

.activation_text {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #323232 !important;
}

.theme--light.v-stepper {
  background-color: #fff7f7 !important;
}

.card {
  width: 645px;
  height: 357px;
  background-color: #fff7f7;
  border-radius: 20px !important;
}

.activation_card {
  background-color: #fff7f7 !important;
  border-radius: 11px !important;
}

.welcome_card_title {
  font-size: 28px;
  font-weight: 500;
  color: #323232;
}

.welcome_card_subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #323232;
}

.welcome_card_btn {
  font-size: 17px !important;
  font-weight: 600;
  color: #f2797b;
  border-radius: 20px;
  width: 190px;
  height: 41px;
}

.data_status {
  /* width: 366.33px; */
  height: 162px;
  border-radius: 16px;
  background-color: #ffeded !important;
}

.v-data-table>>>.v-data-table-header {
  background-color: #fbf1f2 !important;
}

.actionBtn {
  border-radius: 10px;
  padding: 6px 10px 6px 10px !important;
  width: 79px !important;
  font-size: 12px !important;
}

/* reports */
.chart_card {
  height: 336.8px !important;
}

.service_card_price_list {
  min-width: 344px !important;
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dis {
  display: none !important;
}

.v-list-item {
  min-height: 58px !important;
}

.status_filter>>>.v-input__slot {
  background-color: white !important;
}

.filter {
  width: 225px !important;
}

.actionBtn {
  border-radius: 10px;
  padding: 6px 10px 6px 10px !important;
  width: 79px !important;
  font-size: 12px !important;
}

.name {
  font-weight: 600;
  font-size: 14px;
}

.number {
  font-size: 12px;
  font-weight: 500;
  color: #697498;
  margin-top: -12px;
}

.card_title {
  font-size: 22px;
  font-weight: 700;
  color: #1e1b39;
}

.card_type {
  font-size: 18px;
  font-weight: 400;
  color: #9291a5;
}

.tab {
  width: 341.33px;
  height: 120px;
  border: solid 1px;
  border-color: #dfe1e3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.active-tab {
  background-color: #f2797b;
  color: white !important;
}

.mob-active-tab {
  color: #f2797b;
}

.v-application .pt-4 {
  padding-top: 0px !important;
}

.upcomingbtn {
  margin: -4%;
  margin-left: 96%;
}

@media all and (min-width: 600px) and (max-width: 999px) {
  .count {
    font-weight: 700;
    font-size: 23px;
    color: #283037;
  }

  .tab_text {
    font-weight: 500;
    font-size: 14px;
    color: #283037;
  }

  .tab_sub_text {
    font-weight: 500;
    font-size: 15px;
    color: #878787;
  }
}

@media all and (max-width: 601px) {
  .v-application .justify-center {
    justify-content: unset !important;
  }

}
</style>

<template>
    <v-row justify="center">
        <v-dialog v-model="flag_value" fullscreen hide-overlay transition="dialog-bottom-transition" persistent
            max-width="528" height="100vh">
            <v-sheet class="sheet">
                <div class="fixed_header">
                    <v-card elevation="1">
                        <v-card-title class="text-center">
                            <v-icon color="black" @click="close_drawer">mdi-arrow-left</v-icon>
                            <span class="ma-auto title">Select Service</span>
                        </v-card-title>
                    </v-card>
                    <v-text-field outlined dense hide-details class="search_bar ma-3"
                        placeholder="Search By Service Name" prepend-inner-icon="mdi-magnify" v-model="searchQuery" />
                </div>

                <div class="middle_content">
                    <v-card elevation="1" class="d-flex mb-3 mx-2 content"
                        v-for="(item, index) in list_of_categories_services" :key="index">
                        <v-card-text>
                            <p class="mb-3 category_name">{{ item.category }}</p>
                            <v-divider></v-divider>
                            <div v-for="(subItem, subIndex) in item.queryset" :key="subIndex">
                                <div class="d-flex align-center">
                                    <v-checkbox v-model="subItem.is_service_added" hide-details
                                        class="shrink mr-2 mt-0">
                                    </v-checkbox>

                                    <div class="d-flex align-center justify-space-between my-3">
                                        <div>
                                            <p class="service_name">{{ subItem.service_name }} <span
                                                    class="time_duration ml-2">{{
            subItem.duration__duration }} {{
            subItem.duration__duration_type }}</span></p>
                                            <p class="description">{{ subItem.description }}</p>
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div>
                                        <p class="ml-10 price">₹{{ subItem.price }}</p>
                                    </div>
                                </div>
                                <v-divider></v-divider>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
                <v-card class="fixed_bottom">
                    <v-card-text>
                        <v-btn block class="booking_btn text-capitalize" @click="addService">
                            Add
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-sheet>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "selectServicesDrawerMob",
    data() {
        return {
            searchQuery: "",
            list_of_categories_services: [],
            filteredServicesArray: []
        }
    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            list_of_categoriesService: "appointment/get_list_of_categories_services",
            flag: "appointment/get_toggle_select_service_drawer_Mob",
            client_id: "appointment/get_selected_client_id",
            booking_id: "appointment/getCurrentBookingId"
        }),
        flag_value: {
            get() {
                return this.flag.flag
            },
            set(newValue) {
                return newValue
            }
        },
    },
    watch: {
        list_of_categoriesService(val) {
            this.list_of_categories_services = val
        },
    },
    methods: {
        close_drawer() {
            let data = {
                flag: false,
                opened_from: ""
            }
            this.$store.dispatch("appointment/toogleSelectServiceDrawerMob", data)
            this.$store.dispatch("appointment/toggleCreateBookingMobileDrawer", true)
        },
        async addService() {
            this.filteredServicesArray = this.list_of_categories_services.flatMap((item) => {
                return item.queryset
                    .filter((subItem) => subItem.is_service_added === true)
                    .map((subItem) => subItem.id);
            });
            var data = {
                "client": this.client_id,
                "service": this.filteredServicesArray
            }
            const successHandler = (response) => {
                console.log("response of send-otp owner", response)
                // get  api for getting selected services list
                let payload = {
                    client_id: this.client_id,
                    appointment: this.booking_id
                }
                this.$store.dispatch("appointment/get_service_cart_list", payload)
                let data = {
                    flag: false,
                    opened_from: ""
                }
                this.$store.dispatch("appointment/toogleSelectServiceDrawerMob", data)
            };
            const failureHandler = (error) => {
                if (error) {
                    this.send_otp_btn_loader = false;
                    this.showSnackBar(error.data.message, "#B3261E", 1000);
                    this.mobile = ""
                }
            };
            return await this.$axios("post", this.$apiUrl.POST_CLIENT_SERVICE, {
                data,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            })

        },
        goBack() {
            let data = {
                flag: false,
                opened_from: ""
            }
            this.$store.dispatch("appointment/toogleSelectServiceDrawer", data)
            this.$store.dispatch("appointment/toggleCreateBookingDrawer", true)
        }
    }
}
</script>

<style scoped>
.v-dialog>* {
    width: 100%;
    height: 100vh;
}

.v-dialog--fullscreen>.v-card {
    min-height: 0% !important
}

p {
    margin-bottom: 0px !important
}

.sheet {
    background-color: #F5F5F5 !important;
}

.search_bar {
    background-color: white !important;
}

.booking_btn {
    background-color: rgb(242, 121, 123) !important;
    color: white !important;
}

.title {
    color: #000000;
    font-weight: 600;
    font-size: 24px;
}

.category_name {
    color: #000000;
    font-weight: 600;
    font-size: 12px;
}

.description {
    color: grey;
    font-weight: 600;
    font-size: 12px;
}

.time_duration {
    color: grey;
    font-weight: 500;
    font-size: 16px;
}

.service_name {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
}

.price {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
}
</style>
<template>
  <v-container class="d-flex align-center justify-center" style="height: 100vh">
    <v-row>
      <v-col md="3" cols=""> </v-col>
      <v-col md="6" cols="">
        <!-- Send-Otp -->
        <v-card class="main_card pa-5" v-if="!switch_verify">
          <v-card-title class="d-flex align-center justify-center">
            <h3>Account Deletion</h3>
          </v-card-title>
          <v-card-text class="pb-0 mb-0">
            <v-form ref="delete_form">
              <div>
                <label>Choose a reason</label>
                <v-select
                  clearable
                  dense
                  outlined
                  :items="reason_list"
                  return-object
                  item-value="id"
                  v-model="selectedReason"
                  item-text="title"
                  placeholder="Select a reason"
                  :rules="[$rules.required]"
                >
                </v-select>
              </div>
              <div>
                <label>Enter Your Mobile Number</label>
                <v-text-field
                  readonly
                  @keypress="isNumber($event)"
                  @keyup.enter="sendOtp()"
                  dense
                  outlined
                  autofocus
                  placeholder="Please enter mobile number"
                  maxlength="10"
                  class="phone_num_field"
                  v-model="mobile"
                  prepend-inner-icon
                  :rules="[$rules.mobile_number]"
                >
                  <template #prepend-inner>
                    <!-- {{ selected_flag }} -->
                    <v-img :src="selected_flag" class="flag_img"></v-img> &nbsp;
                    <p class="mb-0">{{ selected_phone_code }}</p>
                    &nbsp;
                  </template>
                </v-text-field>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="otp_loader"
              block
              color="#F2797B"
              class="text-capitalize white--text"
              @click="sendOtp()"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Verify Otp -->
        <v-card
          v-else
          class="main_card pa-5 d-flex flex-column justify-center align-center py-10"
        >
          <div>
            <v-card-text
              class="sub_div_1 d-flex flex-column align-center justify-center"
            >
              <p class="sub_text_1 mb-0">Enter verification code</p>
              <p class="sub_text_2 mt-2 mb-0">
                The verification code has been sent to
              </p>
              <p>+91 {{ mobile }}</p>
            </v-card-text>
            <v-card-text class="sub_div_2 mt-n2">
              <p class="mb-0">Enter 6 Digit OTP</p>
              <v-otp-input
                type="number"
                length="6"
                size="2"
                class="mb-10"
                v-model="otp_field"
                :rules="[$rules.otp]"
              ></v-otp-input>
              <!-- <div class="d-flex justify-center align-center mt-2">
                                    <p :class="{ 'disable_resend': is_timer_enable }" class="resend_otp_text"
                                        @click="send_otp_again()">
                                        Resend OTP</p> &nbsp;
                                    <p class="resend_otp_timer" v-if="is_timer_enable">{{ countDownTimer }} sec</p>
                                </div> -->
              <v-btn
                class="verify_btn py-5 white--text text-capitalize"
                block
                @click="verifyOtp()"
                :loading="verify_btn_loader"
              >
                Verify
              </v-btn>
              <v-btn
                outlined
                class="go_back_btn py-5 mt-5"
                block
                @click="goBackToMainLoginPage()"
              >
                Back
              </v-btn>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "accountDeletionPage",
  data() {
    return {
      mobile: "",
      reason_list: [
        "Delete",
        "User Experience",
        "Content Quality",
        "Community Intercations",
        "Platform Reliability",
        "Alternative Platforms",
        "Lack of Updates or Innovations",
        "Others",
      ],
      switch_verify: false,
      selectedReason: "",
      otp_field: "",
      verify_btn_loader: false,
      otp_loader: false,
      selected_phone_code: "+91",
      selected_flag:
        "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      parlour_details: [],
    };
  },
  computed: {
    ...mapGetters({
      user_details: "profile/get_current_user_details",
    }),
  },
  mounted() {
    this.getParlourDetails();
  },
  methods: {
    getParlourDetails() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        id: parlour_id,
      };
      const successHandler = (response) => {
        this.parlour_details = response.data.parlour_detail;
        this.mobile = this.parlour_details.logged_in_user.mobile;
      };
      const failureHandler = () => {};
      return this.$axios("get", this.$apiUrl.GET_PARLOUR_DETAILS, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    sendOtp() {
      let parlour_id = localStorage.getItem("parlour_id");
      if (this.$refs.delete_form.validate()) this.otp_loader = true;
      var data = {
        parlour: parlour_id,
        phone_code: "+91",
        mobile: this.mobile,
        reason: this.selectedReason,
      };
      const successHandler = (response) => {
        this.switch_verify = true;
        this.showSnackBar(response.data.message, "#4CA955", 1000);
        this.otp_loader = false;
      };
      const failureHandler = (error) => {
        if (error) {
          this.otp_loader = false;
          this.showSnackBar(error.data.message, "#B3261E", 1000);
          // this.mobile = "";
        }
      };
      return this.$axios("post", this.$apiUrl.DELETE_ACCOUNT, {
        data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    goBackToMainLoginPage() {
      this.switch_verify = false;
    },
    verifyOtp() {
      let parlour_id = localStorage.getItem("parlour_id");
      if (this.otp_field) {
        var data = {
          parlour: parlour_id,
          phone_code: "+91",
          mobile: this.mobile,
          otp: this.otp_field,
        };
        const successHandler = (response) => {
          this.showSnackBar(response.data.message, "#4CA955", 1000);
          this.verify_btn_loader = false;
          this.showSnackBar("account successfully deleted", "#4CA955", 1500);
          localStorage.removeItem("user_id");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user_role");
          localStorage.removeItem("access_token");
          localStorage.removeItem("is_parlour_registered");
          localStorage.removeItem("parlour_is_active");
          localStorage.removeItem("parlour_id");
          this.$router.push({
            name: "login",
          });
        };
        const failureHandler = (error) => {
          if (error) {
            this.verify_btn_loader = false;
            this.showSnackBar(error.data.message, "#B3261E", 1000);
          }
        };
        return this.$axios("post", this.$apiUrl.DELETE_VERIFY_OTP, {
          data,
          onSuccess: successHandler,
          onFailure: failureHandler,
          isTokenRequired: true,
        });
      }
    },
  },
};
</script>
<style scoped>
.phone_num_field >>> .v-input__prepend-inner {
  display: flex;
  align-items: center;
}

.main_card {
  border-radius: 15px;
  max-width: 420px;
}

.flag_img {
  width: 20px;
  height: 20px;
}

.go_back_btn {
  text-transform: initial !important;
  border-radius: 9px !important;
}

.verify_btn {
  background-color: #f2797b !important;
  border-radius: 8px !important;
}

.sub_text_1 {
  font-weight: 700;
  font-size: 1.5rem;
  color: #404c57;
  line-height: 1.2 !important;
}

.sub_text_2 {
  font-weight: 400;
  font-size: 1rem;
  color: grey;
}

@media all and (max-width: 600px) {
  .sub_text_2 {
    font-weight: 400;
    font-size: 0.8rem;
    color: grey;
  }
}
</style>

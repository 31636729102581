<template>
    <v-row justify="center">
        <v-dialog v-model="flag" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Edit Category</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <!-- text-field -->
                            <v-col cols="12" sm="6" md="12" v-if="category_is_active == false">
                                <span class="label">Are you sure you want to activate category ?</span>
                            </v-col>
                            <v-col cols="12" sm="6" md="12" v-else>
                                <span class="label">Are you sure you want to Deactivate category ?</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="EditCategory()">
                        Yes
                    </v-btn>
                    <v-btn @click="closeModal()" class="text-capitalize white--text" color="#F2797B">
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "ConfirmationModal",
    data() {
        return {
            current_category_id: "",
            flag: "",
            category_is_active: ""
        }
    },
    computed: {
        ...mapGetters({
            current_category_data: "dialog/getEditCategoryConfirmition",
        }),
    },
    watch: {
        current_category_data(val) {
            this.current_category_id = val.category_id,
                this.flag = val.flag,
                this.category_is_active = val.category_is_active
        }
    },
    methods: {
        closeModal() {
            let payload = {
                flag: false
            }
            this.$store.dispatch("dialog/setEditCategoryConfirmition", payload)
        },
        EditCategory() {
            let params = {
                id: this.current_category_id
            }
            let data = {
                "is_active": !this.category_is_active
            }
            const successHandler = (response) => {
                console.log("response", response)
                let payload = {
                    flag: false
                }
                this.$store.dispatch("dialog/setEditCategoryConfirmition", payload)
                if (this.$isDesktop) {
                    this.$store.dispatch("services/ListCategory")
                }
                else {
                    // call api for mobile view listing refresh
                    this.$emit('callGetApi')
                }
            }
            const failureHandler = (error) => {
                console.log("error", error)
                if (error && error.data && error.data.message) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("patch", this.$apiUrl.EDIT_CATEGORY, {
                params,
                data,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
    }
}
</script>
<style scoped>
label {
    color: black !important
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}

.flag_img {
    width: 20px;
    height: 20px;
}
</style>
<template>
    <div>
        <div class="d-flex flex-column justify-center">
            <v-row class="ma-0">
                <v-col class="d-flex align-center ml-10">
                    <p class="module_title mb-0">{{ $route.meta.title }}</p>
                </v-col>
                <v-col class="d-flex justify-end mr-7">
                    <v-btn class="text-capitalize white--text ma-5" color="#F2797B" @click="openEnquiryModal">
                        <v-icon left>
                            mdi-plus-circle-outline
                        </v-icon>Add Enquiry
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-tabs height="95px" grow class="mx-15" active-class="active_tab" v-model="current_tab_index">
                    <v-tab v-for="(item, index) in tab_name" :key="index" @click="changeTabTitle(item)"
                        class="d-flex align-start flex-column">
                        <div class="tab_name text-capitalize mt-3">{{ item.text }}</div>
                        <div class="tab_number_data">
                            <span v-if="item.text === 'New Enquiries'" class="tab_number_data"> {{ total_enquiries
                                }}</span>
                            <span v-else-if="item.text === 'Follow Ups'" class="tab_number_data"> {{ total_follow_ups
                                }}</span>
                            <span v-else-if="item.text === 'Irrelevant'" class="tab_number_data">
                                {{ total_irrelevant
                                }}</span>
                            <span v-else-if="item.text === 'Enquiries Booked'" class="tab_number_data"> {{
                        num_enquiry_booked
                    }}</span>
                        </div>
                    </v-tab>
                </v-tabs>
            </v-row>
            <!-- <v-row v-if="current_tab_index !== 0">
                <v-col md="3" class="ml-12 mt-5">
                    <v-select clearable :items="status_items" outlined dense placeholder="Status :"
                        v-model="selected_status" @change="filterEnquiryList" class="filter">
                        <template v-slot:prepend-inner>
                            <div class="d-flex align-center">
                                <v-icon>mdi-filter-outline</v-icon>
                                <span class="mr-1">Status</span>
                                <span class="mr-1">:</span>
                            </div>
                        </template>
</v-select>
</v-col>
</v-row> -->
            <!-- search bar here  -->
            <v-row>
                <v-col md="3" class="ml-12 mt-5">
                    <v-text-field outlined dense hide-details placeholder="Search Enquiries"
                        prepend-inner-icon="mdi-magnify" v-model="searchQuery" />
                </v-col>
            </v-row>

            <v-row>
                <v-col md="12" class="mr-15">
                    <!-- Data Table -->
                    <v-data-table :loading="show_Loader" :headers="getTableHeaders()" :items="enquiry_list"
                        :items-per-page="5" class="elevation-1 mx-12">
                        <template v-slot:[`item.actions`]="{ item }">

                            <v-btn outlined class="text-capitalize actionBtn my-2"
                                :class="action_list[0] === 'Create Booking' ? 'primary_btn' : 'secondary_btn'"
                                @click="handleAction(action_list[0], item.id)"> {{ action_list[0] }}
                            </v-btn>
                            <br>
                            <v-btn outlined class="text-capitalize actionBtn my-2"
                                :class="action_list[1] === 'Create Booking' ? 'primary_btn' : 'secondary_btn'"
                                @click="handleAction(action_list[1], item.id)">{{ action_list[1] }}</v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <NewEnquiryModal></NewEnquiryModal>
            <ConfirmationModal></ConfirmationModal>
            <IrrelevantModal></IrrelevantModal>
            <appointmentDrawer @reload-enquiry-list="getListOfAllEnquiries()" />
            <selectServicesDrawer></selectServicesDrawer>
        </div>
    </div>
</template>
<script>
import ConfirmationModal from '@/components/enquiry/ConfirmationModal.vue'
import NewEnquiryModal from '@/components/enquiry/NewEnquiryModal.vue'
import IrrelevantModal from '@/components/enquiry/IrrelevantModal.vue'
import appointmentDrawer from "../appointments/appointmentDrawer.vue"
import selectServicesDrawer from "@/components/appointments/selectServicesDrawer.vue"
import { mapGetters } from 'vuex'
export default {
    name: "EnquiryDesktop",
    data() {
        return {
            current_tab_name: "new_enquiry",
            current_tab_index: "",
            current_action_name: "",
            current_action_id: "",
            total_enquiries: "",
            total_follow_ups: "",
            total_irrelevant: "",
            num_enquiry_booked: "",
            action_list: [],
            enquiry_list: [],
            params: null,
            show_Loader: false,
            status_items: ["all", "active", "inactive"],
            selected_status: "all",
            searchQuery: "",
            tab_name: [
                {
                    text: "New Enquiries",
                    align: "center",
                    sortable: false,
                    value: "new_enquiry",
                },
                {
                    text: "Follow Ups",
                    align: "center",
                    sortable: false,
                    value: "follow_ups",
                },
                {
                    text: "Irrelevant",
                    align: "center",
                    sortable: false,
                    value: "irrelevant",
                },
                {
                    text: "Enquiries Booked",
                    align: "center",
                    sortable: false,
                    value: "enquiry_booked",
                },
            ],
            action: "",
            data: null
        }
    },
    components: {
        NewEnquiryModal,
        ConfirmationModal,
        IrrelevantModal,
        appointmentDrawer,
        selectServicesDrawer,
    },
    computed: {
        ...mapGetters({ triggerGetListApi: "enquiry_details/getEnquiryListApiFlag" }),
        ...mapGetters({ triggerUpdateListApi: "enquiry_details/getUpdateEnquiryListApiFlag" }),
        ...mapGetters({ moveToIrrelevant: "enquiry_details/getMoveToIrrelevantFlag" }),
        ...mapGetters({
            reason_data: "enquiry_details/getCustomReasonData",
        }),
    },
    watch: {
        triggerGetListApi() {
            this.getListOfAllEnquiries();
        },
        triggerUpdateListApi() {
            this.updateListOfAllEnquiries(this.current_action_name, this.current_action_id);
        },
        moveToIrrelevant() {
            this.updateListOfAllEnquiries(this.current_action_name, this.reason_data.id);
        },
        searchQuery() {
            this.filterEnquiryList();
        }

    },
    mounted() {
        this.getListOfAllEnquiries()
    },
    methods: {
        getTableHeaders() {
            if (this.current_tab_name === "irrelevant") {
                return [
                    {
                        text: "DATE",
                        align: "center",
                        sortable: false,
                        value: "created",
                    },
                    {
                        text: "CLIENT NAME",
                        align: "center",
                        sortable: false,
                        value: "name",
                    },
                    {
                        text: "MOBILE",
                        align: "center",
                        sortable: false,
                        value: "mobile",
                    },
                    {
                        text: "REASON",
                        align: "center",
                        sortable: false,
                        value: "irrelevant_reason",
                    },
                ]
            }
            else if (this.current_tab_name === "enquiry_booked") {
                return [
                    {
                        text: "DATE",
                        align: "center",
                        sortable: false,
                        value: "created",
                    },
                    {
                        text: "CLIENT NAME",
                        align: "center",
                        sortable: false,
                        value: "name",
                    },
                    {
                        text: "MOBILE",
                        align: "center",
                        sortable: false,
                        value: "mobile",
                    },
                    {
                        text: "QUERY",
                        align: "center",
                        sortable: false,
                        value: "query",
                    },

                ]
            }
            else {
                return [
                    {
                        text: "DATE",
                        align: "center",
                        sortable: false,
                        value: "created",
                    },
                    {
                        text: "CLIENT NAME",
                        align: "center",
                        sortable: false,
                        value: "name",
                    },
                    {
                        text: "MOBILE",
                        align: "center",
                        sortable: false,
                        value: "mobile",
                    },
                    {
                        text: "QUERY",
                        align: "center",
                        sortable: false,
                        value: "query",
                    },
                    {
                        text: "ACTION",
                        align: "center",
                        sortable: false,
                        value: "actions",
                    },
                ]
            }
        },
        createNewBooking() {
            this.$store.dispatch('dialog/setNewBookingDrawer', true);
        },
        openEnquiryModal() {
            this.$store.dispatch("dialog/setNewEnquiryDrawer");
        },
        changeTabTitle(item) {
            this.current_tab_name = item.value
            this.getListOfAllEnquiries()
        },
        handleAction(action_name, id) {
            this.current_action_name = action_name
            this.current_action_id = id
            if (action_name === "Create Booking") {
                this.getAppointmentData(id)
                // this.$store.dispatch("appointment/toggleCreateBookingDrawer", true)
                this.$store.dispatch("appointment/setCurrentEnquiry_id", id)
                // this.$store.dispatch("appointment/toggleClientDrawer", true); 
            }
            else if (action_name === "Follow Up Done") {
                this.$store.dispatch("dialog/setConfirmationModal")
            }
            else if (action_name === "Mark As Irrelevant") {
                this.$store.dispatch("dialog/setIrrelevant")
            }
        },
        // updateListOfAllEnquiries
        updateListOfAllEnquiries(action_name, id) {
            if (action_name === "Mark As Irrelevant") {
                var irrelevant_type = id
                this.action = "irrelevant"
                this.data = {
                    "enquiry_status": this.action,
                    "irrelevant_type": irrelevant_type,
                }
                let field_id = this.current_action_id
                this.params = { "id": field_id }
            }
            else if (action_name === "Follow Up Done") {
                this.action = "follow_ups"
                this.data = {
                    "enquiry_status": this.action,
                }
                this.params = { id }
            }
            // make params dynamic
            const successHandler = (response) => {
                this.total_enquiries = response.data.new_enquiry
                this.total_follow_ups = response.data.follow_ups
                this.total_irrelevant = response.data.irrelevant
                this.enquiry_list = response.data.enquiry_list
                this.action_list = response.data.actions
                this.num_enquiry_booked = response.data.enquiry_booked


                if (action_name === "Mark As Irrelevant") {
                    this.$store.dispatch("dialog/setIrrelevant")
                    this.getListOfAllEnquiries()
                } else if (action_name === "Follow Up Done") {
                    this.$store.dispatch("dialog/setConfirmationModal")
                    this.getListOfAllEnquiries()
                }

            }
            const failureHandler = (error) => {
                if (error && error.data && error.data.message) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("patch", this.$apiUrl.UPDATE_ENQUIRY_LIST, {
                params: this.params,
                data: this.data,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },

        filterEnquiryList() {
            let parlour_id = localStorage.getItem("parlour_id")
            let params = {
                "parlour_id": parlour_id,
                "status": this.current_tab_name,
                "search": this.searchQuery
            }
            const successHandler = (response) => {
                this.total_enquiries = response.data.new_enquiry
                this.total_follow_ups = response.data.follow_ups
                this.total_irrelevant = response.data.irrelevant
                this.enquiry_list = response.data.enquiry_list
                this.action_list = response.data.actions
            }
            const failureHandler = (error) => {
                if (error && error.data && error.data.message) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("get", this.$apiUrl.GET_ENQUIRY_LIST, {
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
        // api calling for enquiries list
        getListOfAllEnquiries() {
            this.show_Loader = true
            let parlour_id = localStorage.getItem("parlour_id")
            let status = this.current_tab_name
            let params = {
                "parlour_id": parlour_id,
                "status": status,
                "search": this.searchQuery
            }
            const successHandler = (response) => {
                this.show_Loader = false
                this.total_enquiries = response.data.new_enquiry
                this.total_follow_ups = response.data.follow_ups
                this.total_irrelevant = response.data.irrelevant
                this.enquiry_list = response.data.enquiry_list
                this.num_enquiry_booked = response.data.enquiry_booked

                if (response?.data?.actions) this.action_list = response.data.actions
                this.show_Loader = false
            }
            const failureHandler = (error) => {
                if (error && error.data && error.data.message) {
                    this.show_Loader = false
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("get", this.$apiUrl.GET_ENQUIRY_LIST, {
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
        getAppointmentData(id) {
            let parlour_id = localStorage.getItem("parlour_id")
            let params = {
                "parlour_id": parlour_id,
                "enquiry_id": id
            }
            const successHandler = (response) => {
                const clientDetail = response.data.response_json.client_detail;
                console.log(clientDetail.mobile)
                console.log(clientDetail.first_name)
                this.$store.dispatch("appointment/setClientDetail", clientDetail);
                if (response.data.response_json.flag_client_exists == true) {
                    this.$store.dispatch("appointment/toggleCreateBookingDrawer", true)
                }
                else {
                    this.$store.dispatch("appointment/toggleCreateBookingDrawer", true)
                    this.$store.dispatch("appointment/toggleClientDrawer", true);
                }
            }
            const failureHandler = (error) => {
                if (error && error.data && error.data.message) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("get", this.$apiUrl.GET_APPOINTENT_DATA, {
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
    }
}
</script>
<style scoped>
.v-data-table>>>.v-data-table__wrapper table td {
    color: #333333 !important;
    font-weight: 550 !important;
    font-size: 14px !important;
}

.primary_btn {
    color: white;
    background-color: #F2797B !important;
}

.secondary_btn {
    color: #F2797B !important;
}

.v-data-table>>>.v-data-table__wrapper table thead tr th {
    color: #333333 !important;
    font-size: 14px !important;
}

.filter {
    width: 228px !important;
}

.v-tab {
    border: solid 1px #DFE1E3 !important;
}

.active_tab {
    background-color: #F2797B !important;
    color: #FFFFFF !important;
}

.tab_name {
    font-weight: 500 !important;
    font-size: 20px !important;
}

.tab_number_data {
    font-weight: 700 !important;
    font-size: 42px !important;
}

.v-data-table>>>.v-data-table-header {
    background-color: #FBF1F2 !important;
}

.name {
    font-weight: 600;
    font-size: 14px;
    font-family: cursive;
}

.actionBtn {
    border-radius: 10px;
    padding: 6px 10px 6px 10px !important;
    /* width: 79px !important; */
    font-size: 12px !important;
}

.v-btn {
    min-width: 164px !important;
}

.number {
    font-size: 12px;
    font-weight: 500;
    color: #697498;
    margin-top: -12px;
}

.data_status {
    width: 325.33px;
    height: 162px;
    border-radius: 16px;
    background-color: #FFEDED !important;
}

.tab {
    width: 341.33px;
    height: 120px;
    border: solid 1px;
    border-color: #DFE1E3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.active-tab {
    background-color: #F2797B;
    color: white !important
}

.mob-active-tab {
    color: #F2797B;
}

.v-application .pt-4 {
    padding-top: 0px !important;
}
</style>
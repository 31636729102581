<template>
    <v-app class="main_div">
        <v-container class="mt-11">
            <v-row class="d-flex align-center">
                <v-col v-if="$isDesktop">
                    <v-img src="../../assets/brush_logo.svg" class="logo_img"></v-img>
                </v-col>
                <v-col class="d-flex">
                    <v-row>
                        <v-col md="2" cols="3" class="d-flex align-center pl-0">
                            <v-icon large left @click="goBack">mdi-arrow-left-thick</v-icon>
                        </v-col>
                        <v-col md="8" cols="6" class="d-flex align-center justify-center mr-auto header">
                            <h1>Privacy Policy</h1>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="d-flex">
                <v-col md="6" v-if="$isDesktop">
                    <v-img src="../../assets/welcome_img.svg" class="welcome_img" v-if="$isDesktop"></v-img>
                </v-col>
                <v-col md="6" class="pa-0">
                    <v-card class="policy_card">
                        <v-card-text>
                            <p>This <span class="title">Privacy Policy</span> outlines <span class="title">Brush
                                    Circle's</span> (Suraj Laxmi
                                Technologies Pvt. Ltd incorporated
                                and registered under companies Act 2013 regd office at 10, geedam road, Jagdalpur)
                                approach
                                to Data Protection and Privacy to fulfill its obligations under the applicable laws and
                                regulations. This Privacy Policy applies to your Personal Data which is processed by us,
                                whether in physical or electronic mode.</p>
                            <p> We are committed to protecting your privacy and hence our Personal Data handling
                                practices
                                are continually reviewed to ensure compliance with the <span class="title">
                                    applicable Privacy laws and
                                    regulations
                                </span>
                            </p>
                            <p class="title">Data NOT collected and processed by us are as follows</p>
                            <ul>
                                <li>Your data is saved in Cloud Database and not accessed by Brush circle.</li>
                                <li>Your client and account details are not accessed by Brush circle.</li>
                            </ul>
                            <br>
                            <p class="title">Categories of Personal Data collected and processed by us are as follows;
                            </p>
                            <ul>
                                <p class="title">Demographic & Identity data</p>
                                <li>Contact details provided by you while making profile such as Name, contact number,
                                    country,date of birth etc.</li>
                                <li>Details such as Transaction amount, Bank Name, Card Type, Card number.</li>
                            </ul>
                            <br>
                            <ul>
                                <p class="title">
                                    Online Identifiers and other Technical Data
                                </p>
                                <li>Technical details such as device information, location and network carrier when you
                                    use
                                    our mobile applications</li>
                                <li>Communications details such as the Metadata and other Personal Data we get from
                                    communications done through e-mails, SMS, instant messages and calls</li>
                            </ul>
                            <br>
                            <ul>
                                <p class="title">
                                    Reasons for disclosing your Personal Data to other parties:
                                </p>
                                <p class="sub_title">We may disclose your Personal Data to others where it is lawful to
                                    do
                                    so including where
                                    we or they:</p>
                                <li>need to provide you with products or services</li>
                                <li>where you have initiated a payment</li>
                                <li>have a legal obligation to do so, e.g. to assist with detecting and preventing fraud
                                </li>
                                <li>have requirement in connection with regulatory reporting, litigation or asserting or
                                    defending legal rights and interests</li>
                                <li>have a legitimate business reason for doing so</li>
                                <li>want to send you information and promotional material regarding our products and
                                    services,</li>
                                <li>have asked you for your consent to share it, and you’ve agreed</li>
                                <li>Improve your online experience for using our product.</li>
                                <br>
                                <p class="title">To whom your Personal Data may be disclosed:</p>
                                <p class="sub_title">We may disclose your Personal Data for the above purposes to other
                                    parties including:</p>
                                <li>Other group companies and any subcontractors, agents or service providers who work
                                    for
                                    us or provide services to us or group companies (including their employees,
                                    sub-contractors, service providers, directors and officers)
                                </li>
                                <li>Law Enforcement Authorities, Government Authorities, courts, dispute resolution
                                    bodies,
                                    our regulators, auditors and any party appointed or requested by our regulators to
                                    carry
                                    out investigations or audits of our activities</li>
                                <li>Statutory and regulatory bodies and authorities (including the government)
                                    investigating
                                    agencies and entities or persons, to whom or before whom it is mandatory to disclose
                                    the
                                    Personal Data as per the applicable law, courts, judicial and quasi-judicial
                                    authorities
                                    and tribunals, arbitrators and arbitration tribunals</li>
                                <li>Overseas regulators, Anybody else that we’ve been instructed to share your Personal
                                    Data
                                    with by you.</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
<script>
export default {
    name: "TermsOfService",
    data() {
        return {
            is_resend_enable: true,
            is_timer_enable: false,
            countDownTimer: null,
            otp_field: "",
            mobile: null,
            send_otp_btn_loader: false,
            verify_btn_loader: false,
            flag: "",
            country_name: "",
            phone_code_list: null,
            selected_phone_code: "+91",
            selected_flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
            switch_to_verify_page: false,
        }
    },
    components: {

    },
    watch: {
        otp_field() {
            if (this.otp_field.length === 6) {
                this.verifyOtp();
            }
        }
    },
    methods: {
        goBackToMainLoginPage() {
            this.switch_to_verify_page = false,
                this.mobile = ""
        },
        goBack() {
            this.$router.go(-1)
            // this.$router.push({
            //     name: "login"
            // })
        }
    }
}
</script>
<style scoped>
.main_div {
    background-image: url("../../assets/bg_img_1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.logo_img {
    width: 185.96px;
}

.policy_card {
    overflow-y: scroll;
    height: 80vh !important;
}

::-webkit-scrollbar {
    display: none;
}

.title {
    color: black !important;
    font-weight: 400 !important;
    font-size: 15px !important;
}

.sub_title {
    color: black !important;
    font-weight: 300 !important;
    font-size: 14px !important;
}

@media all and (max-width:600px) {
    .header {
        font-size: 12px !important;
    }
}
</style>
<template>
  <v-container class="">
    <!-- 1st row  title-->
    <v-row class="ma-0 title_bar">
      <v-col class="d-flex align-center">
        <p class="module_title mb-0">{{ $route.meta.title }}</p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end">
        <v-menu class="kkk">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-capitalize white--text ma-5 mr-0"
              color="#F2797B"
              v-on="on"
              v-bind="attrs"
            >
              Create New<v-icon> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in action_items" :key="index">
              <v-list-item-title @click="handleAction(item.title)">
                <v-icon outlined class="ma-2 plus_icon" color="#F2797B">
                  mdi-plus-circle-outline </v-icon
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- 2nd row search-bar -->
    <v-row class="mt-3 search_and_filter">
      <v-col md="3">
        <v-text-field
          outlined
          dense
          hide-details
          class="search_bar"
          placeholder="Search Category"
          prepend-inner-icon="mdi-magnify"
          v-model="searchQuery"
        />
      </v-col>
      <v-col md="3">
        <v-select
          clearable
          :items="category_status"
          item-value="value"
          outlined
          dense
          v-model="status_category"
          class="status_filter"
        >
          <template v-slot:prepend-inner>
            <div class="d-flex align-center">
              <v-icon>mdi-filter-outline</v-icon>
              <p class="mr-1 mb-0 mt-1">Status</p>
            </div>
          </template></v-select
        >
      </v-col>
    </v-row>
    <!-- 3rd row  category-btns -->
    <v-row class="d-flex flex-column">
      <v-col class="pl-0 mt-5">
        <p class="mb-0 category_text">Select a category</p>
      </v-col>
      <!-- add a scrollbar  -->
      <v-sheet class="mx-auto" elevation="0" max-width="1500">
        <v-slide-group center-active show-arrows v-model="model">
          <v-slide-item
            v-for="(item, index) in category_list"
            :key="index"
            v-slot="{ active, toggle }"
          >
            <v-btn
              :input-value="active"
              outlined
              text
              class="mr-2"
               :class="['category_btn', { active: selectedItem === item.id, 'grey-btn': !item.is_active }]"
              @click="
                toggle();
                filterServices(item.id);
              "
            >
              {{ item.category_name }}
              <!-- menu -->
              <v-menu id="create_new_menu">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon right v-on="on" v-bind="attrs"
                    >mdi-dots-vertical</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item
                    v-for="(
                      actionItem, actionIndex
                    ) in item.category_action_items"
                    :key="actionIndex"
                  >
                    <v-list-item-title
                      @click="
                        handleAction(
                          actionItem.title,
                          item.category_name,
                          item.id,
                          item.description,
                          '',
                          '',
                          '',
                          item.is_active
                        )
                      "
                    >
                      {{ actionItem.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <!-- {{service_list}} -->
    </v-row>
    <!-- 4th row  services card-->
    <v-row
      v-if="
        service_list && service_list.length !== 0 && category_list.length !== 0
      "
    >
      <v-col class="pt-5">
        <v-row class="two-cards-per-row">
          <v-col md="4" class="d-flex">
            <!-- add skeleton loader -->

            <div
              class="two-cards-per-row"
              style="max-height: 335px"
              v-for="(item, index) in service_list"
              :key="index"
            >
              <v-skeleton-loader
                v-if="!show_service_loader"
                class="mx-auto"
                width="300"
                type="card"
              ></v-skeleton-loader>
              <v-card
                v-else
                elevation="2"
                outlined
                width="310"
                color="#FFF7F7"
                class="services_card mx-2 mb-3"
              >
                <v-card-title>
                  <div>{{ item.service_name }}</div>
                  <v-spacer></v-spacer>
                  <!-- v-menu -->
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon right v-on="on" v-bind="attrs"
                        >mdi-dots-vertical</v-icon
                      >
                    </template>
                    <v-list
                      v-for="(actionItem, actionIndex) in service_action_list"
                      :key="actionIndex"
                    >
                      <v-list-item
                        @click="
                          handleAction(
                            actionItem.title,
                            item.service_name,
                            item.id,
                            item.description,
                            item.category,
                            item.price,
                            item.duration_id
                          )
                        "
                      >
                        <v-list-item-title>
                          {{ actionItem.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
                <v-card-subtitle>
                  <p class="mb-0">{{ item.duration }} . ₹ {{ item.price }}</p>
                  <p v-if="item?.description">
                    {{ truncateDescription(item.description) }}
                    <span
                      v-if="item?.description && more_flag"
                      class="show_more_btn"
                      @click="showMoreDescription(item)"
                      >show more ...</span
                    >
                  </p>
                </v-card-subtitle>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else class="data_unavailable">
      <p>Services for this category is not available</p>
    </v-row>
    <NewCategoryModal></NewCategoryModal>
    <NewServiceModal></NewServiceModal>
    <EditCategoryConfirmitionModal></EditCategoryConfirmitionModal>
    <ServiceDescription></ServiceDescription>
  </v-container>
</template>
<script>
import NewCategoryModal from "./NewCategoryModal.vue";
import NewServiceModal from "./NewServiceModal.vue";
import EditCategoryConfirmitionModal from "@/components/services/EditCategoryConfirmitionModal.vue";
import ServiceDescription from "./ServiceDescription.vue";
import { mapGetters } from "vuex";
export default {
  name: "ServicesDesktop",
  data() {
    return {
      action_items: [
        { title: "Add A Category" },
        { title: "Create A Service" },
      ],
      service_action_list: [{ title: "Edit Service" }],
      category_status: [
        { text: "All Categories", value: "all" },
        { text: "Active Categories", value: "active" },
        { text: "Inactive Categories", value: "inactive" },
      ],
      searchQuery: "",
      category_list: [],
      service_list: null,
      selectedItem: "",
      model: null,
      show_service_loader: false,
      status_category: "all",
      firstCategoryItem: "",
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("services/ListCategory");
      const categoryList = this.get_category_list;
      if (categoryList && categoryList.length > 0) {
        this.firstCategoryItem = categoryList[0];
        await this.filterServices(this.firstCategoryItem.id);
      }
      // I am calling the filterService with the first category id to list the services of the first category by default  thats'y i have commented the listServices api bcz it is listing all the services by default when the component is mounted
    } catch (error) {
      // Handle error if needed
    }
  },
  computed: {
    ...mapGetters({
      get_category_list: "services/get_category_list",
      serviceListFromState: "services/get_services_list",
    }),
  },
  components: {
    NewCategoryModal,
    NewServiceModal,
    EditCategoryConfirmitionModal,
    ServiceDescription,
  },
  watch: {
    async status_category() {
      await this.searchCategory();
      this.filterServices(this.firstCategoryItem.id);
    },
    get_category_list() {
      this.category_list = this.get_category_list;
    },
    serviceListFromState() {
      this.service_list = this.serviceListFromState;
      this.selectedItem = this.service_list[0].category;
    },
    searchQuery() {
      this.searchCategory();
    },
  },
  methods: {
    showMoreDescription(item) {
      let payload = {
        data: item,
        flag: true,
      };
      this.$store.dispatch("services/toogleDescriptionModal", payload);
    },
    truncateDescription(description) {
      if (description.length > 10) {
        this.more_flag = true;
        return description.slice(0, 10);
      } else {
        this.more_flag = false;
        return description;
      }
    },
    handleAction(
      title,
      current_item_name,
      current_item_id,
      current_item_description,
      category,
      price,
      duration_id,
      category_is_active,
    ) {
      this.current_item_name = current_item_name;
      this.current_item_id = current_item_id;
      this.current_item_descripton = current_item_description;
      let payload = {
        title,
        current_item_name,
        current_item_id,
        current_item_description,
        category,
        price,
        duration_id,
        category_is_active,
      };
      if (title == "Add A Service") {
        this.$store.dispatch("dialog/setServiceDrawer", payload);
      } else if (title == "Create A Service") {
        this.$store.dispatch("dialog/setServiceDrawer", payload);
      } else if (title == "Edit Service") {
        this.$store.dispatch("dialog/setServiceDrawer", payload);
      } else if (title == "Activate Category") {
        let category_payload = {
          flag: true,
          category_id: current_item_id,
          category_is_active: category_is_active,
        };
        this.$store.dispatch(
          "dialog/setEditCategoryConfirmition",
          category_payload
        );
      } else if (title == "Deactivate Category") {
        let category_payload = {
          flag: true,
          category_id: current_item_id,
          category_is_active: category_is_active,
        };
        this.$store.dispatch(
          "dialog/setEditCategoryConfirmition",
          category_payload
        );
      } else if (title == "Add A Category") {
        this.$store.dispatch("dialog/setCategoryDrawer", payload);
      }
    },
    navigateToDashboard() {
      this.$router.push({
        name: "dashboard",
      });
    },
    searchCategory() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        search: this.searchQuery,
        status: this.status_category,
      };
      let data = {
        service_name: this.service_name,
      };
      const successHandler = (response) => {
        this.category_list = response.data.service_category_list;
        // find first Category item here
        if (this.category_list && this.category_list.length > 0) {
          this.firstCategoryItem = this.category_list[0];
        }
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.LIST_CATEGORY, {
        params,
        data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    filterServices(item_id) {
      this.selectedItem = item_id;
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        category: item_id,
      };
      const successHandler = (response) => {
        this.show_service_loader = true;
        this.service_list = response.data.service_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.LIST_SERVICE, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    
  },
};
</script>
<style scoped>
.v-sheet >>> .v-slide-group__wrapper {
  background-color: #fafafa !important;
}

.search_and_filter {
  display: flex;
  justify-content: space-between !important;
}

.data_unavailable {
  display: flex !important;
  justify-content: center !important;
  margin-top: 125px !important;
}

.scrollable-row {
  overflow-y: auto;
}

.category_btn.active {
  background-color: #f2797b;
  color: white;
}

.category_btn.grey-btn {
  background-color:#E0E0E0;
  color: black;
}

.title_bar {
  display: flex;
  align-items: center !important;
}

.search_bar {
  background-color: white !important;
}

.status_filter >>> .v-text-field__details {
  display: none !important;
}

.v-toolbar__content {
  display: block !important;
}

.v-application .d-flex {
  display: contents !important;
  align-items: flex-end !important;
}

.v-list-item {
  cursor: pointer !important;
}

.two-cards-per-row {
  display: flex !important;
  flex-wrap: wrap;
  column-gap: 50px !important;
  margin-bottom: 3px !important;
}

.services_card {
  border-left: solid 5px #f2797b !important;
}

.v-list {
  background-color: #fff7f7 !important;
}

.v-list-item__title,
.show_more_btn {
  color: #f2797b !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.category_text {
  color: #9397a7;
  font-size: 20px !important;
  font-weight: 500;
}
</style>

<template>
    <v-container>
        <v-row>
            <v-col>
                <v-text-field outlined dense hide-details placeholder="Search Team Members"
                    prepend-inner-icon="mdi-magnify" v-model="search_query" class="search_bar" />
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6">
                <v-select clearable :items="status_items" item-value="value" outlined dense placeholder="Status " 
                    v-model="selected_status" class="filter narrow-select" @change="filterFun" prepend-inner-icon="mdi-filter-outline">
                    <!-- <template v-slot:prepend-inner>
                        <div class="d-flex align-center">
                            <v-icon>mdi-filter-outline</v-icon>
                            <span class="mr-1">Status</span>
                            <span class="mr-1">:</span>
                        </div>
                    </template> -->
                    <template v-slot:prepend-inner>
                        <div class="d-flex align-center">
                            <img :src="require('@/assets/filter_icon.png')" alt="Status Icon" class="icon-image mr-1">
                            <span class="mr-1 status_bar">Status</span>
                            <span class="mr-1">:</span>
                        </div>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row class="ma-2 header">Team Members</v-row>

        <!-- skeleton loader -->
        <v-row v-if="isLoading" class="my-4 mx-2">
            <v-col cols="12">
                <v-skeleton-loader max-width="400" type="list-item-two-line"></v-skeleton-loader>
            </v-col>
            <v-col cols="12">
                <v-skeleton-loader max-width="400" type="list-item-two-line"></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-simple-table fixed-header>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="(item, index) in team_list" :key="index" class="py-12" @click="openTeamDetailPage()">
                            <td>
                                <!-- <p class="client_name my-2">{{ item.full_name }}</p> -->
                                <router-link :to="{ name: 'member-details', params: { id: item.id } }"
                                    class="client_name my-2" v-if="item?.full_name">{{ item?.full_name
                                    }}</router-link>
                                <p class="client_number my-2" v-if="item?.mobile">+ 91{{ item?.mobile }}</p>
                            </td>
                            <td class="text-right"> 
                                <!-- <v-chip
                                    :class="[item.is_active ? 'status_active' : 'status_inactive']">{{ item?.status
                                    }}</v-chip> -->
                                    <v-chip class="status_active" v-if="item.status == 'Activate'">Active Member</v-chip>
                         <v-chip class="status_inactive" v-else>InActive Member</v-chip>
                                    </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-fab-transition>
                <v-btn color="pink" dark absolute top right fab class="fab_btn" @click="openCreateNewTeamMemberModal()">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-row>
        <InviteTeamModalMobile></InviteTeamModalMobile>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import InviteTeamModalMobile from './InviteTeamModalMobile.vue';
export default {
    name: "TeamManagementMobile",
    data() {
        return {
            team_list: [],
            search_query: "",
            status_items: [{ text: "All", value: "all" }, { text: "Active", value: "active" }, { text: "Inactive", value: "inactive" }],
            selected_status: "all",
           
        };
    },
    components: {
        InviteTeamModalMobile
    },
    mounted() {
        let parlour_id = localStorage.getItem("parlour_id")
        let payload = {
            parlour_id: parlour_id,
            searchQuery: this.search_query,
            status: this.selected_status
        }
        this.$store.dispatch("team/callTeamListApi", payload)
    },
    computed: {
        ...mapGetters({ staff_list_state: 'team/get_team_list' }),
        isLoading() {
            return this.$store.state.team.isTeamLoading;
        },
    },
    watch: {
        staff_list_state() {
            this.team_list = this.staff_list_state
        },
        search_query() {
            let parlour_id = localStorage.getItem("parlour_id")
            let payload = {
                parlour_id: parlour_id,
                searchQuery: this.search_query,
                status: this.selected_status
            }
            this.$store.dispatch("team/callTeamListApi", payload)
        }
    },
    methods: {
        openCreateNewTeamMemberModal() {
            let payload = {
                open: true,
                flag: "add"
            }
            this.$store.dispatch("dialog/setNewMemberModalMobile", payload)
        },
        filterFun() {
            let parlour_id = localStorage.getItem("parlour_id")
            let payload = {
                parlour_id: parlour_id,
                searchQuery: this.search_query,
                status: this.selected_status
            }
            this.$store.dispatch("team/callTeamListApi", payload)
        },
        openTeamDetailPage() {

        }
    }
};
</script>
<style scoped>
.fab_btn {
    position: fixed;
    left: 80vw;
    bottom: 5vh;
    border-radius: 30%;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
    top:76vh !important;
}

p {
    margin-bottom: 0 !important;
}

.filter>>>.v-text-field__details {
    display: none !important;
}

.search_bar,
.filter {
    background-color: white !important;
    
}

.header {
    color: black !important;
    font-weight: 600;
    font-size: 20px;
}

.row {
    display: block !important;
}

.client_name {
    font-size: 16px;
    font-weight: 400;
    color: #404C57;
}

.client_number {
    font-size: 11px;
    font-weight: 400;
    color: #727272;
}

.status_active {
    background-color: #E6FBD9 !important;
    color: #0F5B1D;
}

.status_inactive {
    background-color: #FFE8D7 !important;
    color: #931222 !important
}
.narrow-select {
    width:180px;
    font-size: 12px;

   
}
.status_bar{
      margin:7px;

  }
</style>
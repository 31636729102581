<template>
  <v-container class="d-flex flex-column justify-center mt-5" v-if="$isDesktop">
    <!--1-->
    <v-row>
      <v-col class="d-flex align-center">
        <p class="module_title">{{ $route.meta.title }}</p>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          class="text-capitalize white--text mr-1"
          color="#F2797B"
          @click="openNewBookingDrawer()"
          v-if="selected_appointment.length == 0"
        >
          <v-icon left> mdi-plus-circle-outline </v-icon>New Appointment
        </v-btn>
        <v-btn
          v-if="booking_list.length !== 0 && selected_appointment.length !== 0"
          class="text-capitalize white--text mr-1"
          color="#F2797B"
          @click="completePayment()"
        >
          <v-icon left> mdi-plus-circle-outline </v-icon>Complete Payment
        </v-btn>
        <v-btn
          v-if="booking_list.length !== 0 && selected_appointment.length !== 0"
          class="text-capitalize white--text mr-1"
          color="#F2797B"
          @click="cancelAppointment()"
        >
          <v-icon left> mdi-plus-circle-outline </v-icon>Cancel
        </v-btn>
      </v-col>
    </v-row>
    <!--2-->
    <v-row class="mx-0">
      <v-tabs
        height="95px"
        grow
        class="text-capitalize"
        color="#F2797B"
        active-class="active_tab"
        v-model="current_tab_index"
      >
        <v-tab
          v-for="(item, index) in tab_name"
          :key="index"
          @click="changeTabTitle(item)"
          class="d-flex flex-column align-start"
        >
          <div class="tab_name text-capitalize mt-3">{{ item.text }}</div>
          <div class="tab_number_data">
            <span v-if="item.value === 'upcoming'"> {{ total_upcomings }}</span>
            <span v-else-if="item.value === 'completed'">
              {{ total_completed }}</span
            >
            <span v-else-if="item.value === 'cancelled'">
              {{ total_cancelled }}</span
            >
          </div>
        </v-tab>
      </v-tabs>
    </v-row>
    <!--3-->
    <v-row class="mt-8 d-flex justify-space-between">
      <v-col md="3">
        <v-text-field
          outlined
          dense
          hide-details
          placeholder="Search Appointments"
          prepend-inner-icon="mdi-magnify"
          v-model="searchQuery"
        />
      </v-col>
      <v-col md="3" v-if="current_tab_index == 0" class="d-flex justify-end">
        <v-menu
          ref="menu4"
          v-model="menu_4"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="status_filter"
              clearable
              outlined
              dense
              :value="dateFilter2"
              placeholder="From - To"
              append-icon="mdi-chevron-down"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="(dates_4 = []), getBookingList()"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates_4" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu4 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="handleOKClickDate()">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <!-- Bill Type Filter -->
      <v-col sm="3" v-if="current_tab_index == 1" class="mx-1">
        <v-select
          clearable
          :items="bill_type"
          item-value="value"
          outlined
          dense
          v-model="selected_bill_type"
          @change="getBookingList"
          class="bill-type mx-1"
        >
          <template v-slot:prepend-inner>
            <div class="d-flex align-center">
              <v-icon>mdi-filter-outline</v-icon>
              <span class="mr-1">Bill</span>
              <span class="mr-1">Type</span>
              <span>:</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col sm="3" v-if="current_tab_index !== 0">
        <v-menu
          ref="menu3"
          v-model="menu_2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFilter"
              class="status_filter"
              clearable
              outlined
              dense
              placeholder="From - To"
              append-icon="mdi-chevron-down"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="(dates_2 = []), getBookingList()"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates_2" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu3 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="handleOKClick"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <!-- Months filter -->
      <!-- <v-col md="3" v-if="current_tab_index == 1 || current_tab_index == 2">
               
            </v-col> -->
    </v-row>
    <!--data table -->
    <!-- skeleton loader -->

    <v-row>
      <v-col md="12" class="mt-n7">
        <v-skeleton-loader
          v-if="isLoading"
          class="mx-auto"
          max-width="1190"
          type="card"
        ></v-skeleton-loader>
        <v-data-table
          @click:row="(item) => openAppointmentDetailsDrawer(item)"
          v-else
          :headers="getTableHeaders()"
          :items="filteredArray.length > 0 ? filteredArray : booking_list"
          :items-per-page="5"
          class="elevation-1 table"
          v-model="selected_appointment"
          :show-select="current_tab_name === 'upcoming'"
        >
          <template v-slot:[`item.services`]="{ item }">
            <div
              class="services_column d-flex"
              v-for="(service, subindex) in item.services.split(',')"
              :key="subindex"
            >
              <p class="mb-0">
                {{ service.trim() }}
              </p>
              <br />
            </div>
          </template>

          <!-- <template v-slot:[`item.client_full_name`]="{ item }">
                        <td @click="openAppointmentDetailsDrawer(item)" class="services_column"
                            style="text-decoration: underline;color:#F2797B;font-weight: 500;cursor: pointer;">{{
                                item.client_full_name }}</td>
                    </template> -->

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="current_tab_name === 'upcoming'"
              @click.stop="reschedule(item)"
              class="text-capitalize white--text completeActionBtn my-2"
              color="#F2797B"
              @click="reschedule(item)"
            >
              {{ item.Actions }}Reschedule</v-btn
            >
            <!-- for completed -->
            <!-- <v-icon v-if="current_tab_name === 'completed'" @click="activeViewBtn">mdi-dots-vertical</v-icon> -->
            <v-menu offset-y v-if="current_tab_name === 'completed'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-btn
                @click="openAppointmentDetailsDrawer(item)"
                color="primary"
                class="text-capitalize white--text"
                >Appointment Details</v-btn
              >
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <appointmentDrawer></appointmentDrawer>
    <selectServicesDrawer></selectServicesDrawer>
    <paymentPage
      @clear_selected_appointment_data="clearAppointmentData"
    ></paymentPage>
    <appointmentCancelModal
      @clear_selected_appointment_data="clearAppointmentData"
    >
    </appointmentCancelModal>
    <updateAppointmentDrawer></updateAppointmentDrawer>
    <appointmentDetailsDrawer></appointmentDetailsDrawer>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import appointmentDrawer from "@/components/appointments/appointmentDrawer.vue";
import selectServicesDrawer from "@/components/appointments/selectServicesDrawer.vue";
import paymentPage from "@/components/appointments/paymentPage.vue";
import appointmentCancelModal from "./appointmentCancelModal.vue";
import updateAppointmentDrawer from "./updateAppointmentDrawer.vue";
import appointmentDetailsDrawer from "../../components/appointments/appointmentDetailsDrawer.vue";
export default {
  name: "appointmentDesktop",
  data() {
    return {
      total_cancelled: null,
      dates_2: [],
      menu_2: "",
      dates_4: [],
      menu_4: "",
      total_completed: null,
      total_upcomings: null,
      booking_list: [],
      tab_name: [
        {
          text: "Upcoming",
          align: "center",
          sortable: false,
          value: "upcoming",
          // class: "primary_2 white--text",
        },
        {
          text: "Completed",
          align: "center",
          sortable: false,
          value: "completed",
          // class: "primary_2 white--text",
        },
        {
          text: "Cancelled",
          align: "center",
          sortable: false,
          value: "cancelled",
          // class: "primary_2 white--text",
        },
      ],
      current_tab_index: "",
      current_tab_name: "upcoming",
      num_of_bookings: "",
      searchQuery: "",
      bill_type: [
        {
          text: "All",
          value: "ALL",
        },
        {
          text: "Gst",
          value: "GST",
        },
        {
          text: "Estimate",
          value: "Estimate",
        },
      ],
      selected_bill_type: "",
      next_days_list: [
        {
          text: "7 Days",
          value: "7",
        },
      ],
      selected_day_type: "",
      past_months: [
        {
          text: "1 Month",
          value: this.currentDate(),
        },
      ],
      selected_month_type: "",
      // singleSelect: "",
      selected_appointment: [],
      selected_cancel_completed_checbox: "",
      filteredArray: [],
    };
  },
  components: {
    appointmentDrawer,
    selectServicesDrawer,
    paymentPage,
    appointmentCancelModal,
    updateAppointmentDrawer,
    appointmentDetailsDrawer,
  },
  computed: {
    ...mapGetters({
      booking_lists: "appointment/get_booking_list",
      total_booking_num: "appointment/get_total_booking_num",
    }),
    isLoading() {
      return this.$store.state.appointment.isBookingLoading;
    },
    dateFilter() {
      return this.dates_2.join(" ~ ");
    },
    dateFilter2() {
      return this.dates_4.join(" ~ ");
    },
  },
  watch: {
    booking_lists(val) {
      this.booking_list = val;
    },
    total_booking_num(val) {
      this.total_upcomings = val.upcoming;
      this.total_completed = val.completed;
      this.total_cancelled = val.cancelled;
    },
    searchQuery() {
      // call get api for booking
      let payload = {
        status: this.current_tab_name,
        search: this.searchQuery,
        bill_type: this.selected_bill_type,
      };
      if (this.dates_2[0] && this.dates_2[1]) {
        payload.from_date = this.dates_2[0];
        payload.to_date = this.dates_2[1];
      } else if (this.dates_4[0] && this.dates_4[1]) {
        payload.from_date = this.dates_4[0];
        payload.to_date = this.dates_4[1];
      }
      this.$store.dispatch("appointment/getBookingList", payload);
    },
    selected_appointment(val) {
      if (val.length === 0) {
        this.filteredArray = [];
      }
      if (val) {
        this.filteredArray = this.booking_list.filter((item) => {
          return item?.client_mobile_number === val[0]?.client_mobile_number;
        });
      }
    },
  },
  mounted() {
    let payload = {
      status: this.current_tab_name,
      search: this.searchQuery,
      bill_type: this.selected_bill_type,
    };
    if (this.dates_2[0] && this.dates_2[1]) {
      payload.from_date = this.dates_2[0];
      payload.to_date = this.dates_2[1];
    } else if (this.dates_4[0] && this.dates_4[1]) {
      payload.from_date = this.dates_4[0];
      payload.to_date = this.dates_4[1];
    }
    this.$store.dispatch("appointment/getBookingList", payload);
  },
  methods: {
    handleOKClickDate() {
      this.$refs.menu4.save(this.dates_4);
      this.menu_4 = false;
      this.getBookingList();
    },
    handleOKClick() {
      this.$refs.menu3.save(this.dates_2);
      this.menu_2 = false;
      this.getBookingList();
    },
    openNewBookingDrawer() {
      this.$store.dispatch("appointment/toggleCreateBookingDrawer", true);
    },
    openAppointmentDetailsDrawer(item) {
      let payload = {
        flag: true,
      };
      this.$store.dispatch("appointment/toggleDetailsDrawer", payload);
      this.$store.dispatch("appointment/get_appointment_detail_api", item.id);
    },
    getBookingList() {
      let payload = {
        status: this.current_tab_name,
        search: this.searchQuery,
        bill_type: this.selected_bill_type,
        next_days: this.selected_day_type,
      };
      if (this.dates_2[0]) {
        payload.from_date = this.dates_2[0];
        payload.to_date = this.dates_2[1];
      } else if (this.dates_4[0]) {
        payload.from_date = this.dates_4[0];
        payload.to_date = this.dates_4[1];
      }
      this.$store.dispatch("appointment/getBookingList", payload);
    },
    currentDate() {
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      return formattedDate;
    },
    changeTabTitle(item) {
    this.searchQuery="";
      this.current_tab_name = item.value;
      let payload = {
        status: this.current_tab_name,
        search: this.searchQuery,
        bill_type: this.selected_bill_type,
      };
      if (this.dates_2[0] && this.dates_2[1]) {
        payload.from_date = this.dates_2[0];
        payload.to_date = this.dates_2[1];
      } else if (this.dates_4[0] && this.dates_4[1]) {
        payload.from_date = this.dates_4[0];
        payload.to_date = this.dates_4[1];
      }
      this.$store.dispatch(
        "appointment/set_current_tab_name",
        this.current_tab_name
      );
      this.$store.dispatch("appointment/getBookingList", payload);
    },
    getTableHeaders() {
      if (this.current_tab_name === "upcoming") {
        return [
          {
            text: "",
            align: "left",
            sortable: false,
            value: "checkbox",
          },
          {
            text: "APPOINMENT DATE",
            align: "left",
            sortable: false,
            value: "booking_date",
          },
          {
            text: "CLIENT NAME",
            align: "left",
            sortable: false,
            value: "client_full_name",
            // class: "primary_2 white--text",
          },
          {
            text: "MOBILE",
            align: "left",
            sortable: false,
            value: "client_mobile_number",
            // class: "primary_2 white--text",
          },

          {
            text: "SERVICES",
            align: "left",
            sortable: false,
            value: "services",
            // maxWidth:'2%'
          },
          {
            text: "EXECUTIVE",
            align: "left",
            sortable: false,
            value: "executive",
            // class: "primary_2 white--text",
          },
          {
            text: "ACTIONS",
            align: "left",
            sortable: false,
            value: "actions",
            // class: "primary_2 white--text",
          },
        ];
      } else if (this.current_tab_name === "completed") {
        return [
          {
            text: "BOOKING ID",
            align: "left",
            sortable: false,
            value: "booking_ref_id",
            // class: "primary_2 white--text",
          },
          {
            text: "INVOICE",
            align: "left",
            sortable: false,
            value: "invoice_no",
            // class: "primary_2 white--text",
          },
          {
            text: "DATE",
            align: "left",
            sortable: false,
            value: "booking_date",
            // class: "primary_2 white--text",
          },
          {
            text: "CLIENT NAME",
            align: "left",
            sortable: false,
            value: "client_full_name",
            // class: "primary_2 white--text",
          },
          {
            text: "SERVICES",
            align: "left",
            sortable: false,
            value: "services",
            // class: "primary_2 white--text",
          },
          {
            text: "TOTAL",
            align: "left",
            sortable: false,
            value: "total_amount",
            // class: "primary_2 white--text",
          },
          {
            text: "BILL TYPE",
            align: "left",
            sortable: false,
            value: "bill_type",
            // class: "primary_2 white--text",
          },
          {
            text: "CREATED BY",
            align: "left",
            sortable: false,
            value: "created_by",
            // class: "primary_2 white--text",
          },
          {
            text: "ACTIONS",
            align: "left",
            sortable: false,
            value: "actions",
            // class: "primary_2 white--text",
          },
        ];
      } else {
        return [
          {
            text: "BOOKED ON",
            align: "left",
            sortable: false,
            value: "booked_on",
            // class: "primary_2 white--text",
          },
          {
            text: "CLIENT NAME",
            align: "left",
            sortable: false,
            value: "client_full_name",
            // class: "primary_2 white--text",
          },
          {
            text: "MOBILE",
            align: "left",
            sortable: false,
            value: "client_mobile_number",
            // class: "primary_2 white--text",
          },
          {
            text: "BOOKING DATE",
            align: "left",
            sortable: false,
            value: "booking_date",
            // class: "primary_2 white--text",
          },
          {
            text: "REASON",
            align: "left",
            sortable: false,
            value: "cancelled_reason",
            // class: "primary_2 white--text",
          },
        ];
      }
    },
    // api calling for appointment list
    cancelAppointment() {
      this.$store.dispatch(
        "appointment/selected_appointments_data",
        this.selected_appointment
      );
      // first open the modal for reasons
      this.$store.dispatch("appointment/toggleCancellationModal", true);
    },
    completePayment() {
      this.$store.dispatch(
        "appointment/selected_appointments_data",
        this.selected_appointment
      );
      this.$store.dispatch("appointment/togglePaymentPage", true);
    },
    clearAppointmentData() {
      this.selected_appointment = [];
    },
    reschedule(item) {
      this.$store.dispatch("appointment/toggleUpdateAppointmentDrawer", true);
      this.$store.dispatch("appointment/get_appointment_detail_api", item.id);
    },
  },
};
</script>
<!-- .v-data-table >>> .v-data-table__wrapper table thead tr th:first-child {
  display: none;
} -->
<style scoped>
.v-data-table >>> .v-data-table__wrapper table thead tr th:first-child div {
  display: none;
}
.v-data-table >>> .v-data-table__wrapper table thead tr th {
  color: #333333 !important;
  font-size: 14px !important;
}

.v-data-table >>> .v-data-table__wrapper table td {
  color: #333333 !important;
  font-weight: 550 !important;
  font-size: 14px !important;
}

.v-data-table >>> .v-data-table__wrapper tbody tr {
  cursor: pointer !important;
}

.filter-day-type >>> .v-menu__content {
  top: 297px !important;
}

.gst_chip {
  background-color: #e6fbd9 !important;
  color: #242d35 !important;
}

.estimate_chip {
  background-color: #f1f1f1 !important;
  color: #242d35 !important;
}

.services_column {
  max-width: 300px !important;
  word-wrap: break-word !important;
  text-align: center;
}

.bill-type {
  /* width: 223px !important; */
  margin-left: 65px !important;
}

.filter-month-type,
.filter-day-type,
.bill-type {
  width: 225px !important;
}

.v-tab {
  border: solid 1px #dfe1e3 !important;
}

.active_tab {
  background-color: #f2797b !important;
  color: white !important;
}

.tab_name {
  font-weight: 500 !important;
  font-size: 20px !important;
}

.tab_number_data {
  font-weight: 700 !important;
  font-size: 42px !important;
}

p {
  text-transform: capitalize !important;
}

.cancelActionBtn {
  border-radius: 10px;
  padding: 6px 10px 6px 10px !important;
  width: 79px !important;
  font-size: 12px !important;
}

.completeActionBtn {
  border-radius: 10px;
  padding: 6px 10px 6px 10px !important;
  width: 150px !important;
  font-size: 12px !important;
}

.v-data-table >>> .v-data-table-header {
  background-color: #fbf1f2 !important;
}

.name {
  font-weight: 600;
  font-size: 14px;
  font-family: poppins;
}

.number {
  font-size: 12px;
  font-weight: 500;
  color: #697498;
  margin-top: -12px;
}

.data_status {
  width: 325.33px;
  height: 162px;
  border-radius: 16px;
  background-color: #ffeded !important;
}

.tab {
  width: 262px;
  height: 120px;
  border: solid 1px;
  border-color: #dfe1e3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border-radius: 0px, 1px, 0px, 1px; */
}

.active-tab {
  background-color: #f2797b;
  color: white !important;
}

.mob-active-tab {
  color: #f2797b;
}

.create_booking_btn {
  background-color: #f2797b !important;
  color: white;
}

.v-application .pt-4 {
  padding-top: 0px !important;
}

.card-text {
  color: #39434d;
  font-weight: 500;
}
</style>

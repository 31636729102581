import urls from "../../app-js/urls";
import axios from "../../app-js/axios";

export default {
  namespaced: true,
  state: {
    clientDetail: {},
    booking_list: [],
    total_booking_num: null,
    toggle_new_booking_drawer: null,
    
    toggle_select_service_drawer: {
      flag: false,
      opened_from: "",
    },
    toggle_select_service_drawer_mob: {
      flag: false,
      opened_from: "",
    },
    list_of_categories_services: [],
    selected_client_id: "",
    cart_list: [],
    toggle_payment_page: "",
    selected_appointments_data: [],
    current_tab_name: "upcoming",
    appointment_cancellation_data: false,
    update_appointment_drawer: "",
    update_appointment_mob: "",
    appointment_detail: null,
    isBookingLoading: false,
    details_drawer: {
      flag: false,
    },
    mobile_details_drawer: {
      flag: false,
    },
    client_drawer: false,
    toggle_mobile_appointment_drawer: false,
    toggle_payment_page_mob: false,
    client_drawer_toggle: "",
    isLoadingBookingMob: false,
    current_booking_id: "",
    current_enquiry_id : "",
  },
  getters: {
    get_booking_list: (state) => state.booking_list,
    get_total_booking_num: (state) => state.total_booking_num,
    get_toggle_booking_drawer: (state) => state.toggle_new_booking_drawer,
    get_toggle_select_service_drawer: (state) =>
      state.toggle_select_service_drawer,
    get_toggle_select_service_drawer_Mob: (state) =>
      state.toggle_select_service_drawer_mob,
    get_list_of_categories_services: (state) =>
      state.list_of_categories_services,
    get_selected_client_id: (state) => state.selected_client_id,
    get_cart_list: (state) => state.cart_list,
    get_toggle_payment_page: (state) => state.toggle_payment_page,
    get_toggle_payment_page_mob: (state) => state.toggle_payment_page_mob,
    get_selected_appointments_data: (state) => state.selected_appointments_data,
    get_current_tab_name: (state) => state.current_tab_name,
    getAppointmentCancellationData: (state) =>
      state.appointment_cancellation_data,
    getUpdateAppointmentDrawer: (state) => state.update_appointment_drawer,
    getUpdateAppointmentMobile: (state) => state.update_appointment_mob,
    getAppointmentDetail: (state) => state.appointment_detail,
    getDetailDrawer: (state) => state.details_drawer,
    getMobileDetailDrawer: (state) => state.mobile_details_drawer,
    getClientModalToggle: (state) => state.client_drawer,
    getAppointmentMobileDrawer: (state) =>
      state.toggle_mobile_appointment_drawer,
    getClientDrawerMobile: (state) => state.client_drawer_toggle,
    getIsLoadingBookingMobile: (state) => state.isLoadingBookingMob,
    getCurrentBookingId: (state) => state.current_booking_id,
    getCurrentEquiryId: (state) => state.current_enquiry_id,
  },
  mutations: {
    SET_CLIENT_DETAIL(state, clientDetail) {
      state.clientDetail = clientDetail;
    },
    SET_BOOKING_LIST_API(state, payload) {
      state.booking_list = payload;
    },
    SET_TOTAL_BOOKING_NUMBER(state, payload) {
      state.total_booking_num = payload;
    },
    TOGGLE_NEW_BOOKING_DRAWER(state, payload) {
      state.toggle_new_booking_drawer = payload;
    },
    TOGGLE_SELECT_SERVICE_DRAWER(state, payload) {
      state.toggle_select_service_drawer = payload;
    },
    SET_LIST_OF_CATEGORIES_SERVICES(state, payload) {
      state.list_of_categories_services = payload;
    },
    SET_SELECTED_CLIENT_DATA(state, payload) {
      state.selected_client_id = payload;
    },
    SET_CART_LIST(state, payload) {
      state.cart_list = payload;
    },
    TOGGLE_PAYMENT_PAGE(state, payload) {
      state.toggle_payment_page = payload;
    },
    TOGGLE_PAYMENT_MOB(state, payload) {
      state.toggle_payment_page_mob = payload;
    },
    SELECTED_APPOINTMENTS_DATA(state, payload) {
      state.selected_appointments_data = payload;
    },
    SET_CURRENT_TAB_NAME(state, payload) {
      state.current_tab_name = payload;
    },
    SET_CANCELLATION_MODAL(state, payload) {
      state.appointment_cancellation_data = payload;
    },
    SET_CLEAR_CART_LIST(state) {
      state.cart_list = [];
    },
    CLEAR_SELECTED_APPOINTMENT_DATA(state) {
      state.selected_appointments_data = [];
    },
    UPDATE_APPOINTMENT_DRAWER(state, payload) {
      state.update_appointment_drawer = payload;
    },
    UPDATE_APPOINTMENT_MOBILE(state, payload) {
      state.update_appointment_mob = payload;
    },
    GET_APPOINTMENT_DETAIL(state, payload) {
      state.appointment_detail = payload;
    },
    SET_VALUE_OF_CART_LIST(state, payload) {
      state.cart_list = payload;
    },
    SET_LOADING(state, payload) {
      state.isBookingLoading = payload;
    },
    TOGGLE_DETAILS_DRAWER(state, payload) {
      state.details_drawer = payload;
    },
    TOGGLE_MOB_DETAILS_DRAWER(state, payload) {
      state.mobile_details_drawer = payload;
    },
    TOGGLE_CLIENTS_DRAWER(state, payload) {
      state.client_drawer = payload;
    },
    TOGGLE_CREATE_BOOKING_MOBILE_DRAWER(state, payload) {
      state.toggle_mobile_appointment_drawer = payload;
    },
    TOGGLE_SELECT_SERVICE_DRAWER_MOB(state, payload) {
      state.toggle_select_service_drawer_mob = payload;
    },
    SET_NEW_CLIENT_DRAWER_MOB(state, payload) {
      state.client_drawer_toggle = payload;
    },
    SET_CURRENT_BOOKING_ID(state, payload) {
      state.current_booking_id = payload;
    },
    SET_CURRENT_ENQUIRY_ID(state, payload) {
      state.current_enquiry_id = payload;
    },
  },
  actions: {
    setClientDetail({ commit }, clientDetail) {
      commit('SET_CLIENT_DETAIL', clientDetail);
    },
    //call api to get parlour details
    getBookingList({ state, commit }, payload) {
      state.isLoadingBookingMob = true;
      commit("SET_LOADING", true);
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        status: payload.status,
        search: payload.search,
        bill_type: payload.bill_type,
        next_days: payload.next_days,
        from_date: payload.from_date,
        to_date: payload.to_date,
      };
      const successHandler = (response) => {
        state.isLoadingBookingMob = false;
        commit("SET_LOADING", false);
        let booking_list = response.data.booking_list;
        let upcoming = response.data.upcoming;
        let completed = response.data.completed;
        let cancelled = response.data.cancelled;
        let total_booking_num = {
          upcoming,
          completed,
          cancelled,
        };
        commit("SET_BOOKING_LIST_API", booking_list);
        commit("SET_TOTAL_BOOKING_NUMBER", total_booking_num);
      };
      const failureHandler = () => {
        state.isLoadingBookingMob = false;
        commit("SET_LOADING", false);
      };
      return axios("get", urls.GET_BOOKING_LIST, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getBookingDetails({ commit }, payload) {
      let params = {
        id: payload,
      };
      const successHandler = (response) => {
        let booking_detail = response.data.booking_detail;
        commit("SET_BOOKING_DETAILS", booking_detail);
        commit("SET_CURRENT_BOOKING_ID", payload);
      };
      const failureHandler = () => {};
      return axios("get", urls.GET_BOOKING_DETAILS, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    set_current_tab_name({ commit }, payload) {
      commit("SET_CURRENT_TAB_NAME", payload);
    },
    toggleCreateBookingDrawer({ commit }, payload) {
      commit("TOGGLE_NEW_BOOKING_DRAWER", payload);
    },
    toogleSelectServiceDrawer({ commit }, payload) {
      commit("TOGGLE_SELECT_SERVICE_DRAWER", payload);
    },
    getListOfCategoriesAndServices({ commit }, payload) {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        client_id: payload.client_id,
        search: payload.search,
        appointment: payload.booking_id,
      };
      const successHandler = (response) => {
        let list_of_categories_services = response.data.response;
        commit("SET_LIST_OF_CATEGORIES_SERVICES", list_of_categories_services);
        commit("SET_CURRENT_BOOKING_ID", payload.booking_id);
      };
      const failureHandler = () => {};
      return axios("get", urls.CATEGORY_AND_SERVICE_LIST, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    setSelectedClientData({ commit }, payload) {
      commit("SET_SELECTED_CLIENT_DATA", payload);
    },
    get_service_cart_list({ commit }, payload) {
      let params = {
        client: payload.client_id,
        appointment: payload.appointment,
      };
      const successHandler = (response) => {
        let cart_list = response.data.cart_list;
        commit("SET_CART_LIST", cart_list);
      };
      const failureHandler = () => {};
      return axios("get", urls.GET_SERVICE_CART_LIST, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    togglePaymentPage({ commit }, payload) {
      commit("TOGGLE_PAYMENT_PAGE", payload);
    },
    togglePaymentPageMob({ commit }, payload) {
      commit("TOGGLE_PAYMENT_MOB", payload);
    },
    selected_appointments_data({ commit }, payload) {
      commit("SELECTED_APPOINTMENTS_DATA", payload);
    },
    toggleCancellationModal({ commit }, payload) {
      commit("SET_CANCELLATION_MODAL", payload);
    },
    clear_cart_list({ commit }) {
      commit("SET_CLEAR_CART_LIST");
    },
    clear_selected_appointment_data({ commit }) {
      commit("CLEAR_SELECTED_APPOINTMENT_DATA");
    },
    toggleUpdateAppointmentDrawer({ commit }, payload) {
      commit("UPDATE_APPOINTMENT_DRAWER", payload);
    },
    toggleUpdateAppointmentMob({ commit }, payload) {
      commit("UPDATE_APPOINTMENT_MOBILE", payload);
    },
    get_appointment_detail_api({ commit }, payload) {
      let params = {
        id: payload,
      };
      const successHandler = (response) => {
        commit("GET_APPOINTMENT_DETAIL", response.data.booking_detail);
      };
      const failureHandler = () => {};
      return axios("get", urls.GET_BOOKING_DETAILS, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    setValueOfCartList({ commit }, payload) {
      commit("SET_VALUE_OF_CART_LIST", payload);
    },
    toggleDetailsDrawer({ commit }, payload) {
      commit("TOGGLE_DETAILS_DRAWER", payload);
    },
    toggleMobDetailsDrawer({ commit }, payload) {
      commit("TOGGLE_MOB_DETAILS_DRAWER", payload);
    },
    toggleClientDrawer({ commit }, payload) {
      commit("TOGGLE_CLIENTS_DRAWER", payload);
    },
    toggleCreateBookingMobileDrawer({ commit }, payload) {
      commit("TOGGLE_CREATE_BOOKING_MOBILE_DRAWER", payload);
    },
    toogleSelectServiceDrawerMob({ commit }, payload) {
      commit("TOGGLE_SELECT_SERVICE_DRAWER_MOB", payload);
    },
    setNewClientDrawerMob({ commit }, payload) {
      commit("SET_NEW_CLIENT_DRAWER_MOB", payload);
    },
    setCurrentEnquiry_id({ commit }, payload){
      commit("SET_CURRENT_ENQUIRY_ID", payload);
    }
  },
};

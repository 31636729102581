export default {
  namespaced: true,
  state: {
    query_id: null,
    query_title: "",
    custom_query_object: {
      id: "",
      query_title: "",
    },
    query_list_api_flag: false,
    enquiry_list_api_flag: false,
    update_enquiry_list_api_flag: false,
    move_to_irrelevant_flag: false,
    // reason_id: null,
    // reason_text: "",
    custom_reason_data:null
  },
  getters: {
    getCustomQueryId: (state) => state.query_id,
    getCustomQueryText: (state) => state.query_title,
    getCustomQueryObject: (state) => state.custom_query_object,
    getQueryListApiFlag: (state) => state.query_list_api_flag,
    getEnquiryListApiFlag: (state) => state.enquiry_list_api_flag,
    getUpdateEnquiryListApiFlag: (state) => state.update_enquiry_list_api_flag,
    getReasonId: (state) => state.reason_id,
    getCustomReasonText: (state) => state.reason_text,
    getCustomReasonData: (state) => state.custom_reason_data,
    getMoveToIrrelevantFlag: (state) => state.move_to_irrelevant_flag,
  },
  mutations: {
    SET_CUSTOM_QUERY_INFO(state, payload) {
      state.query_id = payload.query_id;
      state.query_title = payload.query_text;
      state.query_list_api_flag = payload.flag;
      state.query_list_api_flag = payload.flag;
      let object = {
        id: payload.query_id,
        query_title: payload.query_text,
      };
      state.custom_query_object = object;
    },
    SET_CUSTOM_REASON_INFO(state, payload) {
      // state.reason_id = payload.id;
      // state.reason_text = payload.title;
      state.custom_reason_data = payload;
    },
    TRIGGER_ENQUIRY_LIST_API(state) {
      state.enquiry_list_api_flag = !state.enquiry_list_api_flag;
    },
    TRIGGER_UPDATE_ENQUIRY_LIST_API(state) {
      state.update_enquiry_list_api_flag = !state.update_enquiry_list_api_flag;
    },
    MOVE_TO_IRRELEVANT_FLAG(state) {
      state.move_to_irrelevant_flag = !state.move_to_irrelevant_flag;
    },
  },
  actions: {
    setCustomQueryInfo({ commit }, payload) {
      commit("SET_CUSTOM_QUERY_INFO", payload);
    },
    setEnquiryListApi({ commit }) {
      commit("TRIGGER_ENQUIRY_LIST_API");
    },
    setUpdateEnquiryListApi({ commit }) {
      commit("TRIGGER_UPDATE_ENQUIRY_LIST_API");
    },
    setCustomReason({ commit }, payload) {
      commit("SET_CUSTOM_REASON_INFO", payload);
    },
    setMoveToIrrelevant({ commit }) {
      commit("MOVE_TO_IRRELEVANT_FLAG");
    },
  },
};

<template>
    <v-row justify="center">
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          color="#F2797B"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
    </v-row>
</template>
<script>
export default {
    name: "DatePicker"
}
</script>
<style scoped></style>
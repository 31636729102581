<template>
  <v-container class="px-5">
    <v-row class="ma-0">
      <v-col class="d-flex align-center pl-0">
        <v-icon large color="black" class="mr-2" @click="goBackToClientPage"
          >mdi-arrow-left</v-icon
        >
        <p class="page_title">{{ $route.meta.title }}</p>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="user_info_div pl-0">
        <div>
          <v-avatar
            color="#F2797B"
            size="60"
            class="logged_in_user_avatar white--text ma-2"
            >{{ initials }}</v-avatar
          >
        </div>
        <div>
          <p class="mb-0 member_name">{{ full_name }}</p>
          <p class="mb-0 member_role">{{ role }}</p>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-col class="d-flex justify-end">
                <v-icon color="black" large>mdi-dots-vertical-circle-outline</v-icon>
            </v-col> -->
    </v-row>
    <!-- tabs -->
    <v-tabs v-model="tabs">
      <v-tab class="ml-n1">Details</v-tab>
      <!-- <v-tab class="ml-8">Activity</v-tab> -->
      <v-tabs-items v-model="tabs">
        <!-- 1st tab -->
        <v-tab-item class="mt-3">
          <v-card width="100%">
            <v-card-title class="d-flex align-center">
              <p class="header">Personal Information</p>
              <v-spacer></v-spacer>
              <v-btn
                class="edit_btn text-capitalize"
                outlined
                color="#79747E"
                @click="openClientEditModal"
                ><v-icon>mdi-file-edit</v-icon>Edit</v-btn
              >
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="mb-3" v-if="client_details">
                <p class="title">Name</p>
                <p class="sub_title">{{ client_details?.full_name }}</p>
              </div>
              <div class="mb-3" v-if="client_details">
                <p class="title">Gender</p>
                <p class="sub_title">{{ client_details?.gender }}</p>
              </div>
              <div class="mb-3" v-if="client_details && client_details.mobile">
                <p class="title">Phone</p>
                <p class="sub_title">(+91){{ client_details.mobile }}</p>
              </div>
              <div class="mb-3" v-if="client_details && client_details.email">
                <p class="title">Email</p>
                <p class="sub_title">{{ client_details?.email }}</p>
              </div>
              <div
                class="mb-3"
                v-if="client_details && client_details.birthday_date"
              >
                <p class="title">Birthday</p>
                <p class="sub_title">{{ client_details?.birthday_date }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- 2nd tab -->
        <!-- skeleton loaderor line showing there is no data -->
        <!-- <div v-if="upcoming_data === undefined && past_data === undefined" class="d-flex align-center justify-center mt-15">
                    <p >There is no data avaialable</p>
                </div> -->
        <!--  -->
        <!-- <v-tab-item>
                
                    <div v-if="upcoming_data">
                        <p class="mt-3 mb-5 activity_card_status">{{ upcoming_data.status }}</p>
                        <v-card width="100%" v-for="(item, index) in upcoming_data.booking_list" :key="index" class="mb-5">
                            <v-card-title class="d-flex">
                                <p class="activity_card_bold_text">Appointment</p>
                                <v-spacer></v-spacer>
                                <v-chip
                                    :class="{ 'completed_status': item.status === 'completed', 'booked_status': item.status === 'booked', 'cancelled_status': item.status === 'cancelled' }">
                                    {{ item.status }}
                                </v-chip>
                            </v-card-title>
                            <v-card-text>
                                <div class="d-flex">
                                    <p>On<span class="activity_card_black_text"> {{ item.appointment_date }}</span></p>
                                    <p class="mx-2">At <span class="activity_card_black_text">{{ item.appointment_start_time_formate }}</span></p>
                                </div>
                                <p>Executive <span class="activity_card_black_text">{{ item.executive }}</span></p>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="d-flex" v-for="(service_item, index) in upcoming_data.booking_list.services"
                                :key="index">
                                <p>{{ service_item.service_name }}</p>
                                <v-spacer></v-spacer>
                                <p>{{ service_item.price }}</p>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="d-flex">
                                <p class="activity_card_bold_text">Total</p>
                                <v-spacer></v-spacer>
                                <p class="activity_card_bold_text">{{ item.payable_amount }}</p>
                            </v-card-text>
                        </v-card>
                    </div>
               
                    <div v-if="past_data">
                        <p class="mt-3 mb-1 activity_card_status">{{ past_data.status }}</p>
                        <v-card width="100%" v-for="(item, index) in past_data.booking_list" :key="index" class="mb-5">
                            <v-card-title class="d-flex">
                                <p class="activity_card_bold_text">Appointment</p>
                                <v-spacer></v-spacer>
                                <v-chip
                                    :class="{ 'completed_status': item.status === 'completed', 'booked_status': item.status === 'booked', 'cancelled_status': item.status === 'cancelled' }">
                                    <span>{{ item.status
                                    }}</span>
                                </v-chip>
                            </v-card-title>
                            <v-card-text>
                                <div class="d-flex">
                                    <p>On <span class="activity_card_black_text">{{ item.appointment_date }}</span></p>
                                    <p class="mx-2">At <span class="activity_card_black_text">{{ item.appointment_start_time_formate }}</span></p>
                                </div>
                                <p>Executive <span class="activity_card_black_text">{{ item.executive }}</span></p>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="d-flex" v-for="(service_item, index) in upcoming_data.booking_list.services"
                                :key="index">
                                <p>{{ service_item.service_name }}</p>
                                <v-spacer></v-spacer>
                                <p>{{ service_item.price }}</p>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="d-flex">
                                <p class="activity_card_bold_text">Total</p>
                                <v-spacer></v-spacer>
                                <p class="activity_card_bold_text">{{ item.payable_amount }}</p>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-tab-item> -->
      </v-tabs-items>
    </v-tabs>
    <CreateClientModal></CreateClientModal>
    <CreateClientModalMobile></CreateClientModalMobile>
  </v-container>
</template>
<script>
import CreateClientModal from "@/components/clients/CreateClientModal.vue";
import CreateClientModalMobile from "@/components/clients/CreateClientModalMobile.vue";
import { mapGetters } from "vuex";
export default {
  name: "ClientDetail",
  data() {
    return {
      client_details: null,
      initials: "",
      full_name: "",
      role: "",
      tabs: "",
      activity_data: [],
      upcoming_data: null,
      past_data: null,
    };
  },
  components: {
    CreateClientModal,
    CreateClientModalMobile,
  },
  computed: {
    ...mapGetters({
      client_detail: "client/get_client_details",
    }),
  },
  watch: {
    client_detail(val) {
      this.client_details = val;
      this.full_name = val.full_name;
      this.initials = this.$initials(this.full_name);
    },
  },
  mounted() {
    this.client_id = this.$route.params.id;
    this.$store.dispatch("client/callGetClientDetails", this.client_id);
    this.client_activity();
  },
  methods: {
    openClientEditModal() {
      if (this.$isDesktop) {
        let data = {
          toggle_flag: "true",
          clientAddOrEditFlag: "edit",
        };
        this.$store.dispatch("dialog/setNewClientDrawer", data);
      } else {
        let data = {
          toggle_flag: true,
          clientAddOrEditFlag: "edit",
        };
        this.$store.dispatch("dialog/setNewClientDrawerMobile", data);
      }
    },
    goBackToClientPage() {
      this.$router.push({
        name: "client",
      });
    },
    client_activity() {
      this.verify_btn_loader = true;
      const params = {
        client_id: this.client_id,
      };
      const successHandler = (response) => {
        this.upcoming_data = response.data.response[0];
        this.past_data = response.data.response[1];
      };
      const failureHandler = () => {};
      const finallyHandler = () => {
        this.verify_btn_loader = false;
      };
      return this.$axios("get", this.$apiUrl.GET_CLIENT_ACTIVITY_HISTORY, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        onFinally: finallyHandler,
        isTokenRequired: false,
      });
    },
  },
};
</script>
<style scoped>
.completed_status {
  background-color: #e6fbd9 !important;
  color: #0f5b1d !important;
  font-weight: 500;
  font-size: 12px;
}

.cancelled_status {
  background-color: #ffe8d7 !important;
  color: #931222 !important;
  font-weight: 500;
  font-size: 12px;
}

.booked_status {
  background-color: #d8d8fe !important;
  color: #181894 !important;
  font-weight: 500;
  font-size: 12px;
}

.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #49454f;
  text-transform: capitalize;
}

.v-tab.v-tab {
  text-transform: capitalize !important;
}

.v-item-group {
  background-color: #fafafa !important;
}

.v-tabs >>> .v-slide-group__content {
  background-color: #fafafa !important;
}
p {
  margin-bottom: 0px;
}

.id_proof {
  max-width: 120px !important;
  max-height: 120px !important;
}

.page_title {
  color: #404c57;
  font-weight: 700;
  font-size: 30px;
}

.user_info_div {
  display: flex;
  align-content: center !important;
  align-items: center;
}

.edit_btn {
  border-radius: 15px;
}

.member_name {
  color: #000000;
  font-weight: 500;
  font-size: 28px !important;
}

.member_role {
  color: #9397a7;
  font-weight: 400;
  font-size: 18px !important;
}

.header {
  color: #39434d;
  font-weight: 600;
  font-size: 20px !important;
}

.title {
  color: #6e7b89;
  font-weight: 400;
  font-size: 14px !important;
}

.sub_title {
  color: #39434d;
  font-weight: 500;
  font-size: 16px !important;
}

.activity_card_bold_text {
  color: #39434d;
  font-weight: 600;
  font-size: 16px !important;
}

.activity_card_black_text {
  color: #39434d;
  font-weight: 600;
  font-size: 12px !important;
}

.activity_card_status {
  color: #9397a7;
  font-weight: 500;
  font-size: 16px !important;
}

@media all and (max-width: 601px) {
  .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .member_name {
    color: #000000;
    font-weight: 500;
    font-size: 21px !important;
  }
}
</style>

<template>
    <!-- Desktop View -->
    <v-navigation-drawer app permanent height="100vh" width="235" v-model="drawer" v-if="$isDesktop">
        <template v-slot:prepend>
            <v-list-item class="mb-6">
                <v-img src="../../assets/brush_logo.svg" class="logo_img mt-8"></v-img>
            </v-list-item>
        </template>
        <v-list dense>
            <v-list-item v-for="item in items" :key="item.title" :to="item.url"
                :class="{ 'v-list-item--active': isActiveTab(item.url) }">
                <v-list-item-icon>
                    <Dasboard_icon v-if="item.icon === 'dashboard'"></Dasboard_icon>
                    <Client_icon v-if="item.icon === 'clients'"></Client_icon>
                    <Enquiry_icon v-if="item.icon === 'enquiries'"></Enquiry_icon>
                    <Services_icon v-if="item.icon === 'services'"></Services_icon>
                    <Team_icon v-if="item.icon === 'team-management'"></Team_icon>
                    <Booking_icon v-if="item.icon === 'appointments'"></Booking_icon>
                    <Report_icon v-if="item.icon === 'reports'"></Report_icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <div class="user_info_div" @click="reDirectToProfile()">
            <div class="ml-3">
                <v-avatar color="#F2797B" size="36" class="logged_in_user_avatar white--text ma-2" >
                    <!-- {{ initials_of_name}} -->
                <img
                :src= profile_img
              ></v-avatar>
            </div>
            <div class="ma-auto">
                <p class="mb-0 logged_in_user_name">{{ current_user_name }}</p>
                <p class="mb-0 logged_in_user_number">{{ user_mobile_num }}</p>
            </div>
        </div>
    </v-navigation-drawer>
    <!-- Mobile View -->
    <v-bottom-navigation app color="primary" v-else fixed>
        <v-btn @click="navigateToUrl('Dashboard')">
            <span>Home</span>
            <Dasboard_icon></Dasboard_icon>
        </v-btn>
        <v-btn @click="navigateToUrl('Clients')">
            <span>Clients</span>
            <Client_icon></Client_icon>
        </v-btn>
        <v-btn @click="navigateToUrl('Enquiries')">
            <span>Enquiries</span>
            <Enquiry_icon></Enquiry_icon>
        </v-btn>
        <v-btn @click="navigateToUrl('Bookings')">
            <span>Bookings</span>
            <Booking_icon></Booking_icon>
        </v-btn>
        <v-btn @click="navigateToUrl('More')">
            <span>More</span>
            <More_icon></More_icon>
        </v-btn>
    </v-bottom-navigation>
</template>
<script>
import Dasboard_icon from "../../assets/Dashboard_icon.vue"
import Client_icon from "../../assets/Client_icon.vue"
import Enquiry_icon from "../../assets/Enquiry_icon.vue"
import Services_icon from "../../assets/Services_icon.vue"
import Team_icon from "../../assets/Team_icon.vue"
import Booking_icon from "../../assets/Booking_icon.vue"
import More_icon from "../../assets/More_icon.vue"
import Report_icon from "@/assets/Report_icon.vue"
import { mapGetters } from 'vuex'
export default {
    name: "Drawer",
    data() {
        return {
            items: [
                { title: 'Dashboard', icon: 'dashboard', url: '/dashboard' },
                { title: 'Appointments', icon: 'appointments', url: '/appointments' },
                { title: 'Clients', icon: 'clients', url: '/client' },
                { title: 'Enquiries', icon: 'enquiries', url: '/enquiries' },
                { title: 'Team Management', icon: 'team-management', url: '/team-management' },
                { title: 'Services', icon: 'services', url: '/services' },
                { title: 'Reports', icon: 'reports', url: '/reports' },
            ],
            drawer: false,
            current_user_name: "",
            user_mobile_num: "",
            initials_of_name: "",
            profile_img:"",
        }
    },
    components: {
        Dasboard_icon,
        Client_icon,
        Enquiry_icon,
        Services_icon,
        Team_icon,
        Booking_icon,
        More_icon,
        Report_icon
    },
    computed: {
        ...mapGetters({ parlour_details: 'profile/get_parlour_details' }),
        ...mapGetters({ user_details: 'profile/get_current_user_details' })
    },
    mounted() {
        this.$store.dispatch("profile/callParlourDetailsApi")
        this.current_user_name = this.user_details.name
        this.user_mobile_num = this.user_details.number
        this.profile_img = this.user_details.logo
        this.initials_of_name = this.$initials(this.current_user_name)
    },
    watch: {
        user_details(newVal) {
            this.current_user_name = newVal.name
            this.profile_img = newVal.logo
            this.initials_of_name = this.$initials(this.current_user_name)
            this.user_mobile_num = newVal.number
        }
    },
    methods: {
        isActiveTab(url) {
            const currentRoute = this.$route.name;
            if (currentRoute === 'member-details' && url === '/team-management') {
                return true
            }
            else if (currentRoute === 'client-details' && url === '/client') {
                return true
            }
        },
        navigateToUrl(tab) {
            if (tab == 'Dashboard') {
                this.$router.push('/dashboard').catch((error) => {
                    console.log(error)
                })
            }
            else if (tab == 'Bookings') {
                this.$router.push('/appointments').catch((error) => {
                    console.log(error)
                })
            }
            else if (tab == 'Clients') {
                this.$router.push('/client').catch((error) => {
                    console.log(error)
                })
            }
            else if (tab == 'Enquiries') {
                this.$router.push('/enquiries').catch((error) => {
                    console.log(error)
                })
            }
            else if (tab == 'Services') {
                this.$router.push('/services').catch((error) => {
                    console.log(error)
                })
            }
            else if (tab == 'More') {
                this.$router.push('/more-tabs').catch((error) => {
                    console.log(error)
                })
            }
        },
        reDirectToProfile() {
            this.$router.push({
                name: "profile"
            }).catch((error) => {
                console.log(error)
            })
        }
    }
}
</script>
<style scoped>
.user_info_div {
    position: absolute;
    bottom: 0;
    cursor: pointer !important;
    display: flex;
    margin-bottom: 3px;
}

.logged_in_user_name {
    font-weight: 500 !important;
    font-size: 16px;
    text-decoration: underline !important;
}

.logged_in_user_number {
    font-weight: 400;
    font-size: 12px;
}

.logged_in_user_avatar {}

.logo_img {
    max-width: 185.96px !important;
    height: 58px !important;
}

/* .v-list .v-list-item--active {
    background-color: #FCE4E5 !important;
    color: #FCE4E5 !important;
} */

.v-list .v-list-item--active {
    background-color: #FBF1F2 !important;
    color: #FCE4E5 !important;
}

.v-list-item .v-list-item__title {
    color: black !important
}

.v-navigation-drawer {
    position: fixed;
}
</style>
<template>
    <v-row justify="center">
        <!-- Confirmation Modal -->
        <v-dialog v-model="drawer" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Edit Phone Number</span>
                    <v-spacer></v-spacer>
                    <v-icon large @click="closeModal">mdi-close</v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-form ref="user_profile">
                            <v-row dense class="d-flex flex-column align-center justify-center">
                                <div class="d-flex flex-column align-center justify-center">
                                    <p class="confirmation_question mb-0 mt-3">Are you sure you want to change your
                                        registered</p>
                                    <p class="confirmation_question">phone number?</p>
                                </div>
                                <v-col cols="12" sm="6" md="12">
                                    <label>Mobile Number</label>
                                    <v-text-field readonly dense filled
                                    rounded disabled placeholder="Please enter mobile number" 
                                        class="md-mr-0 mr-2 phone_num_field" v-model="mobile" prepend-inner-icon>
                                        <template #prepend-inner>
                                            <v-img :src="selected_flag" class="flag_img"></v-img> &nbsp;
                                            {{ selected_phone_code }} &nbsp;
                                            <!-- <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item v-for="(item, index) in country_list" :key="index"
                                                        @click="selectPhoneCode(item)">
                                                        <v-list-item-title>{{ item.phone_code }} {{ item.name
                                                        }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu> -->
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal">
                        Cancel
                    </v-btn>
                    <v-btn class="text-capitalize white--text" color="#F2797B" @click="sendOtp">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Confirmation Modal -->

        <!-- verify-otp-modal -->
        <v-dialog v-model="otp_dialog" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Edit Phone Number</span>
                    <v-spacer></v-spacer>
                    <v-icon large @click="closeVerifyOtpModal">mdi-close</v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-form ref="num_edit_dialog">
                            <v-row dense>
                                <v-col cols="12" sm="6" md="12">
                                    <div class="d-flex flex-column align-center justify-center">
                                        <p class="confirmation_question mb-0 mt-3">Please enter the OTP sent to your
                                            registered</p>
                                        <p class="confirmation_question">phone number</p>
                                    </div>

                                    <label>Enter 6 Digit Otp</label>
                                    <v-otp-input length="6" size="2" class="custom-otp-input" v-model="otp"
                                        :rules="[$rules.otp]"></v-otp-input>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeVerifyOtpModal">
                        Cancel
                    </v-btn>
                    <v-btn class="text-capitalize white--text" color="#F2797B" @click="verifyOtp">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- verify-otp-modal -->
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "UserNumberEditDialog",
    data() {
        return {
            first_name: "",
            last_name: "",
            mobile: null,
            country_id: "103",
            address: "Raipur,Pandri",
            selected_phone_code: "+91",
            selected_flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
            otp: "",
            otp_dialog: false,
        }
    },
    computed: {
        ...mapGetters({
            drawer: "dialog/getEditUserNumFlag",
            user_details: "profile/get_current_user_details",
        })
    },
    watch: {
        user_details() {
            this.first_name = this.user_details.first_name
            this.last_name = this.user_details.last_name
            this.mobile = this.user_details.number
        },
        otp() {
            if (this.otp.length === 6) {
                this.verifyOtp();
            }
        },
    },
    mounted() {
        this.$store.dispatch("profile/callParlourDetailsApi")
        this.getListOfPhoneCodes()
    },
    methods: {
        closeModal() {
            this.$store.dispatch("dialog/setUserNumEdit", false)
        },
        // selectPhoneCode
        selectPhoneCode(item) {
            this.selected_phone_code = item.phone_code
            this.selected_flag = item.flag
            this.country_id = item.id
        },
        closeVerifyOtpModal() {
            this.otp_dialog = false
            this.otp = ""
        },
        getListOfPhoneCodes() {
            const successHandler = (response) => {
                this.country_list = response.data.phone_code_list
            }
            const failureHandler = (error) => {
                if (error) {
                    console.log("error is ", error)
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("get", this.$apiUrl.PHONE_CODE, {
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: false,
            });
        },
        sendOtp() {
            // call api to send otp 
            let data = new FormData()
            data.append("mobile", this.mobile)
            data.append("phone_code", this.selected_phone_code)
            const successHandler = (response) => {
                console.log("response of editStaffDetails api is ", response)
                this.$store.dispatch("dialog/setUserNumEdit", false)
                this.otp_dialog = true
            }
            const failureHandler = (error) => {
                if (error) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("post", this.$apiUrl.EDIT_MOBILE_SEND_OTP, {
                data,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
        verifyOtp() {
            if (this.$refs.num_edit_dialog.validate()) {
                let user_id = localStorage.getItem("user_id")
                let params = {
                    id: user_id
                };
                let data = new FormData()
                data.append("phone_code", this.selected_phone_code)
                data.append("mobile", this.mobile)
                data.append("otp", this.otp)
                const successHandler = (response) => {
                    console.log("response of editStaffDetails api is ", response)
                    this.$store.dispatch("dialog/setUserNumEdit", false)
                    this.otp = ""
                    this.otp_dialog = false
                    this.$store.dispatch("dialog/setNewNumDialog", true)
                }
                const failureHandler = (error) => {
                    if (error) {
                        this.showSnackBar(error.data.message, "#B3261E", 1500);
                    }
                }
                return this.$axios("post", this.$apiUrl.EDIT_MOBILE_VERIFY_OTP, {
                    data,
                    params,
                    onSuccess: successHandler,
                    onFailure: failureHandler,
                    isTokenRequired: true,
                });
            }
        }
    }
}
// }
</script>
<style scoped>
.phone_num_field>>>.v-input__prepend-inner {
    display: flex;
    align-items: center;
}
label {
    color: black !important
}

.flag_img {
    width: 20px;
    height: 20px;
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}

.confirmation_question {
    weight: 500;
    font-size: 16px;
    color: #39434D;
}
</style>
const state = {
  text: "",
  color: "",
  flag: false,
  timeout: "",
};
const getters = {
  getSnackBar: (state) => state,
};

const mutations = {
  SHOW_MESSAGE(state, payload) {
    state.text = payload.text;
    state.color = payload.color;
    state.flag = payload.flag;
    state.timeout = payload.timeout;
  },
};
const actions = {
  showSnack({ commit }, payload) {
    commit("SHOW_MESSAGE", payload);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Address</span>
          <v-spacer></v-spacer>
          <v-icon color="black" large @click="closeModal">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <!-- map section -->
              <v-col md="12">
                <v-row>
                  <!-- <v-icon>mdi-magnify</v-icon> -->
                  <div class="d-flex">
                    <GmapAutocomplete :key="autocompleteInputValue" class="autocomplete_field my-2 px-2 py-3 mr-2"
                      @place_changed='setPlace' />
                    <v-icon @click="getCurrentAddress">mdi-crosshairs-gps</v-icon>
                  </div>
                </v-row>
                <v-row>
                  <GmapMap :center="center" :zoom="zoom" style="width: 100%; height: 367px">
                    <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position"
                      @click="center = m.position" />
                  </GmapMap>
                </v-row>
                <v-row>
                  <!-- address text-fields -->
                  <v-textarea class="my-2" outlined label="Address" v-model="address" rows="1" row-height="15"
                    full-width></v-textarea>
                </v-row>
              </v-col>
              <!-- map section -->
              <!-- <v-spacer></v-spacer> -->
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-btn outlined color="white" text @click="closeAndSave" class="save_btn ml-2">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// import { GmapMap, GmapMarker, GmapAutocomplete } from 'vue2-google-maps';

import { mapGetters } from "vuex";
export default {
  name: "LocationModal",
  data() {
    return {
      center: { lat: 20.5937, lng: 78.9629 }, // Centered in India
      zoom: 5,
      currentPlace: null,
      markers: [],
      places: [],
      searchQuery: "",
      address: "",
      autocompleteInputValue: 0,
      current_latitude: "",
      current_longitude: "",
      googlePlacesAPIKey: "AIzaSyAj-8YOIObv9854-VOk1uXfuzf_LTJ7Yuc"
    };
  },
  computed: {
    ...mapGetters({ dialog: "dialog/getLocationModal" }),
  },
  mounted() {
    this.address = ""
  },

  methods: {
    getCurrentAddress() {
      navigator.geolocation.getCurrentPosition(position => {
        let marker = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.current_latitude = marker.lat,
          this.current_longitude = marker.lng
        this.markers.push({ position: marker });
        this.center = marker;
        this.getStreetAddressFromLatLog(marker)
      });
    },
    getStreetAddressFromLatLog(marker) {
      this.autocompleteInputValue += 1
      const xhr = new XMLHttpRequest()
      const successHandler = (res) => {
        let Place = res.results[0]
        // Save address data
        this.setPlace(Place, "currentLocation")
      }
      xhr.onreadystatechange = function () {
        // if (xhr.readyState === 4) {
        // }
        if (xhr.readyState === 4 && xhr.status === 200) {
          successHandler(xhr.response)
        }
      }

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${marker.lat},${marker.lng}&key=${this.googlePlacesAPIKey}`
      xhr.responseType = 'json'
      xhr.open('GET', url)
      xhr.send()
    },

    closeModal() {
      this.$store.dispatch("dialog/setLocation", false);
      this.currentPlace = null,
        this.address = null,
        this.autocompleteInputValue += 1
    },
    closeAndSave() {
      this.$store.dispatch("dialog/setLocation", false);
      let address_data = {
        address: this.address,
        latitude: this.current_latitude,
        longitude: this.current_longitude
      }
      this.$store.dispatch("parlour_details/setMapAddress", address_data)
      this.currentPlace = null,
        this.address = null,
        this.autocompleteInputValue += 1
    },
    setPlace(place, val) {
      this.address = place.formatted_address
      this.currentPlace = place
      if (val !== "currentLocation") {
        this.addMarker()
      }
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.current_latitude = marker.lat,
          this.current_longitude = marker.lng
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;

      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>
<style scoped>
.map {
  height: 230px;
}

.v-text-field__details {
  display: none !important;
}

.pin_add_btn {
  color: black !important;
  font-weight: 500;
}

.autocomplete_field {
  border: solid 2px black;
}

.save_btn {
  background-color: #F2797B !important;
  margin-top: -37px !important;
  min-width: 450px !important;
}

@media all and (min-width: 900px) {
  .autocomplete_field {
    border: solid 2px black;
    width: 405px !important
  }
}

@media all and (max-width: 600px) {
  .save_btn {
    background-color: #F2797B !important;
    margin-top: -37px !important;
    min-width: 323px !important;
  }
}
</style>
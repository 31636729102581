import urls from "../../app-js/urls";
import axios from "../../app-js/axios";
export default {
  namespaced: true,
  state: {
    update_profile_flag: false,
    // the above flag i am using to call get parlour detail api
    current_user_details: {
      name: "",
      number: "",
      role: "",
      user_id: "",
      first_name: "",
      last_name: "",
    },
    parlour_details: {
      parlour_id: "",
      is_staff_added: "",
      is_service_added: "",
      is_parlour_registered: "",
    },
    parlour_basic_details: null,
  },
  getters: {
    get_update_profile_flag: (state) => state.update_profile_flag,
    get_current_user_details: (state) => state.current_user_details,
    get_parlour_details: (state) => state.parlour_details,
    get_parlour_basic_details: (state) => state.parlour_basic_details,
  },
  mutations: {
    SET_PROFILE_FLAG(state) {
      state.update_profile_flag = !state.update_profile_flag;
    },
    SET_USER_DETAILS(state, payload) {
      state.current_user_details = payload;
    },
    SET_PARLOUR_DETAILS(state, payload) {
      state.parlour_details = payload;
    },
    SET_PARLOUR_DETAILS_API(state, payload) {
      state.current_user_details = payload;
    },
    SET_PARLOUR_BASIC_DETAILS(state, payload) {
      state.parlour_basic_details = payload;
    },
    SET_PARLOUR_REGISETRATION_DETAILS(state, payload) {
      state.parlour_details = payload;
    },
  },
  actions: {
    setUpdateProfileFlag({ commit }) {
      commit("SET_PROFILE_FLAG");
    },
    setUserDetails({ commit }, payload) {
      commit("SET_USER_DETAILS", payload);
    },
    setParlourDetails({ commit }, payload) {
      commit("SET_PARLOUR_DETAILS", payload);
    },
    //call api to get parlour details
    callParlourDetailsApi({ commit }) {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        id: parlour_id, // Adding parlour_id as a query parameter
      };
      const successHandler = (response) => {
        let data = response.data.parlour_detail;
        let parlour_details = {
          is_parlour_registered: data.is_parlour_registered,
          is_service_added: data.is_service_added,
          is_staff_added: data.is_staff_added,
          el: data.el,
          is_parlour_active: data.is_active,
        };

        let current_user_details = {
          name: data.logged_in_user.full_name,
          number: data.logged_in_user.mobile,
          logo: data.logo,
          role: data.logged_in_user.role,
          user_id: data.logged_in_user.id,
          first_name: data.logged_in_user.first_name,
          last_name: data.logged_in_user.last_name,
        };
        let parlour_basic_details = {
          parlour_name: data.parlour_name,
          location: data.location,
          country_name: data.country_name,
        };
        commit("SET_PARLOUR_REGISETRATION_DETAILS", parlour_details);
        commit("SET_PARLOUR_DETAILS_API", current_user_details);
        commit("SET_PARLOUR_BASIC_DETAILS", parlour_basic_details);
      };
      const failureHandler = () => {};
      return axios("get", urls.GET_PARLOUR_DETAILS, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};

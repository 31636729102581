<template>
  <v-container>
    <v-row class="flex_row">
      <v-col cols="6">
        <v-card class="booking_card py-8">
          <div class="d-flex flex-column align-center">
            <v-img src="../../assets/businessFinance.svg" class="booking_icon mb-4"></v-img>
            <p>{{ total_sales }}</p>
            <p>Today's Sales</p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="booking_card py-8">
          <div class="d-flex flex-column align-center">
            <v-img src="../../assets/booking.svg" class="booking_icon mb-4"></v-img>
            <p>{{ total_appointments }}</p>
            <p>Appointments</p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" offset="3" class="d-flex justify-center">
        <v-card class="booking_card py-8 total_visits">
          <div class="d-flex flex-column align-center">
            <v-img src="../../assets/businessFinance.svg" class="booking_icon mb-4"></v-img>
            <p>{{ total_visits }}</p>
            <p>Total Visits</p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- Graph here -->
    <v-row class="scrollable-content mt-5">
      <!-- sales -->
      <v-col md="6" class="fill-height">
        <v-card class="fill-height graph_card" :class="{ chart_card: service_report_list.length === 0 }">
          <v-card-text>
            <div>
              <p class="mb-0 card_type pl-2">Sales</p>
              <div class="d-flex align-center justify-space-between">
                <div md="4" class="card_title mx-2">
                  <p>By Services</p>
                </div>
              </div>
              <div class="d-flex">
                <div class="ma-2">
                  <!-- <v-select clearable v-model="week_filter" filled rounded dense
                                        placeholder="This Week" :items="week_filter_items"></v-select> -->
                  <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="sales_dates"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field style="min-width: 150.68px; max-width: 170px" clearable filled rounded dense
                        v-model="sales_dates" placeholder="From - To" append-icon="mdi-chevron-down" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="sales_dates" no-title scrollable range>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu1.save(sales_dates)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="ma-2 all_services">
                  <v-select clearable v-model="service_filter_value" filled rounded dense placeholder="All Services"
                    :items="services_filter_items" item-value="id" item-text="service_name"></v-select>
                </div>
              </div>
            </div>
            <div>
              <v-divider></v-divider>
            </div>
            <!-- pie chart -->
            <div v-if="service_report_list.length === 0"
              class="d-flex align-center justify-center mt-12 pk text-center">
              <p>There is not data available</p>
            </div>
            <div class="d-flex flex-column align-center justify-center pk text-center"
              :class="{ dis: service_report_list.length === 0 }">
              <div class="d-flex align-center justify-center">
                <apexchart width="180" height="180" type="pie" :options="options" :series="options.series"
                  :labels="options.labels" :colors="options.colors">
                </apexchart>
              </div>
              <div class="service_card_price_list">
                <v-row v-for="(item, index) in service_report_list" :key="index" class="d-flex">
                  <v-col md="3.5" v-if="item.service_name" class="d-flex pr-0 report_service_name">
                    <v-icon x-small class="mr-3" :color="colors[index % colors.length]">mdi-circle</v-icon>
                    {{ item.service_name }}</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                  <v-col md="3.5" v-if="item.amount" class="pr-0">₹{{ item.amount }}</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                  <v-col md="3.5" v-if="item.percent" class="pr-0">{{ item.percent }}%</v-col>
                  <v-col v-else class="pr-0">-</v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- executive -->
      <v-col md="6" class="fill-height">
        <v-card class="fill-height graph_card" :class="{ chart_card: executive_list_report.length === 0 }">
          <v-card-text>
            <p class="mb-0 card_type pl-2">Sales</p>
            <p class="card_title mx-2">By Executives</p>
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable filled rounded dense v-model="dates" placeholder="From - To"
                      append-icon="mdi-chevron-down" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" no-title scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(dates)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div md="4" class="mx-2 all_services">
                <v-select class="fixed-width-select" clearable v-model="executive_filter_value" filled rounded dense
                  placeholder="All Executives" :items="executive_list" item-value="id" item-text="full_name"></v-select>
              </div>
            </div>
            <div>
              <v-divider></v-divider>
            </div>
            <!-- pie chart -->
            <div class="d-flex flex-column align-center justify-center">
              <div v-if="executive_list_report.length === 0" class="d-flex align-center justify-center mt-10 mx-auto">
                <p>There is not data available</p>
              </div>
              <div class="d-flex align-center justify-center kkk" :class="{ dis: executive_list_report.length === 0 }">
                <apexchart width="180" height="180" type="pie" :options="options_executive"
                  :series="options_executive.series" :labels="options_executive.labels"
                  :colors="options_executive.colors"></apexchart>
              </div>
              <div class="service_card_price_list" :class="{ dis: executive_list_report.length === 0 }">
                <v-row v-for="(item, index) in executive_list_report" :key="index" class="d-flex">
                  <v-col md="3.5" v-if="item.executive_name" class="d-flex pr-0 report_service_name">
                    <v-icon x-small class="mr-3" :color="colors[index % colors.length]">mdi-circle</v-icon>
                    {{ item.executive_name }}</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                  <v-col md="3.5" v-if="item.amount" class="pr-0">₹{{ item.amount }}</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                  <v-col md="3.5" v-if="item.percent" class="pr-0">{{ item.percent }}%</v-col>
                  <v-col md="3.5" v-else class="pr-0">-</v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
       
          <v-avatar color="primary white--text" size="56" class="upcomingbtn" v-bind="attrs" v-on="on">New</v-avatar>
      
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in upcomingAppointment" :key="index">
          <v-list-item-title>{{ item.message }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <NewServiceModal></NewServiceModal>
    <InviteTeamModal></InviteTeamModal>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import InviteTeamModal from "../team-management/InviteTeamModal.vue";
import NewServiceModal from "../services/NewServiceModal.vue";
export default {
  name: "DashboardMobile",
  data() {
    return {
      total_appointments: "",
      total_sales: "",
      appointment_list: "",
      is_parlour_registered: "",
      is_staff_added: "",
      is_service_added: "",
      is_parlour_active: "",
      colors: [
        "#AA4B4D",
        "#CE6264",
        "#F2797B",
        "#F59495",
        "#F7AFB0",
        "#FAC9CA",
      ],
      total_visits: "0",
      options: {
        series: [],
        labels: [],
        colors: [
          "#AA4B4D",
          "#CE6264",
          "#F2797B",
          "#F59495",
          "#F7AFB0",
          "#FAC9CA",
        ],
        legend: {
          show: false,
        },
      },
      options_executive: {
        series: [],
        labels: [],
        colors: [
          "#AA4B4D",
          "#CE6264",
          "#F2797B",
          "#F59495",
          "#F7AFB0",
          "#FAC9CA",
        ],
        legend: {
          show: false,
        },
      },
      client_list: [],
      status_items: ["all", "active", "inactive"],
      selected_status: "all",
      searchQuery: "",
      dates: [],
      menu: "",
      sales_dates: [],
      menu1: "",
      week_filter_items: ["This Week"],
      services_filter_items: [],
      service_filter_value: "",
      week_filter: "",
      week_filter_value: "",
      service_report_list: [],
      executive_list_report: [],
      executive_list: [],
      executive_filter_value: "",
      upcomingAppointment: [],
    };
  },
  components: {
    InviteTeamModal,
    NewServiceModal,
  },
  computed: {
    ...mapGetters({
      parlourDetails: "profile/get_parlour_details",
      userDetails: "profile/get_current_user_details",
    }),
    el: {
      get() {
        return this.parlourDetails.el;
      },
    },
  },
  watch: {
    // check if the parlour is registered or not and 2 steps (staff and service) are added or not  , we will check it from get parlour details api
    parlourDetails(val) {
      this.is_parlour_registered = val.is_parlour_registered;
      this.is_service_added = val.is_service_added;
      this.is_staff_added = val.is_staff_added;
      this.is_parlour_active = val.is_parlour_active;
      localStorage.setItem("parlour_is_active", this.is_parlour_active);
    },
    executive_filter_value() {
      this.getExecutiveReport();
    },
    dates(val) {
      if (val[1]) {
        this.getExecutiveReport();
      }
    },
    sales_dates(val) {
      if (val[1]) {
        this.getServiceReport();
      }
    },

    service_filter_value(val) {
      this.getServiceReport(val);
    },
  },
  mounted() {
    this.is_parlour_active = localStorage.getItem("parlour_is_active");
    if (this.$router.path !== "/dashboard" && this.drawer == true) {
      this.$store.dispatch("dialog/setNewBookingDrawer", true);
    }
    this.$store.dispatch("profile/callParlourDetailsApi");
    this.getListing();
    this.getServiceReport();
    this.getExecutiveReport();
    this.getServiceList();
    this.getExecutiveList();
    this.getUpcomingAppointmentList();
  },
  methods: {
    getInitialsOfName(name) {
      return this.$initials(name);
    },
    addStaff() {
      this.$store.dispatch("dialog/setInviteTeamModal");
    },
    addService() {
      let payload = {
        title: "Create A Service",
      };
      this.$store.dispatch("dialog/setServiceDrawer", payload);
    },
    openNewBookingModal() {
      this.$store.dispatch("dialog/setNewBookingMobileDrawer", true);
    },
    openCreateBillModal() {
      this.$store.dispatch("dialog/setCreateBillDrawer");
    },
    getListing() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        status: "upcoming",
      };
      const successHandler = (response) => {
        this.appointment_list = response.data.appointment_list;
        this.total_appointments = response.data.appointments.count;
        this.total_sales = response.data.recent_sales.amount;
        this.total_visits = response.data.total_visits.count;
        this.show_Loader = false;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_DASHBOARD_LISTING, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    // add graphs here
    getServiceReport(selected_service_id) {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        from_date: this.sales_dates[0],
        to_date: this.sales_dates[1],
        // date: this.week_filter_value,
        service: selected_service_id,
      };
      const successHandler = (response) => {
        this.service_report_list = response.data.response;
        this.options = {
          labels: this.service_report_list.map((item) => item.service_name),
        };
        this.options.series = this.service_report_list.map(
          (item) => item.amount
        );
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_SERVICE_REPORT, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getExecutiveReport() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        from_date: this.dates[0],
        to_date: this.dates[1],
        executive: this.executive_filter_value,
      };
      const successHandler = (response) => {
        this.executive_list_report = response.data.response;
        this.options_executive = {
          labels: this.executive_list_report.map(
            (value) => value.executive_name
          ),
        };
        this.options_executive.series = this.executive_list_report.map(
          (value) => value.amount
        );
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_EXECUTIVE_REPORT, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getServiceList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.services_filter_items = response.data.service_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_SERVICE_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getExecutiveList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.executive_list = response.data.staff_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_EXECUTIVE_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getUpcomingAppointmentList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.upcomingAppointment = response.data.response_json.upcoming_bookings;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_UPCOMING_APPOINTMENT_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>
<style scoped>
.upcomingbtn {
  margin: -4%;

  position: fixed;
  bottom: 90px;
  /* Adjust as needed */
  right: 50px;
}

.row {
  margin: 0px !important;
}

.flex_row {
  display: flex !important;
}

.fab_btn {
  position: fixed;
  left: 80vw;
  bottom: 5vh;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: 76vh !important;
}

.title {
  color: #9397a7;
  weight: 600;
  font-size: 20px;
}

.booking_card {
  background-color: #fce4e5 !important;
  height: 100%;
}

.booking_icon {
  width: 24px !important;
  height: 24px !important;
}

.margin_bottom {
  margin-bottom: 0px !important;
}

.grey_text {
  font-size: 11px;
  font-weight: 500;
  color: #697498;
}

.black_text {
  font-size: 14px;
  font-weight: 500;
  color: #39434d;
}

.user_name {
  font-size: 14px;
  font-weight: 600;
  color: #39434d;
}

.create_booking_btn {
  color: white !important;
  background-color: #f2797b !important;
}

.center_style {
  display: flex !important;
  align-content: center !important;
  justify-items: center !important;
  align-items: center !important;
}

.graph_card {
  border-radius: 20px !important;
}

.report_service_name {
  color: #1e1b39 !important;
  font-weight: 600 !important;
}

.count {
  font-weight: 700;
  font-size: 32px;
  color: #283037;
}

.tab_text {
  font-weight: 500;
  font-size: 24px;
  color: #283037;
}

.tab_sub_text {
  font-weight: 500;
  font-size: 20px;
  color: #878787;
}

.user_name {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #9397a7 !important;
}

.card {
  width: 645px;
  height: 357px;
  background-color: #fff7f7;
  border-radius: 20px !important;
}

.data_status {
  /* width: 366.33px; */
  height: 162px;
  border-radius: 16px;
  background-color: #ffeded !important;
}

.v-data-table>>>.v-data-table-header {
  background-color: #fbf1f2 !important;
}

.actionBtn {
  border-radius: 10px;
  padding: 6px 10px 6px 10px !important;
  width: 79px !important;
  font-size: 12px !important;
}

/* reports */
.chart_card {
  height: 336.8px !important;
}

.service_card_price_list {
  min-width: 322px !important;
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dis {
  display: none !important;
}

.v-list-item {
  min-height: 58px !important;
}

.status_filter>>>.v-input__slot {
  background-color: white !important;
}

.filter {
  width: 225px !important;
}

.actionBtn {
  border-radius: 10px;
  padding: 6px 10px 6px 10px !important;
  width: 79px !important;
  font-size: 12px !important;
}

.name {
  font-weight: 600;
  font-size: 14px;
}

.number {
  font-size: 12px;
  font-weight: 500;
  color: #697498;
  margin-top: -12px;
}

.card_title {
  font-size: 22px;
  font-weight: 700;
  color: #1e1b39;
}

.card_type {
  font-size: 18px;
  font-weight: 400;
  color: #9291a5;
}

.tab {
  width: 341.33px;
  height: 120px;
  border: solid 1px;
  border-color: #dfe1e3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.active-tab {
  background-color: #f2797b;
  color: white !important;
}

.mob-active-tab {
  color: #f2797b;
}

.v-application .pt-4 {
  padding-top: 0px !important;
}

@media all and (min-width: 600px) and (max-width: 999px) {
  .count {
    font-weight: 700;
    font-size: 23px;
    color: #283037;
  }

  .tab_text {
    font-weight: 500;
    font-size: 14px;
    color: #283037;
  }

  .tab_sub_text {
    font-weight: 500;
    font-size: 15px;
    color: #878787;
  }
}

.total_visits {
  width: 310px;
  height: 170px;
}

.all_services {
  overflow: hidden;
  width: 400px;
}


.fixed-width-select {
  width: 400px;
}
</style>

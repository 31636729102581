<template>
    <v-row justify="space-around">
        <v-dialog transition="dialog-bottom-transition" max-width="400" height="100" v-model="dialog" persistent>
            <v-card>
                <v-toolbar color="#F2797B" dark>Enter Duration</v-toolbar>
                <v-card-text>
                    <v-form ref="custom_duration_form">
                        <v-row>
                            <v-col md="6">
                                <v-text-field clearable class="mt-10" outlined v-model="duration" name="input-7-4"
                                    :rules="[$rules.NUMBER]" label="Duration">
                                </v-text-field>
                            </v-col>
                            <v-col md="6">
                                <v-select class="mt-10" outlined v-model="duration_type" item-value="value" :items="duration_type_list"
                                    label="Type" required></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal">Close</v-btn>
                    <v-btn class="text-capitalize white--text" color="#F2797B" @click="CreateCustomDuration">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "CustomDurationModal",
    data() {
        return {
            duration: "",
            flag: false,
            custom_duration_id: "",
            duration_type_list: [{
                text: "Minutes",
                value: "minutes"
            },
            {
                text: "Hours",
                value: "Hours"
            }
            ],
            duration_type: ""
        }
    },
    computed: {
        ...mapGetters({
            dialog: "dialog/getCustomDurationModal",
            // custom_query_dialog:"dialog/getCustomQueryModal"
        }),
    },
    methods: {
        // create new query custom query to get Id
        CreateCustomDuration() {
            if (this.$refs.custom_duration_form.validate()) {
                let parlour_id = localStorage.getItem("parlour_id")
                let data = {
                    "parlour": parlour_id,
                    "duration": this.duration,
                    "duration_type": this.duration_type
                }
                const successHandler = (response) => {
                    this.custom_duration_id = response.data.response.id
                    this.$store.dispatch("dialog/setCustomDurationModal")
                    // this.query_id = response.data.response.query_id;
                    // this.flag = true,
                    this.$store.dispatch("services/setCustomDurationData", {
                        id: this.custom_duration_id,
                        duration: this.duration,
                        // flag: true
                    })
                }
                const failureHandler = (error) => {
                    console.log("error of createClient ", error)
                };
                const finallyHandler = () => {
                    console.log("finallyHandler")
                };
                return this.$axios("post", this.$apiUrl.CREATE_CUSTOM_SERVICE_DURATION, {
                    data,
                    onSuccess: successHandler,
                    onFailure: failureHandler,
                    onFinally: finallyHandler,
                    isTokenRequired: true,
                });
            }
        },
        handleDurationType(value) {
            this.duration_type = value
        },
        closeModal() {
            this.$store.dispatch("dialog/setCustomDurationModal", false)
            this.$refs.custom_duration_form.reset();
        }
    }
}
</script>
<style scoped>
.duration_type {
    cursor: pointer !important
}
</style>
<template>
    <v-container>
        <v-row class="ma-2 header">Services</v-row>
        <v-row>
            
            <v-col md="3">
                <v-select clearable :items="category_status_items" item-value="value" outlined dense
                    placeholder="Status " v-model="status_category" class="filter narrow-select">
                    <template v-slot:prepend-inner>
                        <div class="d-flex align-center">
                            <img :src="require('@/assets/filter_icon.png')" alt="Status Icon" class="icon-image mr-1">
                            <span class="mr-1 status_bar">Status</span>
                            <span class="mr-1">:</span>
                        </div>
                    </template>
                </v-select>
            </v-col><v-col md="3">
                <v-text-field outlined dense hide-details class="search_bar" placeholder="Search Services"
                    prepend-inner-icon="mdi-magnify" v-model="searchQuery" />
            </v-col>

        </v-row>

        <!-- skeleton loader -->
        <v-row v-if="category_service_list.length === 0" class="my-4 mx-2">
            <v-col cols="12">
                <v-skeleton-loader max-width="400" type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="12">
                <v-skeleton-loader max-width="400" type="card"></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row>
            <v-card elevation="1" class="d-flex mt-5 mx-2 content" v-for="(item, index) in category_service_list"
                :key="index">
                <v-card-text>
                    <div class="d-flex justify-space-between align-center category_color">
                        <p class="mt-1 category_name ">{{ item.category }} </p>
                        <!-- <v-icon>mdi-dots-vertical</v-icon> -->
                        <v-menu id="create_new_menu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon right v-on="on" v-bind="attrs">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list>
                                <v-list-item v-for="(actionItem, actionIndex) in item.category_action_items"
                                    :key="actionIndex">
                                    <v-list-item-title class="list_item"
                                        @click="handleAction(actionItem.title, item.category_name, item.category_id, item.description, '', '', '', item.is_active)">
                                        {{ actionItem.title }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <v-divider></v-divider>
                    <div v-for="(subItem, subIndex) in item.queryset" :key="subIndex">
                        <div class="d-flex align-center">
                            <div class="d-flex align-center justify-space-between my-3">
                                <div>
                                    <p class="service_name">{{ subItem.service_name }} <span
                                            class="time_duration ml-2">{{
                        subItem.duration }} {{
                        subItem.duration__duration_type }}</span></p>
                                    <p class="description">{{ subItem.description }}</p>
                                </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="d-flex align-center">
                                <p class="price">₹{{ subItem.price }}</p>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon class="mr-2"  v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in service_action_list" :key="index">
                                            <v-list-item-title @click="handleAction(item.title)">{{ item.title }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                        <v-divider></v-divider>
                    </div>
                </v-card-text>
            </v-card>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-fab-transition>
                        <v-btn color="pink" v-bind="attrs" v-on="on" dark absolute top right fab class="fab_btn">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in action_items" :key="index">
                        <v-list-item-title class="list_item" @click="handleAction(item.title)">
                            <v-icon outlined class="ma-2 plus_icon" color="#F2797B">
                                mdi-plus-circle-outline </v-icon>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-row>
        <NewCategoryModalMobile @callGetApi="getCategoryAndServicesListing"></NewCategoryModalMobile>
        <NewServiceModalMobile @callGetApi="getCategoryAndServicesListing"></NewServiceModalMobile>
        <EditCategoryConfirmitionModal @callGetApi="getCategoryAndServicesListing"></EditCategoryConfirmitionModal>
    </v-container>
</template>
<script>
// import { mapGetters } from 'vuex'
import NewCategoryModalMobile from './NewCategoryModalMobile.vue';
import NewServiceModalMobile from './NewServiceModalMobile.vue';
import EditCategoryConfirmitionModal from "@/components/services/EditCategoryConfirmitionModal.vue"
export default {
    name: "ServicesMobile",
    data() {
        return {
            action_items: [{ title: "Add A Category" }, { title: "Create A Service" }],
            service_action_list: [{ title: "Edit Service" }],
            category_status: [{ text: "Active Categories", value: "active" }, { text: "Inactive Categories", value: "inactive" }],
            searchQuery: "",
            selectedItem: "",
            model: null,
            show_service_loader: false,
            status_category: "active",
            category_service_list: "",
            category_status_items: [{ text: "All", value: "all" }, { text: "Active", value: "active" }, { text: "Inactive", value: "inactive" }],
        };
    },
    components: {
        NewCategoryModalMobile,
        NewServiceModalMobile,
        EditCategoryConfirmitionModal
    },
    mounted() {
        this.getCategoryAndServicesListing()
    },
    computed: {
        // ...mapGetters({ list_of_categoriesService: "appointments/get_list_of_categories_services" },
        // ),
    },
    watch: {
        status_category() {
            this.getCategoryAndServicesListing()
        },
        list_of_categoriesService(val) {
            this.category_service_list = val
        },
        searchQuery() {
            // let payload = {
            //     parlour_id: "",
            //     search: this.searchQuery,
            // };
            // this.$store.dispatch("appointments/getListOfCategoriesAndServices", payload);
            this.getCategoryAndServicesListing()
        },
    },
    methods: {
        handleAction(title, current_item_name, current_item_id, current_item_description, category, price, duration_id, category_is_active) {
            this.current_item_name = current_item_name
            this.current_item_id = current_item_id
            this.current_item_descripton = current_item_description
            let payload = {
                title,
                current_item_name,
                current_item_id,
                current_item_description,
                category,
                price,
                duration_id,
                category_is_active
            }
            if (title == "Add A Service") {
                this.$store.dispatch("dialog/setServiceDrawer", payload);
            }
            else if (title == "Create A Service") {
                this.$store.dispatch("dialog/setServiceDrawer", payload);
            }
            else if (title == "Edit Service") { 
                //   // this.filterServices(current_item_name, current_item_id)
                this.$store.dispatch("dialog/setServiceDrawer", payload);
            }
            else if (title == "Activate Category") {
                // this.$store.dispatch("dialog/setCategoryDrawer", payload);
                // this.$store.dispatch("services/ListCategory")
                let category_payload = {
                    flag: true,
                    category_id: current_item_id,
                    category_is_active: category_is_active
                }
                this.$store.dispatch("dialog/setEditCategoryConfirmition", category_payload);
            }
            else if (title == "Deactivate Category") {
                let category_payload = {
                    flag: true,
                    category_id: current_item_id,
                    category_is_active: category_is_active
                }
                this.$store.dispatch("dialog/setEditCategoryConfirmition", category_payload);
            }
            else if (title == "Add A Category") {
                this.$store.dispatch("dialog/setCategoryDrawerMobile", payload);
            }
        },
        navigateToDashboard() {
            this.$router.push({
                name: "dashboard",
            });
        },
        // CATEGORY_AND_SERVICE_LIST
        getCategoryAndServicesListing() {
            let parlour_id = localStorage.getItem("parlour_id")
            let params = {
                parlour_id: parlour_id,
                search: this.searchQuery,
                status: this.status_category
            }
            const successHandler = (response) => {
                this.show_service_loader = true
                this.category_service_list = response.data.response
            }
            const failureHandler = (error) => {
                if (error && error.data && error.data.message) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("get", this.$apiUrl.GET_CATEGORY_WITH_SERVICES, {
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        }
    },
};
</script>
<style scoped>
.list_item {
    cursor: pointer;
}

p {
    margin-bottom: 0 !important;
}

.fab_btn {
    position: fixed;
    left: 80vw;
    bottom: 5vh;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
    top: 76vh !important;
}

.filter>>>.v-text-field__details {
    display: none !important;
}

.search_bar,
.filter {
    background-color: white !important;
    
}

.header {
    color: black !important;
    font-weight: 600;
    font-size: 20px;
}

.row {
    display: block !important;
}

.client_name {
    font-size: 16px;
    font-weight: 400;
    color: #404C57;
}

.client_number {
    font-size: 11px;
    font-weight: 400;
    color: #727272;
}

.status_active {
    background-color: #E6FBD9 !important;
    color: #0F5B1D;
}

.status_inactive {
    background-color: #FFE8D7 !important;
    color: #931222 !important
}
.category_color{
font-weight: bold;
padding:9px;
background-color:#FCE4E5;
}
.narrow-select {
    width:200px;
    font-size: 12px;

   
}
.icon-image {
  width: 14px; 
  height: 14px;
  padding:1px;
  }
  .status_bar{
      margin:7px;

  }
</style>
<template>
  <v-container>
    <div class="d-flex flex-column justify-center" v-if="$isDesktop">
      <!-- 1 -->
      <v-row class="ma-0">
        <v-col class="d-flex align-center pb-0">
          <p class="module_title mb-0">{{ $route.meta.title }}</p>
        </v-col>
      </v-row>
      <!--2 -->
      <v-row class="mx-0 colored_row mt-6 d-flex align-center justify-center">
        <v-col md="3" class="pb-0">
          <p class="mb-0">Report Type</p>
          <v-select
            clearable
            :items="report_type_list"
            item-value="id"
            item-text="category_name"
            outlined
            dense
            v-model="selected_report_type"
            class="status_filter"
            placeholder="Select Report Type"
          >
          </v-select>
        </v-col>
        <v-col md="3" class="pb-0" v-if="active_filter === 'Sales Report'">
          <p class="mb-0">Category Type</p>
          <v-select
            clearable
            :items="category_list"
            item-value="id"
            item-text="category_name"
            outlined
            dense
            v-model="selected_category"
            class="status_filter"
            placeholder="Select Category"
          >
          </v-select>
        </v-col>
        <v-col md="3" class="pb-0" v-else>
          <p class="mb-0">Executive</p>
          <v-select
            clearable
            :items="executive_list"
            item-value="id"
            item-text="full_name"
            outlined
            dense
            v-model="selected_executive"
            class="status_filter"
            placeholder="Select Executive"
          >
          </v-select>
        </v-col>
        <v-col md="3" class="pb-0">
          <p class="mb-0">Date</p>
          <v-menu
            ref="menu2"
            v-model="menu_2"
            :close-on-content-click="false"
            :return-value.sync="dates_2"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="status_filter"
                clearable
                outlined
                dense
                v-model="dates_2"
                placeholder="From - To"
                append-icon="mdi-chevron-down"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates_2" no-title scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu_2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu2.save(dates_2)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" class="d-flex align-center justify-end mt-3">
          <v-btn
            :disabled="isGenerateReportDisabled"
            class="text-capitalize white--text"
            color="#F2797B"
            @click="generateReport"
          >
            <v-icon left> mdi-plus-circle-outline </v-icon>Generate Report
          </v-btn>
        </v-col>
      </v-row>
      <!--3-->
      <!-- <v-row class="mt-4">
                <v-col md="3">
                    <v-text-field outlined dense hide-details placeholder="Search Reports"
                        prepend-inner-icon="mdi-magnify" v-model="searchQuery" />
                </v-col>
            </v-row> -->
      <!-- 4 -->
      <v-row class="pt-0 mt-14">
        <v-col md="12" class="pt-0 pb-0">
          <p class="table_title mb-0">
            {{ selected_report_type }}
            <span class="date_report" v-if="dates_2[0]"
              >({{ dates_2[0] }} <span v-if="dates_2[1]">to</span>
              {{ dates_2[1] }})</span
            >
          </p>
        </v-col>
      </v-row>
      <!-- 5 -->
     
      <div
        class="d-flex align-center text-end justify-end mb-2"
        v-if="total_amount"
      >
        <div class="font-weight-bold ">Total Amount : Rs {{ total_amount }}</div>
      </div>
      <v-row>
        <v-col md="12">
          <!-- Data Table -->
          <v-data-table
            :loading="show_Loader"
            :headers="getTableHeaders()"
            :items="table_list"
            class="tt"
          >
            <!-- sales -->
            <template v-slot:[`item.service_name`]="{ item }">
              <div v-if="item.services.length == 0">-</div>
              <div v-else>
                <div v-for="(service, index) in item.services" :key="index">
                  {{ service?.service_name || "-" }}
                  <v-divider
                    v-if="index != item?.services?.length - 1"
                  ></v-divider>
                </div>
              </div>
            </template>

            <template v-slot:[`item.total_generated_amount`]="{ item }">
              <div v-if="item.services.length == 0">-</div>
              <div v-else>
                <div v-for="(service, index) in item.services" :key="index">
                  {{ service.total_generated_amount }}
                  <v-divider
                    v-if="index != item?.services?.length - 1"
                  ></v-divider>
                </div>
              </div>
            </template>

            <template v-slot:[`item.no_of_times_availed`]="{ item }">
              <div v-if="item.services.length == 0">-</div>
              <div v-else>
                <div v-for="(service, index) in item.services" :key="index">
                  {{ service.no_of_times_availed }}
                  <v-divider
                    v-if="index != item?.services?.length - 1"
                  ></v-divider>
                </div>
              </div>
            </template>
            <!--executive  -->
            <template v-slot:[`item.serviceName`]="{ item }">
              <div v-if="item.total_services === 0">-</div>
              <div v-else>
                <div
                  v-for="(service, index) in item.total_services"
                  :key="index"
                >
                  <span>{{ service.service_name }}</span>
                  <v-divider
                    v-if="index != item?.total_services?.length - 1"
                  ></v-divider>
                </div>
              </div>
            </template>

            <template v-slot:[`item.no_of_services_provided`]="{ item }">
              <div v-if="item.total_services === 0">-</div>
              <div v-else>
                <div
                  v-for="(service, index) in item.total_services"
                  :key="index"
                >
                  {{ service.no_of_services_provided }}
                  <v-divider
                    v-if="index != item?.total_services?.length - 1"
                  ></v-divider>
                </div>
              </div>
            </template>

            <template v-slot:[`item.sales_generated`]="{ item }">
              <div v-if="item.total_services === 0">-</div>
              <div v-else>
                <div
                  v-for="(service, index) in item.total_services"
                  :key="index"
                >
                  {{ service.sales_generated }}
                  <v-divider
                    v-if="index != item?.total_services?.length - 1"
                  ></v-divider>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "reports",
  data() {
    return {
      table_headers: [
        {
          text: "Services",
          value: "service_name",
          align: "start",
          sortable: false,
        },
        {
          text: "Sales Generated",
          value: "total_earning",
          align: "center",
          sortable: false,
        },
        {
          text: "No. Of  Services Provided",
          value: "total_client",
          align: "center",
          sortable: false,
        },
      ],
      searchQuery: "",
      show_Loader: false,
      menu_2: "",
      dates_2: [],
      executive_list: [],
      selected_executive: "",
      selected_category: "",
      status_category: "",
      table_list: [],
      report_type_list: ["Sales Report", "Executive Report"],
      selected_report_type: "Executive Report",
      active_filter: "",
      total_amount: "",
    };
  },
  computed: {
    ...mapGetters({
      category_list: "services/get_category_list",
    }),
    isGenerateReportDisabled() {
      if (
        // (this.selected_category || this.selected_executive) &&
        this.dates_2 &&
        this.dates_2[0] &&
        this.dates_2[1] &&
        this.selected_report_type
      ) {
        return false; // Enable button if both category/executive and dates are selected
      } else {
        return true; // Disable button if any condition is not met
      }
    },
  },
  watch: {
    searchQuery() {
      this.generateReport();
    },
    selected_executive(val) {
      if (val) {
        this.selected_category = "";
        // this.generateReport()
      }
    },
    selected_category(val) {
      if (val) {
        // this.generateReport()
        this.selected_executive = "";
      }
    },
    selected_report_type(val) {
      if (val === "Executive Report") {
        this.active_filter = "Executive Report";
      } else {
        this.active_filter = "Sales Report";
      }
    },
  },
  components: {},
  mounted() {
    this.getExecutiveList();
    this.getCategoryListing();
    // this.generateReport()
  },
  methods: {
    getTableHeaders() {
      if (this.selected_executive == "") {
        // for category
        return [
          {
            text: "Category Name",
            value: "category_name",
            align: "center",
            divider: true,
            sortable: false,
          },
          {
            text: "Services",
            value: "service_name",
            align: "center",
            divider: true,
            sortable: false,
          },
          {
            text: "Sales Generated",
            value: "total_generated_amount",
            align: "center",
            divider: true,
            sortable: false,
          },
          {
            text: "No. Of  Times availed",
            value: "no_of_times_availed",
            align: "center",
            divider: true,
            sortable: false,
          },
        ];
      } else {
        // for executive
        return [
          {
            text: "Executive Name",
            value: "full_name",
            align: "center",
            divider: true,
            sortable: false,
          },
          {
            text: "Services",
            value: "serviceName",
            align: "center",
            divider: true,
            sortable: false,
          },
          {
            text: "Sales Generated",
            value: "sales_generated",
            align: "center",
            divider: true,
            sortable: false,
          },
          {
            text: "Clients Served",
            value: "no_of_services_provided",
            align: "center",
            divider: true,
            sortable: false,
          },
        ];
      }
    },
    getExecutiveList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.executive_list = response.data.staff_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_EXECUTIVE_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getCategoryListing() {
      this.$store.dispatch("services/ListCategory");
    },
    generateReport() {
      this.show_Loader = true;
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        executive: this.selected_executive,
        from_date: this.dates_2[0],
        to_date: this.dates_2[1],
        search: this.searchQuery,
        service_category: this.selected_category,
      };
      const successHandler = (response) => {
        this.show_Loader = false;
        this.table_list = response.data.response;
        this.table_list.forEach((item) => {
          this.total_amount = item.total_amount;
        });
      };
      const failureHandler = (error) => {
        this.show_Loader = false;
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GENERATE_REPORT, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>

<style scoped>
.v-data-table >>> .v-data-table__wrapper table thead tr th {
  color: #333333 !important;
  font-size: 14px !important;
}

.v-data-table >>> .v-data-table__wrapper table td {
  color: #333333 !important;
  font-weight: 550 !important;
  font-size: 14px !important;
}

.v-data-table >>> td {
  padding: 0 !important;
}

.table_title {
  font-weight: 600 !important;
  font-size: 19px !important;
  color: #1e1b39 !important;
}

.date_report {
  font-weight: 500 !important;
  font-size: 19px !important;
  color: #1e1b39 !important;
}

.v-list-item {
  min-height: 58px !important;
}

.status_filter >>> .v-input__slot {
  background-color: white !important;
}

.colored_row {
  background-color: #fce4e5;
  border-radius: 15px !important;
}

.filter {
  width: 225px !important;
}

.v-data-table >>> .v-data-table-header {
  background-color: #fbf1f2 !important;
}

.name {
  font-weight: 600;
  font-size: 14px;
}

.tab {
  width: 341.33px;
  height: 120px;
  border: solid 1px;
  border-color: #dfe1e3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.active-tab {
  background-color: #f2797b;
  color: white !important;
}

.mob-active-tab {
  color: #f2797b;
}

.v-application .pt-4 {
  padding-top: 0px !important;
}
</style>

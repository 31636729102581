<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="528"
      height="100vh"
    >
      <v-card>
        <v-card-title class="d-flex align-center py-4" color="#FCE4E5">
          <span class="header_text">Create New Enquiry</span>
          <v-spacer></v-spacer>
          <span>
            <v-btn icon>
              <v-icon color="black" @click="closeModal"
                >mdi-close</v-icon
              ></v-btn
            >
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-6">
          <v-container>
            <v-form ref="new_enquiry_creating_form_mob">
              <v-row dense>
                <v-col cols="12" sm="6" md="12">
                  <label>Name*</label>
                  <v-text-field
                    dense
                    outlined
                    placeholder="Enter Your Name"
                    required
                    v-model="name"
                    :rules="[$rules.required, $rules.name_2]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Mobile Number*</label>
                  <v-text-field
                    @keypress="isNumber($event)"
                    maxlength="10"
                    dense
                    outlined
                    placeholder="Please enter mobile number"
                    class="md-mr-0 mr-2"
                    v-model="mobile"
                    prepend-inner-icon
                    type="number"
                    hide-spin-buttons
                    :rules="[$rules.required, $rules.mobile_number]"
                  >
                    <template #prepend-inner>
                      <v-img :src="selected_flag" class="flag_img"></v-img>
                      &nbsp;
                      <p class="mt-1">{{ selected_phone_code }}</p>
                      &nbsp;
                      <!-- <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item v-for="(item, index) in country_list" :key="index"
                                                        @click="selectPhoneCode(item)">
                                                        <v-list-item-title>{{ item.phone_code }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu> -->
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Query*</label>
                  <v-select
                    clearable
                    dense
                    outlined
                    :items="query_list"
                    return-object
                    item-value="query_title"
                    item-text="query_title"
                    placeholder="Select a query"
                    v-model="selectedQuery"
                    :rules="[$rules.required]"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card class="btn_card">
          <v-card-actions class="py-5">
            <v-row>
              <v-col sm="12">
                <v-btn
                  outlined
                  color="#F2797B"
                  block
                  class="text-capitalize py-5"
                  @click="closeModal()"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col sm="12">
                <v-btn
                  @click="CreateNewEnquiry()"
                  block
                  class="text-capitalize white--text py-5"
                  color="#F2797B"
                >
                  Add Enquiry
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <CustomQueryModal></CustomQueryModal>
  </v-row>
</template>
<script>
import CustomQueryModal from "./CustomQueryModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "EnquiryModalMobile",
  data() {
    return {
      parlour: "",
      name: "",
      country: "103",
      mobile: "",
      selectedQuery: "",
      query_id: "",
      query: "",
      country_list: [],
      selected_flag:
        "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
      selected_country: "India",
      selected_phone_code: "+91",
      query_list: [],
    };
  },
  components: {
    CustomQueryModal,
  },
  mounted() {
    this.getListOfPhoneCodes();
  },
  computed: {
    ...mapGetters({
      dialog: "dialog/getNewEnquiryModalMobileFlag",
      custom_query_id: "enquiry_details/getCustomQueryId",
      custom_query_text: "enquiry_details/getCustomQueryText",
      querylist_apiFlag: "enquiry_details/getQueryListApiFlag",
    }),
  },
  watch: {
    custom_query_id(newValue) {
      this.query_id = newValue;
    },
    selectedQuery(newVal) {
      if (newVal.query_title == "Others") {
        this.$store.dispatch("dialog/setCustomQuery");
      } else {
        // Assuming query_title is a string property
        this.query = newVal ? newVal.query_title : null;
        if (typeof newVal === "object") {
          this.query_id = newVal.id;
        }
      }
    },
    custom_query_text(newValue) {
      this.selectedQuery = newValue;
    },
    querylist_apiFlag() {
      this.getListOfQueries();
    },
    dialog(value) {
      if (value == true) this.getListOfQueries();
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch("dialog/setNewEnquiryDrawerMob", false);
    },
    // post new enquiry into the table
    CreateNewEnquiry() {
      if (this.$refs.new_enquiry_creating_form_mob.validate()) {
        this.parlour = localStorage.getItem("parlour_id");
        let data = {
          parlour: this.parlour,
          name: this.name,
          country: this.country,
          mobile: this.mobile,
          query: this.query_id,
        };
        const successHandler = (response) => {
          this.$store.dispatch("dialog/setNewEnquiryDrawerMob", false);
          this.$refs.new_enquiry_creating_form_mob.reset();
          console.log(response);
          // trigger get api for listing all enquiries
          this.$store.dispatch("enquiry_details/setEnquiryListApi");
        };
        const failureHandler = (error) => {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        };
        const finallyHandler = () => {};
        return this.$axios("post", this.$apiUrl.CREATE_ENQUIRY, {
          data,
          onSuccess: successHandler,
          onFailure: failureHandler,
          onFinally: finallyHandler,
          isTokenRequired: true,
        });
      }
    },
    // getListOfPhoneCodes api calling
    getListOfPhoneCodes() {
      const successHandler = (response) => {
        this.country_list = response.data.phone_code_list;
      };
      const failureHandler = (error) => {
        console.log("error is ", error);
      };
      return this.$axios("get", this.$apiUrl.PHONE_CODE, {
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: false,
      });
    },

    selectPhoneCode(item) {
      this.selected_flag = item.flag;
      this.selected_country = item.name;
      this.country = item.id;
      this.selected_phone_code = item.phone_code;
    },

    // list all queries
    getListOfQueries() {
      let params = {
        parlour_id: localStorage.getItem("parlour_id"),
      };
      const successHandler = (response) => {
        this.query_list = response.data.query_list;
      };
      const failureHandler = (error) => {
        console.log("error is ", error);
      };
      return this.$axios("get", this.$apiUrl.GET_QUERY_LIST, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>
<style scoped>
.btn_card {
  bottom: -194px;
}

.flag_img {
  width: 20px;
  height: 20px;
}

.v-input >>> .v-input__slot {
  border-radius: 8px !important;
}

label {
  line-height: 2.5;
}

.v-btn {
  border-radius: 20px !important;
}

.header_text {
  font-size: 22px;
  font-weight: 500;
}

.v-card__title {
  background-color: #fce4e5;
}

label {
  color: black !important;
}

.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 20px !important;
}
</style>

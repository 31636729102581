<template>
  <v-row justify="center">
    <v-dialog
      v-model="booking_cancellation_data"
      persistent
      max-width="328"
      height="200"
    >
      <v-card>
        <v-card-title class="mb-4">
          <span>Cancel Booking</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="cancellation_modal">
            <v-row dense>
              <v-col cols="12" sm="6" md="12">
                <label>Choose a reason</label>
                <v-select
                  clearable
                  dense
                  outlined
                  :items="reason_list"
                  return-object
                  item-value="id"
                  v-model="selectedReason"
                  item-text="title"
                  placeholder="Select a reason"
                  :rules="[$rules.required]"
                >
                </v-select>
              </v-col>
              <!--  -->
              <v-col
                cols="12"
                sm="6"
                md="12"
                v-if="
                  selectedReason?.title === 'Others' && custom_reason_id === ''
                "
              >
                <label>Custom reason</label>
                <v-text-field
                  v-model="custom_reason"
                  outlined
                  dense
                  clearable
                  placeholder="Enter Custom Reason"
                  :rules="[$rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="text-capitalize"
            color="#F2797B"
            @click="closeModal"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="selectedReason?.title === 'Others' && custom_reason_id === ''"
            color="#F2797B"
            class="text-capitalize white--text"
            @click="postCustomReason()"
          >
            Confirm
          </v-btn>
          <v-btn
            v-else
            color="#F2797B"
            class="text-capitalize white--text"
            @click="postReason()"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "appointmentCancelModal",
  data() {
    return {
      reason_list: [],
      selectedReason: "",
      reason_title: "",
      flag: "",
      booking_id_array: [],
      custom_reason: "",
      custom_reason_id: "",
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      booking_cancellation_data: "appointment/getAppointmentCancellationData",
      current_tab_name: "appointment/get_current_tab_name",
      selected_appointments: "appointment/get_selected_appointments_data",
    }),
  },
  watch: {
    selected_appointments(val) {
      this.booking_id_array = val.map((value) => value.id);
    },
    selectedReason(val) {
      if (val) {
        this.reason_title = val?.title;
        this.reason_id = val?.id;
      }
    },
  },
  mounted() {
    this.getReasonList();
  },
  methods: {
    closeModal() {
      this.$store.dispatch("appointment/toggleCancellationModal", false);
      this.$refs.cancellation_modal.reset();
    },
    getReasonList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.reason_list = response.data.reason_list;
      };
      const failureHandler = () => {};
      return this.$axios("get", this.$apiUrl.GET_CANCELLATION_REASON_LISTING, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    postReason() {
      if (this.$refs.cancellation_modal.validate()) {
        let data = {
          appointments: this.booking_id_array,
          cancelled_reason: this.reason_id,
        };
        const successHandler = () => {
          this.$store.dispatch("appointment/toggleCancellationModal", false);

          // trigger get api for listing all enquiries
          let payload = {
            status: this.current_tab_name,
          };
          this.$store.dispatch("appointment/getBookingList", payload);
          this.$refs.cancellation_modal.reset();
          this.$emit("clear_selected_appointment_data");
        };
        const failureHandler = () => {
          this.$refs.cancellation_modal.reset();
        };
        const finallyHandler = () => {};
        return this.$axios("post", this.$apiUrl.POST_CANCELLATION_REASON, {
          data,
          onSuccess: successHandler,
          onFailure: failureHandler,
          onFinally: finallyHandler,
          isTokenRequired: true,
        });
      }
    },
    async postCustomReason() {
      let parlour_id = localStorage.getItem("parlour_id");
      // if (this.$refs.cancellation_modal.validate()) {
      let data = {
        parlour: parlour_id,
        title: this.custom_reason,
      };
      const successHandler = (response) => {
        this.custom_reason_id = response.id;
        this.selectedReason = response.data.response;
        this.$emit("clear_selected_appointment_data");
        this.$store.dispatch("appointment/toggleCancellationModal", false);
      };
      const failureHandler = () => {};
      const finallyHandler = () => {};
      return await this.$axios("post", this.$apiUrl.POST_CUSTOM_CANCEL_REASON, {
        data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        onFinally: finallyHandler,
        isTokenRequired: true,
      });
    },
  },
  // }
};
</script>
<style scoped>
label {
  color: black !important;
}

.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px !important;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 20px !important;
}

.flag_img {
  width: 20px;
  height: 20px;
}
</style>

<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">New Category</span>
                    <v-spacer></v-spacer>
                    <span>
                        <v-icon color="black" large @click="closeModal">mdi-close</v-icon>
                    </span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-form ref="new_category_modal">
                            <v-row dense>
                                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                                    <label>Category Name*</label>
                                    <v-text-field dense outlined placeholder="e.g. Hair" required v-model="category_name"
                                        @input="category_name = capitalize(category_name)"
                                        :rules="[ $rules.required,$rules.noSpaces]"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="12" class="pt-0 pb-0">
                                    <label>Description</label>
                                    <v-text-field dense outlined placeholder="Write a description about the category"
                                        v-model="description" @input="description = capitalize(description)">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeModal()">
                        Cancel
                    </v-btn>
                    <v-btn :loading="loader" @click="CreateOrEditCategory()" class="text-capitalize white--text" color="#F2797B">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "NewCategoryModal",
    data() {
        return {
            category_name: "",
            description: "",
            flagForAddOrEdit: "",
            current_item_id: "",
            current_item_name: "",
            current_item_description: "",
            category_list: [],
            loader:false
        }
    },
    computed: {
        ...mapGetters({
            dialog: "dialog/getCategoryDrawer",
            get_category_list: "services/get_category_list",
            getAddOrEditCategory: "dialog/getAddOrEditCategory"
        })
    },
    watch: {
        get_category_list() {
            this.category_list = this.get_category_list
            // this.category_name = this.category_list.category_name
            // this.description = this.category_list.description
        },
        getAddOrEditCategory(val) {
            this.current_item_id = val.current_item_id
            this.current_item_name = val.current_item_name
            this.current_item_description = val.current_item_description
            if (val.title === "Add A Category") {
                this.flagForAddOrEdit = true
            }
            else if (val.title === "Edit Category") {
                this.category_name = this.current_item_name;
                this.description = this.current_item_description;
                this.flagForAddOrEdit = false
            }
        },
    },
    methods: {
        closeModal() {
            this.$store.dispatch("dialog/setCategoryDrawer")
            this.$refs.new_category_modal.reset();
        },
        CreateOrEditCategory() {
            if (this.flagForAddOrEdit == true) {
                this.CreateNewCategory();
            } else {
                this.EditCategory()
            }
        },
        CreateNewCategory() {
            if (this.$refs.new_category_modal.validate()) {
                this.loader = true
                let parlour_id = localStorage.getItem("parlour_id");
                let data = {
                    "parlour": parlour_id,
                    "category_name": this.category_name,
                    "description": this.description,
                }
                const successHandler = (response) => {
                    this.loader = false
                    this.$store.dispatch("dialog/setCategoryDrawer")
                    this.$store.dispatch("services/ListCategory")
                    this.showSnackBar(response.data.message, "#4CA955", 1500);
                    this.$refs.new_category_modal.reset();
                }
                const failureHandler = (error) => {
                    if (error && error.data && error.data.message) {
                        this.loader = false
                        this.showSnackBar(error.data.message, "#B3261E", 1500);
                    }
                }
                return this.$axios("post", this.$apiUrl.CREATE_CATEGORY, {
                    data,
                    onSuccess: successHandler,
                    onFailure: failureHandler,
                    isTokenRequired: true,
                });
            }
        },
        EditCategory() {
            let params = {
                id: this.current_item_id
            }
            let data = {
                "category_name": this.category_name,
                "description": this.description
            }
            const successHandler = (response) => {
                console.log("response of EditCategory api is ", response)
                this.$store.dispatch("dialog/setCategoryDrawer")
                this.$store.dispatch("services/ListCategory")
            }
            const failureHandler = (error) => {
                if (error && error.data && error.data.message) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("patch", this.$apiUrl.EDIT_CATEGORY, {
                params,
                data,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
    }
}
</script>
<style scoped>
label {
    color: black !important
}

.v-dialog>.v-card>.v-card__text {
    padding-bottom: 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 20px !important;
}
</style>
<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-sheet class="sheet">
        <v-card elevation="1">
          <v-card-title class="text-center">
            <v-icon color="black" @click="close_drawer"
              >mdi-close-circle</v-icon
            >
            <span class="ma-auto">New Appointment</span>
          </v-card-title>
        </v-card>
        <div>
          <v-form ref="new_appointment_drawer">
            <v-card elevation="1" class="mt-5 mx-2 pb-2">
              <v-card-text>
                <p class="header">CLIENT*</p>
                <v-divider></v-divider>
              </v-card-text>
              <v-autocomplete
                outlined
                clearable
                class="mx-3"
                dense
                :items="client_list"
                item-value="id"
                item-text="full_name"
                label="Client Name"
                placeholder="Select a client or create new"
                v-model="selectedClient"
                :rules="[$rules.required]"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-group>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.full_name }} - {{ item.mobile }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </template>
              </v-autocomplete>
              <p
                class="mx-4 client_text d-flex align-center jutify-center"
                @click="openCreateClientDialog"
              >
                <v-icon class="mr-2" color="primary">mdi-plus-circle</v-icon>Add
                Client
              </p>
            </v-card>
            <v-card class="mt-5 mx-2">
              <v-card-text>
                <div class="d-flex">
                  <p class="header">DATE & TIME OF APPOINTMENT*</p>
                  <v-spacer></v-spacer>
                  <!-- <p class="sub_text_2nd_card">Doesn't Repeat
                                </p> -->
                </div>
                <v-divider></v-divider>
              </v-card-text>
              <!-- Date Field -->
              <v-col cols="12" sm="6" md="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      outlined
                      dense
                      label="Appointment Date"
                      placeholder="Choose Appointment Date"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[$rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :active-picker.sync="activePicker"
                    @change="save"
                    event-color="#F2797B"
                    v-model="date"
                    no-title
                    scrollable
                    :min="currentDate()"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="#F2797B" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="#F2797B" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- Time Field -->
              <v-col cols="12" sm="6" md="12">
                <v-row class="d-flex">
                  <v-col md="3.5">
                    <v-autocomplete
                      v-model="hrs"
                      :items="hours"
                      outlined
                      dense
                      placeholder="HH"
                    ></v-autocomplete>
                  </v-col>
                  <v-col md="3.5">
                    <v-autocomplete
                      placeholder="MM"
                      v-model="mins"
                      :items="minutes"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col md="3.5" class="pt-2">
                    <v-btn-toggle
                      class="d-flex"
                      active-class="activeDiscBtn"
                      v-model="selectedAMPM"
                      rounded
                      mandatory
                    >
                      <v-btn class="rupee_dis_btn" value="AM">AM</v-btn>
                      <v-btn class="percent_dis_btn" value="PM">PM</v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
            <!--  -->
            <v-card class="mt-5 mx-2">
              <v-card-text>
                <p class="header">EXECUTIVES*</p>
                <v-divider> </v-divider>
              </v-card-text>
              <v-select
                clearable
                dense
                class="ma-2"
                outlined
                :items="executive_list"
                item-value="id"
                item-text="full_name"
                placeholder="Assign an Executive"
                v-model="selectedExecutive"
                :rules="[$rules.required]"
              >
              </v-select>
            </v-card>
            <!--  -->
            <v-card class="mt-5 mx-2">
              <v-card-text>
                <p class="header">SERVICES*</p>
                <v-divider></v-divider>
                <div
                  class=""
                  v-for="(item, index) in service_cart_list"
                  :key="index"
                >
                  <div class="d-flex align-center my-3">
                    <div>
                      <div>
                        <p class="mb-0 service_title">
                          {{ item.service_item.service_name }}
                        </p>
                        <p class="mb-2 service_sub_title">
                          {{ item.service_item.duration }}
                          {{ item.service_item.duration_type }}
                          <v-icon>mdi-circle-small</v-icon> ₹ {{ item.price }}
                        </p>
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn outlined text class="multiple_services">
                      <v-icon
                        dark
                        left
                        color="#F2797B"
                        @click="
                          addOrDeleteServices(item.quantity, 'minus', item.id)
                        "
                      >
                        mdi-minus-circle
                      </v-icon>
                      {{ item.quantity }}
                      <v-icon
                        dark
                        right
                        color="#F2797B"
                        @click="
                          addOrDeleteServices(item.quantity, 'add', item.id)
                        "
                      >
                        mdi-plus-circle
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions class="d-flex align-center mt-n2">
                <v-btn
                  text
                  dense
                  class="text_3rd_card text-capitalize"
                  :disabled="service_disable"
                  @click="openSelectServiceModalMob()"
                  ><v-icon class="icon_3rd_card" left>mdi-plus-circle</v-icon>
                  Add A Service</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
          <v-card class="mt-5 mx-2">
            <v-card-text>
              <p class="header">NOTES</p>
              <v-divider> </v-divider>
            </v-card-text>
            <v-text-field
              placeholder="Add an appointment note"
              class="ma-2 mx-4"
              v-model="notes"
              @input="notes = capitalize(notes)"
            ></v-text-field>
          </v-card>
        </div>
        <v-card class="fixed_bottom">
          <v-card-text cols="12">
            <v-btn
              block
              class="booking_btn text-capitalize"
              @click="createBooking()"
            >
              Save Appointment
            </v-btn>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-dialog>
    <clientModal></clientModal>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import clientModal from "../mobile_view/clientModalMob.vue";
export default {
  data() {
    return {
      name: "appointmentDrawerMob",
      menu: "",
      date: "",
      dateFormatted: "",
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
      selectedExecutive: "",
      advance_amount: 1000,
      notes: "",
      payment_mode: 1,
      selectedClient: "",
      client_list: [],
      executive_list: [],
      time: null,
      menu2: false,
      current_booking_id: null,
      number_of_service: 1,
      cart_list: [],
      filtered_services_id_list: [],
      service_disable: true,
      activePicker: null,
      hours: Array.from({ length: 12 }, (_, i) =>
        String(i + 1).padStart(2, "0")
      ),

      // minutes: [
      //   "00",
      //   ...Array.from({ length: 59 }, (_, i) => String(i + 1).padStart(2, "0")),
      // ],
      minutes: ["00", "15", "30", "45"],
      hrs: "",
      mins: "00",
      selectedAMPM: "",
    };
  },
  components: {
    clientModal,
  },
  computed: {
    ...mapGetters({
      dialog: "appointment/getAppointmentMobileDrawer",
      service_cart_list: "appointment/get_cart_list",
      new_client_id: "appointment/get_selected_client_id",
      enquiry_id: "appointment/getCurrentEquiryId",
    }),
    dialog_value: {
      get() {
        return this.dialog;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  watch: {
    new_client_id(val) {
      this.getClientList(val);
    },
    selectedClient(val) {
      if (val != undefined) {
        this.service_disable = false;
      }
      this.$store.dispatch("appointment/setSelectedClientData", val);
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    service_cart_list(val) {
      this.filtered_services_id_list = val.map((current_value) => {
        return current_value.id;
      });
    },
  },
  mounted() {
    this.getClientList();
    this.getExecutiveList();
  },
  methods: {
    convertTo24HourFormat(dateString, hours, minutes, ampm) {
      const [year, month, day] = dateString.split("-");
      const seconds = "00"; // Always set seconds to '00'
      if (ampm === "PM" && hours != 12) {
        hours = parseInt(hours) + 12;
      } else if (ampm === "AM" && hours === 12) {
        hours = 0;
      }
      return `${year}-${month}-${day}T${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds}`;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getCurrentTime() {
      const now = new Date();
      return `${now.getHours()}:${now.getMinutes()}`;
    },
    close_drawer() {
      this.$store.dispatch(
        "appointment/toggleCreateBookingMobileDrawer",
        false
      );
      // clear the data after closing
      this.$refs.new_appointment_drawer.reset();
      this.notes = "";
      this.$store.dispatch("appointment/clear_cart_list");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    currentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
      const day = String(today.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    openCreateClientDialog() {
      // let data = {
      //     toggle_flag: true,
      //     clientAddOrEditFlag: ""
      // }
      this.$store.dispatch("appointment/setNewClientDrawerMob", true);
    },
    getClientList(id) {
      this.selectedClient = id;
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        status: "all",
        search: "",
      };
      const successHandler = (response) => {
        this.client_list = response.data.client_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.GET_CLIENT_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    getExecutiveList() {
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
      };
      const successHandler = (response) => {
        this.executive_list = response.data.executive_list;
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("get", this.$apiUrl.LIST_EXECUTIVE, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    createBooking() {
      if (
        this.$refs.new_appointment_drawer.validate() &&
        this.filtered_services_id_list.length !== 0
      ) {
        this.loader = true;
        return new Promise((resolve, reject) => {
          let parlour_id = localStorage.getItem("parlour_id");
          let data = {
            parlour: parlour_id,
            client: this.selectedClient,
            appointment_date: this.date,
            appointment_start_time: this.convertTo24HourFormat(
              this.date,
              this.hrs,
              this.mins,
              this.selectedAMPM
            ),
            // "services": this.selectedCheckboxId,
            executive: this.selectedExecutive,
            note: this.notes,
            service_cart_item: this.filtered_services_id_list,
          };
          if (this.$route.name == "enquiries") {
            data.is_enquiry = true;
            data.enquiry_id = this.enquiry_id;
          }

          // In the success handler:
          const successHandler = (response) => {
            this.loader = false;
            this.current_booking_id = response.data.response.id;
            resolve(this.current_booking_id); // Resolve the promise with the booking ID
            let payload = {
              status: "upcoming",
            };
            this.$store.dispatch("appointment/getBookingList", payload);
            this.showSnackBar(response.data.message, "#4CA955", 1500);
            // clear the data after closing
            this.$store.dispatch(
              "appointment/toggleCreateBookingMobileDrawer",
              false
            );
            this.$refs.new_appointment_drawer.reset();
            this.notes = "";
            this.$store.dispatch("appointment/clear_cart_list");
            if (this.$route.name == "enquiries")
              this.$emit("reload-enquiry-list");
          };

          // In the failure handler:
          const failureHandler = (error) => {
            this.loader = false;
            reject(error);
            this.showSnackBar(error.data.message, "#B3261E", 1500);
          };

          // Call the API
          return this.$axios("post", this.$apiUrl.CREATE_BOOKING, {
            data,
            onSuccess: successHandler,
            onFailure: failureHandler,
            // Other configurations
          });
        });
      } else {
        this.showSnackBar("please fill necessary details", "#B3261E", 1500);
      }
    },
    openSelectServiceModalMob() {
      let payload = {
        parlour_id: "",
        client_id: this.selectedClient,
        search: "",
        booking_id: "",
      };
      this.$store.dispatch(
        "appointment/getListOfCategoriesAndServices",
        payload
      );
      let data = {
        flag: true,
        opened_from: "appointmentDrawer",
      };
      this.$store.dispatch("appointment/toogleSelectServiceDrawerMob", data);
    },
    Calculatedtime() {
      const combinedDateTime = `${this.date}T${this.time}:00`;
      return combinedDateTime;
    },
    addOrDeleteServices(quantity, action_name, service_id) {
      var updated_quantity;
      if (action_name === "minus" && quantity != 0) {
        updated_quantity = quantity - 1;
      } else if (action_name === "add") {
        updated_quantity = quantity + 1;
      }
      let params = {
        id: service_id,
      };
      let data = {
        quantity: updated_quantity,
      };
      const successHandler = () => {
        // call get api for cart
        let payload = {
          client_id: this.selectedClient,
          appointment: "",
        };
        this.$store.dispatch("appointment/get_service_cart_list", payload);
      };
      const failureHandler = (error) => {
        if (error && error.data && error.data.message) {
          this.showSnackBar(error.data.message, "#B3261E", 1500);
        }
      };
      return this.$axios("patch", this.$apiUrl.ADD_OR_DELETE_SERVICES, {
        params: params,
        data: data,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};
</script>

<style scoped>
.activeDiscBtn {
  color: #f2797b !important;
}
.v-dialog--fullscreen > .v-card {
  min-height: 0% !important;
}

.client_text {
  cursor: pointer !important;
}

.multiple_services {
}

.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-color: #f2797b;
}

.service_title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.service_sub_title {
  color: grey;
  font-weight: 400;
  font-size: 14px;
}

.service_cost {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.sheet {
  background-color: #fafafa !important;
}

.header {
  font-size: 12px;
  font-weight: 600;
}

.text_3rd_card {
  color: rgb(242, 121, 123);
  cursor: pointer;
}

.v-text-field .v-input__slot {
  border-radius: 200px !important;
}

.icon_3rd_card {
  color: rgb(242, 121, 123);
}

.booking_btn {
  background-color: rgb(242, 121, 123) !important;
  color: white !important;
}
</style>

//call api to get parlour details
import urls from "../../app-js/urls";
import axios from "../../app-js/axios";
export default {
  namespaced: true,
  state: {
    team_list: [],
    staff_detail: [],
    isTeamLoading: false,
  },
  getters: {
    get_team_list: (state) => state.team_list,
    get_staff_details: (state) => state.staff_detail,
  },
  mutations: {
    GET_TEAM_LIST_API(state, payload) {
      state.team_list = payload;
    },
    GET_STAFF_DETAILS_API(state, payload) {
      state.staff_detail = payload;
    },
    SET_LOADING(state, payload) {
      state.isTeamLoading = payload;
    },
  },
  actions: {
    //call api to get parlour details
    callTeamListApi({ commit }, payload) {
      commit("SET_LOADING", true);
      let parlour_id = localStorage.getItem("parlour_id");
      let params = {
        parlour_id: parlour_id,
        search: payload.searchQuery,
        status: payload.status,
      };
      const successHandler = (response) => {
        commit("SET_LOADING", false);
        let team_list = response.data.staff_list;
        commit("GET_TEAM_LIST_API", team_list);
      };
      const failureHandler = (error) => {
        if (error) {
          commit("SET_LOADING", false);
        }
      };
      return axios("get", urls.GET_TEAM_LIST, {
        params: params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
    callGetStaffDetails({ commit }, staff_id) {
      let params = {
        id: staff_id,
      };
      const successHandler = (response) => {
        let staff_detail = response.data.staff_detail;
        commit("GET_STAFF_DETAILS_API", staff_detail);
      };
      const failureHandler = () => {};
      return axios("get", urls.GET_STAFF_DETAIL, {
        params,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: true,
      });
    },
  },
};

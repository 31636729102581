<template>
    <v-row justify="center">
        <!-- New Number Modal -->
        <v-dialog v-model="drawer" persistent max-width="528" height="200">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Edit Phone Number</span>
                    <v-spacer></v-spacer>
                    <v-icon large @click="closeNewNumberModal">mdi-close</v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-form ref="new_number_dialog" @submit.prevent>
                            <v-row dense>
                                <v-col cols="12" sm="6" md="12">
                                    <div class="d-flex flex-column align-center justify-center">
                                        <p class="confirmation_question mt-3">Please enter the new phone number below</p>
                                    </div>
                                    <label class="confirmation_question">New Phone Number</label>
                                    <v-text-field dense outlined placeholder="Please enter mobile number" @keyup.enter="editNewNumber"
                                        :rules="[$rules.mobile_number]" class="md-mr-0 mr-2 phone_num_field" v-model="new_mobile"
                                        prepend-inner-icon>
                                        <template #prepend-inner>
                                            <v-img :src="selected_flag" class="flag_img"></v-img> &nbsp;
                                            {{ selected_phone_code }} &nbsp;
                                            <!-- <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item v-for="(item, index) in country_list" :key="index"
                                                        @click="selectPhoneCode(item)">
                                                        <v-list-item-title>{{ item.phone_code }} {{ item.name
                                                        }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu> -->
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="#F2797B" class="text-capitalize" @click="closeNewNumberModal">
                        Cancel
                    </v-btn>
                    <v-btn class="text-capitalize white--text" color="#F2797B" @click="editNewNumber">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "UserNewNumberDialog",
    data() {
        return {
            new_mobile: "",
            country_id: "103",
            address: "Raipur,Pandri",
            selected_phone_code: "+91",
            selected_flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
        }
    },
    computed: {
        ...mapGetters({
            drawer: "dialog/getnewNumDialog",
        })
    },
    mounted() {
        // this.getListOfPhoneCodes()
    },
    methods: {
        selectPhoneCode(item) {
            this.selected_phone_code = item.phone_code
            this.selected_flag = item.flag
            this.country_id = item.id
        },
        closeNewNumberModal() {
            this.$store.dispatch("dialog/setNewNumDialog", false)
            this.new_mobile = ""
        },
        editNewNumber() {
            let logged_in_user = localStorage.getItem("user_role")
            if (logged_in_user === "staff") {
                this.editStaffDetails()
            }
            else if (logged_in_user === "admin") {
                this.editParlourDetails()
            }
        },
        editParlourDetails() {
            if (this.$refs.new_number_dialog.validate()) {
                let params = {
                    "id": localStorage.getItem("parlour_id")
                }
                let data = new FormData()
                data.append("mobile", this.new_mobile)
                data.append("country", this.country_id)
                const successHandler = (response) => {
                    console.log("response of editParlourDetails is ", response)
                    this.$store.dispatch("dialog/setNewNumDialog", false)
                    this.new_mobile = ""
                    this.$store.dispatch("profile/setUpdateProfileFlag")
                }
                const failureHandler = (error) => {
                    if (error) {
                        this.showSnackBar(error.data.message, "#B3261E", 1500);
                    }
                }
                return this.$axios("patch", this.$apiUrl.EDIT_PARLOUR_DETAILS, {
                    data,
                    params,
                    onSuccess: successHandler,
                    onFailure: failureHandler,
                    isTokenRequired: true,
                });
            }
        },
        editStaffDetails() {
            let user_id = localStorage.getItem("user_id")
            let params = {
                id: user_id
            };
            let data = new FormData()
            data.append("mobile", this.new_mobile)
            data.append("country", this.country_id)
            const successHandler = (response) => {
                console.log("response of editStaffDetails api is ", response)
                this.$store.dispatch("dialog/setNewNumDialog", false)
                this.new_mobile = ""
                this.$store.dispatch("profile/setUpdateProfileFlag")
            }
            const failureHandler = (error) => {
                if (error) {
                    this.showSnackBar(error.data.message, "#B3261E", 1500);
                }
            }
            return this.$axios("patch", this.$apiUrl.EDIT_STAFF, {
                data,
                params,
                onSuccess: successHandler,
                onFailure: failureHandler,
                isTokenRequired: true,
            });
        },
    }
}
</script>
<style scoped>
.phone_num_field>>>.v-input__prepend-inner {
    display: flex;
    align-items: center;
}
.flag_img {
    width: 20px;
    height: 20px;
}

.confirmation_question {
    weight: 500;
    font-size: 16px;
    color: #39434D;
}
</style>